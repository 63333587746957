
import { apiCall } from "../services/api";
import {Alert} from 'rsuite'

export const getComments = ({triggerId,trigger})=>{
  const url = `${process.env.REACT_APP_API}/comments?trigger=${trigger}&triggerId=${triggerId}`
  
    return   apiCall('get',url)
      .then(list=>list)
      .catch(error=>Alert.error(error.message))
}
export const addComment = ({triggerId,trigger,text})=>{
  const url = `${process.env.REACT_APP_API}/comments?trigger=${trigger}&triggerId=${triggerId}`
  
    return   apiCall('post',url,{text})
      .then(list=>list)
      .catch(error=>Alert.error(error.message))
}
