const initState = {
  filters: {
    itemsPerPage: 10,
    currentPage: 1,
    businessclient: "",
    status: null,
    dateFrom: null,
    dateTo: null,
  },
  list: [],
  total: null,
  isLoading: false,

  servicesForExport: [],
  servicesForBilling: {foundServices:[]},
  billServicesTotal: 0,
  billServicesSummary: {foundServices:[]},

  bills: {
    filters: {
      itemsPerPage: 10,
      currentPage: 1,
      businessclient: "",
      status: null,
      dateFrom: null,
      dateTo: null,
    },
    list: [],
    total: null,
  },

  openBill: { bill: {}, countServices: null },

};

const services = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_FILTER":
      return { ...state, filters: { ...state.filters, ...action.payload } };

    case "GET_SERVICES_FOR_EXPORT":
      return {
        ...state,
        servicesForExport: action.payload,
        isLoading: false,
      };

  

      case "GET_BILLABLE_SERVICES":
      return {
        ...state,
        servicesForBilling: null,
        list: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };

    case "UPDATE_BILLS_FILTER":
      return {
        ...state,
        bills: {
          ...state.bills,
          filters: { ...state.bills.filters, ...action.payload },
        },
      };

    case "GET_BILLS":
      return {
        ...state,
        bills: {
          ...state.bills,
          list: action.payload.list,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case "GET_BILL":
      return {
        ...state,
        openBill: {...action.payload.bill,countServices:action.payload.countServices},

        isLoading: false,
      };

      case "GET_BILL_SERVICES":
        return {
          ...state,
          billServicesTotal: action.payload.total,
          billServicesSummary: {foundServices:action.payload.servicesSummary},        isLoading: false,
        };
    case "CLEAR_SERVICES_FOR_BILLING":
      return {
        ...state,
        servicesForBilling: null,
      };
    case "START_SERVICES_LOADING":
      return { ...state, isLoading: true };
    case "STOP_SERVICES_LOADING":
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default services;
