import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Main from "./Main";
import {Navbar,Dropdown,Icon,Nav,Sidenav,Container,Sidebar,Content} from 'rsuite'
import {Link} from 'react-router-dom'
import TopNavUser from '../components/Sidebar/TopNavUser'
import lang from '../lang/config.json'
import Responsive from "../components/Responsive";
import { isMobile } from "react-device-detect";

// console.log("isMobile:", isMobile, localStorage.jwtToken);

const headerStyles = {
  padding: 18,
  fontSize: 16,
  height: 56,
  background: '#34c3ff',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
};

const NavToggle = ({ expand, onChange }) => {

  return (
    <Navbar appearance="subtle" className="nav-toggle">
      
      <Navbar.Body>
      {/* <Nav>
        <Dropdown
          placement="topStart"
          trigger="click"
          renderTitle={children => {
            return <Icon style={iconStyles} icon="cog" />;
          }}
        >
    
          <Dropdown.Item>Sign out</Dropdown.Item>
        </Dropdown>
      </Nav> */}

        <Nav pullRight>
          <Nav.Item onClick={onChange} style={{ width: 56, textAlign: 'center' }}>
            <Icon icon={expand ? 'angle-left' : 'angle-right'} />
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

const MobileTopBar = ({ showMenu, setShowMenu }) => (
  <Navbar>
    <Navbar.Body>
      <div className="flex--between">
        <div>
          <Nav>
            {!showMenu && (
              <Nav.Item>
                <Icon
                  onClick={() => setShowMenu()}
                  icon="bars"
                  size="2x"
                ></Icon>
              </Nav.Item>
            )}
            {showMenu && isMobile && (
              <Nav.Item>
                <Icon
                  onClick={() => setShowMenu()}
                  icon="angle-double-left"
                  size="2x"
                ></Icon>
              </Nav.Item>
            )}
          </Nav>
        </div>
        <div style={{ margin: "5px" }}>
          <Responsive
            mobileComponent={<TopNavUser mobile={isMobile} />}
            desktopComponent={<TopNavUser mobile={isMobile} />}
          ></Responsive>
        </div>
      </div>
    </Navbar.Body>
  </Navbar>
);


const MainWithNav =(props)=>{
const {user}=props

  
  const [showMenu,setShowMenu] = useState(true)
  const [expand,setExpand] = useState(true)
  // const [mobile,setMobile] = useState(isMobile)

  // const setWithFunct = () => {
  //   const width = window.innerWidth;
  //   if(width>600&&expand) {
  //     setMobile(false)
  //     setShowMenu(true)
  //   }
  //   // if(width<=1000&&expand) setExpand(false)

  // };


  useEffect(() => {
    // setWithFunct();
    
   return  ()=>{
    if (localStorage.jwtToken) setShowMenu(false)}
      
  },[]);
  
  // window.addEventListener("resize", setWithFunct);

  if(!isMobile&&!localStorage.jwtToken) return <Main></Main>

    return (
      <div className="show-fake-browser sidebar-page">
        <Container>
          {showMenu && (
            <Sidebar
              style={{ display: "flex", flexDirection: "column" }}
              width={expand ? 210 : 56}
              collapsible
            >
              <Sidenav
                expanded={expand}
                defaultOpenKeys={["4", "9"]}
                appearance="default"
              >
                <Sidenav.Header>
                  <div style={headerStyles}>
                    <Icon
                      icon="logo-analytics"
                      size="lg"
                      style={{ verticalAlign: 0 }}
                    />
                    <span style={{ marginLeft: 12 }}> WePack</span>
                  </div>
                </Sidenav.Header>
                <Sidenav.Body>
                  <Nav>
                    {/* <Nav.Item eventKey="1" active icon={<Icon icon="dashboard" />}>
                    Dashboard
                  </Nav.Item> */}

                    <Nav.Item
                      onSelect={() => {
                        if (isMobile) {
                          
                          setShowMenu(false)};
                      }}
                      eventKey="2"
                      icon={<Icon icon="list" />}
                    >
                      <Link to="/orders">
                        {" "}
                        <span>
                          {
                            lang.leftnav.menulist.item1[
                              user.currentUser.sys_lang
                            ]
                          }
                        </span>
                      </Link>
                    </Nav.Item>

                    <Nav.Item
                      onSelect={() => {
                        if (isMobile) setShowMenu(false);
                      }}
                      eventKey="2"
                      icon={<Icon icon="file" />}
                    >
                      <Link to="/invoices">
                        {" "}
                        <span>
                        
                          {
                            lang.leftnav.menulist.item8[
                              user.currentUser.sys_lang
                            ]
                          }
                        </span>
                      </Link>
                    </Nav.Item>

                    <Nav.Item
                      onSelect={() => {
                        if (isMobile) setShowMenu(false);
                      }}
                      eventKey="3"
                      icon={<Icon icon="building" />}
                    >
                      <Link to="/warehouse">
                        {" "}
                        <span>
                          {
                            lang.leftnav.menulist.item2[
                              user.currentUser.sys_lang
                            ]
                          }
                        </span>
                      </Link>
                    </Nav.Item>

                     <Nav.Item
                      onSelect={() => {
                        if (isMobile) setShowMenu(false);
                      }}
                      eventKey="7"
                      icon={<Icon icon="bars" />}
                    >
                      <Link to="/products">
                        {" "}
                        <span>
                          {" "}
                          {
                            lang.leftnav.menulist.item3[
                              user.currentUser.sys_lang
                            ]
                          }
                        </span>
                      </Link>
                    </Nav.Item> 

                    <Dropdown
                      eventKey="9"
                      trigger="hover"
                      title={
                        lang.leftnav.menulist.item7[user.currentUser.sys_lang]
                      }
                      icon={<Icon icon="truck" />}
                      placement="rightStart"
                      onClick={() => setExpand(true)}
                    >
                      <Link to="/shipments/inbound">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="9-1"
                        >
                          {" "}
                          <span>
                            {
                              lang.leftnav.menulist.item9.item1[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                      <Link to="/parcels/inbound">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="9-2"
                        >
                          <span>
                            {
                              lang.leftnav.menulist.item9.item2[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                    </Dropdown>
                    <Dropdown
                      eventKey="4"
                      trigger="hover"
                      title={
                        lang.leftnav.menulist.item4[user.currentUser.sys_lang]
                      }
                      icon={<Icon icon="money" />}
                      placement="rightStart"
                      onClick={() => setExpand(true)}
                    >
                      <Link to="/billing/bills">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="4-1"
                        >
                          {" "}
                          <span>
                            {
                              lang.leftnav.menulist.item4.item1[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                      <Link to="/transfers">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="4-2"
                        >
                          <span>
                            {
                              lang.leftnav.menulist.item4.item2[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                      <Link to="/refunds">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="4-2"
                        >
                          <span>
                            {
                              lang.leftnav.menulist.item4.item4[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                      {/* <Link to='/billing/services'><Dropdown.Item onSelect={()=>{if(isMobile)setShowMenu(false)}} eventKey="4-3"><span >{lang.leftnav.menulist.item4.item3[user.currentUser.sys_lang]}</span></Dropdown.Item></Link>  */}
                    </Dropdown>

                    <Dropdown
                      eventKey="6"
                      trigger="hover"
                      title={
                        lang.leftnav.menulist.item5[user.currentUser.sys_lang]
                      }
                      icon={<Icon icon="line-chart" />}
                      placement="rightStart"
                    >
                      <Link to="/reports/nogoods">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="6-1"
                        >
                          <span>No goods</span>
                        </Dropdown.Item>
                      </Link>
                      <Link to="/reports/stockturnover">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="6-1"
                        >
                          <span>
                            {
                              lang.leftnav.menulist.item5.item1[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link>
                      {/* <Link to="/reports/invoices">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="6-2"
                        >
                          <span>
                            {
                              lang.leftnav.menulist.item5.item2[
                                user.currentUser.sys_lang
                              ]
                            }
                          </span>
                        </Dropdown.Item>
                      </Link> */}
                    </Dropdown>

                    <Dropdown
                      eventKey="5"
                      trigger="hover"
                      title={
                        lang.leftnav.menulist.item6[user.currentUser.sys_lang]
                      }
                      icon={<Icon icon="gear-circle" />}
                      placement="rightStart"
                    >
                      <Link to={`/profile/${user.currentUser.businessclient}`}>
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="5-1"
                        >
                          {
                            lang.leftnav.menulist.item6.company[
                              user.currentUser.sys_lang
                            ]
                          }
                        </Dropdown.Item>
                      </Link>
                      <Link to="/eshops">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="5-1"
                        >
                          {
                            lang.leftnav.menulist.item6.item1[
                              user.currentUser.sys_lang
                            ]
                          }
                        </Dropdown.Item>
                      </Link>
                      <Link to="/couriers">
                        <Dropdown.Item
                          onSelect={() => {
                            if (isMobile) setShowMenu(false);
                          }}
                          eventKey="5-2"
                        >
                          {
                            lang.leftnav.menulist.item6.item2[
                              user.currentUser.sys_lang
                            ]
                          }
                        </Dropdown.Item>
                      </Link>
                    </Dropdown>
                  </Nav>
                </Sidenav.Body>
              </Sidenav>
              <NavToggle expand={expand} onChange={() => setExpand(!expand)} />
            </Sidebar>
          )}
          <Container>
            <Content>
              <MobileTopBar
                isMobile={isMobile}
                showMenu={showMenu}
                setShowMenu={() => setShowMenu(!showMenu)}
                user={user}
              ></MobileTopBar>

              <Main></Main>
            </Content>
          </Container>
        </Container>
      </div>
    );
  
}

const mapStateToProps = state => ({
  user: state.user
});

export default withRouter(connect(mapStateToProps, {})(MainWithNav));
