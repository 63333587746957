import React from "react";
import { Input } from "semantic-ui-react";
import { connect } from "react-redux";

import { updateFilters } from "../../../actions/couriers";
import CountryFilter from "../../Filters/CountryFilter";

function Filters({ filters, updateFilters, isLoading }) {
  const handleUpdateFilters = (e, { name, value }) =>
    updateFilters({ [name]: value });

  // const handleSearch = (e, { value }) => setSearchTerm(value);
  return (
    <section className="courier-filters">
      <Input
        autoComplete="nope"
        className="courier-filters__search"
        loading={isLoading}
        placeholder="Search..."
        icon="search"
        name="name"
        value={filters.name}
        onChange={handleUpdateFilters}
      />

      <CountryFilter
        multiple={true}
        className="courier-filters__country"
        value={filters.country}
        handleChange={handleUpdateFilters}
      />
    </section>
  );
}

const mapStateToProps = (reduxState) => ({
  filters: reduxState.couriers.filters,
});

export default connect(mapStateToProps, { updateFilters })(Filters);
