import React,{useState,useEffect} from 'react'
import {Input,Modal,Button,Message} from 'rsuite'
import './styles.scss'

const config = {
    wisper:{
        orders:{cz:"Pocet mesicnich objednavek"}
    }
}

const CostcalculatorRo =(props)=>{


const [countOrders, setCountOrders]                 = useState(1000)
const [headcount,setHeadcount]                      = useState(1)
const [headCost,setHeadCost]                        = useState(2000)
const [totalHeadCost,setTotalHeadCost]              = useState(2000)
const [rent,setRent]                                = useState(12000)
const [directMaterial,setDirectMaterial]            = useState(1.25)
const [directMaterialTotal,setDirectMaterialTotal]  = useState(1.25)
const [totalCost,setTotalCost]                      = useState(Math.ceil(totalHeadCost+directMaterialTotal+rent))
const [offer,setOffer]                              = useState(3.25)

useEffect(()=>{
   setHeadcount(Math.ceil((countOrders/19/150))) 
   setDirectMaterialTotal(Math.ceil(directMaterial*countOrders))
   setTotalHeadCost(Math.ceil(Math.ceil(headCost*headcount)))

   if(countOrders<=1000)                    { setRent(400)
                                              setOffer(3.25)}
   if(countOrders>1000 && countOrders<=4000) {setRent(1200)
                                            setOffer(2.5)}
   if(countOrders>4000) {setRent(3500)
                        setOffer('individual')}
   
},[countOrders,directMaterial,headCost])

useEffect(()=>{
        setTotalHeadCost(Math.ceil(headCost*headcount))
   
},[headcount])

useEffect(()=>{

   setTotalCost((totalHeadCost+directMaterialTotal+rent*1))
   
},[rent,directMaterialTotal,totalHeadCost])


const message =<Message
                        type="info"
                        style={{marginTop:'10px'}}
                        // title=""
                        description={
                                    <div>
                                       

                                    <div className='flex--between' style={{ fontSize:'18px'}}>
                                        <h5>Cost/comanda</h5>
                                        <div>{(totalCost/countOrders)} Lei</div>
                                    </div>
                                    <div className='flex--between' style={{color:'green'}} >
                                        <h5>Oferta noastra</h5>
                                        <div style={{color:'green',fontSize:'20px',textWeight:'bold'}}>{offer} Lei</div>
                                    </div>
                                                                    </div>
                                }
                                />

 const body =  (
    <div style={{width:'500px',margin:'20px'}}>
    
        <div >
            <label>Nr. comenzi/luna</label>
            <Input style={{ width: 170 }} value = {countOrders} onChange={(value)=>setCountOrders(value)}></Input>
        </div>
        <div>  
              
            <div className='flex--between'>
                
                <div>
                        <label>Nr. angajati</label>
                            <Input value = {headcount} onChange={(value)=>setHeadcount(value)}></Input>
                        </div>  
                        <div>
                            <label>Costuri pe angajat incl. asigurarile de stat</label>
                            <Input value = {headCost} onChange={(value)=>setHeadCost(value)}></Input>            
                        </div>
                
                </div> 
    
            <div className='flex--between totals' style={{marginTop:'5px'}}>
                <label>Total </label>
               <p >{totalHeadCost} Lei</p> 
            </div>

      
            <div >
                <label>Consumabile/comanda</label>
                
                  <div className='flex--between totals'>
                      <Input style={{ width: 160 }} value = {directMaterial} onChange={(value)=>setDirectMaterial(value)}></Input>
                        <label>Total </label>
                        {directMaterialTotal} Lei
                </div>
            </div>
            
 
          
            
            <div style={{marginTop:'5px'}}>
                <label>Chirie depozit/lunar</label>
                <Input type='number'value = {rent} onChange={(value)=>setRent(value)}></Input>
            </div>
        </div>
         <div className='flex--between totals'style={{marginTop:'10px'}}>
            <h5>Costuri Total:</h5>
            <div>{totalCost} Lei</div>
        </div>
         {/* <div className='flex--between totals'>
            <h5>Náklad na objednávku:</h5>
            <div>{Math.ceil(totalCost/countOrders)} Kč</div>
        </div>
          <div className='flex--between totals'>
            <h5>Naše cenová nabídka</h5>
            <div>26 Kč</div>
        </div> */}
        {message}

    </div>)
    
    const modal = <div className="modal-container">
  
            <Modal  show={true} onHide={()=>{}}>
                <Modal.Title>Calculator Costuri Fulfilment</Modal.Title>
            <Modal.Body>
             {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>{}} appearance="primary">
               <a href='http://www.wyzbox.com' style={{color:'white'}}>Sunt interesat</a> 
                </Button>
                
            </Modal.Footer>
            </Modal>
        </div>

return modal

}

export default CostcalculatorRo