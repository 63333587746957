import React, { useState,useEffect } from "react";
import {
  Segment,
  Header,
  Button,
  Pagination,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  
  getAllInventory,
  searchSku,
  // updateFilter,
} from "../../actions/inventory";
import {getBalanceAtDate} from '../../actions/inventory'
import {updateFilters} from '../../actions/filtersmain'

// import CanViewPage from "../../role-based-access/CanViewPage";
// import CanView from "../../role-based-access/CanView";
import ItemsPerPage from "../ItemsPerPage";
import ExportButton from "./ExportButton";
import { Inventorylist } from "./inventorylist";
import Filters from "./filters";
import "./inventory.scss";
import Responsive from "../Responsive.jsx";
import CardsList from "../CardsList.jsx";
import moment from 'moment'
import {DatePicker} from 'rsuite'

const Stocks =({updateFilters,filters})=> {
  
  const [inventory,setInventory]           = useState([])
  const [totals,setTotals]                 = useState(0)
  const [isLoading,setIsloading]           = useState(false)
  const [atDate,setDateAt]                 = useState(new Date())
  const [balanceView,setBalanceView]       = useState(false)
  const [currentPage,setCurrentpage]       = useState(1)
  const [itemsPerPage,setItemsPerPage]     = useState(10)
  const [sortTotal,setSortTotal]           = useState(-1)
  const [sortName,setSortName]             = useState(-1)

  // const [filters,updateFilter]   = useState({
  //                                           businessclients:'',
  //                                           warehouse:     [],
  //                                                           })

  const [state,setState]         = useState({
                                            showModalCount: false,
                                            checkedItems: [],
                                            headerIsChecked: false,
                                                })
    

  const showModal=()          => setState({ showModalCount: !state.showModalCount })
          

  
 const handleUpdateFilter=(e, { value, name }) =>{
      
  if(name==='productSKU') {
     if(value) updateFilters({ ...filters,[name]: value ,products: [] })
          else updateFilters({ ...filters,[name]: null ,products: [] })
      
     return
  } 
  if(name==='warehouse'){
    
    //removing warehouses return to non balance at date view data
    if(value&&value.length===0) setBalanceView(false)
    updateFilters({ [name]: value });
    return
  }
    updateFilters({[name]: value });
    setCurrentpage(1)
  }

// const handleGetBalances=()=>{

//   setBalanceView(true)
//   const products = inventory.map(item=>item.product_details[0]._id)
//     setIsloading(true)
//     getBalanceAtDate({
//         products:products,
//         warehouse:filters.warehouse,
//         atDate,
//       })
//     .then(resp=>{
//             setInventory(resp.list)
//             setTotals(resp.totals)
//               setIsloading(false)

//     })
//                               }

useEffect(()=>{
// console.log('ping',balanceView)
  setIsloading(true)

   if(!balanceView)   getAllInventory({...filters,itemsPerPage,currentPage,sortTotal,sortName})
                        .then(resp=>{
                              setIsloading(false)
                              setInventory(resp.list)
                              setTotals(resp.totals)
                    
                    }).catch(error=>      {
                          setIsloading(false)
                          setInventory([])
                        }
)  
  
  
  // props.getProductGroups();
},
[filters,balanceView,itemsPerPage,currentPage,sortTotal,sortName]
)

useEffect(()=>{
    const today = new Date();
    const time1 = moment(today) .format('YYYY-MM-DD');
    const time2 = moment(atDate).format('YYYY-MM-DD');

  if(time1!=time2)   {
      setBalanceView(true)
      if(inventory)
       { 
         const products = inventory.map(item=>{if(item.product_details) return item.product_details._id})
        setIsloading(true)
        getBalanceAtDate({
            products:   products,
            warehouse:  filters.warehouse,
            atDate,
          })
        .then(resp=>{
                setInventory(resp.list)
                setTotals(resp.totals)
                setIsloading(false)

        })}
      }  

},[atDate])

    return (
      // <CanViewPage path="/warehouse">
        <div className="inventory">
          <div className="flex_spaceBetween">
            <Header as="h3">Stock Balances</Header>
            <div className="flex_alignRight">
              {/* <CanView path="/warehouse" action="create new product"> */}
                <Link to="/products/create/new">
                  <Button color="blue">+Product</Button>
                </Link>
              {/* </CanView> */}
        
            </div>
          </div>
        
          <Filters
            filters={filters}
            handleUpdateFilter={handleUpdateFilter}
          />

          <Segment padded>
            <div className="flex--between" style={{ marginBottom: "1em" }}>
          
            {/* <CanView path="/warehouse" action="export"> */}

              <ExportButton
                    inventory={inventory}
                    filename ={`stockbalance_${moment(new Date())}`}
                  />
        {/* </CanView>           */}
              <div className="flex--left">
                <div>
                 <DatePicker
                    disabled={filters.warehouse.length===0}
                    style={{width:'200px',marginRight:"5px"}}
                      oneTap
                      placeholder="date to.."
                      value={atDate}
                      format="DD-MM-YYYY"
                      // ranges={[dateFrom,atDate]}
                      onChange={(atDate) => {if(!atDate){
                                                    setDateAt(new Date()) 
                                                    setBalanceView(false)
                                                    }
                                                        else {
                                                          setDateAt(atDate)
                                                          // handleGetBalances()
                                                          }
                                                      
                                                            }}
                    />
                  {filters.warehouse.length===0&&<div className="smallinfo">*select warehouse to activate(beta)</div>}
                   </div>
              

                  <ItemsPerPage
                    style={{maxHeight:'30px'}}
                    disabled={balanceView}
                    total={totals}
                    value={itemsPerPage}
                    handleChange={(e, d) => {
                          // if(d.value==products.total) setCurrentpage(1)
                        setItemsPerPage(d.value);
                    }}
                  />
             
                    
              </div>
          
            </div>
            <Responsive
              mobileComponent={
                <CardsList
                  items={inventory}
                  isLoading={isLoading}
                  notFoundMsg="Nothing was found"
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                >
                </CardsList>
              }
              desktopComponent={
                <Inventorylist
                  inventory={inventory}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  sortTotal={sortTotal}
                  setSortTotal={setSortTotal}
                  isLoading={isLoading}
                />
              }
            />

            {/* {totals > filters.itemsPerPage && ( */}
              <Pagination
                style={{ marginTop: "1em" }}
                activePage={currentPage}
                totalPages={Math.ceil(totals /itemsPerPage)}
                boundaryRange={1}
                // siblingRange={1}
                firstItem={null}
                lastItem={null}
                  onPageChange={(e,data)=>setCurrentpage(data.activePage)}

              />
            {/* )} */}
          </Segment>
        </div>
      // </CanViewPage>
    )
}

function mapStateToProps(ReduxState) {
  return {
    filters:ReduxState.filtersmain,
    productgroups:  ReduxState.inventory.productGroups,
    
    
  };
}

export default connect(mapStateToProps, {
  // ,
  searchSku,  
  updateFilters,
  
})(Stocks);
