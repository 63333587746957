import React from "react";
import { Segment,  Header } from "semantic-ui-react";
import lang from '../../../lang/config.json'

const StockAvailable = ({ stockTotals = [],user }) => {
  const products = stockTotals.map(item => (
    <tr>
      <td>{item.warehouse?item.warehouse.name:'test'}</td>
      <td>{item.available}</td>
      <td>{item.blocked}</td>
      <td>{item.total}</td>
    </tr>
  ));

  return (
    <div className="card_">
      <Segment>
        <Header as="h4">
        {lang.products.viewproduct.stockbalances[user.currentUser.sys_lang]}

        </Header>
        {/* <Divider /> */}
        <table className="stock-available-table">
          <thead>
            <tr>
              <th>{lang.products.viewproduct.warehouse[user.currentUser.sys_lang]}</th>
              <th>{lang.products.viewproduct.available[user.currentUser.sys_lang]}</th>
              <th>{lang.products.viewproduct.blocked[user.currentUser.sys_lang]}</th>
              <th>{lang.products.viewproduct.total[user.currentUser.sys_lang]}</th>
            </tr>
          </thead>
          <tbody>{products}</tbody>
        </table>
      </Segment>
    </div>
  );
};

export default StockAvailable;
