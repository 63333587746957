import React,{useEffect,useState} from 'react'
import axios from 'axios'
import {Toggle,Button,Divider,Alert} from 'rsuite'
import {setAuthorizationToken} from '../../../actions/auth'
import {Segment,Label} from 'semantic-ui-react'
import {Link} from 'react-router-dom'
import lang from '../../../lang/config.json'
import {connect} from 'react-redux'


const Shoptetsettings = (props)=>{

    const  {id} = props.match.params
    const {syslang} =props

    const [shoptet_oauthaccesstoken,setOauthToken] =useState()
    const [shoptet_eshopId,setShoptetId] =useState()
    const [businessclient,setBusinessclient] =useState()
    const [webHooks,setWebHooks] = useState([])
    
    const [eshop,setEshop] = useState({
            shoptet_settings:{
                import_orders_all:false,
                import_orders_only_selected_products:false
            }
    })

    const [importOrders,setimportOrders]=useState(false)
    
    const [importingProducts, setImportdingproducts] = useState(false)
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getEshop =async ()=>{
        try {
            const eshop = await axios.get(`${process.env.REACT_APP_API}/settings/eshops/${id}`)

            setEshop(eshop.data)

        } catch (error) {
            Alert.error(error.message)
        }
    }

    const getOauth =()=>{
        const authUrl=`${process.env.REACT_APP_API}/settings/eshops/${id}/auth`
            axios.get(authUrl)
            .then(resp=>{
                setOauthToken(resp.data.shoptet_oauthaccesstoken)
                setShoptetId(resp.data.shoptet_eshopId)
                setBusinessclient(resp.data.businessclient)
            })
            .catch(error=>{
                setAuthorizationToken(false)
                localStorage.clear();
                props.history.push(`/`)
            }
            )
       
    }

    const getWebhooks =()=>{
        const url=`${process.env.REACT_APP_ADDONS_API}/shoptet/settings/webhooks?shoptet_eshopId=${shoptet_eshopId}&shoptet_oauthaccesstoken=${shoptet_oauthaccesstoken}`
                axios.get(url)
                .then(resp=>{
                    setWebHooks(resp.data.data.webhooks)
                    resp.data.data.webhooks.length>0?setimportOrders(true):setimportOrders(false)
                })
                .catch(error=>{
                    Alert.error(error.message)
                    
                })
    }
    
 
    const addOrderWebhooks =()=>{
        const url=`${process.env.REACT_APP_ADDONS_API}/shoptet/settings/webhooks?shoptet_eshopId=${shoptet_eshopId}&shoptet_oauthaccesstoken=${shoptet_oauthaccesstoken}`
        
        const body = {
                    data:[
                    {  event:'order:create',
                        url:`${process.env.REACT_APP_ADDONS_API_PROD}/shoptet/webhookevents`},
                    { event:'order:update',
                        url:`${process.env.REACT_APP_ADDONS_API_PROD}/shoptet/webhookevents`},
                    { event:'order:delete',
                        url:`${process.env.REACT_APP_ADDONS_API_PROD}/shoptet/webhookevents`}
                    ]
                }

                axios.post(url,body)
                .then(resp=>{
                    
                    setWebHooks(resp.data.data.webhooks)
                    setimportOrders(true)
                }
                    )
                .catch(err=>alert(err.message))
            
                }

    const removeOrderWebhooks =()=>{
        for(let i=0;i<webHooks.length;i++){
            const url=`${process.env.REACT_APP_ADDONS_API}/shoptet/settings/webhooks/${webHooks[i].id}?shoptet_eshopId=${shoptet_eshopId}&shoptet_oauthaccesstoken=${shoptet_oauthaccesstoken}`
            axios.delete(url)
            .then(resp=>{
                setWebHooks([])
                setimportOrders(false)
            })
            .catch(err=>alert(err.message))
            
        }
    }


    const importProducts=async ()=>{
        try {
            let page  =1
            let itemsPerPage=10     
            const importUrl = `${process.env.REACT_APP_ADDONS_API}/shoptet/products?&page=${page}&itemsPerPage=${itemsPerPage}&businessclient=${businessclient}&shoptet_eshopId=${shoptet_eshopId}&shoptet_oauthaccesstoken=${shoptet_oauthaccesstoken}`    

            setImportdingproducts(true)

            await axios.get(importUrl)
            
            setImportdingproducts(false)
            
            Alert.success('All products have been imported succesfully')

        } catch (error) {
            setImportdingproducts(false)
            Alert.error(error.message,5000)
            // console.log(error)
        }
    }
    const getProductUpdates=async ()=>{
        try {
            
            const importUrl = `${process.env.REACT_APP_ADDONS_API}/shoptet/products?businessclient=${businessclient}&shoptet_eshopId=${shoptet_eshopId}&shoptet_oauthaccesstoken=${shoptet_oauthaccesstoken}`    

            setImportdingproducts(true)

            await axios.put(importUrl)
            
            setImportdingproducts(false)
            
            Alert.success('All products have been updated succesfully')

        } catch (error) {
            setImportdingproducts(false)
            Alert.error(error.message,5000)
            // console.log(error)
        }
    }

    const updateEshop =async (query)=>{
        try {
            const url=`${process.env.REACT_APP_API}/settings/eshops/${id}`

            const updated = await axios.put(url,query)
            setEshop(updated.data)
            Alert.success("Updated")
        } catch (error) {
            Alert.error(error.message,5000)
        }
    }
    

    useEffect(()=>{
        if(shoptet_oauthaccesstoken&&shoptet_eshopId) getWebhooks()
          
    },[shoptet_oauthaccesstoken, shoptet_eshopId])


    useEffect(()=>{
      
        getEshop()
        
        if(!shoptet_oauthaccesstoken)  getOauth()


    },[ props.match.params.id])

    
    if(!shoptet_oauthaccesstoken) return (<div>loading...</div>)

    return (
        <div style={{margin:'10px 0 0 40px',maxWidth:'500px'}}>
            {eshop.isActive&&<Label color='green' ribbon>{lang.settings.eshops.shoptet.connectedstatus[syslang]}</Label>}
            {!eshop.isActive&&<Label color='red' ribbon>{lang.settings.eshops.shoptet.disconnectedstatus[syslang]}</Label>}
            <div>
                <div className='flex--between'>
                    
                    <h1>{lang.settings.eshops.shoptet.title[syslang]}</h1>
                    
                </div>
                <div><Toggle 
                                
                                checked={eshop.isActive}
                                onChange={()=>{
                                    if(eshop.isActive) removeOrderWebhooks()
                                    updateEshop({isActive:!eshop.isActive})
                                }}
                            ></Toggle>
                        <span style={{marginLeft:'10px'}}>
                            {lang.settings.eshops.shoptet.eshopconnection[syslang]}
                            </span></div>
                    
               </div>
              
              
              <Segment disabled={!eshop.isActive}>            
                             
            <section>
                <h4>{lang.settings.eshops.shoptet.productsettings[syslang]}</h4>
                
                <Divider></Divider>
                <div >
                    <div className='flex--between' style={{margin:'10px',marginBottom:"20px"}}>
                            <lable >{lang.settings.eshops.shoptet.importallproducts[syslang]}</lable>
                        
                        
                            <Button
                                // color='green'
                                disabled={importingProducts}
                                
                                loading={importingProducts}
                                onClick={()=>importProducts()}

                                > {!importingProducts?'Start':'Importing...'}</Button>  
                        
                        {/* {importPorudctDone&& <td> - Hotovo!</td>} */}
                    </div>
                    <div className='flex--between' style={{margin:'10px',marginBottom:"20px"}}>
                    <lable >{lang.settings.eshops.shoptet.updateproducts[syslang]}</lable>

                    <Button
                                // color='blue'
                                disabled={importingProducts}
                                
                                loading={importingProducts}
                                onClick={()=>getProductUpdates()}

                                > {!importingProducts?'Start':'updating...'}</Button>  

                    </div>
                    </div>
                    
                </section>

             
            
            </Segment>
            
            <Segment disabled={!eshop.isActive}>
                    <h4>{lang.settings.eshops.shoptet.ordersettings.title[syslang]}</h4>
                    <Divider></Divider>
                        <div style={{marginBottom:'10px'}}>

                            <Toggle
                                checked={importOrders}
                                onChange={()=>{
                                        if(importOrders) removeOrderWebhooks()
                                        if(!importOrders) addOrderWebhooks()
                                }}
                            ></Toggle>
                            <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importorders[syslang]}</span>
                            
                        </div>
                            <div style={{marginBottom:'10px'}}>                            <Toggle
                                disabled={!importOrders}
                                checked={eshop.shoptet_settings.import_orders_all}
                                onChange={()=>{
                                    updateEshop({
                                        'shoptet_settings.import_orders_all':!eshop.shoptet_settings.import_orders_all,
                                        'shoptet_settings.import_orders_only_selected_products':eshop.shoptet_settings.import_orders_all===false?false:eshop.shoptet_settings.import_orders_only_selected_products,
                                            })
                                }}
                            ></Toggle>
                            <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importallorders[syslang]}</span>
                        </div>
                        
                        <div style={{marginBottom:'10px'}}>
                        <Toggle 
                                disabled={!importOrders}
                                checked={eshop.shoptet_settings.import_orders_only_selected_products}
                                onChange={()=>{
                                    updateEshop({
                                        'shoptet_settings.import_orders_only_selected_products':!eshop.shoptet_settings.import_orders_only_selected_products,
                                        'shoptet_settings.import_orders_all':eshop.shoptet_settings.import_orders_only_selected_products===false?false:eshop.shoptet_settings.import_orders_all,
                                            })
                                }}
                            ></Toggle>
                        <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importordersselected[syslang]} <Link to='/products'>{lang.table.settings[syslang]}</Link></span>

                        </div>
                        
                        {/* <div>
                        <Toggle
                                checked={importOrders_selected}
                                onChange={()=>{
                                        
                                }}
                            ></Toggle>
                                                        <span style={{marginLeft:'10px'}}>Import only if "Goods Available"</span>

                        </div> */}
                </Segment>
               <div className='flex--between'>
                   <div></div>
                   <Button color='blue' onClick={()=>props.history.push('/orders')}>{lang.settings.eshops.shoptet.buttondone[syslang]} </Button>
               </div>
               
               
            
    </div>
    )


  

}
function mapStatetoProps(reduxstate){
    return ({
        syslang:reduxstate.user.currentUser.sys_lang
    })
}
export default connect(mapStatetoProps,{})(Shoptetsettings)