import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import CRM from './containers/CRM';

// eslint-disable-next-line no-unused-vars
// import * as serviceWorker from './serviceWorker';

ReactDOM.render(<CRM />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.register();
