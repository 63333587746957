import React from "react";
import { Table } from "semantic-ui-react";
import ProductItem from "./ProductItem";

function ProductsList(props) {
  const { items,handleDeleteItem} = props;

  if (items.length === 0) return null;

  const tableRows = items.map((p, i) => (
      <ProductItem
        key={p.sku}
        // itemId={p._id}
        i={i}
        product={p}
        handleDelete={handleDeleteItem}
      />
    ));

  // console.log("PRODUCT LIST: ORder ID ",orderId)
  return (
    <div >
      <Table striped  textAlign="left">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell>SKU</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Total price</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{tableRows}</Table.Body>
      </Table>
    </div>
  );
}

export default ProductsList;
