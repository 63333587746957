import React from "react";
import { Button, Input } from "semantic-ui-react";
// import { connect } from "react-redux";
// import { getClientProductList } from "../../../actions/product.js";
import "./productlist.scss";
import ProductFilter from "../../../Filters/ProductFilterWarehouse";

const initState = {
  product: "",
  quantity: 1,
  price: 0,
  codes: "",
  name: "",
  weight: ""
};

class AddProduct extends React.Component {
  constructor() {
    super();
    this.state = initState;
  }

  handleSubmit = () => {
    const prodToAdd = {
      product: this.state.product,
      quantity: Number(this.state.quantity),
      price: Number(this.state.price) * Number(this.state.quantity),
      sku: this.state.codes.sku,
      name: this.state.name,
      weight: this.state.weight
    };
    // console.log(`in submit`,prodToAdd)
    this.props.handleAddProduct(prodToAdd);
    this.setState(initState);
  };

  render() {
    const {  warehouse } = this.props;

    const { quantity, price } = this.state;

    return (
      <div>
        <div>
          <ProductFilter
            style={{ margin: "4px" }}
            // value={null}
            warehouse={warehouse}
            fluid={true}
            handleChange={(e, { value }) => {
              // getProduct({product:value})

              this.setState({
                product: value
              });
            }}
          />
          <div
            className="flex--between"
            // style={{margin:'10px'}}
          >
         
              <Input
                // fluid
                type="number"
                min="1"
                label="Quantity:"
                placeholder="quantity"
                name="quantity"
                value={quantity}
                onChange={(e, d) => this.setState({ quantity: d.value })}
              />

              <Input
                // fluid
                type="number"
                min="0"
                label="Price:"
                placeholder="price"
                name="price"
                value={price}
                onChange={(e, d) => this.setState({ price: d.value })}
              />
            </div>
          
        </div>
          <Button
              className="add-product-button"
              color="grey"
              basic
              content="ADD Product"
              onClick={this.handleSubmit}
              style={{height:'30px',float:'right',margin:"5px 0px 5px 0px"}}
              // disabled={!this.state.product || !this.state.quantity}
            />
      </div>
    );
  }
}

export default AddProduct;
