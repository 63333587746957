import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";

function EditableDropdown({
  placeholder,
  value,
  options,
  handleChange,
  editable = true,
  transparentBackground = false
}) {
  const [isHovered, setHovered] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);

  const handleEdit = () => {
    setIsEditable(true);
  };

  const hadleInternChange = (e, d) => {
    setIsEditable(false);
    setHovered(false);
    handleChange(e, d);
  };

  const obj = options.find(o => value === o.value);
  const text = obj && obj.text;

  if (!editable) {
    return (
      <div
        className={`editable  editable-field__text  ${
          transparentBackground ? "editable_text" : ""
        }`}
      >
        {text}
      </div>
    );
  } else {
    if (!isEditable) {
      return (
        <div
          className={`editable  editable-field__text  ${
            transparentBackground ? "editable_text" : ""
          }`}
          onClick={handleEdit}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {text}
          <span
            className={`editable-field__icons ${!isHovered &&
              "editable-field__icons_invisible"}`}
          >
            <Icon
              name="pencil alternate"
              color="grey"
              style={{ marginRight: "0px" }}
              onClick={handleEdit}
            />
          </span>
        </div>
      );
    } else {
      return (
        <Dropdown
          search
          selection
          fluid
          placeholder={placeholder}
          value={value}
          options={options}
          onChange={hadleInternChange}
        />
      );
    }
  }
}

export default EditableDropdown;
