
import React ,{useState} from 'react'
import {apiCall} from '../../../services/api'
import {
  Container,Message,Content,FlexboxGrid,
  ButtonToolbar,Form,FormGroup,
  Panel,FormControl,Button,Alert,
InputGroup,Input} from 'rsuite'
// import woo from './woo.png'
import Modal from "react-responsive-modal";


const Estetologin =(props) =>{
  
  
  const [api_username,setapi_username] = useState()
  const [shopUrl,setUrl] = useState('')
  const [api_password,setapi_password] = useState()
  const [isModalOpen,setModalOpen]  = useState(false)

  const createEstetoShop =()=>{
  const url = `${process.env.REACT_APP_API}/eshops/esteto`;

     apiCall('post',url,{api_username,api_password,shopUrl})
      .then(eshop=>{
        
        Alert.success('Added new Eshop')
        props.history.push(`/eshops/esteto/${eshop._id}`)
      })
      .catch(error=>Alert.error(error.message,5000))
  }

  const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          {/* <img style={{cursor:'zoom-in'}}src={woo} alt="help"></img> */}
                      </Modal>
  
  return (
  <div >
{viewImg}
    <Container>

        <Message
                                showIcon
                                type="warning"
                                title="Pas 1. Chei de access Esteto"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    <p>Instructiuni:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Logativa in contul personal Esteto</li>
                                      <li>{`2. Accesul dvs.la API a fost activat. Credentialele le gasiti in platforma, accesand "Contul Meu"->"Informatii API".
                                               Tot acolo va trebui sa introduceti adresa (adresele) IP de la care veti accesa API-ul.`}
                                          </li>
                                      {/* <li>{`3. Copiati utilizatorul din tabelul cu IP pentru a fi inclus in campul de mai jos "api_username:"`}
                                          </li> */}
                                      
                                    </ul>
                                  </div>
                                  
                                       {/* <a  onClick={()=>setModalOpen(true)}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}} src={woo} alt="help"></img></a>  */}
                                  </div>
                                  }
                              />

      <Content>
                  
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Esteto LOGIN</h3>} bordered>
              <Form fluid >
                   <Message
                                // showIcon
                                type="info"
                                title="Eshop URL"
                                description={
                                  <div>
                                   <InputGroup >
                                      <InputGroup.Addon>https://www.</InputGroup.Addon>
                                      <Input value={shopUrl} onChange={(value)=>{setUrl(value)}}/>
                                    </InputGroup>
                                  </div>
                                  }
                              />
                       <Message
                                // showIcon
                                type="warning"
                                title="Chei de acces"
                                description={
                                  <div>
                                    <p>Username</p>
                                      <FormControl name="api_username" onChange={(value)=>setapi_username(value)} />
                                      <p>Password</p>
                                      <FormControl name="api_password" onChange={(value)=>setapi_password(value)}/>
                                  </div>
                                  }
                              />
                <FormGroup>
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={()=>createEstetoShop()}>Login</Button>
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  </div>)
}

export default Estetologin