import {
  ADD_INBOUND_SHIPMENT,
  DELETE_SHIPMENT,
  ADD_INBOUND_SHIPMENT_PHOTOS,
  INBOUND_SHIPMENT_GOODSRECEIVED,
  GET_INBOUND_SHIPMENTS,
  INBOUND_FILTER
} from "./actionTypes";

import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

export const createInboundShipment = query => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/shipments/inbound/create/new`;
  apiCall("post", url, query)
    .then(resp => dispatch(addShipmentAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};
const addShipmentAction = shipment => ({
  type: ADD_INBOUND_SHIPMENT,
  shipment
});

// export const getShipments = (filters) => (dispatch => {
//     dispatch(removeError())
//     const url = `${process.env.REACT_APP_API}/shipments`
//     apiCall('post', url, filters)
//         .then(resp => dispatch(getShipmentsAction(resp)))
//         .catch(err => dispatch(addError(err.message)))
// })

export const getShipment = id => dispatch => {
  dispatch(removeError());
  dispatch({ type: "START_LOADING" });
  dispatch({
    type: "UPDATING"
  });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
  apiCall("get", url)
    .then(resp => dispatch(addShipmentAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

export const updateShipment = ({ id, query }) => dispatch => {
  dispatch({
    type: "UPDATING"
  });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
  apiCall("put", url, query)
    .then(resp => dispatch(addShipmentAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

export const deleteShipment = id => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}`;
  apiCall("delete", url)
    .then(resp => {
      if (resp.status === "deleted.") {
        dispatch(deleteShipmentAction(id));
      }
    })
    .catch(err => dispatch(addError(err.message)));
};

const deleteShipmentAction = id => ({
  type: DELETE_SHIPMENT,
  id
});

export const uploadFiles = ({ id, files }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/documents`;
  apiCall("post", url, files)
    .then(photos => dispatch(addFilesAction(photos)))
    .catch(err => dispatch(addError(err.message)));
};

const addFilesAction = photos => ({
  type: ADD_INBOUND_SHIPMENT_PHOTOS,
  photos
});

export const createInventoryCard = ({ id, item }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived`;
  apiCall("post", url, item)
    .then(goodsreceived => dispatch(addInventoryToShipment(goodsreceived)))
    .catch(err => dispatch(addError(err.message)));
};

const addInventoryToShipment = goodsreceived => ({
  type: INBOUND_SHIPMENT_GOODSRECEIVED,
  goodsreceived
});

export const removeInventoryCard = ({ id, item }) => dispatch => {
  dispatch(removeError());
  dispatch({ type: "UPDATING" });
  const url = `${process.env.REACT_APP_API}/shipments/inbound/${id}/goodsreceived/${item}`;
  apiCall("delete", url)
    .then(goodsreceived => dispatch(setGoodsreceived(goodsreceived)))
    .catch(err => dispatch(addError(err.message)));
};

const setGoodsreceived = goodsreceived => ({
  type: INBOUND_SHIPMENT_GOODSRECEIVED,
  goodsreceived
});

export const getShipments = filter => dispatch => {
  const url = `${process.env.REACT_APP_API}/shipments/inbound`;
  apiCall("post", url, filter)
    .then(shipments => dispatch(getShipmentsAction(shipments)))
    .catch(err => dispatch(addError(err.message)));
};

const getShipmentsAction = shipments => ({
  type: GET_INBOUND_SHIPMENTS,
  shipments
});

export const updateFilter = filter => ({
  type: INBOUND_FILTER,
  filter
});
