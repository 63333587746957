import React,{useEffect,useState} from 'react'
import {apiCall} from '../../../services/api'
import { Alert,Input,Toggle,Message,FormControl } from 'rsuite'
import {Header,Segment,Button,Divider,Label} from 'semantic-ui-react'
import emag from './emag.jpg'
import Modal from "react-responsive-modal";

const Emagsettings =(props)=>{

    const {id} = props.match.params
    const [eshop,setEshop] = useState(null)

    const [username,setUsername] = useState()
    const [password,setPassword] = useState()
    const [isChanged,setIschanged] = useState(false)
    const [newPass,setNewpass]  = useState(false)
    const [loading,setIsloading]  = useState(false)
    const [isModalOpen,setModalOpen]  = useState(false)

    const updateCredentials =()=>{
        setIschanged(false)
        setNewpass(false)
        
        const url = `${process.env.REACT_APP_API}/eshops/emag/${id}`;

         apiCall('put',url,{username,password})
          .then(resp=>{
                Alert.success('Username/Password updated')
                setEshop(resp)
                setUsername(resp.emag_settings.username)
                
            })
          .catch(error=>Alert.error(error))

    }

    const testConnection=()=>{

            setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/emag/${id}/test`;
               apiCall('get',url)
                .then(resp=>{
                      Alert.success('connection good')
                      setIsloading(false)
                      
                      setEshop({...eshop,emag_settings:{...eshop.emag_settings,is_setUp:true}})
                  })
                .catch(error=>
                    {
                      setIsloading(false)
                    Alert.error(`${error.message}`,10000)})
    }


    const importProducts=()=>{
      setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/emag/${id}/importproducts`
               apiCall('get',url)
                .then(resp=>{
                      Alert.success(`Imported new products: ${resp.imported}`,10000)
                      setIsloading(false)
                  })
                .catch(error=>
                    {
                      setIsloading(false)
                    Alert.error(`${error.message}`,10000)})
    }


    const autoImportOrders =()=>{
           setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/emag/${id}/autoimport`
               apiCall('put',url,{autoSync:!eshop.emag_settings.get_orders_api})
                .then(resp=>{

                      Alert.success(`Auto-import is turned ${eshop.emag_settings.get_orders_api?'Off':'On'}`)
                      setIsloading(false)
                      setEshop(resp)
                  })
                .catch(error=>
                    {
                      setIsloading(false)
                    Alert.error(`${error.message}`,10000)})
    }


    useEffect(()=>{
          const url = `${process.env.REACT_APP_API}/eshops/emag/${id}`;
          apiCall('get',url)
          .then(resp=>{
                setEshop(resp)
                setUsername(resp.emag_settings.username)
            })
          .catch(error=>Alert.error(error))

    },[id])

    const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          <img style={{cursor:'zoom-in'}}src={emag} alt="help"></img>
                      </Modal>
    if(!eshop) return <div>loading...</div>
      return (
    <div>
      {viewImg}
      <Header as="h2" style={
                {
                  marginLeft:'20px',
                  marginTop :'20px'
                }}>Emag settings</Header>


             {/* {!eshop.emag_settings.is_setUp && <Message
                                showIcon
                                type="warning"
                                title="Pas 1. Setare IP [37.139.16.98]"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    <p>Instructiuni:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Logativa in contul EMAG</li>
                                      <li>{`2. Adaugati IP in lista aprobata. 
                                            Menu[Contul meu] > Profil > Detalii Technice > Adauga IP nou`}
                                          </li>
                                      <li>{`3. Copiati utilizatorul din tabelul cu IP pentru a fi inclus in campul de mai jos "Username:"`}
                                          </li>
                                      
                                    </ul>
                                  </div>
                                  
                                       <a  onClick={()=>setModalOpen(true)}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={emag} alt="help"></img></a> 
                                  </div>
                                  }
                              />}  */}


      <div className='flex--left' style={{marginTop:"30px"}}>
      <section style={{padding:'20px',maxWidth:'800px'}}>
      
        <Segment padded>
        
        
            <div className='flex--between'>    
                
              <h6>API Username/Password</h6>

                <div>
                  {isChanged                     && <Button color='blue' onClick={()=>updateCredentials()}>Update</Button>}
                  {!eshop.emag_settings.is_setUp && <Button color='orage' loading={loading} disabled={loading} onClick={()=>testConnection()}>Pas 2. Conecteaza Cont</Button>}
                  {eshop.emag_settings.is_setUp  && <Label color='green'>Cont Connectat</Label>}
                </div>
                  

            </div>
                  <Divider></Divider>

            <div>
                <label>Username:</label>   
                <Input 
                  size='md'
                  placeholder='username'
                  name='username'
                  value ={username}
                  onChange ={(value)=>{
                    setIschanged(true)
                    setUsername(value)
                  }}
                  ></Input>
            </div>
                      <div>
                <label>Password:</label>   
                {newPass&&<Input size ='md' placeholder='password' type='password'
                            onChange ={(value)=>{
                                              setIschanged(true)
                                              setPassword(value)
                  }}
                ></Input>}
                <br></br>
                {!newPass&&<button onClick={()=>setNewpass(true)}>change</button>}
            </div>

      </Segment>

  {!eshop.emag_settings.is_setUp &&<Divider horizontal> Test Connection to unlock</Divider>}
      <Segment padded disabled={!eshop.emag_settings.is_setUp}>
            <div className='flex--between'>    
            
                      <h6>Products</h6>
                      <Button disabled={loading||!eshop.emag_settings.is_setUp} loading={loading} 
                      onClick={()=>importProducts()}
                          >Pas 3. Import Produse Noi</Button>
              </div>
            <Divider></Divider>

                  <Message
                    type={!eshop.emag_settings.get_orders_api?"warning":"success"}
                    title="Descarcarea automat de comenzi noi"
                    description={
                      <div className='flex--between'>
                        
                      <p>
                      Descarcarea si avizarea comenzilor noi se face in mod automatizat in fiecare 2 minute.
                      </p>
                      <Toggle 
                            disabled={!eshop.emag_settings.is_setUp}
                            checked={eshop.emag_settings.get_orders_api}
                            onChange={()=>autoImportOrders()}
                        />

                        </div>
                    }
                  />
              

      </Segment>


      </section>
      
     {!eshop.emag_settings.is_setUp&& <section>
       <h5>Descrierea instructiunilor in cazul erorilor</h5>
                     <Message
                                showIcon
                                type="info"
                                title="ERROR: Invalid vendor ip [37.139.16.98]"
                                description={
                                  <div>
                                    <p>Daca primiti aceasta erroare - IP nu este inclus in lista aprobata EMAG.</p>
                                    <p>Instructiuni:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Logativa in contul EMAG</li>
                                      <li>{`2. Adaugati IP in lista aprobata. 
                                            Menu[Contul meu] > Profil > Detalii Technice > Adauga IP nou`}
                                          </li>
                                    </ul>
                                  
                                  
                                  <a  onClick={()=>setModalOpen(true)}><img style={{maxWidth:'200px',cursor:'zoom-in'}}src={emag} alt="help"></img></a> 
                                  </div>
                                  }
                              />
                     <Message
                                showIcon
                                type="info"
                                title="ERROR: You are not allowed to use this API."
                                description={
                                  <div>
                                    <p>Username / Password nu este corect.</p>
                                   
                                  </div>
                                  }
                              />
      </section>}
      </div>
    </div>
  );
}


export default Emagsettings