import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../../ExportExcel";

function ExportButton({ data, filename }) {
  const [dataSet, setData] = useState();

  const colNames = [
    { label: "id", value: "id" },
    { label: "date", value: "createdAt" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Product", value: "product" },
    { label: "Sku", value: "sku" },
    { label: "Quantity", value: "quantity" },
    // { label: "Total", value: "total" },
    // { label: "Turnover 7 days", value: "turnover7days" }
  ];

  useEffect(() => {
    if (!data || data.length === 0) setData({});
    else {
      let tempDataSet = [];

      for (let i = 0; i < data.length; i++) {
        
        const { goodsreceived } = data[i];
        
        const { warehouse, createdAt, _id } = data[i];

        const items = goodsreceived.map((item) => ({
          id: _id,
          sku: item.product.codes.sku || "",
          product: item.product.name || "",
          quantity: item.stockMovement.quantity,
          warehouse: warehouse.name || "",
          createdAt,
        }));

        tempDataSet = [...tempDataSet, ...items];
      }
      console.log(tempDataSet);

      setData(tempDataSet);
    }
  }, [data]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button basic color="grey" disabled={!data || !data.length}>
          <Icon name="excel file" />
          Export {`${data && data.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
