import React from "react";
import { Segment, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function MobileItemCard({ item }) {
  const { _id, name, country, isDefault } = item;

  return (
    <Segment>
      <div className="order-card">
        <Link to={"/couriers/" + _id} className="order-card--link">
          {name} {isDefault && <Icon name="check" size="tiny" />}
        </Link>

        <div className="order-card--status">
          <div className="smallinfo">{_id}</div>
          {country}
        </div>
      </div>
    </Segment>
  );
}

export default MobileItemCard;
