import React from "react";
import { Segment, Dropdown } from "semantic-ui-react";
import { DateRangePicker } from "rsuite";

// import ClientFilter from "../Filters/ClientFilter";

function Filters({ filters, handleFilterChange, updateFilters,warehouses}) {
  const { businessclient, date,warehouse } = filters;
  return (
    <Segment>
      <p>Filters:</p>
      {/* <ClientFilter
        name="businessclient"
        value={businessclient}
        handleChange={handleFilterChange}
      /> */}
      {/* <div className="services-filters-date">
        <DateRangePicker
          placeholder="Select Date Range"
          block
          // value={date}
          onChange={([dateFrom, dateTo]) =>
            updateFilters({ dateFrom, dateTo, currentPage: 1 })
          }
        />
      </div> */}
      <Dropdown
        placeholder="warehouse"
        name="warehouse"
        value={warehouse}
        clearable
        selection
        selectOnBlur={false}
        closeOnChange={true}
        onChange={handleFilterChange}
        options={warehouses.map(({ _id, name })=>({key:_id,text:name,value:_id}))}
      />
    </Segment>
  );
}
export default Filters;
