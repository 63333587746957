import React from "react";
import Moment from "react-moment";

const Comment = ({ username='', text, date }) => {
  return (
    <div>
      <div className="comment">
        <div className="comment__meta">
          <div>{username}</div>
          <div className="comment__date">
            <Moment format="DD/MM/YY HH:mm">{date}</Moment>
          </div>
        </div>

        <div className="comment__text">
          <li>{text}</li>
        </div>
      </div>
    </div>
  );
};

export default Comment;
