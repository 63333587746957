import React from "react";
import { Timeline } from "rsuite";
import Moment from "react-moment";
import { Segment,Header, Divider } from "semantic-ui-react";

function ChangeLogs({ changeLogs }) {
  if (!changeLogs || !changeLogs.length) return <p>Nothing</p>;

  const timelineItems = () => {
    let items = [];
    for (let i = changeLogs.length - 1; i >= 0; i--) {
      const { what, who, when } = changeLogs[i];

      const item = (
        <Timeline.Item
          key={i}
          className={`rs-timeline-item ${
            i === changeLogs.length - 1 ? "rs-timeline-item-first" : ""
          }`}
        >
          <p>
            <Moment format="DD-MM-YY HH:mm">{when}</Moment>
            <span className="small--text ml--1 mr--1">
              by: <em>{who ? who.username : "API"}</em>
            </span>
            <span className="bold--text">
              {typeof what === "string" && <div>{what}</div>}{" "}
              {typeof what === "object" &&
                Object.entries(what) &&
                Object.entries(what).map(([key, value]) => (
                  <span key={key}>
                    {key} - {value}
                  </span>
                ))}
            </span>
          </p>
        </Timeline.Item>
      );
      items.push(item);
    }
    return items;
  };

  return (
    <div>
      <Segment name="logs" style={{ maxWidth: "900px", width: "900px" }}>
        <Header>Change logs</Header>
        <Divider></Divider>
        <Timeline>{timelineItems()}</Timeline>;
      </Segment>
    </div>
  );
}

export default ChangeLogs;
