import React from "react";
import { Segment, Dropdown } from "semantic-ui-react";
import { DateRangePicker } from "rsuite";


function Filters({ filters, handleFilterChange, updateFilters }) {
  const {  status, date } = filters;
  return (
    <Segment>
      <p>Filters:</p>
     
      <div className="services-filters-date">
        <DateRangePicker
          placeholder="Select Date Range"
          // block
          // value={date}
          onChange={([dateFrom, dateTo]) =>
            updateFilters({ dateFrom, dateTo, currentPage: 1 })
          }
        />
      </div>
      <Dropdown
        placeholder="status..."
        name="status"
        value={status}
        clearable
        selection
        selectOnBlur={false}
        closeOnChange={true}
        onChange={handleFilterChange}
        options={[
          { key: 0, value: "true", text: "Billed" },
          { key: 1, value: "false", text: "Not billed" },
          { key: 2, value: "", text: "All" },
        ]}
      />
    </Segment>
  );
}
export default Filters;
