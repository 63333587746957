const initstate={
    list:[],
    total:null,
    openShipment:{
        to:{
            warehouse_id:{
                name:'',
                address:{
                    country:'',
                    
                }
            }
        },
        
        from:{
            name: '',
            address:{
                country:null
            }
        },
        tracking:{
            nr:''
        },
        items:{
            pos:[],
            products:[]
        }
    },
    // warehouseOptions:[],
    addressOptions:[],
    pickups:[]
}

const shipments =(state=initstate,action)=>{
    switch (action.type) {
        case "GET_SHIPMENTS":
            return{...state,
                list: action.shipments.list,
                total:action.shipments.total
            }
        case "GET_SHIPMENT":
        return {...state,
            openShipment: action.data
        }
        
        case "GET_ADDRESSOPTIONS":
            return {...state,
                addressOptions:action.data

            }
        case "CLEAR_SHIPMENT_STATE":
            return {
                ...state,
                openShipment: {...initstate.openShipment}
                        
            }
        case 'GET_PICKUPS':
            return {
                ...state,
                pickups:action.data
            }
        case 'CREATE_PICKUP':
            state.pickups.push(action.pickup)

            return {
                ...state,
               
            }
        case 'DELETE_SHIPMENT':
            // debugger
            const newList = []
            
            state.list.forEach(item =>{
                if(item._id!==action.id){
                    newList.push(item)
                    }})

            return {
                ...state,
                    list:newList,
                    total:state.total-1
            }
        default:
            return state;
    }
}

export default shipments