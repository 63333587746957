import { ADD_XLS_PRODUCTS, CREATE_PRODUCT } from "../actions/actionTypes";

const initState = {
  isLoading: false,
  resp: {
    status: false,
    unsuccessful: []
  },
  product: {}
};

const products = (state = initState, action) => {
  switch (action.type) {
    case ADD_XLS_PRODUCTS:
      return {
        ...state,
        resp: action.payload,
        isLoading: false
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        product: action.product,
        isLoading: false
      };
    case "START_LOADING_NEW_PROD":
      return { ...state, isLoading: true };
    case "STOP_LOADING_NEW_PROD":
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default products;
