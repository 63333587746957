import React, {useState,useEffect} from 'react'
import { Link } from "react-router-dom";
import{apiCall} from '../../services/api'
import {Table} from 'semantic-ui-react'
import {Dropdown,Message} from 'rsuite'
import lang from '../../lang/config.json'

function Eshops (props){
    const syslang = props.user.sys_lang

    const [eshops,setEshops] = useState([])
    const [isLoading,setIsloading] = useState(false)

    const getEshops = ()=>{
        setIsloading(true)
            apiCall('get',`${process.env.REACT_APP_API}/settings/eshops`)
            .then(list =>{
                setEshops(list)
                setIsloading(false)
            }).catch(error=>alert(error.message))
    }

    useEffect(()=>getEshops(),[])
    

    const newEshops=<Dropdown title="Add New">
                        <Dropdown.Item onSelect={()=>props.history.push('/eshops/Eshop-rychle/addnew/login')}>Eshop Rychle</Dropdown.Item>
                        <Dropdown.Item>Shoptet</Dropdown.Item>
                        <Dropdown.Item onSelect={()=>props.history.push('/eshops/emag/addnew/login')}>Emag</Dropdown.Item>
                        <Dropdown.Item onSelect={()=>props.history.push('/eshops/woocommerce/addnew/login')}>Woocommerce</Dropdown.Item>
                        <Dropdown.Item onSelect={()=>props.history.push('/eshops/esteto/addnew/login')}>Esteto</Dropdown.Item>
                        <Dropdown.Item disabled>Shopify</Dropdown.Item>
                        
                    </Dropdown>
         
    const eshopList = eshops.map((eshop,index)=>
         
        (<tr>
            <td>{index+1}</td>
            <td>{eshop.eshopType}</td>
            <td>{eshop.eshopUrl}</td>
            <td>{eshop.contactEmail}</td>
            <td><Link to={`/eshops/${eshop.eshopType}/${eshop._id}`} >
            {lang.table.settings[syslang]}
            
            </Link></td>
        </tr>)
    )

    if(eshops.length===0&&!isLoading) return(
        <Message
        style={{  margin:" auto",
                    width: "50%",
                    border: "3px",
                    padding: "70px 0",
                    textAlign: "center"
                }}
        type={'info'}
        title="No Eshops added. "
        description={
          <div>
            
            {newEshops}

            </div>
        }
      />    
    )

    return (<div style={{margin:'10px'}}>
                 
                 <div className='flex--left'>
                     <h4>Eshops</h4>
                 {eshops.length>0&& newEshops}
  </div>


                <Table>
                <Table.Row>
                    <th>#</th>
                    <th>Type</th>
                    <th>Url</th>
                    <th>Email</th>
                    <th></th>
                </Table.Row>
                {eshopList}
                </Table>

    </div>)
}


export default  Eshops