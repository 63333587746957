import React,{ useState,useEffect } from "react";
import { Header, Loader, Segment } from "semantic-ui-react";
import InvoicesTable from "./InvoicesTable";



function InvoicePerCountryList({ invoices, isLoading, filters ,creditNotes}) {
  const months_names = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [groupedSummary, setGroupedSummary] = useState([]);
  useEffect(() => {
    let summary = {};

    //add invoicess to summary

    for (let item of invoices) {
      const month = item._id.month
      if(!summary[month]) summary[month]={invoices:[],creditNotes:[]}
      summary[month].invoices.push(item)
    }

    //add credit notes to summary

    for (let item of creditNotes) {
      const month = item._id.month
      if(!summary[month]) summary[month]={invoices:[],creditNotes:[]}
      summary[month].creditNotes.push(item)
    }

    setGroupedSummary(summary);
  }, [invoices,creditNotes]);

  console.log(groupedSummary);
 
  const months = Object.keys(groupedSummary).sort((a,b)=>b-a)
  

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );
  
  if (months.length>0)
    return (
      <div>

        {months.map(month=> (
          <Segment
            secondary
            key={month}
            style={{ marginTop: "1em", marginBottom: "3em" }}
          >
            <Header as="h2">{months_names[month-1] || "N/A"}</Header>
            <InvoicesTable
              invoices={groupedSummary[month].invoices}
              creditNotes={groupedSummary[month].creditNotes}
              country={month}
              filters={filters}
            />
          </Segment>
        ))}
      </div>
    );
  return (
    <Header as="h4" textAlign="center">
      No invoices found
    </Header>
  );
}

export default InvoicePerCountryList;
