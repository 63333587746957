import React from "react";
import { Table } from "semantic-ui-react";
import EditableText from "../../../EditableText";

function ItemsList({ items, updateItem, editable ,lang,syslang}) {
  if (!items.length) return null;
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.description[syslang]}</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.quantity[syslang]}</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.unitprice[syslang]}</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.vatrate[syslang]}</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.totalnetto[syslang]}</Table.HeaderCell>
          <Table.HeaderCell>{lang.bills.viewbill.itemstable.totalbrutto[syslang]}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map(
          (
            {
              description,
              quantity,
              VAT_procent,
              brutto_total,
              netto_total,
              netto_price,
            },
            i
          ) => (
            <Table.Row key={i}>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>
                {editable ? (
                  <EditableText
                    placeholder="description..."
                    name="description"
                    value={description}
                    maxWidth={160}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  description
                )}
              </Table.Cell>
              <Table.Cell>
                {editable ? (
                  <EditableText
                    placeholder="quantity..."
                    name="quantity"
                    value={quantity}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  quantity
                )}
              </Table.Cell>
              <Table.Cell>
                {editable ? (
                  <EditableText
                    placeholder="NETTO price..."
                    name="NETTO"
                    value={netto_price}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  netto_price
                )}
              </Table.Cell>
              <Table.Cell>
                {editable ? (
                  <EditableText
                    placeholder="VAT rate..."
                    name="vat_procent"
                    value={VAT_procent}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  VAT_procent
                )}
              </Table.Cell>

              <Table.Cell>
                <span style={editable ? { lineHeight: "2.7" } : {}}>
                  {netto_total}
                </span>
              </Table.Cell>
              <Table.Cell>
                <span style={editable ? { lineHeight: "2.7" } : {}}>
                  {brutto_total}
                </span>
              </Table.Cell>
             
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
}

export default ItemsList;
