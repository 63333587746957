import React from "react";
import { Form} from "semantic-ui-react";

class CompanyForm extends React.Component {
  render() {
    const { companyName, phone, taxId, handleChange,lang,user } = this.props;
    return (
      <div className="form-columns__client">
        <Form.Input
         autoComplete="nope"
          width={16}
          name="companyName"
          label={lang.orders.viewOrder.companyname[user.currentUser.sys_lang]}
          // placeholder="Name"
          value={companyName}
          onChange={handleChange}
        />
        <Form.Input
         autoComplete="nope"
          width={16}
          name="taxId"
          label={lang.orders.viewOrder.taxid[user.currentUser.sys_lang]}
          // placeholder="TAX ID"
          value={taxId}
          onChange={handleChange}
        />
        <Form.Input
         autoComplete="nope"
          width={16}
          name="phone"
          label={lang.orders.viewOrder.phone[user.currentUser.sys_lang]}
          // placeholder="Phone"
          value={phone}
          onChange={handleChange}
        />
      </div>
    );
  }
}

export default CompanyForm;
