import React, { useState } from 'react'
import {connect} from 'react-redux'
import {Message,Input,Button} from 'rsuite'
import { apiCall } from "../../../services/api";
import {setAuthorizationToken,setCurrentUser} from '../../../actions/auth'
import logo from './wyzbox_activation_small.jpg'
import {Alert} from 'rsuite'
import lang from '../../../lang/config.json'

const styles = {
  width: 300,
  marginBottom: 10
};

const Shoptetactivation =(props)=>{

const {activationkey,syslang='CZ',username=null} =props.match.params
const[password,setPassword] = useState()
const [isLoading,setLoading] = useState(false)

const handleSubmit =()=>{
    
  const url = `${process.env.REACT_APP_ADDONS_API}/shoptet/newinstall/activation`
          // console.log('decoding key....',url)
  setLoading(true)
  apiCall('post',url,{activationkey,password,syslang})
      .then(resp=>{
       
            localStorage.setItem(`jwtToken`, resp.token);
            setAuthorizationToken(resp.token);
            setCurrentUser(props.user)
            props.history.push(`/eshops/shoptet/${resp.eshopId}`)
       
      })
      .catch(error=>{
       
                setLoading(false)
                  Alert.error(`Activation link expired. Reinstall ADDON.`,5000)
                  setAuthorizationToken(false)
                  localStorage.clear();
                  //props.history.push(`/signin`)
      })
      
}


    return (<div className='flex--between' style={{height:'100%'}}>
              <div>
                <img style={{width:"100%",height:"100%",objectFit:"cover",overflow:'hidden'}} src={logo} alt='logo'></img>
              </div>
               <Message
                    type="info"
                    title={lang.settings.eshops.shoptet.activate.landing.title[syslang]}
                    style={{width:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}
                    description={
                      <div>
                          <p>
                          {lang.settings.eshops.shoptet.activate.landing.title2[syslang]}
                          </p>
                            <br />
                            <div className='flex--left'>
                              <Input 
                                    onChange={(data)=>setPassword(data)}
                                    type='password' placeholder={lang.placeholders.password[syslang]}
                                    style={styles}
                                      />                                
                          

                    <Button
                      loading={isLoading}
                      appearance="primary"
                      style={{
                          height:'35px',
                          marginLeft:'15px',
                          width:'100px'
                              }}
                      onClick={handleSubmit}
                    >
                      Ok
                    </Button>
                        </div>
                        <p style={{marginLeft:'3px'}}>({lang.settings.eshops.shoptet.activate.landing.username[syslang]}: <span>{username}</span>)</p> 
                      
                      </div>
                    }
                  />
                
                  
             </div>)
}
function mapStatetoProps(reduxstate){
  return(
    {
      syslang:reduxstate.user.currentUser.sys_lang,
      user:reduxstate.user.currentUser,
      
    }
  )
}
export default connect(mapStatetoProps,{})(Shoptetactivation)