import React,{useState,useEffect} from 'react'
import {Input,Modal,Button,Message} from 'rsuite'
import './styles.scss'

const config = {
    wisper:{
        orders:{cz:"Pocet mesicnich objednavek"}
    }
}

const Costcalculator =(props)=>{


const [countOrders, setCountOrders]                 = useState(1000)
const [headcount,setHeadcount]                      = useState(1)
const [headCost,setHeadCost]                        = useState(25000*1.34)
const [totalHeadCost,setTotalHeadCost]              = useState(25000*1.34)
const [rent,setRent]                                = useState(12000)
const [directMaterial,setDirectMaterial]            = useState(6.5)
const [directMaterialTotal,setDirectMaterialTotal]  = useState(6.5)
const [totalCost,setTotalCost]                      = useState(Math.ceil(totalHeadCost+directMaterialTotal+rent))

useEffect(()=>{
   setHeadcount(Math.ceil((countOrders/19/150))) 
   setDirectMaterialTotal(Math.ceil(directMaterial*countOrders))
   setTotalHeadCost(Math.ceil(Math.ceil(headCost*headcount)))

   if(countOrders<=1000) setRent(12000)
   if(countOrders>1000&&countOrders<=4000) setRent(24000)
   if(countOrders>4000) setRent(50000)
   
},[countOrders,directMaterial,headCost])

useEffect(()=>{
        setTotalHeadCost(Math.ceil(headCost*headcount))
   
},[headcount])

useEffect(()=>{

   setTotalCost((totalHeadCost+directMaterialTotal+rent*1))
   
},[rent,directMaterialTotal,totalHeadCost])


const message =<Message
                        type="info"
                        style={{marginTop:'10px'}}
                        // title=""
                        description={
                                    <div>
                                       

                                    <div className='flex--between' style={{ fontSize:'18px'}}>
                                        <h5>Náklad na objednávku:</h5>
                                        <div>{Math.ceil(totalCost/countOrders)} Kč</div>
                                    </div>
                                    <div className='flex--between' style={{color:'green'}} >
                                        <h5>Naše cenová nabídka</h5>
                                        <div style={{color:'green',fontSize:'20px',textWeight:'bold'}}>26 Kč</div>
                                    </div>
                                                                    </div>
                                }
                                />

 const body =  (
    <div style={{width:'500px',margin:'20px'}}>
    
        <div >
            <label>Počet objednávek/měsíc</label>
            <Input style={{ width: 170 }} value = {countOrders} onChange={(value)=>setCountOrders(value)}></Input>
        </div>
        <div>  
              
            <div className='flex--between'>
                
                <div>
                        <label>Počet zaměstnanců</label>
                            <Input value = {headcount} onChange={(value)=>setHeadcount(value)}></Input>
                        </div>  
                        <div>
                            <label>Náklad na zaměstnance vč. pojistného</label>
                            <Input value = {headCost} onChange={(value)=>setHeadCost(value)}></Input>            
                        </div>
                
                </div> 
    
            <div className='flex--between totals' style={{marginTop:'5px'}}>
                <label>Osobní náklady celkem:</label>
               <p >{totalHeadCost} Kč</p> 
            </div>

      
            <div >
                <label>Spotřeba materiálu/obj.</label>
                
                  <div className='flex--between totals'>
                      <Input style={{ width: 160 }} value = {directMaterial} onChange={(value)=>setDirectMaterial(value)}></Input>
                        <label>Celkem </label>
                        {directMaterialTotal} Kč
                </div>
            </div>
            
 
          
            
            <div style={{marginTop:'5px'}}>
                <label>Pronájem skladových prostor/měsíc</label>
                <Input type='number'value = {rent} onChange={(value)=>setRent(value)}></Input>
            </div>
        </div>
         <div className='flex--between totals'style={{marginTop:'10px'}}>
            <h5>Náklady celkem:</h5>
            <div>{totalCost} Kč</div>
        </div>
         {/* <div className='flex--between totals'>
            <h5>Náklad na objednávku:</h5>
            <div>{Math.ceil(totalCost/countOrders)} Kč</div>
        </div>
          <div className='flex--between totals'>
            <h5>Naše cenová nabídka</h5>
            <div>26 Kč</div>
        </div> */}
        {message}

    </div>)
    
    const modal = <div className="modal-container">
  
            <Modal  show={true} onHide={()=>{}}>
                <Modal.Title>Kalkulačka skladových nákladů</Modal.Title>
            <Modal.Body>
             {body}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={()=>{}} appearance="primary">
               <a href='http://www.wyzbox.com' style={{color:'white'}}>Mám zájem</a> 
                </Button>
                
            </Modal.Footer>
            </Modal>
        </div>

return modal

}

export default Costcalculator