import React, { useEffect, useState } from "react";
import { Header, Segment, Button } from "semantic-ui-react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import { getBillableServices, updateFilters } from "../../../actions/billableservices";
// import { useTableCheckbox }                   from "../../../hooks/useTableCheckbox";

import ExportButton                           from "../../ExportButton";
import ItemsPerPage                           from "../../ItemsPerPage";
import Pagination                             from "../../Filters/Pagination";
import ServicesTable                          from "./ServicesTable";
import Filters                                from "./Filters";
import "./services.scss";

function Services({
  services,
  filters,
  isLoading,
  total,
  getBillableServices,
  updateFilters,
}) {
  const { itemsPerPage, currentPage } = filters;
  // const {
  //   isHeaderChecked,
  //   checkedItems,
  //   handleSingleCheck,
  //   handleMultipleCheck,
  //   handleClearCheckedItems,
  // } = useTableCheckbox(services);

  useEffect(() => getBillableServices(filters), [filters]);

  const handleFilterChange = (e, { name, value }) => {
    updateFilters({ [name]: value, currentPage: 1 });
    // handleClearCheckedItems();
  };
  const handlePageChange = ({ currentPage }) => {
    updateFilters({ currentPage });
    // handleClearCheckedItems();
  };

  const { dateFrom, dateTo, status } = filters;
  const [fileName, setFileName] = useState("services");
  useEffect(
    () =>
      setFileName(
        `services${dateFrom ? " - " + dateFrom.toLocaleDateString() : ""}${
          dateTo ? " - " + dateTo.toLocaleDateString() : ""
        }${status === "false" ? " - not billed" : ""}${
          status === "true" ? " - billed" : ""
        }`
      ),
    [dateFrom, dateTo, status]
  );

  return (
      <main className="page-wrapper">
        <Header>Services</Header>

        <Filters
          filters={filters}
          handleFilterChange={handleFilterChange}
          updateFilters={updateFilters}
        />

        <Segment>
          <div className="flex--right">
            {/* <ExportButton
              fileName={fileName}
              total={total}
              filters={filters}
              // mapData={mapData}
              url="/services/export"
            /> */}
            <ItemsPerPage
              style={{ marginLeft: "10px" }}
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
            />
          </div>

          <ServicesTable
            services={services}
            isLoading={isLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            // isHeaderChecked={isHeaderChecked}
            // checkedItems={checkedItems}
            // handleSingleCheck={handleSingleCheck}
            // handleMultipleCheck={handleMultipleCheck}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={handlePageChange}
            />
          )}
        </Segment>
      </main>
    
  );
}

const mapStateToProps = (reduxState) => ({
  filters: reduxState.services.filters,
  isLoading: reduxState.services.isLoading,
  services: reduxState.services.list,
  total: reduxState.services.total,
});
export default connect(mapStateToProps, {
  getBillableServices,
  updateFilters,
})(Services);
