import React from "react";
import { Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { updateFilter } from "../../../actions/orders";

function SortArrows({ updateFilter, filterSort, sortBy }) {
  const handleUp = () => {
    updateFilter({ sort: { [sortBy]: "up" } });
  };
  const handleDown = () => {
    updateFilter({ sort: { [sortBy]: "down" } });
  };

  return (
    <span
      style={{ display: "inline-block", marginLeft: "2em", cursor: "pointer" }}
    >
      <Icon
        inverted={filterSort[sortBy] !== "down"}
        // size="small"
        name="arrow down"
        onClick={handleDown}
        color={filterSort[sortBy] === "down" ? "blue" : "grey"}
      />
      <Icon
        inverted={filterSort[sortBy] !== "up"}
        // size="small"
        name="arrow up"
        onClick={handleUp}
        color={filterSort[sortBy] === "up" ? "blue" : "grey"}
      />
    </span>
  );
}

function mapStateToProps(reduxState) {
  return {
    filterSort: reduxState.orders.filters.sort
  };
}

export default connect(mapStateToProps, {
  updateFilter
})(SortArrows);
