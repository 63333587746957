import React from "react";
import Moment from "react-moment";

function CreateUpdateTime({ createdAt, updatedAt, newLine = false }) {
  return (
    <>
      Updated: {newLine && <br />}
      <Moment format="DD-MM-YY HH:mm" style={{ whiteSpace: "pre" }}
       
       >
        {updatedAt}
      </Moment>
      <br />
      <span className="smallinfo">
        Created: {newLine && <br />}
        <Moment
          style={{ color: "#565656", whiteSpace: "pre" }}
          format="DD-MM-YY HH:mm"
          
        >
          {createdAt}
        </Moment>
      </span>
    </>
  );
}

export default CreateUpdateTime;
