import React from "react";
import Moment from "react-moment";
import EditableText from "../../EditableText";

function ShipmentInfo(props) {
  const {
    
    openShipment,
    user,lang
  } = props;

  return (
    <div className="edit-inbound-shipment__grid">
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>          
          {lang.table.warehouse[user.currentUser.sys_lang]}
        </label>
          <EditableText
            name="warehouse"
            value={openShipment.warehouse.name}
            editable={false}
          />
        </div>
        {/* <div className="edit-inbound-shipment__field">
          <label>Client:</label>
          <EditableDropdown
            placeholder="client..."
            name="client"
            value={openShipment.client}
            options={options.businessclients}
            handleChange={handleClientChange}
            editable={false}
          />
        </div> */}
        <div className="edit-inbound-shipment__field">
          {/* <div> */}
       <label>{lang.table.supplier[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="seller..."
            name="seller"
            value={openShipment.seller.name}
            editable={false}
          />
        </div>

        <div className="edit-inbound-shipment__field">
          <label>{lang.table.transport[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="transporter company ..."
            name="transporter"
            value={openShipment.transporter}
            // handleChange={handleFieldChange}
            editable={false}
          />
        </div>
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.nrofpacks[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="nr of packs ..."
            name="nrOfPacks"
            value={openShipment.nrOfPacks}
            editable={false}
          />
        </div>
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.supplier[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="weight in kg ..."
            name="weightKg"
            value={openShipment.weightKg}
            editable={false}
          />
        </div>
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
        <label>{lang.table.updatedAt[user.currentUser.sys_lang]}</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.updatedAt}</Moment>
          </span>
        </div>
        <div className="edit-inbound-shipment__field">
        <label>{lang.table.createdAt[user.currentUser.sys_lang]}</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.createdAt}</Moment>
          </span>
        </div>
      </div>
    </div>
  );
}




export default (ShipmentInfo)
