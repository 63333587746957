import React from "react";
import { Button, Icon, Segment, Form, Header } from "semantic-ui-react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

import { openFilters, closeFilters } from "../actions/filters";

// const initialFiltersState = {
//   status: "Order Ready for picking",
//   product: [],
//   quantity: "",
//   courier: [],
//   dateFrom: null,
//   dateTo: null
// };

function MobileFilters(props) {
  const {
    filtersOpen,
    openFilters,
    closeFilters,
    filters,
    addFilterAndGetItems,
    initialFiltersState,
    children
  } = props;

  const [touched, setTouched] = useState(false);
  const [filtersWereApplied, setApplied] = useState(false);
  const [internFilters, setFilters] = useState(initialFiltersState);

  useEffect(() => {
    // const { status, product, quantity, courier, dateFrom, dateTo } = filters;
    setFilters(filters);
  }, [filters]);

  const handleDropdownChange = (e, { name, value }) => {
    setFilters({ ...internFilters, [name]: value });
    setTouched(true);
  };
  const handleDateChange = dateObj => {
    setFilters({ ...internFilters, ...dateObj });
    setTouched(true);
  };
  const handleApplyFilters = () => {
    addFilterAndGetItems(internFilters);
    setTouched(false);
    closeFilters();
    setApplied(true);
  };
  const handleCancelFilters = () => {
    setTouched(false);
    closeFilters();
    setFilters(initialFiltersState);
  };
  const handleClearAllFilters = () => {
    setApplied(false);
    setFilters(initialFiltersState);
    addFilterAndGetItems(initialFiltersState);
  };
  return (
    <>
      <div>
        {/* <Button
          color="yellow"
          size="mini"
          style={{ marginRight: "0.7em", marginTop: "1em" }}
          onClick={openFilters}
        >
          <Icon name="filter" />
          Add Filters
        </Button> */}
        {!filtersOpen && filtersWereApplied && (
          <Button size="mini" onClick={handleClearAllFilters}>
            <Icon name="times" />
            Clear Filters
          </Button>
        )}
      </div>

      {filtersOpen && (
        <Segment className="filters-segment" padded>
          {/* color="yellow" inverted secondary */}
          <Header
            as="h4"
            style={{ display: "block", margin: 0, lineHeight: "1.4" }}
          >
            Filters
          </Header>
          <Form className="mobile-filters">
            {React.cloneElement(children, {
              internFilters,
              handleDropdownChange,
              handleDateChange
            })}
          </Form>

          <Button
            size="small"
            color="blue"
            disabled={!touched}
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>

          <Button size="small" onClick={handleCancelFilters}>
            Cancel
          </Button>
        </Segment>
      )}
    </>
  );
}

const mapStateToProps = reduxState => ({
  filtersOpen: reduxState.filters.filtersOpen
});

export default connect(mapStateToProps, { openFilters, closeFilters })(
  MobileFilters
);
