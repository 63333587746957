import React, { useState, useEffect } from "react";
import { Header, Button, Input, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import { getFilteredProductList } from "../../../actions/product";

import ItemsPerPage from "../../ItemsPerPage";
import ProductList from "./ProductList";

function AddProduct(props) {
  const {
    getFilteredProductList,
    total,
    products,
    isLoading,
    addProducts,
    productsFromPG = []
  } = props;
  const {
    searchTerm,
    itemsPerPage,
    currentPage,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange
  } = useProductFilters(getFilteredProductList);

  return (
    <section className="pg-add-products">
      <Header>Add products to the group</Header>

      <div className="flex--between" style={{ marginBottom: "1.3em" }}>
        <Input
          loading={isLoading}
          placeholder="Search..."
          icon="search"
          value={searchTerm}
          onChange={handleSearch}
          action={{
            icon: "eraser", // "times"
            onClick: e => handleSearch(e, { value: "" })
          }}
          iconPosition="left"
        />

        {searchTerm.length > 0 && products.length > 0 && (
          <ItemsPerPage
            total={total}
            style={{ height: "1em", marginLeft: "1em" }}
            value={itemsPerPage}
            options={[10, 20, 30, 50, "All"]}
            handleChange={handleItemsPerPageChange}
          />
        )}
      </div>

      {searchTerm.length > 0 && (
        <ProductList
          products={products.filter(({ _id }) => !productsFromPG.includes(_id))}
          isLoading={isLoading}
          button={{
            action: addProducts,
            text: "Add",
            color: "teal",
            icon: "plus"
          }}
        />
      )}

      {searchTerm.length > 0 &&
        products.length > 0 &&
        total > itemsPerPage &&
        !isLoading && (
          <div style={{ marginTop: "1em" }}>
            <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(total / itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={handlePageChange}
            />
          </div>
        )}
    </section>
  );
}

const mapStateToProps = reduxState => ({
  products: reduxState.inventory.products,
  total: reduxState.inventory.productsTotal,
  isLoading: reduxState.loading.loading
});
export default connect(mapStateToProps, { getFilteredProductList })(AddProduct);

export function useProductFilters(getFilteredProductList) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm.length) {
      getFilteredProductList({
        currentPage,
        itemsPerPage,
        searchTerm,
        selectedBclients: []
      });
    }
  }, [currentPage, itemsPerPage]);
  useEffect(() => {
    if (searchTerm.length) {
      setCurrentPage(1);
      getFilteredProductList({
        currentPage: 1,
        itemsPerPage,
        searchTerm,
        selectedBclients: []
      });
    }
  }, [searchTerm]);

  const handleSearch = (e, { value }) => setSearchTerm(value);
  const handleItemsPerPageChange = (e, { value }) => setItemsPerPage(value);
  const handlePageChange = (e, { activePage }) => setCurrentPage(activePage);

  return {
    searchTerm,
    itemsPerPage,
    currentPage,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange
  };
}
