import React, { useState, useEffect } from "react";
import {SelectPicker} from 'rsuite'

import { getProductListLimit } from "../../actions/product";

function ProductFilter({
  handleChange,
  style,
  placeholder='select'
}) {
  const [options, setOptions] = useState([]);
  const [searchTerm,setSearch] = useState('')                                

  
  const getProducts = async ({searchTerm})=> getProductListLimit({searchTerm})
                                        .then(data=>setOptions(
                                          data.list.map(p => ({
                                                                                // role: p._id,
                                                                                value: p._id,
                                                                                label: `${p.name}-${p.codes.sku}`
                                                                              }))
    )).catch(error=>console.log(error))
  
  const handleSelected =(value)=>{
    const selected = options.filter(item=>item.value===value)

    handleChange({
          name: selected.length>0?selected[0].label:'',
           _id: value
    })
  }
  useEffect(()=>{getProducts({searchTerm})},[])

  useEffect(()=>{

    if(searchTerm)   getProducts({searchTerm})

  },[searchTerm])
  


  return (

    <SelectPicker
        placeholder={placeholder}
        data={options} 
        style={style}
        onSearch={(text,event)=>setSearch(text)}
        // onChange={(value,e)=>console.log(value,e)}
        onChange={handleSelected}
        onClean	={()=>setSearch('')}
    ></SelectPicker>
    // <Dropdown
    //   placeholder="product..."
    //   name={name}
    //   value={value}
    //   multiple={multiple}
    //   fluid={fluid}
    //   error={error}
    //   clearable
    //   selection
    //   search
    //   selectOnBlur={false}
    //   closeOnChange={true}
    //   onChange={handleChange}
    //   options={options}
    // />
  );
}


export default (ProductFilter)
