import React from "react";
import { Dropdown } from "semantic-ui-react";

const nrOfTabsToDisplay = 10;

function Tabs({ handleClick, totals = [], selectedStatus,isLoading }) {
  

  return (
    <div className="flex--between">
      <ul className="status-nav">
        {totals.map((tab, i) => {
          if (i < nrOfTabsToDisplay)
            return (
              <li
                key={i}
                className={`status-nav__item   ${
                  selectedStatus === tab[0] ? "status-nav__item_active" : null
                }`}
                onClick={() =>{if(!isLoading) handleClick({ name:'status',value: tab[0] })}}
              >
                {tab[0]}
                {tab[1]>0&&<span className="span-text_tabs">({tab[1]})</span>}
              </li>
            );
        })}

        <li style={{ listStyleType: "none", marginTop: "2px" }}>
          <Dropdown
            disabled={isLoading}
            style={{ minWidth: "7em" }}
            className={`status-nav__item`}
            placeholder="other..."
            name="status"
            search
            // clearable
            closeOnBlur
            closeOnChange
            // selection
            value={selectedStatus}
            onChange={(e, { value }) =>{if(!isLoading) handleClick({ name:'status',value})}}
            options={totals.slice(nrOfTabsToDisplay).map(([statusName], i) => ({
              key: i,
              value: statusName,
              text: `${statusName} ${totals[nrOfTabsToDisplay+i][1]>0?totals[nrOfTabsToDisplay+i][1]:''}`,
            }))}
          />
        </li>
      </ul>

      {/* <li className="status-nav__item">Other</li> */}
    </div>

  
  );
}

export default Tabs;
