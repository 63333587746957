import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";

import ExportExcel from "../ExportExcel";

function ExportButton(props) {
  const { inventory,filename } = props;
  const [dataSet, setData] = useState();

  const colNames = [
    { label: "SKU", value: "sku" },
    { label: "Product", value: "product" },
    { label: "Business Client", value: "businessclient" },
    { label: "Warehouse", value: "warehouse" },
    { label: "Available", value: "available" },
    // { label: "Blocked", value: "blocked" },
    // { label: "Total", value: "total" },
    // { label: "Turnover 7 days", value: "turnover7days" }
  ];

  useEffect(() => {
    if(!inventory||inventory.length===0) setData({})
    else {
      const tempDataSet = inventory.map(
        ({
          product_details = [{ codes: { sku: "" }, name: "" }],
          businessclient = { name: "" },
          warehouse = { name: "" },
          available,
          
          
        }) => {
          if (product_details && product_details) {
            return {
              sku: product_details.codes.sku || "",
              product: product_details.name || "",
              businessclient: businessclient.name || "",
              warehouse: warehouse.name || "",
              available,
            
            };
          }
          return {
            sku: "",
            product: "",
            businessclient: "",
            warehouse: "",
            available,
           
          };
        }
      );

      setData(tempDataSet)
    }
  }, [inventory]);

  return (
    <ExportExcel
      filename={filename}
      excelSheetName="list"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button basic color="grey" disabled={!inventory||!inventory.length}>
          <Icon name="excel file" />
          Export {`${inventory&&inventory.length}`}
        </Button>
      }
    />
  );
}
export default ExportButton;
