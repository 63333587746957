import React,{useEffect} from 'react'
import {signInAction} from '../actions/auth'
import { connect } from "react-redux";
// import {isMobile} from 'react-device-detect';

const Demoaccount =(props)=>{

const {username,password} = props.match.params
const {signInAction,isAuthenticated,}=props


useEffect(()=>

   {
       if(!isAuthenticated) signInAction({username,password})},[isAuthenticated]
    )


    return( 
        <div>   
            {isAuthenticated && window.location.replace("/orders")}
            {!isAuthenticated && <div>loading account...</div>}
            </div>
             

    )
}


export default connect(null,{signInAction})(Demoaccount)