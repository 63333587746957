import React, { useState } from "react";
import {
  Segment,
  Header,
  Input
} from "semantic-ui-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
// import DateFilter from "../../Filters/DateFilter";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import { useEffect } from "react";
import ExportMovementsButton from "./ExportMovementsButton";
import lang from '../../../lang/config.json'
import {DatePicker} from 'rsuite'

export const StockMovements = ({user,
  stockMovements: { movements = [], total },
  getMovements,
  productId,
  loading
}) => {
  const [filters, setFilters] = useState({
    dateFrom: null,
    dateTo: null,
    description: "",
    currentPage: 1,
    itemsPerPage: 10
  });

  
  const { description, currentPage, itemsPerPage } = filters;
  const handleChange = (e, { name, value }) =>
    setFilters(prev => ({ ...prev, [name]: value, currentPage: 1 }));
  const handleDateFromChange = dateFrom =>
    setFilters(prev => ({ ...prev, dateFrom, currentPage: 1 }));
  const handleDateToChange = dateTo =>
    setFilters(prev => ({ ...prev, dateTo, currentPage: 1 }));
  const handlePageChange = ({ currentPage }) =>
    setFilters(prev => ({ ...prev, currentPage }));

  useEffect(() => productId && getMovements({ id: productId, filters }), [
    productId,
    filters
  ]);

  const tableHeader = (
    <tr>
      <th>#</th>
      <th>
      {lang.products.viewproduct.description[user.currentUser.sys_lang]}

      </th>
      <th>        {lang.products.viewproduct.trigger[user.currentUser.sys_lang]}
</th>
      <th>        {lang.products.viewproduct.inventorycard[user.currentUser.sys_lang]}
</th>
      <th>        {lang.products.viewproduct.date[user.currentUser.sys_lang]}
</th>
      <th>        {lang.products.viewproduct.quantity[user.currentUser.sys_lang]}
</th>
      <th>        {lang.products.viewproduct.warehouse[user.currentUser.sys_lang]}
</th>
      <th>        {lang.products.viewproduct.available[user.currentUser.sys_lang]}
</th>
    </tr>
  );

  const tableBody = movements.map((move={triggerId:{_id:null,clientOrderNr:''}}, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>{move.description}</td>
      <td>
        {move.triggredBy}
        <br></br>
        {move.triggerId&&move.triggerId._id&&move.triggredBy == "Order" && (
          <span className="smallinfo">
            <Link target="_blank" to={`/orders/${move.triggerId._id}`}>
              {move.triggerId.clientOrderNr}
            </Link>
          </span>
        )}
        {move.triggerId&&move.triggerId._id&&move.triggredBy == "Inboundshipment" && (
          <span className="smallinfo">
            <Link
              target="_blank"
              to={`/shipments/inbound/${move.triggerId._id}`}
            >
              {move.triggerId._id}
            </Link>
          </span>
        )}
        {/* {move.triggredBy === "Purchaseorder" && (
          <span className="smallinfo">
            <Link target="_blank" to={`/pos/${move.triggerId}`}>
              {move.triggerId}
            </Link>
          </span>
        )} */}
      </td>
      <td>{move.inventory}</td>
      <td>
        <Moment format="DD/MM/YY hh:mm">{move.createdAt}</Moment>
      </td>
      <td>{move.quantity}</td>
      <td>{move.warehouse.name}</td>
      <td>{move.quantity_available}</td>
    </tr>
  ));

  return (
    <div className="card_">
      <Segment loading={loading}>
        <div className="stockmovement-top">
          <Header as="h4">
          {lang.products.viewproduct.stockmovements[user.currentUser.sys_lang]}

          </Header>
          <div className="stockmovement-filters">
            <Input
              className="stockmovement-filters__description"
              type="text"
              name="description"
              value={description}
              placeholder="description..."
              onChange={handleChange}
            />
            <div>
             <DatePicker
                   value={filters.dateFrom}
                   isClearable={true}
                   style={{width:'200px'}}
                   placement="topStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateFromChange(date)}
                   placeholder={lang.filters.datepicker.from[user.currentUser.sys_lang]}
                   oneTap
                 ></DatePicker>
         
     
                 <DatePicker
                  oneTap
                   value={filters.dateTo}
                    style={{width:'200px'}}
                   isClearable={true}
                  placement="topStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateToChange(date)}
                   placeholder={lang.filters.datepicker.to[user.currentUser.sys_lang]}
                 ></DatePicker>
             
              </div>   
            {/* <DateFilter
              style={{ marginRight: "0.5em", flexDirection: "row" }}
              dateFrom={dateFrom}
              dateTo={dateTo}
              handleDateFromChange={handleDateFromChange}
              handleDateToChange={handleDateToChange}
            /> */}
            <ItemsPerPage
              style={{ marginBottom: "0.5em" }}
              value={itemsPerPage}
              handleChange={handleChange}
              total={total}
            />
          </div>
          <div>
            <ExportMovementsButton items={movements} />
          </div>
        </div>
        {/* <Divider /> */}
        <table
          fixed
          className="hoverTable dataTable"
          // style = {{width:"100%"}}
        >
          <thead>{tableHeader}</thead>
          <tbody>{tableBody}</tbody>
        </table>

        <Pagination
          total={total}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          updateFilter={handlePageChange}
        />
      </Segment>
    </div>
  );
};
