import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Button, Divider, TextArea } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { createProduct } from "../../../actions/product";

const requiredFields = ["name", "sku"];
const initState = {
  product: {
    name: "",
    sku: "",
    barcode: "",
    color: "",
    size: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    temp: "",
    propertiesNote: "",
    storageNote: ""
  },
  validationErrors: {
    name: false,
    sku: false
  },
  formHasChanges: false,
  errMessage: ""
};

class NewProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = initState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
  }

  handleChange(e, { name, value }) {
    this.setState(prevState => {
      if (prevState[name] != value)
        return {
          ...this.state,
          formHasChanges: true,
          product: {
            ...this.state.product,
            [name]: value
          }
        };
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    // console.log("submit");

    const emptyReqField = {};
    for (let field of requiredFields) {
      if (!this.state.product[field]) emptyReqField[field] = true;
    }
    this.setState(prevState => ({
      validationErrors: { ...prevState.validationErrors, ...emptyReqField }
    }));

    const validationErrsPresent =
      !!Object.values(this.state.validationErrors).find(value => value) ||
      !!Object.values(emptyReqField).find(value => value);
    // console.log(validationErrsPresent);

    if (validationErrsPresent) {
      this.setState({
        errMessage: "Please fill in the required fields before submitting"
      });
      // console.log("error msg");
      return;
    }

    const {
      name,
      sku,
      barcode,
      color,
      size,
      height,
      length,
      width,
      weight,
      temp,
      propertiesNote,
      storageNote
    } = this.state.product;
    const product = {
      name,
      "codes.sku": sku,
      "codes.barcode": barcode,
      color,
      size,
      "proprities.weight": weight,
      "proprities.dimentions.height": height,
      "proprities.dimentions.lenght": length,
      "proprities.dimentions.width": width,
      "proprities.note": propertiesNote,
      "storage.temp": temp,
      "storage.note": storageNote
    };
    this.props.createProduct(product);
  }

  handleClearForm() {
    this.setState({ ...initState });
  }

  componentDidUpdate(prevProps, prevState) {
    for (let field of requiredFields) {
      // user typed and then cleaned the input -> show error
      if (
        prevState.product[field].length > 0 &&
        this.state.product[field].length === 0 &&
        this.state.formHasChanges
      )
        this.setState(prevState => ({
          validationErrors: { ...prevState.validationErrors, [field]: true }
        }));

      //if there had been an error and user started typing -> remove error
      if (
        prevState.product[field].length === 0 &&
        this.state.product[field].length > 0 &&
        this.state.validationErrors[field]
      )
        this.setState(prevState => ({
          validationErrors: { ...prevState.validationErrors, [field]: false }
        }));
    }

    if (
      !prevProps.errors.message &&
      this.props.errors.message &&
      this.props.errors.message.includes("E11000")
    )
      this.setState({
        errMessage:
          "SKU has to be unique. A product with the same SKU already exists"
      });
  }

  render() {
    const {
      name,
      sku,
      barcode,
      color,
      size,
      height,
      length,
      width,
      weight,
      temp,
      propertiesNote,
      storageNote
    } = this.state.product;

    return (
      <>
        <Form
          success
          autoComplete="off"
          className="product-form"
          onSubmit={this.handleSubmit}
        >
          {/* <div id="product_img">
                <img src="https://react.semantic-ui.com/imgs/wireframe/square-image.png" />
              </div> */}

          <div className="product-form__section product-form__name">
            <h4>Product Identification</h4>
            <Divider />
            <Form.Group>
              <Form.Input
                required
                error={this.state.validationErrors.name}
                label="Product name"
                placeholder="name"
                value={name}
                name="name"
                onChange={this.handleChange}
              />
              <Form.Input
                required
                error={this.state.validationErrors.sku}
                label="SKU"
                placeholder="SKU"
                name="sku"
                value={sku}
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Barcode"
                name="barcode"
                placeholder="Barcode"
                value={barcode}
                onChange={this.handleChange}
              />
            </Form.Group>
          </div>

          <div className="product-form__section product-form__options">
            <h4>Product options</h4>
            <Divider />
            <Form.Group>
              <Form.Input
                label="Color"
                name="color"
                placeholder="color"
                value={color}
                onChange={this.handleChange}
              />
              <Form.Input
                label="Size"
                placeholder="size"
                name="size"
                value={size}
                onChange={this.handleChange}
              />
            </Form.Group>
          </div>

          <div className="product-form__section product-form__description">
            <h4>Description</h4>
            <Divider />
            <Form.Group>
              <Form.Input
                label="Height"
                name="height"
                placeholder="cm..."
                value={height}
                onChange={this.handleChange}
              />
              <Form.Input
                label="Length"
                name="length"
                placeholder="cm..."
                value={length}
                onChange={this.handleChange}
              />
              <Form.Input
                label="Width"
                placeholder="cm..."
                value={width}
                name="width"
                onChange={this.handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Input
                label="Weight"
                placeholder="grams..."
                value={weight}
                name="weight"
                onChange={this.handleChange}
              />
            </Form.Group>
            <TextArea
              rows={2}
              placeholder="Other notes..."
              value={propertiesNote}
              name="propertiesNote"
              onChange={this.handleChange}
            />
          </div>

          <div className="product-form__section product-form__storage">
            <h4>Storage</h4>
            <Divider />
            <Form.Group>
              <Form.Input
                label="Temperature"
                placeholder="°C ..."
                value={temp}
                name="temp"
                onChange={this.handleChange}
              />
            </Form.Group>
            <TextArea
              rows={2}
              placeholder="Other notes..."
              value={storageNote}
              name="storageNote"
              onChange={this.handleChange}
            />
          </div>

          <Button
            color="blue"
            size="large"
            disabled={!this.state.formHasChanges}
            loading={this.props.loading}
            onClick={this.handleSubmit}
          >
            Create
          </Button>
          <Button
            type="clear"
            size="large"
            disabled={!this.state.formHasChanges}
            onClick={this.handleClearForm}
          >
            Clear
          </Button>
        </Form>

        {this.props.id && (
          <div className=" feedback-section feedback-section_success ">
            <p className="feedback-section__success">
              {/* <Icon name="check" color="green" /> */}
              Product successfully saved!
            </p>
            <Link to={`/products/${this.props.id}`}>See product</Link>
          </div>
        )}
        {this.state.errMessage && !this.props.id && (
          <div className=" feedback-section feedback-section_errors  ">
            <p className="feedback-section__errors">
              {/* <Icon name="check" color="green" /> */}
              {this.state.errMessage}
            </p>
          </div>
        )}
      </>
    );
  }
}
function mapsStateToProps(reduxState) {
  return {
    isLoading: reduxState.newproducts.isLoading,
    id: reduxState.newproducts.product._id
  };
}
export default connect(mapsStateToProps, { createProduct })(NewProductForm);
