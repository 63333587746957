
import {getOfficesV2} from '../../../../actions/orders'
import React, { useEffect,useState } from 'react'
import { Dropdown,Checkbox } from 'semantic-ui-react'

const Parcelshops =({country,courierId,selectedOffice,isOffice,office})=>{

    const [offices,setOffices]=useState([])
    const [gettingOffices,setGettingOffices] = useState(false)
    const [isShipToOffce,setIshipTooffice] = useState(false)

    useEffect(()=>{
      if( isShipToOffce||isOffice){
          setGettingOffices(true)
          getOfficesV2({ country, courierId})
          .then(resp=>{
              setOffices(resp)
              setGettingOffices(false)

            })
          .catch(err=>err)
      }
       
    },[isShipToOffce,isOffice])

    return (<div>
         <Checkbox
                label="Ship To Office(pick-up)"
                style={{ paddingRight: "2.5em" }}
                name={"order.api_request.to_office"}
                checked={isOffice?true:false}
                onChange={(e,{checked})=>{
                    selectedOffice(checked)

                                            }}
              />
              <br></br>
              {/* {office||offices.length > 0 && ( */}
                <Dropdown
                  fluid
                  placeholder='select office...'
                  label="Select Office"
                  loading={gettingOffices}
                  search
                  options={offices.map(item=>({
                      text:item.name,
                      value:item.code,
                      key:item.name
                  }))}
                  name={"api_request.office_code"}
                  value={office}
                  onChange ={(e,{value})=>
                    // console.log(data)
                    selectedOffice(value)
                }
                />
            
    </div>)
}


export default Parcelshops