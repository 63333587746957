import React from "react";
import Img from "react-image";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ItemList = ({
  goodsreceived,
  handleViewPhotos
}) => {
  // const files = goodsreceived.stockMovement.inventory.photos.files

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {/* <Table.HeaderCell>Photos</Table.HeaderCell> */}
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>IDS</Table.HeaderCell>
      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Position</Table.HeaderCell>
      <Table.HeaderCell>Quantity</Table.HeaderCell>
      
    </Table.Row>
  );

  const tableBody = goodsreceived.map((item, index) => {
    let product = {};
    if (!item.product) {
      product = {
        name: "NA",
        codes: { sku: "NA", barcode: "NA", clientsku: "NA" }
      };
    } else product = item.product;

    return (
      <Table.Row>
        <Table.Cell>{index + 1}</Table.Cell>
        
        <Table.Cell>
          <Link to={`/products/${item.product._id}`} target="_blank">
            {product.name}
          </Link>
         
        </Table.Cell>
        <Table.Cell>
          <span className="small--text bold--text">
            <span className="normal--text inbound-goodsreceived__label">
              Barcode:
            </span>
            {product.codes.barcode || ""}
            <br />
            <span className="normal--text inbound-goodsreceived__label">
              SKU:
            </span>
            {product.codes.sku || ""}
            {/* <br />
            <span className="normal--text">Client:</span>{" "}
            {product.codes.clientsku || ""} */}
          </span>
        </Table.Cell>
        <Table.Cell>
          {item.inventoryCard.description || ""}
        </Table.Cell>
        <Table.Cell>
          {item.inventoryCard.inhouse_position || ""}
        </Table.Cell>
        <Table.Cell>
          {item.stockMovement.quantity}
          {item.stockMovement.quantity_damaged
            ? `/${item.stockMovement.quantity_damaged}`
            : ""}
        </Table.Cell>
        
      </Table.Row>
    );
  });

  return (
    <Table basic="very" unstackable style={{ marginTop: "2em" }}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
};

export default ItemList;
