import React from "react";
import { Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";
// import { connect } from "react-redux";
// import { cancelOrder } from "../../../actions/orders";

function CancelOrderModal(props) {
  const { isModalOpen, handleCloseModal, handleOrderAction } = props;


  return (
    <Modal
      open={isModalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{
        modal: ""
      }}
    >
      <div style={{ padding: "2.5em 0.7em 1em" }}>
        <p>Are you sure you want to cancel this order?</p>
        <div style={{ marginTop: "1.5em" }} className="flex--right">
          <Button onClick={()=>handleOrderAction(false)}>No</Button>
          <Button color="red" onClick={()=>handleOrderAction('Cancel')}>
            Yes, Cancel Order
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default CancelOrderModal
