import React,{useState, useEffect} from 'react'
import {Modal,Button,Form,FormGroup,FormControl,Input,InputGroup,ControlLabel,Alert,HelpBlock} from 'rsuite'
// import CurrencyFilter from '../../Filters/CurrencyFilter'
import { Divider } from 'semantic-ui-react'
import {getRefund,updateRefund} from '../../actions/refunds'

const Viewrefund =({show=false,closeModal,refundId,updateList})=>{
const [isLoading,setLoading] = useState(true)
  const [formValue,setFormvalue]=useState({
    name:'',
    country:'',
    city:'',
    street:'',
    amount:1,
    IBAN:'',
    account_nr:'',
    account_code:'',
    currency:'EUR',
    phone:''

  })
  
 const submit =()=>{
          setLoading(true)

          updateRefund(refundId,formValue)
          .then(resp=>{
            closeModal()
            setLoading(false)
            updateList()
            Alert.success(JSON.stringify("Refund was updated"),3000)})
          .catch(error=>{  
            setLoading(false)
            closeModal()
            Alert.error(error.message,5000)})
        }
        
  useEffect(()=>{
    if(refundId){

      getRefund(refundId)
      .then(refund=>{
        setLoading(false)
        setFormvalue({
          ...formValue,
          country:  refund.country,
          amount:   refund.amount,
          city:     refund.city,
          street:   refund.street,
          currency: refund.currency,
          name:     refund.name,
          IBAN:     refund.IBAN,
          account_nr:refund.account_nr,
          account_code:refund.account_code,
          phone:    refund.phone
        })
      })
      .catch(error=>alert(error.message))
    }
    
  },[refundId])
  
    return (
        <Modal   show={show} onHide={closeModal} >
        <Modal.Header>
        <Modal.Title>Edit Refund</Modal.Title>
        </Modal.Header>
        <Divider></Divider>
        <Modal.Body>
        <Form 
            
              onChange={formValue => {  
                setFormvalue(formValue)
               
              }}
              formValue={formValue}
            >
              <div className='flex--column'>
              <ControlLabel>Amount</ControlLabel>
              <InputGroup style={{
                                width: 250,
                                marginBottom: 10
                              }}>
                <InputGroup.Addon>{formValue.currency}</InputGroup.Addon>
                <Input  
                    type='number'
                    value={formValue.amount} 
                    onChange={(value)=>setFormvalue({...formValue,amount:value})} />               
                    
              </InputGroup>

              {formValue.currency==='EUR'&&<FormGroup>
                <ControlLabel>IBAN</ControlLabel>
                <FormControl name="IBAN" value={formValue.IBAN}   disabled={isLoading}/>
              </FormGroup>}

              {formValue.currency!=='EUR'&&<div className='flex--left'>
              <FormGroup >
                <ControlLabel>Bank Account</ControlLabel>
                <FormControl style={{
                                width: 250,
                                // marginBottom: 10
                              }}
                              disabled={isLoading}
                              name="account_nr" value={formValue.account_nr}/>
              </FormGroup>
              
              <FormGroup >

                  <ControlLabel>Bank Code</ControlLabel>
                -  
                  <FormControl style={{
                                width: 100,
                                // marginBottom: 10
                              }}
                              disabled={isLoading}
                              name="account_code" value={formValue.account_code}/>
                </FormGroup>
                </div>
            }

              <Divider></Divider>
                {/* <FormControl      
                      type='number'
                      name="amount"
                      placeholder="amount"
                      style={{ width: 100,marginRight:'10px' }}
                  /> */}

                  {/* <CurrencyFilter
                      fluid={false}
                      handleChange={(e,{value})=>setCurrency(value)}
                      value={currency}
                      multiple={false}
                  /> */}
                  </div>
              <FormGroup>
                <ControlLabel>Name</ControlLabel>
                <FormControl name="name" value={formValue.name} disabled={isLoading}/>
                <HelpBlock>phone: {formValue.phone}</HelpBlock>

              </FormGroup>
              <div className='flex--left'>
              <FormGroup>
                <ControlLabel>Country</ControlLabel>
                <FormControl style={{
                                width: 150,
                                // marginBottom: 10
                              }}
                              name="country" value={formValue.country}
                              disabled={isLoading}
                              />
              </FormGroup>
              <FormGroup  style={{
                                marginLeft: 10
                              }}>
                <ControlLabel>City</ControlLabel>
                <FormControl style={{
                                width: 150,
                              }}
                              name="city" value={formValue.city}
                              disabled={isLoading}/>
              </FormGroup>
              </div>
              <FormGroup>
                <ControlLabel>Street</ControlLabel>
                <FormControl name="street" value ={formValue.street} disabled={isLoading}/>
              </FormGroup>
            
            </Form>          
    </Modal.Body>
 <Modal.Footer>
   <Button onClick={()=>submit()} appearance="primary">
     Ok
   </Button>
   <Button onClick={closeModal} appearance="subtle">
     Cancel
   </Button>
 </Modal.Footer>
</Modal>
       )
}
 



export default Viewrefund