import React from "react";
import { Tab } from "semantic-ui-react";

import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import NewProductForm from "./NewProductForm";
import ProductFromExcel from "./ProductFromExcel";

import "./newproducts.scss";

function NewProducts({ errors }) {
  const panes = [
    {
      menuItem: "Single Product Form",
      render: () => (
        <Tab.Pane attached={false}>
          <NewProductForm errors={errors} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Upload from Excel file",
      render: () => (
        <Tab.Pane attached={false}>
          <ProductFromExcel />
        </Tab.Pane>
      )
    }
  ];

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar
            header="Add new products"
            linkBack="/products"
          />
        }
      />
      <main className="page-wrapper">
        <Responsive
          desktopComponent={
            <Head header="Add new products" linkBack="/products" />
          }
        />
        <Tab
          menu={{
            color: "teal",
            inverted: false,
            attached: false,
            tabular: false,
            borderless: true
          }}
          panes={panes}
        />
      </main>
    </>
  );
}

export default NewProducts;
