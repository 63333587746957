import React,{useEffect,useState} from 'react'
import {apiCall} from '../../../services/api'
import { Alert,Input,Toggle,InputGroup,Icon,Modal,Message,Whisper,Popover} from 'rsuite'
import {Header,Segment,Button,Divider,Label,} from 'semantic-ui-react'
import axios from 'axios'
import lang from '../../../lang/config.json'
import {Link} from 'react-router-dom'
import {connect}from 'react-redux'
import woo from './info.png'
import infoproduct from './infoproduct.png'

const Eshoprychle =(props)=>{
    const {syslang} =props
    const {id} = props.match.params
    
    const [eshop,setEshop] = useState(null)
    const [shopUrl,setUrl] = useState()
    const [isChanged,setIschanged] = useState(false)
    const [showWarning,setShowWarning] = useState(false)
    const [loading,setIsloading]  = useState(false)
    const [importingProducts, setImportdingproducts] = useState(false)
    const [xmlFeed,setXmlfeed] = useState()
    const [showApifoto,setShowApiFoto] = useState(false)
    const [showProductFoto,setShowProductFoto] = useState(false)
    const [showApiWisper,setShowApiWisper] = useState(false)
    const [showProductWisper,setShowProductWisper] = useState(false)


  const importProducts=async ()=>{
    try {
    setIsloading(true)
    const url = `${process.env.REACT_APP_API}/eshops/eshoprychle/${id}/importproducts`;
    const importedProducts = await axios.get(url)

    if(importedProducts.data) Alert.success(`Products imported.`)
      setIsloading(false)
      setImportdingproducts(true)
    } catch (error) {
      setIsloading(false)
      Alert.error('Products not imported')  
    }
    
    }
  
  const testConnection=async ()=>{  
            setShowProductWisper(false)
            setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/eshoprychle/${id}/test`;
              try {

                await axios.get(url)

                setIsloading(false)
                setEshop({...eshop,eshop_rychle_settings:{...eshop.eshop_rychle_settings,is_setUp:true}})
                setShowApiWisper(!shopUrl)
                setShowProductWisper(true)

              } catch (error) {
                setIsloading(false)
                Alert.error(`API Connection error.`,10000)
              }
              
    }


 const updateEshop =async (query)=>{
      try {
          const url=`${process.env.REACT_APP_API}/eshops/eshoprychle/${id}`

          const updated = await axios.put(url,query)
        
          setEshop(updated.data)
          setUrl(updated.data.eshop_rychle_settings.orders_url)
          setShowApiWisper(false)
          setShowProductWisper(false)

          if(!updated.data.eshop_rychle_settings.orders_url)           setShowApiWisper(true)
          if(updated.data.eshop_rychle_settings.is_setUp&&!updated.data.eshop_rychle_settings.products_xml_feed) setShowProductWisper(true)

          Alert.success("Updated")
      } catch (error) {
          Alert.error("Error",5000)
      }
  }

 const handleDelete =async ()=>{
          const url=`${process.env.REACT_APP_API}/settings/eshops/${id}`

          const deleteShop = await axios.delete(url)
          if(deleteShop.data.status==='success') {
                      Alert.success("Deleted")
                      props.history.push(`/eshops`)

                
          } else Alert.error('Eshop could not be deleted.')
 }
   
 useEffect(()=>{
          const url = `${process.env.REACT_APP_API}/eshops/eshoprychle/${id}`;
          apiCall('get',url)
          .then(resp=>{
                setEshop(resp)
                setUrl(resp.eshop_rychle_settings.orders_url)
                setXmlfeed(resp.eshop_rychle_settings.products_xml_feed)

                if(!resp.eshop_rychle_settings.orders_url) setShowApiWisper(true)
                if(resp.eshop_rychle_settings.is_setUp&&!resp.eshop_rychle_settings.products_xml_feed) setShowProductWisper(true)

            })
          .catch(error=>Alert.error("Network Error"))

    },[id])

    const fotoApi = <Modal size='lg' show={showApifoto} onHide={()=>{
                      setShowApiFoto(false)
                      setShowApiWisper(true)
                      }}>
                      <Modal.Header></Modal.Header>
                      <Modal.Body>
                        <img style={{cursor:'zoom-in'}}src={woo} alt="help"></img>
                      </Modal.Body>
                    </Modal>

    const productFoto = <Modal size='lg' show={showProductFoto} onHide={()=>{
                      setShowProductFoto(false)
                      setShowProductWisper(true)
                      }}>
                      <Modal.Header></Modal.Header>
                      <Modal.Body>
                        <img style={{cursor:'zoom-in'}}src={infoproduct} alt="help"></img>
                      </Modal.Body>
                    </Modal>
                                        
    const ApiUrlHelper =  <div> <Message
                                // showIcon
                                type="warning"
                                // title="Nastavení"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    {/* <p>Postup:</p> */}
                                   
                                      <p>1/ Ve vaší Eshop-rychle administrativě, v menu Eshop/Export, v záložce Export objednávek - zkopírujte API adresu k objednávkám (viz obrazek)</p>
                                      <p>2/ Vložte API URL adresu do políčka "API URL</p>
                                  </div>
                                       <a href='none' onClick={()=>{
                                            setShowApiFoto(true)
                                            setShowApiWisper(false)
                                            }}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={woo} alt="help"></img></a> 
                                  </div>
                                  }
                              />
                                  </div>

    const productXMLHelper =<div> <Message
                                // showIcon
                                type="warning"
                                // title="Nastavení"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    {/* <p>Postup:</p> */}
                                   
                                      <p>Ve vaší Eshop-rychle administrativě, v menu Eshop/Export, v záložce Export produktů - vygenerujte XML FEED dle obrazku</p>
                                  </div>
                                       <a  href='none' onClick={()=>{
                                            setShowProductFoto(true)
                                            setShowProductWisper(false)
                                            }}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={infoproduct} alt="help"></img></a> 
                                  </div>
                                  }
                              />
                                  </div>


    const Speaker = ({ content, ...props }) => {
      return (
        <Popover  title="Nastavení" {...props}>
          {/* <p>This is a Popover </p> */}
          <p>{content}</p>
        </Popover>
      );
    };

    const CustomComponent = ({ placement,component,content,open }) => (
      <Whisper
        open={open}
        trigger="none"
        placement={placement}
        speaker={<Speaker content={content} />}
      >
       {component}
      </Whisper>
    );

    if(!eshop) return <div>loading...</div>
      return (
    <div>

    {fotoApi}
    {productFoto}
      
      <Header as="h2" style={
                {
                  marginLeft:'20px',
                  marginTop :'20px'
                }}>
                  {lang.settings.eshops.woo.settings[syslang]}
                </Header>
     

      <div id='settingstabs' className='flex--left' style={{marginTop:"30px"}}>
        <section style={{padding:'20px',maxWidth:'800px',width:'500px'}}>
      
        <Segment padded>
        
        
            <div className='flex--between'>    
                
              <h6>
                {lang.settings.eshops.woo.apiconnection[syslang]}
                </h6>

                <div>
               
                  {!eshop.eshop_rychle_settings.is_setUp && <Button primary 
                                                                    loading={loading} 
                                                                    disabled={loading||!eshop.eshop_rychle_settings.orders_url} 
                                                                    onClick={()=>testConnection()}>
                                                                        {lang.settings.eshops.woo.step1[syslang]}</Button>}
                  {eshop.eshop_rychle_settings.is_setUp  && <Label color='green'>{lang.settings.eshops.woo.connected[syslang]}</Label>}
                </div>
                  

            </div>
                  <Divider></Divider>

            <div>
               <label>Api Url</label>
               <CustomComponent
                placement="autoHorizontalStart"
                content={ApiUrlHelper}
                open={showApiWisper}
                component={<InputGroup >
                  {/* <InputGroup.Addon>https://www.</InputGroup.Addon> */}
                  <Input value={shopUrl} 
                         onChange={(value)=>{
                              setIschanged(true)
                              setUrl(value)
                                
                                                                 }
                              }/>
                </InputGroup>}
               >

               </CustomComponent>
             

            </div>
            <div className='flex--between' style={{marginTop:'10px'}}>

                <div></div>
                <Button 
                          disabled={!isChanged}
                          basic
                          onClick={()=>{
                            
                              updateEshop({
                                        'eshop_rychle_settings.orders_url':shopUrl,
                                        'eshop_rychle_settings.is_setUp':false,
                                            })
                          }}>Ok</Button>
             </div>    
             
             
             </Segment>

         <Segment loading={loading} disabled={!eshop.eshop_rychle_settings.is_setUp}>            
                    
                    <section>
                    <div className='flex--between'>    

                        <h4>{lang.settings.eshops.shoptet.productsettings[syslang]}</h4>
                        <div>
               
                          {!importingProducts && <Button primary
                                  disabled={!eshop.eshop_rychle_settings.products_xml_feed}
                                                                            loading={importingProducts}
                                                                            onClick={()=>importProducts()
                                                                            }>{lang.settings.eshops.eshoprychle.importproducts[syslang]}</Button>}
                          {importingProducts  && <Label color='green'>{lang.settings.eshops.eshoprychle.imported[syslang]}</Label>}
                        </div>
                        </div>
                        <Divider></Divider>
                        <div >
                                    <div>
                          <label>XML FEED:</label>
                          <CustomComponent
                            open={showProductWisper}
                            placement="autoHorizontalStart"
                            content={productXMLHelper}
                            component={  <InputGroup >
                              {/* <InputGroup.Addon>https://www.</InputGroup.Addon> */}
                              <Input value={xmlFeed} 
                                    onChange={(value)=>{
                                          setIschanged(true)
                                          setXmlfeed(value)
                                        }
                                          }/>
                            </InputGroup>}
                          />
                        

                        </div>
                        <div className='flex--between' style={{marginTop:'10px'}}>

                                <div></div>
                                <Button 
                                          disabled={!isChanged}
                                          basic
                                          onClick={()=>{
                                            updateEshop({'eshop_rychle_settings.products_xml_feed':xmlFeed})   
                                          }}>Ok</Button>
                                </div>  
                            </div>
                            
                        </section>
        
                    
                    
                    </Segment>


      <Segment disabled={!eshop.eshop_rychle_settings.is_setUp}>
                    <h4>{lang.settings.eshops.shoptet.ordersettings.title[syslang]}</h4>
                    <Divider></Divider>
                        <div style={{marginBottom:'10px'}}>

                            <Toggle
                                checked={eshop.eshop_rychle_settings.get_orders_api}
                                onChange={()=>{
                                        updateEshop({
                                        'eshop_rychle_settings.get_orders_api':!eshop.eshop_rychle_settings.get_orders_api,
                                        
                                            })
                                }}
                            ></Toggle>
                            <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importorders[syslang]}</span>
                            
                        </div>
                            <div style={{marginBottom:'10px',marginLeft:'10px'}}>
                            <Toggle
                                disabled={!eshop.eshop_rychle_settings.get_orders_api}
                                checked={eshop.eshop_rychle_settings.import_orders_all}
                                onChange={()=>{
                                    updateEshop({
                                        'eshop_rychle_settings.import_orders_all':!eshop.eshop_rychle_settings.import_orders_all,
                                        'eshop_rychle_settings.import_orders_only_selected_products':eshop.eshop_rychle_settings.import_orders_all===false?false:eshop.eshop_rychle_settings.import_orders_only_selected_products,
                                            })
                                }}
                            ></Toggle>
                            <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importallorders[syslang]}</span>
                        </div>
                        
                        <div style={{marginBottom:'10px',marginLeft:'10px'}}>
                          <Toggle 
                                disabled={!eshop.eshop_rychle_settings.get_orders_api}
                                checked={eshop.eshop_rychle_settings.import_orders_only_selected_products}
                                onChange={()=>{
                                    updateEshop({
                                        'eshop_rychle_settings.import_orders_only_selected_products':!eshop.eshop_rychle_settings.import_orders_only_selected_products,
                                        'eshop_rychle_settings.import_orders_all':eshop.eshop_rychle_settings.import_orders_only_selected_products===false?false:eshop.eshop_rychle_settings.import_orders_all,
                                            })
                                }}
                            ></Toggle>
                        <span style={{marginLeft:'10px'}}>{lang.settings.eshops.shoptet.ordersettings.importordersselected[syslang]} <Link to='/products'>{lang.table.settings[syslang]}</Link></span>

                        </div>
                        
                        {/* <div>
                        <Toggle
                                checked={importOrders_selected}
                                onChange={()=>{
                                        
                                }}
                            ></Toggle>
                                                        <span style={{marginLeft:'10px'}}>Import only if "Goods Available"</span>

                        </div> */}
                </Segment>

        <Modal backdrop="static" show={showWarning} onHide={()=>setShowWarning(false)} size="xs">
          <Modal.Body>
            <Icon
              icon="remind"
              style={{
                color: '#ffb300',
                fontSize: 24
              }}
            />
            {'  '}
           Are you sure you want to delete this Eshop?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={()=>{
              
              handleDelete()
              setShowWarning(false)}} appearance="primary">
              Ok
            </Button>
            <Button onClick={()=>setShowWarning(false)} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>



             <Button basic icon='trash' onClick = {()=>setShowWarning(true)}>Delete Eshop</Button>  
      </section>
      
     
           
      </div>
             
    </div>
  );
}

function mapStateToProps(reduxState){
  return ({
    syslang:reduxState.user.currentUser.sys_lang
  })
}
export default connect(mapStateToProps,{})(Eshoprychle)