import React, { useState, useEffect } from "react";
import {
  Table,
  Loader,
  // Checkbox,
  // Label,
  Button,
  Pagination,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import ItemsList from "./ItemsList";
import Modal from "react-responsive-modal";
// import ExportButton from './ExportButton'
import ItemsPerPage from "../../components/ItemsPerPage";
import { getInvoices } from "../../actions/invoices";
import { Alert} from "rsuite";
import Exportids from './Exportids'
import ExportButton from "../ExportButton";

// const status = { true: "Paid", false: "Not paid - In due" };
// const label = { true: "green", false: "yellow" };

function Invoicestable({ country, dateFrom, dateTo,clientOrderNr,sequenceNumber,setExportInputOpen,openModal }) {

  const [viewItems, setViewItems] = useState(false);
  const [currentPage, setPage] = useState(1);
  const [itemsPerPage, setItemsPerpage] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [total, setTotal] = useState([]);


  useEffect(() => {
    setLoading(true);

    getInvoices({
      dateFrom,
      dateTo,
      country,
      itemsPerPage,
      currentPage,
      clientOrderNr,
      sequenceNumber
      
    }).then((resp) => {
      setLoading(false);
      if (resp.foundInvoices) {
        setInvoices(resp.foundInvoices);
        setTotal(resp.total);
        setLoading(false);
      } else Alert.error(JSON.stringify(resp.message),5000);
    });
  }, [country, dateFrom, dateTo, itemsPerPage, currentPage,clientOrderNr,sequenceNumber]);

  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  // if (!isLoading && !invoices.length)
  //   return (
  //     <div style={{ marginTop: "2em", marginBottom: "2em" }}>
  //       <Header as="h4" textAlign="center">
  //         No bills found
  //       </Header>
  //     </div>
  //   );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {/* <Table.HeaderCell> <Checkbox

            onChange={(e,data)=>{
              // console.log(data.checked)
              if(!data.checked) setSelectedItems([])
              else CheckAll()
            }
            }
          /></Table.HeaderCell> */}

      <Table.HeaderCell>Number</Table.HeaderCell>
      <Table.HeaderCell>Order</Table.HeaderCell>
      <Table.HeaderCell>Customer</Table.HeaderCell>
      <Table.HeaderCell>Dates</Table.HeaderCell>
      <Table.HeaderCell>Total</Table.HeaderCell>
      <Table.HeaderCell>Currency</Table.HeaderCell>
      {/* <Table.HeaderCell>Country</Table.HeaderCell> */}
      <Table.HeaderCell>Actions</Table.HeaderCell>
      {/* <Table.HeaderCell>Status</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell> */}
    </Table.Row>
  );

  let tableBody = [];

  if (invoices)
    tableBody = invoices.map(
      (
        {
          _id,
          customer,
          // items,
          dates,
          sequenceNumber,
          currency,
          VAT_total,
          NETTO_total,
          BRUTTO_total,
          actionSourceId,
          // isPaid,
        },
        index
      ) => (
        <Table.Row key={_id}>
          <Table.Cell>
            {" "}
            {typeof itemsPerPage === "number" &&
              itemsPerPage * (currentPage - 1) + index + 1}
            {typeof itemsPerPage != "number" && index + 1}
          </Table.Cell>
          {/* <Table.Cell> 
          <Checkbox
            // label="Is paid"
            checked={selectedItems.includes(_id)}
            _id={_id}
            onChange={(e,data)=>{
              // console.log(data.checked)
              if(!data.checked) unselectItem(_id)
              else setSelectedItems([...selectedItems,_id])
            }
            }
          /></Table.Cell> */}
          <Table.Cell>
            <Link to={`/invoices/${_id}`}>
              {sequenceNumber ? sequenceNumber : "na"}
            </Link>{" "}
          </Table.Cell>
          <Table.Cell>
            <Link to={`/orders/${actionSourceId._id}`}>
              {actionSourceId.clientOrderNr
                ? actionSourceId.clientOrderNr
                : "na"}
            </Link>{" "}
          </Table.Cell>
          {/* <Table.Cell>{businessclient && businessclient.name}</Table.Cell> */}
          <Table.Cell>{customer && customer.firstName}</Table.Cell>

          <Table.Cell>
            <>
              <p className="small--text">
                Issued:{" "}
                <Moment utc format="DD-MM-YY HH:mm">
                  {dates && dates.issuedDate}
                </Moment>
              </p>
              <p className="small--text">
                Tax date:{" "}
                <Moment utc format="DD-MM-YY HH:mm">
                  {dates && dates.taxDate}
                </Moment>
              </p>
              <p className="small--text">
                Due date:{" "}
                <Moment utc format="DD-MM-YY HH:mm">
                  {dates && dates.dueDate}
                </Moment>
              </p>
            </>
          </Table.Cell>
          <Table.Cell>
            <>
              <p className="small--text">VAT total: {VAT_total.toFixed(2)}</p>
              <p className="small--text">
                NETTO total: {NETTO_total.toFixed(2)}
              </p>
              <p className="small--text">
                BRUTTO total: {BRUTTO_total.toFixed(2)}
              </p>
            </>
          </Table.Cell>
          <Table.Cell>{currency}</Table.Cell>

          <Table.Cell>
            <Link to={`/invoices/${_id}/pdf`}>
              <Button
                // primary
                compact
                basic
                color="red"
                size="tiny"

                // onClick={() => {
                //         setItems(items)
                //         setViewItems(true)}}
                // content="View"
              >
                Pdf
              </Button>
            </Link>
            <Button
              primary
              color="green"
              compact
              basic
              size="tiny"
              // onClick={() => {
              //         setItems(items)
              //         setViewItems(true)}}
              // content="View"
            >
              <Link to={`/invoices/${_id}`}>Edit</Link>
            </Button>
          </Table.Cell>
          {/* <Table.Cell>
          <Label color={label[isPaid]}>{status[isPaid]}</Label>
        </Table.Cell>
        <Table.Cell>
          <Checkbox
            label="Is paid"
            checked={isPaid}
            _id={_id}
            // onClick={handleCheck}
          />
        </Table.Cell> */}
        </Table.Row>
      )
    );

  return (
    <>
      <div className="flex--between">
        
        <div className="flex--left">
        <ExportButton
          styles={{ width: "350px",margin:'2px' }}
          total={""}
          filters={{  dateFrom, dateTo, country }}
          fileName={`invoices_${dateFrom.getDate()}.${
            dateFrom.getMonth() + 1
          }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
            dateTo.getMonth() + 1
          }.${dateTo.getFullYear()}`}
          url="/invoices/export"
           text={`Export Period: ${dateFrom.getDate()}.${dateFrom.getMonth()+1}.${dateFrom.getFullYear()}->${dateTo.getDate()}.${dateTo.getMonth()+1}.${dateTo.getFullYear()}`}

        />
        <ExportButton
          styles={{ width: "350px",margin:'2px' }}
          total={""}
          filters={{  dateFrom, dateTo, country }}
          fileName={`invoices_${dateFrom.getDate()}.${
            dateFrom.getMonth() + 1
          }.${dateFrom.getFullYear()}->${dateTo.getDate()}.${
            dateTo.getMonth() + 1
          }.${dateTo.getFullYear()}`}
          url="/invoices/export/creditnotes"
          text={`Credit Notes Period: ${dateFrom.getDate()}.${dateFrom.getMonth()+1}.${dateFrom.getFullYear()}->${dateTo.getDate()}.${dateTo.getMonth()+1}.${dateTo.getFullYear()}`}
          />

          <Button
            onClick={()=>setExportInputOpen(true)}
          >Export from ids</Button>
        </div>
        {/* <ExportButton
        // list={selectedItems}
        selectedObjects={invoices}
        // getSelectedObjects={getSelectedObjects}
        filename={`invoices_${dateFrom && dateFrom.getDate()}.${
          dateFrom && dateFrom.getMonth()
        }-${dateTo && dateTo.getDate()}.${dateTo && dateTo.getMonth()}`}
      ></ExportButton> */}

        <ItemsPerPage
          total={invoices.total}
          style={{ height: "1em" }}
          value={itemsPerPage}
          handleChange={(e, { value }) => setItemsPerpage(value)}
        />
      </div>

      <Table loading={isLoading} celled selectable>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{tableBody}</Table.Body>
      </Table>
      {!isLoading && total >= itemsPerPage && (
        <segment>
          {" "}
          <Pagination
            style={{ marginTop: "1em" }}
            activePage={currentPage}
            totalPages={Math.ceil(total / itemsPerPage)}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            onPageChange={(e, { activePage }) => setPage(activePage)}
          />
        </segment>
      )}
      <Modal
        open={viewItems}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={() => setViewItems(false)}
        center
        classNames={{ modal: "" }}
      >
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            paddingTop: "1.6em",
            paddingRight: "0.7em",
          }}
        >
          <ItemsList
          //  items={items}
          />
        </div>
      </Modal>
      <Exportids openModal={openModal} setExportInputOpen={setExportInputOpen}></Exportids>

    </>
  );
}

export default Invoicestable;
