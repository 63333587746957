import React from "react";
import {  Button,Pagination} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { getProductList } from "../../../actions/product.js";
import { getCouriers } from "../../../actions/couriers.js";
import {
  getOrders,
  updateFilter,
  removeSuccessMsg
} from "../../../actions/orders";

import Notification from "../../Notification";
import MobileFilters from "../../MobileFilters";
import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import Tabs from "./Tabs";
import OrdersTable from "./OrdersTable";
import CardsList from "../../CardsList";
import ItemCard from "./Mobile/ItemCard";
import MobileOrdersFilters from "./Mobile/MobileOrdersFilters";
import Filters from "./Filters";

import "../Order.scss";
import lang from '../../../lang/config.json'

class OrdersListPage extends React.Component {
  state = {
    checkedItems: [],
    headerIsChecked: false
  };

  handleSingleCheck = id => {
    this.setState(prevState => {
      if (prevState.checkedItems.includes(id)) {
        const i = prevState.checkedItems.indexOf(id);
        const len = prevState.checkedItems.length;
        return {
          checkedItems: [
            ...prevState.checkedItems.slice(0, i),
            ...prevState.checkedItems.slice(i + 1, len)
          ]
        };
      } else {
        return {
          checkedItems: [...prevState.checkedItems, id]
        };
      }
    });
  };

  handleClearCheckedItems = () => {
    this.setState({ checkedItems: [], headerIsChecked: false });
  };

  handleMultipleCheck = () => {
    if (!this.state.headerIsChecked) {
      this.setState({
        checkedItems: this.props.orders.map(el => el._id),
        headerIsChecked: true
      });
    } else {
      this.handleClearCheckedItems();
    }
  };

  addFilterAndGetOrders = newfilter => {
    if (this.state.checkedItems.length) this.handleClearCheckedItems();
    this.props.updateFilter(newfilter);
  };

  handleUpdateFilter = ({ name, value }) => {
    this.addFilterAndGetOrders({ [name]: value });
  };

  handleSearch = (e, { name, value }) => {
    this.addFilterAndGetOrders({ [name]: value, status: "All" });
  };

  componentDidMount() {
    this.props.getOrders(this.props.filters);
    this.props.getProductList();
    this.props.getCouriers();
  }

  componentDidUpdate(prevProps) {
    const keyWithDiffVal =
      prevProps.filters &&
      Object.keys(prevProps.filters).find(key => {
        if (prevProps.filters[key] !== this.props.filters[key]) {
          if (key === "searchTerm") {
            return this.props.filters[key].length > 2 ||
              (this.props.filters[key].length === 0 &&
                prevProps.filters[key].length > 0)
              ? true
              : false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
    if (keyWithDiffVal) {
      // if filters change
      this.props.getOrders(this.props.filters);
    }
  }

  render() {
    const { headerIsChecked, checkedItems } = this.state;

    const {
      products,
      couriers,
      filters,
      orders,
      totals,
      isLoading,
      successMsg,
      removeSuccessMsg,
      user
    } = this.props;

    let total = 0;
    const nr = totals.find((tab) => tab[0] === filters.status);
    if (nr && nr.length > 0) total = nr[1];

    return (
      <div className="orders-wrapper">
        <Responsive
          mobileComponent={
            <InfoBar
              filters={true}
              header={lang.orders.list.title[user.currentUser.sys_lang]}
              linkCreateNew="/orders/create/new"
            />
          }
        />

        <main className="page-wrapper">
          <Notification
            type={"success"}
            trigger={successMsg}
            removeNotification={removeSuccessMsg}
          />

          <Responsive
            desktopComponent={
              <Head header={lang.orders.list.title[user.currentUser.sys_lang]}>
                <Link to="/orders/create/new">
                  <Button>
                    {lang.orders.list.addneworder[user.currentUser.sys_lang]}
                  </Button>
                </Link>
              </Head>
            }
          />

          <Responsive
            breakpoint={700}
            mobileComponent={
              <MobileFilters
                filters={filters}
                initialFiltersState={{
                  status: "Order Ready for picking",
                  product: [],
                  quantity: "",
                  courier: [],
                  dateFrom: null,
                  dateTo: null,
                }}
                addFilterAndGetItems={this.addFilterAndGetOrders}
              >
                <MobileOrdersFilters products={products} couriers={couriers} />
              </MobileFilters>
            }
          />

          <Responsive
            breakpoint={600}
            mobileComponent={null}
            desktopComponent={
              <Tabs
                handleClick={this.handleUpdateFilter}
                totals={totals}
                selectedStatus={filters.status}
                user={user}
              />
            }
          />

          <Filters
            lang={lang}
            syslang={user.currentUser.sys_lang}
            filters={filters}
            products={products}
            couriers={couriers}
            total={total}
            checkedItems={checkedItems}
            orders={orders}
            handleUpdateFilter={this.handleUpdateFilter}
            handleSearch={this.handleSearch}
            addFilterAndGetOrders={this.addFilterAndGetOrders}
          />

          <Responsive
            // breakpoint={600} // default
            mobileComponent={
              <CardsList
                items={orders}
                isLoading={isLoading}
                notFoundMsg="No Orders Found"
              >
                <ItemCard />
              </CardsList>
            }
            desktopComponent={
              // <div>hello</div>
              <OrdersTable
                orders={orders}
                checkedItems={checkedItems}
                headerIsChecked={headerIsChecked}
                handleSingleCheck={this.handleSingleCheck}
                handleMultipleCheck={this.handleMultipleCheck}
                currentPage={filters.currentPage}
                itemsPerPage={filters.itemsPerPage}
                checkColumn={true}
                actionColumn={false}
                isLoading={isLoading}
                user={user}
              />
            }
          />
          {total > 0 && filters.itemsPerPage && !isLoading && (
            <Pagination
              style={{ marginTop: "1em" }}
              activePage={filters.currentPage}
              totalPages={Math.ceil(total / filters.itemsPerPage)}
              boundaryRange={1}
              siblingRange={1}
              firstItem={null}
              lastItem={null}
              onPageChange={(e, { activePage }) =>
                this.handleUpdateFilter( {
                  name: "currentPage",
                  value: activePage,
                })
              }
            />
          )}
        </main>
      </div>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    user: reduxState.user,
    orders: reduxState.orders.list,
    filters: reduxState.orders.filters,
    totals: reduxState.orders.totals,
    // updating: reduxState.orders.updating,
    products: reduxState.inventory.products,
    couriers: reduxState.couriers.list,
    isLoading: reduxState.orders.isLoading,
    successMsg: reduxState.orders.successMsg
  };
}

export default connect(mapStateToProps, {
  getOrders,
  updateFilter,
  getProductList,
  getCouriers,
  removeSuccessMsg
})(OrdersListPage);
