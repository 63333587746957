import {
  GET_COURIERS,
  GET_COURIERS_BY_WAREHOUSE,
  GET_COURIER,
  UPDATE_COURIER_FILTER,
  GET_COURIER_QUOTATIONS,
  GET_COURIERS_BY_COUNTRY,
} from "../actions/actionTypes";

const initState = {
  list: [],
  listByWarehouse: [],
  listByCountry: [],
  openCourier: {},
  filters: {
    country: [],
  },
  quotations: [],
};

const couriers = (state = initState, action) => {
  switch (action.type) {
    case GET_COURIERS:
      return { ...state, list: action.payload };
    case GET_COURIERS_BY_WAREHOUSE:
      return { ...state, listByWarehouse: action.payload };
    case GET_COURIER:
      return { ...state, openCourier: action.payload };
    case UPDATE_COURIER_FILTER:
      return { ...state, filters: { ...state.filters, ...action.payload } };

    case GET_COURIER_QUOTATIONS:
      return { ...state, quotations: action.payload };
    case GET_COURIERS_BY_COUNTRY:
      return { ...state, listByCountry: action.payload };
    default:
      return state;
  }
};

export default couriers;
