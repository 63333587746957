import React, { useState } from "react";
import {
  Button,
  Segment,
  Input,
  Divider,
  Header,
  Table,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  createProductGroup,
  getFilteredProductList
} from "../../../actions/product";

import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import AddProducts from "../EditViewPG/AddProducts";
import "../productGroup.scss";

function CreateNewPG(props) {
  const { createProductGroup, history } = props;
  const [products, setProducts] = useState([]);
  const [viewProducts, setViewProducts] = useState([]);
  const [name, setName] = useState("");

  const handleNameChange = (e, { value }) => setName(value);
  const addProducts = (arr, objArr) => {
    setProducts([...products, ...arr]);
    setViewProducts([...viewProducts, ...objArr]);
  };
  const removeProduct = id => {
    setProducts([...products.filter(i => i !== id)]);
    setViewProducts([...viewProducts.filter(({ _id }) => _id !== id)]);
  };
  const handleSubmit = e => e.preventDefault();
  const handleCreate = () => createProductGroup(history, { products, name });

  const tableBody = viewProducts.map(({ _id, name, sku }, index) => (
    <Table.Row key={_id}>
      <Table.Cell verticalAlign="bottom">{index + 1}</Table.Cell>
      <Table.Cell verticalAlign="middle">
        <span>{name}</span> - <span className="grey--text">{sku}</span>
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          size="tiny"
          color="red"
          basic
          icon="trash"
          onClick={() => removeProduct(_id)}
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar
            header="New Product Group"
            linkBack="/products/list/group"
            buttons={
              <Button compact size="tiny" color="blue" onClick={handleCreate}>
                Create Group
              </Button>
            }
          />
        }
      />
      <main className="page-wrapper product-group-container">
        <Responsive
          desktopComponent={
            <Head header="New Product Group" linkBack="/products/list/group">
              <Button color="blue" onClick={handleCreate}>
                Create Group
              </Button>
            </Head>
          }
        />
        {/* <Link to="/products/list/group">
        <Icon name="arrow left" /> Product group List
      </Link> */}
        <Segment>
          <div className="product-group-columns">
            <div className="product-group-columns__info">
              <form autocomplete="off" onSubmit={handleSubmit}>
                <Header> Product-group name</Header>
                <Input
                  name="name"
                  placeholder="product-group name..."
                  focus
                  onChange={handleNameChange}
                />
              </form>
              <br />

              {viewProducts.length > 0 && (
                <Table unstackable selectable style={{ marginTop: "1.3em" }}>
                  <Table.Body>{tableBody}</Table.Body>
                </Table>
              )}
            </div>
            <div className="product-group-columns__add-prod">
              <AddProducts
                addProducts={addProducts}
                productsFromPG={products}
              />
            </div>
          </div>
        </Segment>
      </main>
    </>
  );
}

export default connect(null, { createProductGroup, getFilteredProductList })(
  CreateNewPG
);
