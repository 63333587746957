import React from "react";

// import CanView from "../../role-based-access/CanView";
import EditableField from "./EditableField";

function FormField({ label, name, value, placeholder, handleChange, options,editable }) {
  return (
    <div className="view-grid__item">
      <label className="view-grid__label">{label}:</label>
      {/* <CanView
        path="/businessclients/:id"
        action="edit"
        fallback={<div className="editable-field_non-editable">{value}</div>}
      > */}
        <EditableField
          name={name}
          initialValue={value}
          placeholder={placeholder}
          handleContentUpdate={handleChange}
          options={options}
          label={placeholder}
          editable={editable}
        />
      {/* </CanView> */}
    </div>
  );
}

export default FormField;
