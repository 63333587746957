import {apiCall} from "../services/api"
import {addError,removeError} from "./error"
import {
    ADD_GR_DOCUMENTS,
    UPLOADING_GR_DOCUMENTS,
    ADD_DOCUMENTS_POS
} from './actionTypes'




export const uploadDocuments = ({files}) => {
 
    const url = `${process.env.REACT_APP_API_FILES}/upload`

   return  apiCall('post', url, files) //upload file to fileserver 
                 .then(files => {
                    
                    if (files.length > 0) { //files created > sent back meta data
                        //update local db with file data (path,destination,)
                    
                    const url = `${process.env.REACT_APP_API}/files/create`
                    return apiCall('post', url,files)
                                        .then(files => files)
                                        .catch(err => console.log(err))
                                    }
                                    else{ 
                                        console.log('no files response...');
                                        
                                        return {status:'error.',message:"Files server not responding."}}
                })
                    .catch(err => {
                        return {
                            status: 'error.',
                            message: "Files server not responding."
                        }
                    })
            }

const addDocuments = (documents) => ({
    type: ADD_DOCUMENTS_POS,
    documents
})

export const removeFiles = (files)=>{ 
    
        const url = `${process.env.REACT_APP_API}/files`
        return apiCall('post', url,{files})
                .then(resp =>resp)
                .catch(err => console.log(err))
    }
   

export const removeDocument = ({po,id}) => (dispatch => {

    const url = `${process.env.REACT_APP_API}/pos/${po}/documents/${id}`
    apiCall('delete', url)
        .then(documents => dispatch(addDocuments(documents || [])))
        .catch(err => dispatch(addError(err.message)))
})