import React, { useState, useEffect } from "react";
import { Dropdown, Header } from "semantic-ui-react";

import { getProductListLimit } from "../../actions/product";

function ProductFilter({
  warehouse,
  handleChange,
  fluid = false,
  style = { minWidth: "350px" },
  placeholder = "product...",
  selectOnBlur = false,
  closeOnChange = true,
  disabled = false,
  multiple = false,
  clearable = true,
  className,
}) {
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearch] = useState("");
  const [item, setItem] = useState(multiple ? [] : null);

  useEffect(() => {

      getProductListLimit({ searchTerm, warehouse })
        .then((data) => setProducts(data.list))
        .catch((error) => console.log(error.message));
  }, [ searchTerm, warehouse]);

  return (
    <Dropdown
      clearable={clearable}
      multiple={multiple}
      style={style}
      selection={true}
      fluid={fluid}
      disabled={disabled}
      placeholder={placeholder}
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      className={className}
      search
      onSearchChange={(e, data) => setSearch(data.searchQuery)}
      label="Product"
      name="product"
      value={item}
      options={products.map((product) => {
        const { product_details, warehouse, available,codes,name,_id } = product;

        if (product_details) {
          return {
            value: product_details._id,
            // text: `${product_details.name} -
            //         [sku:${product_details.codes?product_details.codes.sku:''}]
            //        -[ Stock ${warehouse.name}:${available}]`,
            text: `${product_details.name}-[sku:${
              product_details.codes ? product_details.codes.sku : ""
            }]`,
            key: `${product_details._id}_${warehouse.name}`,
            content: (
              <div>
                <Header
                  size="sm"
                  content={`${product_details.name}`}
                  subheader={`
                                                        [sku:${
                                                          product_details.codes
                                                            ? product_details
                                                                .codes.sku
                                                            : ""
                                                        }]
                                                       -[ Stock ${
                                                         warehouse.name
                                                       }:${available}]`}
                ></Header>
              </div>
            ),
          };
        } else
          return {
            value: _id,
            text: `${name} - sku:[${codes.sku}]`,
          };
      })}
      // loading={products.length == 0}
      onChange={(e, data) => {
        console.log(data)
        handleChange(null, {
          name: "product",
          value: multiple ? data.value : [data.value],
          options: data.options,
        });
        setItem(data.value);
      }}
    />
  );
}

export default ProductFilter;
