import React from "react";
import { Loader, Header } from "semantic-ui-react";

// import ItemCard from "./Order/ListOrders/Mobile/ItemCard";

function CardsList({ items=[], isLoading, notFoundMsg, children }) {
  if (isLoading) {
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );
  }

  if (!items.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
       .....
      </Header>
    );
  }
  return (
    <section>
      {items.map(
        (item, index) =>
          React.cloneElement(children, { item, index, key: item._id })
        // passing props to children
        // <ItemCard order={item} key={item._id} />
      )}
    </section>
  );
}

export default CardsList;
