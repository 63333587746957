import React from "react";
import EditableText from "../../EditableText";
import lang from '../../../lang/config.json'

function ProductInfoGrid(props) {
  const { handleFormChange, productForm,user } = props;

  return (
    <div className="product-info-grid">
      <div className="product-info-grid--part">
        <span className="product-info-grid--label">
          {lang.products.viewproduct.name[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"name..."}
          value={productForm.name}
          name={"name"}
          handleChange={handleFormChange}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.sku[user.currentUser.sys_lang]}
        </span>

        <EditableText
          placeholder={"SKU..."}
          value={productForm.codes.sku}
          name={"codes.sku"}
          handleChange={handleFormChange}
        />

        {/* <span className="product-info-grid--label">Client Id:</span> */}
        {/* <EditableText
          placeholder={"client id..."}
          value={productForm.codes.client_id}
          name={"codes.client_id"}
          handleChange={handleFormChange}
        />  */}

        <span className="product-info-grid--label">Barcode:</span>
        <EditableText
          placeholder={"barcode..."}
          value={productForm.codes.barcode ? productForm.codes.barcode : ""}
          name={"codes.barcode"}
          handleChange={handleFormChange}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.color[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"color..."}
          value={productForm.color ? productForm.color : ""}
          name={"color"}
          handleChange={handleFormChange}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.size[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"size..."}
          value={productForm.size}
          name={"size"}
          handleChange={handleFormChange}
        />
      </div>
      <div className="product-info-grid--part">
        <span className="product-info-grid--label">
          {lang.products.viewproduct.height[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"height..."}
          value={
            productForm.proprities && productForm.proprities.dimentions
              ? productForm.proprities.dimentions.height
              : ""
          }
          name={"proprities.dimentions.height"}
          handleChange={handleFormChange}
          maxWidth={"5em"}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.length[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"lenght..."}
          value={
            productForm.proprities && productForm.proprities.dimentions
              ? productForm.proprities.dimentions.lenght
              : ""
          }
          name={"proprities.dimentions.lenght"}
          handleChange={handleFormChange}
          maxWidth={"5em"}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.width[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"width..."}
          value={
            productForm.proprities && productForm.proprities.dimentions
              ? productForm.proprities.dimentions.width
              : ""
          }
          name={"proprities.dimentions.width"}
          handleChange={handleFormChange}
          maxWidth={"5em"}
        />
        <span className="product-info-grid--label">
          {lang.products.viewproduct.weight[user.currentUser.sys_lang]}
        </span>
        <EditableText
          placeholder={"weight..."}
          value={productForm.proprities ? productForm.proprities.weight : ""}
          name={"proprities.weight"}
          handleChange={handleFormChange}
        />
      </div>
    </div>
  );
}

export default ProductInfoGrid;
