import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

import { getWarehouses } from "../../actions/warehouses";

import { setDropList } from "../../actions/filtersmain";
import { connect } from "react-redux";

function WarehouseFilter({
  name = "warehouse",
  style={},
  handleChange,  
  multiple = true,
  fluid = false,
  selection = true,
  error = false,
  clearable=true,
  closeOnChange=true,
  selectOnBlur=false,
  disabled=false,
  placeholder="warehouse...",
  
  value=undefined,
  warehouse,
  setDropList,
  droplists

}) {

  const [searchTerm,setSearch] = useState()
  const [item,setItem]         = useState()                        

useEffect(() => {
    
   if(droplists.warehouses.length===0) 
        getWarehouses({searchTerm})
          .then (data=> setDropList({name:'warehouses',list:data}))
          .catch(error=>alert(error.message))

      }, [searchTerm]);
  
useEffect(()=>{
    if(value) setItem(value)
    else setItem(warehouse)
    
        },[value])

return (
    <Dropdown
      style       ={style}
      disabled    ={disabled}
      placeholder ={placeholder}
      name        ={name}
      value       ={item}
      multiple    ={multiple}
      fluid       ={fluid}
      error={error}
      clearable   ={clearable}
      selection   ={selection}
      search
      selectOnBlur={selectOnBlur}
      closeOnChange={closeOnChange}
      onChange={(e,{name,value})=>{
                // handleChange(null,{name:'warehouse',value:multiple?data.value:[data.value]}) 
                handleChange(null,{name,value}) 
                setItem(value)
              }
              }      
      options={droplists.warehouses.map((item) => ({
                                                  value: item._id,
                                                  text: item.name,
                                                  key: item._id,
                                                  }))
                                                }
      onSearchChange={(e,data)=>setSearch(data.searchQuery)}

    />
  );
}

function mapStateToProps(reduxState){
  return {
    droplists:reduxState.droplists,
    warehouse: reduxState.filtersmain.warehouse,
    
  }
}
export default connect(mapStateToProps,{setDropList})(WarehouseFilter);
