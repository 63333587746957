const filters = (state = { filtersOpen: false }, action) => {
  switch (action.type) {
    case "OPEN_FILTERS":
      return { ...state, filtersOpen: true };
    case "CLOSE_FILTERS":
      return { ...state, filtersOpen: false };
    default:
      return state;
  }
};
export default filters;
