import React, { Component } from "react";
import {   Segment } from "semantic-ui-react";
import { DatePicker,SelectPicker } from "rsuite";
import ProductFilter from '../../Filters/ProductFilter'

class Filters extends Component {
  render() {
    const {
      warehouses,
      companies,
      filters,
      handleUpdateFilter,
      handleDateChange,
      user,lang
    } = this.props;
    return (
      <div >
        <Segment>
        <div className="flex_alignLeft">
          {/* <Header as="h4">{lang.filters.title[user.currentUser.sys_lang]}</Header> */}
        </div>

        <div className="filters-container flex--left" style={{ marginBottom: 0 }}>
          <div className="filters-wrapper_mb filters-wrapper flex--left">
            <div className="filters-item_no-mb filters-item">
              {/* Status: */}
            <SelectPicker
              placeholder={lang.filters.status[user.currentUser.sys_lang]}
              value={filters.status}
                                  style={{width:'200px'}}

              onChange={(value)=>handleUpdateFilter({value,name:'status'})}
              searchable={false}
              data={[{label:"New",value:"New"},{label:"Processed",value:"Processed"}]}
              
              
            />

            </div>

            <div className="filters-item_no-mb filters-item">
              {/* Seller: */}
            <SelectPicker
              placeholder={lang.filters.supplier[user.currentUser.sys_lang]}
              value={filters.seller}
                                  style={{width:'200px'}}

              onChange={(value)=>handleUpdateFilter({value,name:'seller'})}
              searchable={true}
              data={companies.map(item=>({label:item.name,value:item._id}))}
              
              
            />

            </div>
            
              {/* Destination: */}
              <SelectPicker
                placeholder={lang.filters.warehouse[user.currentUser.sys_lang]}
                value={filters.warehouse}
                                    style={{width:'200px'}}

                onChange={(value)=>handleUpdateFilter({value,name:'warehouse'})}
                searchable={true}
                data={warehouses.map(item=>({label:item.name,value:item._id}))}   
            />

           
            

<ProductFilter
                  placeholder={lang.filters.product[user.currentUser.sys_lang]}
                  style={{width:"275px"}}
                  handleChange={(data)=>handleUpdateFilter({value:data._id,name:'product'})}
              />          
          </div>
         
         
        </div>
        </Segment>
        <div className='flex--between'>
         <div>
             <DatePicker
                   value={filters.dateFrom}
                   isClearable={true}
                   style={{width:'200px'}}
                   // placement="leftStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateChange({ dateFrom: date })}
                   placeholder={lang.filters.datepicker.from[user.currentUser.sys_lang]}
                   oneTap
                 ></DatePicker>
         
     
                 <DatePicker
                  oneTap
                   value={filters.dateTo}
                    style={{width:'200px'}}
                   isClearable={true}
                   // placement="leftStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateChange({ dateTo: date })}
                   placeholder={lang.filters.datepicker.to[user.currentUser.sys_lang]}
                 ></DatePicker>
             
              </div>   
                
                <SelectPicker
                    cleanable={false}
                    defaultValue	={{label:"10",value:"10"}}
                    value={filters.displayView}
                    onChange={(value)=>handleUpdateFilter({value,name:'displayView'})}
                    searchable={false}
                    data={[
                      { label: "10", key: "10", value: 10 },
                      { label: "30", key: "30", value: 30 },
                      { label: "50", key: "50", value: 50 },
                      { label: "All", key: "All", value: 0 }
                    ]}
  
                    
                  />
      </div></div>
    );
  }
}

export default Filters;
