import React, { useEffect,useState } from "react";
import { Header, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

// import {
  
//   updateInvoiceFilters,
// } from "../../../actions/invoice";
import {
  getInvoices,  getInvoiceCreditNotesSummary as getCreditNotesSummary,
  updateInvoiceFilters
} from '../../../actions/reports'
// import CanViewPage from "../../../../role-based-access/CanViewPage";
import InvoicePerCountryList from "./InvoicePerMonthList";
import Filters from "./Filters";

function InvoicesList({
  invoices,
  creditNotes,
  // filters,
  isLoading,
  total,
  getInvoices,
  updateInvoiceFilters,
  getCreditNotesSummary
}) {
  
  const [dateFrom,setFrom] = useState()
  const [dateTo,setTo]    = useState()

  useEffect(() => {
  
      getInvoices({dateFrom,dateTo});
      getCreditNotesSummary({dateFrom,dateTo});
    
  }, [dateFrom,dateTo]);

  const handleFilterChange = ({ name, value }) =>{

  }


  return (
    // <CanViewPage path="/reports/invoices">
      <main className="page-wrapper">
        <Header>Invoices</Header>

        <Filters
          filters={{dateFrom,dateTo}}
          setFrom={setFrom}
          setTo={setTo}
          updateFilters={updateInvoiceFilters}
        />
        <Segment padded>
          <div className="flex--right">
            {/* <ItemsPerPage
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
              options={[10, 30, 50, "All"]}
            /> */}
          </div>
          <InvoicePerCountryList
            // businessclient={businessclient}
            invoices={invoices}
            creditNotes={creditNotes}
            // filters={itemsPerPage,currentPage}
            isLoading={isLoading}
            // currentPage={currentPage}
            // itemsPerPage={itemsPerPage}
          />
          {/* {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={({ currentPage }) =>
                updateInvoiceFilters({ currentPage })
              }
            />
          )} */}
        </Segment>
      </main>
    // </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  // filters: reduxState.services.invoices.filters,
  isLoading: reduxState.Invoices.isLoading,
  invoices: reduxState.Invoices.invoices.list,
  creditNotes: reduxState.Invoices.creditNotesSummary,
  total: reduxState.Invoices.invoices.total,
});
export default connect(mapStateToProps, {
  getInvoices,
  updateInvoiceFilters,
  getCreditNotesSummary,
})(InvoicesList);
