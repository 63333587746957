import React, { useState } from 'react'
import {connect} from 'react-redux'
import {Message,Input,Button} from 'rsuite'
import { apiCall } from "../../../services/api";
import {setAuthorizationToken,setCurrentUser,signUpAction} from '../../../actions/auth'
import logo from './wyzbox_activation_small.jpg'
import {Alert} from 'rsuite'
import lang from '../../../lang/config.json'

const styles = {
  width: 300,
  marginBottom: 10
};

const EshoprychleSignup =(props)=>{

const syslang='CZ'
const {signUpAction} = props
const[password,setPassword] = useState()
const[username,setUsername] = useState()
const [isLoading,setLoading] = useState(false)

const handleSubmit =()=>{
        signUpAction({username,password},props.history)
      
}


    return (<div className='flex--between' style={{height:'100%'}}>
              <div>
                <img style={{width:"100%",height:"100%",objectFit:"cover",overflow:'hidden'}} src={logo} alt='logo'></img>
              </div>
               <Message
                    type="info"
                    title={lang.settings.eshops.shoptet.activate.landing.title[syslang]}
                    style={{width:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}
                    description={
                      <div>
                          <p>
                          {lang.settings.eshops.shoptet.activate.landing.title2[syslang]}
                          </p>
                            <br />
                            <div>
                                <Input 
                                        onChange={(data)=>setUsername(data)}
                                         placeholder={lang.placeholders.username[syslang]}
                                        style={styles}
                                          />                                
                              
                                <Input 
                                        onChange={(data)=>setPassword(data)}
                                        placeholder={lang.placeholders.password[syslang]}
                                        style={styles}
                                        type='password'
                                          />                                
                          
                            </div>
                            <div className='flex--between'>
                              <div></div>

                                <Button
                                  loading={isLoading}
                                  appearance="primary"
                                  style={{
                                      height:'35px',
                                      marginLeft:'15px',
                                      width:'100px'
                                          }}
                                  onClick={handleSubmit}
                    >
                      Ok
                    </Button>
                        </div>
                       
                      </div>
                    }
                  />
                
                  
             </div>)
}
function mapStatetoProps(reduxstate){
  return(
    {
      syslang:reduxstate.user.currentUser.sys_lang,
      user:reduxstate.user.currentUser,
      
    }
  )
}
export default connect(mapStatetoProps,{signUpAction})(EshoprychleSignup)