import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Segment } from "semantic-ui-react";

import {
      getProduct,
      updateProduct,
      getMovements,
      pairToOrders
    } from "../../../actions/product";

import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
// import { Label } from "../label";
// import { Websites } from "../web";
import { Documents } from "../documents";
import {  files } from "../data";
import StockAvailable from "./StockAvailable";
import { StockMovements } from "./StockMovements";
import ProductInfoGrid from "./ProductInfoGrid";
import lang from '../../../lang/config.json'

import "../product.scss";
import { ReactComponent as NoImg } from "../../../no-img.svg";
import ClientIds from "./Clientids";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addLabel: false,
      addFile: false,
      addWebsite: false,
      formHasChanges: false,
      productForm: {
        name: "",
        "codes.sku": "",
        "codes.barcode": "",
        color: "",
        size: "",
        "proprities.weight": "",
        "proprities.dimentions.height": "",
        "proprities.dimentions.lenght": "",
        "proprities.dimentions.width": ""
      }
    };
    this.handleAddView    = this.handleAddView.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleUpdateForm = this.handleUpdateForm.bind(this);
  }

  handleUpdateForm() {
    this.setState({
      formHasChanges: false
    });
    this.props.updateProduct(
      this.props.match.params.id,
      this.state.productForm
    );
  }

  handleFormChange({ name, value }) {
    this.setState(prevState => ({
      formHasChanges: true,
      productForm: {
        ...prevState.productForm,
        [name]: value
      }
    }));
  }

  handleAddView(key) {
    this.setState({ ...this.state, [key]: !this.state[key] });
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getProduct(id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.product !== this.props.product) {
      const { name, codes, color, size, proprities } = this.props.product;
      // const hxlxw = proprities.dimentions
      //   ? proprities.dimentions.height
      //     ? proprities.dimentions.height
      //     : " " + "x" + proprities.dimentions.lenght
      //     ? proprities.dimentions.lenght
      //     : " " + "x" + proprities.dimentions.width
      //     ? proprities.dimentions.width
      //     : " "
      //   : "";
      if (proprities)
        this.setState({
          productForm: {
            name,
            "codes.sku": codes.sku,
            "codes.barcode": codes.barcode,
            color,
            size,
            "proprities.weight": proprities.weight,
            "proprities.dimentions.height": proprities.dimentions && proprities.dimentions.height?proprities.dimentions.height:0,
            "proprities.dimentions.lenght": proprities.dimentions&&proprities.dimentions.lenght?proprities.dimentions.lenght:0,
            "proprities.dimentions.width": proprities.dimentions&&proprities.dimentions.width?proprities.dimentions.width:0
          }
        });
    }
  }

  render() {
    const {  formHasChanges } = this.state;
    const { product, stockMovements, stockTotals, getMovements,user,loading } = this.props;
    const panels = [
      {
        key: "details",
        title:lang.products.viewproduct.productdocuments[user.currentUser.sys_lang]
,
        content: {
          content: (
            <Documents
              user={user}
              
              files={files}
              addFile={this.state.addFile}
              handleAddView={this.handleAddView}
            />
          )
        }
      }
    ];

    //**PRODUCT INFO... */
    const productInfo = (
      <div className="card_">
        <Segment>
          <div className="flex_spaceBetween vertical--bottom">
            {formHasChanges && (
              <Button onClick={this.handleUpdateForm} color="teal">
                {lang.products.viewproduct.updatebutton[user.currentUser.sys_lang]}
              </Button>
            )}
          </div>
          {/* <Divider /> */}
          <div className="product_info">
            <div className="product-row__img-container">
              {product.photos && product.photos.lenght ? (
                <img alt={product.name} src={product.photos[0]} />
              ) : (
                <NoImg />
              )}
            </div>

            <ProductInfoGrid
              user={user}
              handleFormChange={this.handleFormChange}
              productForm={product}
            />
          </div>
        </Segment>
      </div>
    );

  

    return (
      <>
        <Responsive
          mobileComponent={
            <InfoBar header=   {lang.products.viewproduct.title[user.currentUser.sys_lang]}
                linkBack="/products" />
          }
        />
        <div className="page-wrapper">
          <Responsive
            desktopComponent={
              <Head header=                {lang.products.viewproduct.title[user.currentUser.sys_lang]}
                linkBack="/products">
                {this.props.product.notPairedOrders > 0 && (
                  <div>
                    Unpaired orders {this.props.product.notPairedOrders}
                    <Button
                      onClick={() =>
                        this.props.pairToOrders(this.props.match.params.id)
                      }
                    >
                      pair
                    </Button>
                  </div>
                )}
              </Head>
            }
          />

          {/* {editMode} */}
          <div className="product">
            {productInfo}
            {/* {documents} */}
            
            
            <ClientIds
              client_ids={this.props.product.client_ids}
            ></ClientIds>
            <StockAvailable user={user} stockTotals={stockTotals} />

            <StockMovements
              user ={user}
              stockMovements={stockMovements}
              getMovements={getMovements}
              productId={this.props.match.params.id}
              loading={loading}
            />
          </div>
        </div>
      </>
    );
  }
}
function mapsStateToProps(reduxState) {
  return {
    product: reduxState.inventory.openProduct,
    stockMovements: reduxState.inventory.openProduct.stockMovements,
    loading: reduxState.picking.isLoading,

    stockTotals: reduxState.inventory.openProduct.stockTotals,
    user:reduxState.user
  };
}
export default connect(mapsStateToProps, {
  getProduct,
  updateProduct,
  getMovements,
  pairToOrders
})(Product);

