
import React ,{useState} from 'react'
import {apiCall} from '../../../services/api'
import {
  Container,Message,Content,FlexboxGrid,
  ButtonToolbar,Form,FormGroup,
  Panel,FormControl,Button,Alert,
InputGroup,Input} from 'rsuite'
import woo from './woo.png'
import Modal from "react-responsive-modal";


const Woologin =(props) =>{
  
  
  const [consumer_key,setconsumer_key] = useState()
  const [shopUrl,setUrl] = useState('')
  const [consumer_secret,setconsumer_secret] = useState()
  const [isModalOpen,setModalOpen]  = useState(false)

  const createwooShop =()=>{
  const url = `${process.env.REACT_APP_API}/eshops/woo`;

     apiCall('post',url,{consumer_key,consumer_secret,shopUrl})
      .then(eshop=>{
        
        Alert.success('added new Eshop')
        props.history.push(`/eshops/woocommerce/${eshop._id}`)
      })
      .catch(error=>Alert.error(error.message,5000))
  }


  const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          <img style={{cursor:'zoom-in'}}src={woo} alt="help"></img>
                      </Modal>
  
  return (
  <div >
{viewImg}
    <Container>

        <Message
                                showIcon
                                type="warning"
                                title="Pas 1. Chei de access Woocommerce"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    <p>Instructiuni:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Logativa in contul personal Woocommerce</li>
                                      <li>{`2. Generati cheile de access. 
                                            Panoul de Control[Woocommerce] > Setari > Avansat > REST API > Adauga cheie`}
                                          </li>
                                      {/* <li>{`3. Copiati utilizatorul din tabelul cu IP pentru a fi inclus in campul de mai jos "consumer_key:"`}
                                          </li> */}
                                      
                                    </ul>
                                  </div>
                                  
                                       <a  onClick={()=>setModalOpen(true)}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={woo} alt="help"></img></a> 
                                  </div>
                                  }
                              />

      <Content>
                  
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>Woo LOGIN</h3>} bordered>
              <Form fluid >
                   <Message
                                // showIcon
                                type="info"
                                title="Eshop URL"
                                description={
                                  <div>
                                   <InputGroup >
                                      <InputGroup.Addon>https://www.</InputGroup.Addon>
                                      <Input value={shopUrl} onChange={(value)=>{setUrl(value)}}/>
                                    </InputGroup>
                                  </div>
                                  }
                              />
                       <Message
                                // showIcon
                                type="warning"
                                title="Chei de acces"
                                description={
                                  <div>
                                    <p>consumer_key</p>
                                      <FormControl name="customer_key" onChange={(value)=>setconsumer_key(value)} />
                                      <p>consumer_secret</p>
                                      <FormControl name="consumer_secret" onChange={(value)=>setconsumer_secret(value)}/>
                                  </div>
                                  }
                              />
                
                  
                
                
                  
                
                <FormGroup>
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={()=>createwooShop()}>Login</Button>
                    
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  </div>)
}

export default Woologin