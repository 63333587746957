import React from "react";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { ReactComponent as NoImg } from "../../../no-img.svg";

function MobileExpandedItemCard({ item }) {
  const {
    _id,
    codes,
    name,
    photos,
    createdAt,
    updatedAt,
    proprities,
    size,
    storage,
    color
  } = item;

  return (
    <Segment>
      <div className="expanded-product-card">
        <div className="expanded-product-card--grid">
          <div className="product-row__img-container">
            {photos.lenght ? <img alt={name} src={photos[0]} /> : <NoImg />}
          </div>

          <div>
            <Link
              className="x-large--text bold--text expanded-product--link"
              to={`/products/${_id}`}
            >
              {name}
            </Link>

            <div className="expanded-product-card--grid expanded-product-card--grid_codes">
              <span className="product-row__label">SKU:</span>
              <span className="dark-blue--text bold--text">{codes.sku}</span>
              <span className="product-row__label">Barcode:</span>
              <span className="dark-blue--text bold--text">
                {codes.barcode}
              </span>
            </div>
          </div>
        </div>

        <Divider />

        <div
          className="expanded-product-card--grid"
          style={{ paddingTop: "0.3em" }}
        >
          <span className="small--text">Date:</span>
          <div>
            <span className="small--text">Updated at:</span>{" "}
            <Moment
              style={{
                color: "#565656",
                whiteSpace: "pre",
                paddingRight: "1em"
              }}
              format="DD-MM-YY HH:mm"
            >
              {updatedAt}
            </Moment>
            <br />
            <span className="small--text">Created at:</span>{" "}
            <Moment
              style={{ color: "#565656", whiteSpace: "pre" }}
              format="DD-MM-YY HH:mm"
            >
              {createdAt}
            </Moment>
          </div>

          {(size || color) && (
            <>
              <span className="small--text">Options:</span>
              <div className="product-row__options">
                {size && (
                  <p className="product-row__row">
                    <span className="product-row__label">Size:</span> {size}
                  </p>
                )}
                {color && (
                  <p className="product-row__row">
                    <span className="product-row__label">Color:</span> {color}
                  </p>
                )}
              </div>
            </>
          )}

          {proprities && (
            <>
              <span className="small--text">Properties:</span>
              <div className="product-row__options">
                {proprities.weight && (
                  <p className="product-row__row">
                    <span className="product-row__label">Weight:</span>{" "}
                    {proprities.weight}g
                  </p>
                )}

                {proprities.dimentions && (
                  <p className="product-row__row">
                    <span className="product-row__label">Dimensions:</span>{" "}
                    {Object.values(proprities.dimentions)
                      .filter(d => d)
                      .map(d => d + "cm")
                      .join(" x ")}
                  </p>
                )}
              </div>
            </>
          )}

          {storage && (
            <>
              <span className="small--text">Storage:</span>

              <div className="product-row__storage">
                {storage.temp && (
                  <p className="product-row__row">
                    <span className="product-row__label">Temperatura:</span>{" "}
                    {storage.temp} °C
                  </p>
                )}
                {storage.note && (
                  <p className="product-row__row">
                    <span className="product-row__label">Note:</span>{" "}
                    {storage.note}
                  </p>
                )}
              </div>
            </>
          )}

          {/* <span>Courier:</span>
          <p>{shipping.courier ? shipping.courier.name : ""}</p> */}
        </div>
      </div>
    </Segment>
  );
}

export default MobileExpandedItemCard;
