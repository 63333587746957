import {
  
  GET_BUSINESSCLIENT,
  UPDATE_BUSINESSCLIENT,
  CREATE_BUSINESSCLIENT_SETTINGS,
  UPDATE_BUSINESSCLIENT_SETTINGS,


} from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";
import {Alert} from 'rsuite'

const startLoading = { type: "START_LOADING" };
const stopLoading = { type: "STOP_LOADING" };


export const createBusinessClient = (history) => (dispatch) => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients`;
  apiCall("post", url)
    .then((resp) => {
        dispatch({type:'USER_ADD_BUSINESSCLIENT',payload:resp._id})
        history.push(`/profile/${resp._id}`)
      })
    .catch((err) => dispatch(addError(err.message)));
};
export const getBusinessClient = (id) => (dispatch) => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type:GET_BUSINESSCLIENT, payload: resp }))
    .catch((err) => dispatch(addError(err.message)));
};

export const updateBusinessClient = ({ id, query, type }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${id}`;
  apiCall("put", url, { query, type })
    .then((resp) => dispatch({ type: UPDATE_BUSINESSCLIENT, payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getBusinessProfile = (id) =>{
  
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
  return apiCall("get", url)
    .then((resp) =>
      resp
    )
    .catch((error) => {
      Alert.error(error.message)
    });
};

export const createBusinessProfile = (id) => (dispatch) => {
  // dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
  apiCall("post", url)
    .then((resp) =>
      dispatch({ type: CREATE_BUSINESSCLIENT_SETTINGS, payload: resp })
    )
    .catch((error) => {
      // dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};


export const updateBusinessProfile = ({ id, update }) => (dispatch) => {
  // dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${id}/businessprofile`;
  apiCall("put", url, update)
    .then((resp) =>
      dispatch({ type: UPDATE_BUSINESSCLIENT_SETTINGS, payload: resp })
    )
    .catch((error) => {
      // dispatch(stopLoading);
      dispatch(addError(error.message));
    });
}


// Sequences
export const getSequences = (client) => (dispatch) => {
  dispatch({ type: "START_SEQUENCES_LOADING" });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/settings/sequences?client=${client}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_SEQUENCES", payload: resp }))
    .catch((error) => {
      dispatch({ type: "STOP_SEQUENCES_LOADING" });
      dispatch(addError(error.message));
    });
};
export const createSequence = (sequence) => (dispatch) => {
  dispatch({ type: "START_SEQUENCES_LOADING" });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/settings/sequences`;
  apiCall("post", url, sequence)
    .then((resp) => dispatch({ type: "CREATE_SEQUENCE", payload: resp }))
    .catch((error) => {
      dispatch({ type: "STOP_SEQUENCES_LOADING" });
      dispatch(addError(error.message));
    });
};
export const updateSequence = ({ id, update }) => (dispatch) => {
  dispatch({ type: "START_SEQUENCES_LOADING" });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/settings/sequences/${id}`;
  apiCall("put", url, update)
    .then((resp) => dispatch({ type: "UPDATE_SEQUENCE", payload: resp }))
    .catch((error) => {
      dispatch({ type: "STOP_SEQUENCES_LOADING" });
      dispatch(addError(error.message));
    });
};
export const deleteSequence = (id) => (dispatch) => {
  dispatch({ type: "START_SEQUENCES_LOADING" });
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/settings/sequences/${id}`;
  apiCall("delete", url)
    .then((resp) => dispatch({ type: "DELETE_SEQUENCE", payload: resp }))
    .catch((error) => {
      dispatch({ type: "STOP_SEQUENCES_LOADING" });
      dispatch(addError(error.message));
    });
};

export const addClientIds = (newClientIds) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${newClientIds.businessclient}/companyids`;
  apiCall("post", url, newClientIds)
    .then((resp) => dispatch({ type: "CREATE_CLIENTIDS", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateClientIds = ({ clientId, id, update }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${clientId}/companyids/${id}`;
  apiCall("put", url, update)
    .then((resp) => dispatch({ type: "UPDATE_CLIENTIDS", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const deleteClientIds = ({ clientId, id }) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${clientId}/companyids/${id}`;
  apiCall("delete", url)
    .then((resp) => dispatch({ type: "DELETE_CLIENTIDS", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateBusinessClientContact = ({
  bclientId,
  contactId,
  updatedContact,
}) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${bclientId}/contacts/${contactId}`;
  apiCall("put", url, updatedContact)
    .then((resp) => dispatch({ type: UPDATE_BUSINESSCLIENT, payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};


export const updateBusinessClientBankAccount = ({
  bclientId,
  accountId,
  updatedBankAccount,
}) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/businessclients/${bclientId}/bankaccounts/${accountId}`;
  apiCall("put", url, updatedBankAccount)
    .then((resp) => dispatch({ type: UPDATE_BUSINESSCLIENT, payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};
