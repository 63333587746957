import React from "react";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

function ItemCard({ item, index, currentPage, itemsPerPage }) {
  const { description, quantity, vat_procent, NETTO } = item;

  return (
    <Segment>
      <span className="bill-items-index">{index + 1}.</span>

      <div className="order-card--grid">
        <span className="small--text">Description:</span>
        <span>{description}</span>

        <span className="small--text">Quantity:</span>
        <span>{quantity}</span>

        <span className="small--text">Unit price (NETTO):</span>
        <span>{NETTO}</span>

        <span className="small--text">VAT rate (%):</span>
        <span>{vat_procent}</span>

        <span className="small--text">Total NETTO:</span>
        <span>{quantity * NETTO}</span>

        <span className="small--text">Total BRUTTO:</span>
        <span>{(quantity * NETTO * (100 + Number(vat_procent))) / 100}</span>
      </div>
    </Segment>
  );
}

export default ItemCard;
