import React,{useEffect,useState} from 'react'
import {apiCall} from '../../../services/api'
import { Alert,Input,Toggle,Message,InputGroup } from 'rsuite'
import {Header,Segment,Button,Divider,Label,} from 'semantic-ui-react'
import Modal from "react-responsive-modal";
import lang from '../../../lang/config.json'

import {connect}from 'react-redux'

const Esteto =(props)=>{
    const {syslang} =props
    const {id} = props.match.params
    const [eshop,setEshop] = useState(null)

    const [consumer_key,setconsumer_key] = useState()
    const [consumer_secret,setconsumer_secret] = useState()
    const [shopUrl,setUrl] = useState()
    const [isChanged,setIschanged] = useState(false)
    const [newPass,setNewpass]  = useState(false)
    const [loading,setIsloading]  = useState(false)
    const [isModalOpen,setModalOpen]  = useState(false)

    const updateCredentials =()=>{
        setIschanged(false)
        setNewpass(false)
        
        const url = `${process.env.REACT_APP_API}/eshops/esteto/${id}`;

         apiCall('put',url,{consumer_key,consumer_secret,shopUrl})
          .then(resp=>{
                Alert.success('Updated success')
                setEshop(resp)
                setconsumer_key(resp.api_username)
                
            })
          .catch(error=>Alert.error(error))

    }

    const testConnection=()=>{

            setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/esteto/${id}/test`;
               apiCall('get',url)
                .then(resp=>{
                      Alert.success('connection good')
                      setIsloading(false)
                      
                      setEshop({...eshop,isActive:true})
                  })
                .catch(error=>
                    {
                      
                      setIsloading(false)
                      Alert.error(`${error.message}`,10000)})
    }


    const importProducts=()=>{
      setIsloading(true)
              const url = `${process.env.REACT_APP_API}/eshops/esteto/${id}/importproducts`
               apiCall('get',url)
                .then(resp=>{
                      Alert.success(`Imported new products: ${resp.imported}`,10000)
                      setIsloading(false)
                  })
                .catch(error=>
                    {
                      setIsloading(false)
                    Alert.error(`${error.message}`,10000)})
    }


    const updateEshop =async (query)=>{
      try {
          const url=`${process.env.REACT_APP_API}/settings/eshops/${id}`

       apiCall('put',url,query).then(resp=>{
                          
                              setEshop(resp)
                            })

      } catch (error) {
          Alert.error(error.message,5000)
      }
  }

    useEffect(()=>{
          const url = `${process.env.REACT_APP_API}/eshops/esteto/${id}`;
          apiCall('get',url)
          .then(resp=>{
                setEshop(resp)
                setUrl(resp.eshopUrl)
                setconsumer_key(resp.api_username)
            })
          .catch(error=>Alert.error(error.message))

    },[id])

    const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          {/* <img style={{cursor:'zoom-in'}}src={woo} alt="help"></img> */}
                      </Modal>
    if(!eshop) return <div>loading...</div>
      return (
    <div>
      {viewImg}
      <Header as="h2" style={
                {
                  marginLeft:'20px',
                  marginTop :'20px'
                }}>
                  {lang.settings.eshops.woo.settings[syslang]}
                </Header>


      <div className='flex--left' style={{marginTop:"30px"}}>
      <section style={{padding:'20px',maxWidth:'800px'}}>
      
        <Segment padded>
        
        
            <div className='flex--between'>    
                
              <h6>
                {lang.settings.eshops.woo.apiconnection[syslang]}
                </h6>
                  
                <div>
               
                  {!eshop.isActive && <Button color='orage' loading={loading} disabled={loading} onClick={()=>testConnection()}>{lang.settings.eshops.woo.step1[syslang]}</Button>}
                  {eshop.isActive  && <Label color='green'>{lang.settings.eshops.woo.connected[syslang]}</Label>}
                </div>

            </div>
                  <Divider></Divider>

            <div>
               <label>Eshop Url:</label>
               <InputGroup >
                  <InputGroup.Addon>https://www.</InputGroup.Addon>
                  <Input value={shopUrl} 
                         onChange={(value)=>{
                              setIschanged(true)
                              setUrl(value)
                              }
                              }/>
                </InputGroup>

                <label>consumer_key:</label>   
                <Input 
                  size='md'
                  placeholder='Username'
                  name='Username'
                  value ={consumer_key}
                  onChange ={(value)=>{
                    setIschanged(true)
                    setconsumer_key(value)
                  }}
                  ></Input>
            </div>
            <div>
             

                <label>consumer_secret:</label>   
                {newPass&&<Input size ='md'
                            placeholder='consumer_secret'
                            onChange ={(value)=>{
                                              setIschanged(true)
                                              setconsumer_secret(value)
                  }}
                ></Input>}
                <br></br>
                {!newPass&&<button onClick={()=>setNewpass(true)}>change</button>}
            </div>
            
            
           {isChanged && <div className='flex--between'>

                <div></div>
                <Button 
                          color='blue' 
                          onClick={()=>updateCredentials()}>Update</Button>
             </div>}
                          
      </Segment>

  
      <Segment padded 
      disabled={!eshop.isActive}
      >
            <div className='flex--between'>    
            
                      <h6>{lang.settings.eshops.woo.products[syslang]}</h6>
                      
              </div>
            <Divider></Divider>

                  
            
              <section>
               
                              <span>
                                Import Estero products to wyzbox. If orders will have products not in wyzbox, the product will be added automaticaly.
                                </span>
                              </section>    
            
                  <Divider></Divider>
                    <div>
                      
                      <Button disabled={loading||!eshop.isActive} loading={loading} 
                      onClick={()=>importProducts()}
                          >{lang.settings.eshops.woo.step2[syslang]}</Button></div>
      </Segment>
      <Segment>
            <Message
                          type={!eshop.import_orders?"warning":"success"}
                          // title="Descarcarea automat de comenzi noi"
                          description={
                            <div className='flex--between'>
                              <h6>
                            {lang.settings.eshops.woo.ordersimportmsg[syslang]}
                            </h6>
 
                         
                            <Toggle 
                                  disabled={!eshop.isActive}
                                  checked={eshop.import_orders}
                                  onChange={()=>updateEshop({
                                    'import_orders':!eshop.import_orders,
                                        })
                                      }
                              />
                                
                              </div>
                          }
                        />
      </Segment>
      <Button basic size='tiny'
        onClick={()=>updateEshop({'isActive':false})
            }
        >Turn off</Button>

      </section>
      
      </div>
    </div>
  );
}

function mapStateToProps(reduxState){
  return ({
    syslang:reduxState.user.currentUser.sys_lang
  })
}
export default connect(mapStateToProps,{})(Esteto)