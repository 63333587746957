import React, { useEffect } from "react";
import { Header, Segment,Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import Filters from './Filters'

import { getStockTurnover, updateFilters } from "../../../actions/reports";
import { getWarehouses} from "../../../actions/inventory";

import ExportButton from "./ExportButton";

// import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";

import "./services.scss";
import Turnovertable from "./Turnovertable";

function Stocksturnover({
  stocks,
  filters,
  isLoading,
  total,
  getStockTurnover,
  updateFilters,

  getWarehouses,
  warehouses
}) {
  useEffect(() => getStockTurnover(filters), [filters]);
  useEffect(() => getWarehouses(filters), [filters.warehouse]);

  const { itemsPerPage, currentPage,days } = filters;

  const handleFilterChange = (e, { name, value }) => {
    updateFilters({ [name]: value, currentPage: 1 });
  };

  const handlePageChange = ({ currentPage }) => {
    updateFilters({ currentPage });
  };


  const optionsDaysSelect = ['7','14','30'].map(nr => ({
    key: nr,
    value: nr,
    text: nr
  }));

  return (
    // <CanViewPage path="/reports/turnover">
      <main className="page-wrapper">
        <div className="flex--between">
          <Header style={{ marginBottom: 0 }}>Turnover</Header>
          <ExportButton />
        </div>
        <Filters
          filters={filters}
          handleFilterChange={handleFilterChange}
          updateFilters={updateFilters}
          warehouses={warehouses}
        />
        <Segment>
          <div className="flex--right">
            {/* <ItemsPerPage
              style={{ marginLeft: "10px" }}
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
            /> */}
            Days:
            <Dropdown
                  selectOnBlur={false}
                  closeOnChange={true}
                  compact
                  selection={true}
                  name="days"
                  value={filters.days}
                  onChange={handleFilterChange}
                  options={optionsDaysSelect}
                  style={{ marginLeft: "10px" }}
              />
          </div>
          <Turnovertable
           
            stocks={stocks}
            isLoading={isLoading}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            value ={days}
            style={{ marginLeft: "10px" }}
            handleChangeDays={handleFilterChange}
            // isHeaderChecked={isHeaderChecked}
            // checkedItems={checkedItems}
            // handleSingleCheck={handleSingleCheck}
            // handleMultipleCheck={handleMultipleCheck}
          />
          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={handlePageChange}
            />
          )}
        </Segment>
      </main>
    // {/* </CanViewPage> */}
  );
}

const mapStateToProps = (reduxState) => ({
  filters:    reduxState.reports.stocksturnover.filters,
  isLoading:  reduxState.reports.stocksturnover.isLoading,
  stocks:     reduxState.reports.stocksturnover.list,
  total:      reduxState.reports.stocksturnover.total,
  warehouses: reduxState.inventory.warehouses

});
export default connect(mapStateToProps, { getStockTurnover, updateFilters,getWarehouses })(
  Stocksturnover
);
