import { useState, useEffect, useRef } from "react";

export function useProductFilters(
  getProductsCallback,
  initialState = { currentPage: 1, itemsPerPage: 10, searchTerm: "" }
) {
  const [currentPage, setCurrentPage] = useState(initialState.currentPage);
  const [itemsPerPage, setItemsPerPage] = useState(initialState.itemsPerPage);
  const [searchTerm, setSearchTerm] = useState(initialState.searchTerm);
  const [selectedBclients, setBclients] = useState([]);
  const prevSearchRef = useRef("");

  useEffect(() => {
    getProductsCallback({
      currentPage,
      itemsPerPage,
      searchTerm,
      selectedBclients
    });
  }, [currentPage, itemsPerPage, selectedBclients]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedBclients]);

  useEffect(() => {
    if (
      searchTerm.length > 2 ||
      (prevSearchRef.current.length > 0 && searchTerm.length === 0)
    ) {
      setCurrentPage(1);
      getProductsCallback({
        currentPage: 1,
        itemsPerPage,
        searchTerm,
        selectedBclients
      });
    }
    prevSearchRef.current = searchTerm;
  }, [searchTerm]);

  const handleSearch = (e, { value }) => setSearchTerm(value);
  const handleItemsPerPageChange = (e, { value }) => setItemsPerPage(value);
  const handlePageChange = (e, { activePage }) => setCurrentPage(activePage);
  const handleBussinesclientSelect = (e, { value }) => setBclients(value);

  return {
    searchTerm,
    itemsPerPage,
    currentPage,
    selectedBclients,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange,
    handleBussinesclientSelect
  };
}
