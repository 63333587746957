const initState = {
  noGoods: { report: [], intrazit: {} },
  stocksturnover: {
    filters: {
      days: "7",
      itemsPerPage: 10,
      currentPage: 1,
      businessclient: "",
      dateFrom: null,
      dateTo: null,
    },
    list: [],
    total: null,
    isLoading: false,
  },
};
  
  const reports = (state = initState, action) => {
    switch (action.type) {
      case "START_LOADING":
        return ({...state,
          stocksturnover:{
            ...state.stocksturnover,
            isLoading:true}})

      case "LOAD_STOCKS_TURNOVER":
        return {
          ...state,
          stocksturnover: {
            ...state.stocksturnover,
            isLoading: false,
            list: action.payload,
          },
        };
      case "UPDATE_FILTER":
        return {
          ...state,
          stocksturnover: {
            ...state.stocksturnover,
            isLoading: true,
            filters: { ...state.stocksturnover.filters, ...action.payload },
          },
        };
      case "LOAD_NOGOODS":
        return {
          ...state,
          noGoods: {
            report: action.payload.report,
            intranzit: action.payload.intranzit,
          },
          isLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default reports;
  