import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import '../Dropzone/dropzone.scss'
import { uploadDocuments} from "../../actions/uploadFiles";
import {connect} from 'react-redux'

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


class Previews extends Component {
  constructor() {
    super();
   
    this.state = {
      files: []
    };
    this.onDrop=this.onDrop.bind(this)
    this.removeItem=this.removeItem.bind(this)
    this.handleUpload=this.handleUpload.bind(this)
    this.getAlert=this.getAlert.bind(this)
    
  
  }

   onDrop  (acceptedFiles)  {
       const files = acceptedFiles.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}))
    
       this.setState({...this.state,
           files: [...this.state.files, ...files],
                      }
       )
      this.props.addFiles(files)
   };

   removeItem(index){
        let {files} = this.state
        files.splice(index,1)
        this.setState({files})
        this.props.addFiles(files)
   }
    getAlert() {
      alert('getAlert from Child');
      this.props.uploadExecuted()
    }



  handleUpload(){
    this.props.uploadExecuted()
    const data = new FormData()
    const {businessclient,sourceId,sourceName,name,description,uploadFiles} = this.props

    data.append(`destination`, `businessclients/${businessclient}/${sourceName}/${sourceId}`)

    for (let i=0;i<this.state.files.length;i++){
        const file = this.state.files[i]
        data.append(`file`,file)
     }

  //upload files to storage server, return files paths metadata
   const files = Promise.resolve(uploadDocuments({
                                          files:data,
                                          metaData:{ 
                                                    sourceName:  sourceName,
                                                    sourceId:    sourceId,
                                                    name:        name || '',
                                                    description: description || ''
                                                    
                                                    }}))
  files.then(files => {
               uploadFiles(files)
              })
        .catch(error=>console.log(error))
    
    // this.props.toggleModal()
    this.setState({files:[]})
}

  render() {
    
    if (this.props.executeChildMethod) this.handleUpload() //execute local function triggred from parent

    const thumbs = this.state.files.map((file,index) => (
      <div>
                                <div style={thumb} key={file.name}>
                                  <div style={thumbInner}>
                                    <img alt={file.name}
                                      src={file.preview}
                                      style={img}
                                    />
                                  </div>
                                
                                </div>
                                  <button className='drop_button'
                                      onClick={()=>this.removeItem(index)}>x</button>
                                </div>
                              ));

    return (
      <div>
      <Dropzone 
            onDrop={this.onDrop}   
            accept= 'image/*'>
       
          {({getRootProps, getInputProps}) => (
            <section className="drop_container">
              < div {
                ...getRootProps({className: 'dropzone'})
              } >
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </div>
              <aside style={thumbsContainer}>
                {thumbs}
              </aside>
                  </section>
          )}
        </Dropzone>
       {/* <div className='flex_alignRight'>
        {this.state.files.length>0&& 
        <button className='flex_'onClick={this.handleUpload}>Update</button>
        }
         </div>  */}
        </div>
      );
  }
}

function mapStateToProps(reduxState){
  return(
    {

    }
  )
}
export default connect(mapStateToProps ,{
  uploadDocuments
})(Previews)