import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";

import "react-notifications-component/dist/theme.css";
import "semantic-ui-css/semantic.min.css";
import 'rsuite/dist/styles/rsuite-default.css';


import { signUpAction, signInAction, logOutAction } from "../actions/auth";
import { getUsers } from "../actions/user";
import { removeError } from "../actions/error";
// import Users from '../components/Users/users'
import Home from "../components/home";
import Notification from "../components/Notification";
// import Droparea from "../components/Dropzone/droparea";

// import Authorization from "../hocs/withLogged";
import { AuthForm } from "../components/AuthForm/authForm";

// DASHBOARD
// import Dashboard from "../components/Dashboard/";
// SHOPTET ACTIVATION
import Shoptetactivation from '../components/Addons/Shoptet'
import Shoptetsettings from '../components/Addons/Shoptet/shoptetsettings.jsx'

// WAREHOUSE
import Warehouse from "../components/Warehouse_v2";
// import Warehouse from "../components/Warehouse";
import Inventorycard from "../components/Inventorycard/inventorycard";

// INBOUND SHIPMENT
import ViewInboundShipment from "../components/InboundShipment/ViewIS/";
import ListInboundShipment from "../components/InboundShipment/ListIS/";

// PO
// import ListPOs from "../components/PO/ListPOs/";
// import CreatePO from "../components/PO/CreatePO/";
// import EditViewPO from "../components/PO/EditViewPO/";

// PRODUCT
import ProductsListPage from "../components/Product/ProductsList/";
import Newproduct from "../components/Product/NewProduct/";
import Product from "../components/Product/EditViewProduct/";
import CreateNewPG from "../components/Productgroup/CreateNewPG";
import EditViewPG from "../components/Productgroup/EditViewPG";
// import ListPG from "../components/Productgroup/ListPG";

// ORDER
import OrdersListPage from "../components/Order/ListOrders";
import CreateOrder from "../components/Order/CreateOrder/";


import Order from "../components/Order/v2/Vieworder"

import GenerateInvoice from "../components/Order/Invoice/GenerateInvoice";

//Reports
import Stocksturnover from "../components/Reports/Stockturnover";
import Nogoodsreport from "../components/Reports/Nogoods";
import InvoicesList from "../components/Reports/InvoicesList"; //exports

//Separate invoice list
import Invoices from '../components/Invoices'
import Viewinvoice from "../components/Invoices/ViewInvoice";


// COURIER
import CourierList from "../components/Courier/CourierList";
import CourierPage from "../components/Courier/CourierPage";

// Billing
import Services from "../components/Billableservices/ServicesList";
import Bills from "../components/Billing/Bills/BillList";
import ViewBill from "../components/Billing/Bills/ViewBill";
import GenereateBill from "../components/Billing/Bills/BillPdf/GenerateBill";
//tranffers


import ListTransfers from "../components/Transfers/ListTransfers";
// import ViewTransfer from "../components/Transfers/ViewTransfer";

//Eshpos
import Eshops from '../components/Eshops'
import Emaglogin from '../components/Eshops/Emag/loginemag'
import Emagsettings from '../components/Eshops/Emag'

import Woologin from '../components/Eshops/Woocommerce/login'
import Woosettings from '../components/Eshops/Woocommerce'

import Eshoprychleapi from '../components/Eshops/Eshoprychle/login'
import Eshoprychle from '../components/Eshops/Eshoprychle'
import EshoprychleSignup from '../components/Eshops/Eshoprychle/signup'

//Help

import UserProfileSettings from "../components/UserProfileSettings";
import NotFound from "../components/NotFound";
import PopupWindow from "../components/PopupWindow.jsx";
import ExportWindow from "../components/ExportWindow.jsx";

//REFUNDS
import Refundstable from "../components/Refund"
import Costcalculator from '../components/Costcalculator'
import CostcalculatorRo from '../components/Costcalculator/ro'

import Inboundparcels from '../components/Inboundparcel/ListIS'
import Inboundparcel from '../components/Inboundparcel/EditViewIS'


//PROFILE
import Businessprofile from '../components/Businessclient'
// import CreateBC from '../components/Businessclient/CreateBC'

import Demoaccount from '../components/demo'
import Loginas from '../components/loginas'
import Estetologin from "../components/Eshops/Esteto/login";
import Esteto from "../components/Eshops/Esteto";

//creat SOCKET.IO connection >>pass as props to child components
// import io from "socket.io-client";

// export const socket = io(`${process.env.REACT_APP_SOCKET}`);


class Main extends Component {
  componentWillMount() {
    // this.props.callLaterOrders()

    // socket.on("orderUpdated", (order) => {
    //   // console.log('server message: orderUpdated ', order.orderNr, `status: ${order.status}`)
    //   this.props.socketOrderUpdatedAction(order);
    // });
    // socket.on("orderBusy", (order) => {
    //   // console.log('server message: orderBusy  ', order.orderNr)
    //   this.props.socketOrderUpdatedAction(order);
    // });

    // socket.on("newOrder", (order) => {
    //   // console.log('server message: NewOrder ', order)
    //   this.props.socketNewOrderReceived(order);
    // });
  }

  componentDidUpdate() {
    if (this.props.errors.message === "Please Login !!!") {
      this.props.logOutAction();
    }
  }

  render() {
    const {
      user,
      errors,
      signUpAction,
      signInAction,
      logOutAction,
      isAuthenticated,
      
      isLoading,
      // searchResult,
      // users,
      removeError,
    } = this.props;

    return (
      // <div className="app-with-nav">
      //   <MainSidebar />

      <div className="app-wrapper">
        <Notification
          type={"error"}
          trigger={errors}
          removeNotification={removeError}
        />

        <PopupWindow>
          <ExportWindow />
        </PopupWindow>

        <Switch>
          {/* <Route  exact /> */}
          <Route
            exact
            path="/signin"
            render={(props) => (
              <AuthForm
                signIn={true}
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                heading={"Welcome Back "}
                buttonText={"Log in"}
                onAuth={signInAction}
                errors={errors}
                logOutAction={logOutAction}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/signup"
            render={(props) => (
              <AuthForm
                signIn={false}
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                heading={"Join today."}
                buttonText={"Sign me up!"}
                onAuth={signUpAction}
                errors={errors}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/"
            render={(props) => (
              <Home
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                logOutAction={logOutAction}
                isLoading={isLoading}
                errors={errors}
                {...props}
              />
            )}
          />
          {/* <Route
            exact
            path="/upload/files"
            render={props => (
              <Droparea socket={socket} {...props} errors={errors} />
            )}
          /> */}
          {/* <Route
            exact
            path="/dashboard"
            render={props => <Dashboard {...props} errors={errors} />}
          /> */}
          {/* <Route
            exact
            path="/businessclient"
            render={(props) => <CreateBC {...props} errors={errors} />}
          /> */}
          <Route
            exact
            path="/profile/:id"
            render={(props) => <Businessprofile {...props} errors={errors} />}
          />
          <Route
            exact
            path="/warehouse"
            render={(props) => <Warehouse {...props} errors={errors} />}
          />
          <Route
            exact
            path="/productgroup/:id"
            render={(props) => <EditViewPG {...props} errors={errors} />}
          />
          <Route
            exact
            path="/productgroup/create/new"
            render={(props) => <CreateNewPG {...props} errors={errors} />}
          />
          {/* <Route
            exact
            path="/productgroups"
            render={(props) => <ListPG {...props} errors={errors} />}
          /> */}
          <Route
            exact
            path="/products"
            render={(props) => (
              <ProductsListPage {...props} user={user} errors={errors} />
            )}
          />
          <Route
            exact
            path="/products/:id"
            render={(props) => (
              <Product
                // socket={socket}
                {...props}
                errors={errors}
              />
            )}
          />
          <Route
            exact
            path="/products/create/new"
            render={(props) => (
              <Newproduct
                // socket={socket}
                {...props}
                errors={errors}
              />
            )}
          />
          <Route
            exact
            path="/:warehouse_id/inventory/:sku/movement/:skip/:limit"
            render={(props) => <Inventorycard {...props} errors={errors} />}
          />
          <Route
            exact
            path="/shipments/inbound"
            render={(props) => (
              <ListInboundShipment {...props} user={user} errors={errors} />
            )}
          />
          <Route
            exact
            path="/shipments/inbound/:id"
            render={(props) => (
              <ViewInboundShipment {...props} errors={errors} />
            )}
          />

          <Route
            exact
            path="/parcels/inbound"
            render={(props) => <Inboundparcels {...props} errors={errors} />}
          />
          <Route
            exact
            path="/parcels/inbound/:id"
            render={(props) => <Inboundparcel {...props} errors={errors} />}
          />
          <Route
            exact
            path="/orders"
            render={(props) => (
              <OrdersListPage
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                errors={errors}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/orders/:id"
            render={(props) => (
              <Order
              // socket={socket}
              user={user}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              errors={errors}
              {...props}
            />
              // <ViewOrder
              //   // socket={socket}
              //   currentUser={user.currentUser}
              //   isAuthenticated={isAuthenticated}
              //   isLoading={isLoading}
              //   errors={errors}
              //   {...props}
              // />
            )}
          />
          <Route
            exact
            path="/orders/v2/:id"
            render={(props) => (
              <Order
                // socket={socket}
                user={user}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                errors={errors}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/orders/create/new"
            render={(props) => (
              <CreateOrder
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                errors={errors}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/invoices"
            render={(props) => (
              <Invoices currentUser={user.currentUser} {...props} />
            )}
          />

          <Route
            exact
            path="/invoices/:id"
            render={(props) => (
              <Viewinvoice
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/invoices/:id/pdf"
            render={(props) => (
              <GenerateInvoice
                currentUser={user.currentUser}
                isAuthenticated={isAuthenticated}
                isLoading={isLoading}
                errors={errors}
                {...props}
              />
            )}
          />
          <Route
            exact
            path="/user-profile-settings"
            render={(props) => (
              <UserProfileSettings currentUser={user.currentUser} {...props} />
            )}
          />
          <Route
            exact
            path="/reports/stockturnover"
            render={(props) => <Stocksturnover {...props} />}
          />
          <Route
            exact
            path="/reports/nogoods"
            render={(props) => <Nogoodsreport {...props} />}
          />
          <Route
            exact
            path="/reports/invoices"
            render={(props) => <InvoicesList {...props} />}
          />

          <Route
            exact
            path="/couriers"
            render={(props) => <CourierList {...props} />}
          />
          <Route
            exact
            path="/couriers/:id"
            render={(props) => <CourierPage {...props} />}
          />

          <Route
            exact
            path="/billing/services"
            render={(props) => <Services {...props} />}
          />
          <Route
            exact
            path="/billing/bills"
            render={(props) => <Bills {...props} />}
          />
          <Route
            exact
            path="/billing/bills/:id"
            render={(props) => <ViewBill {...props} />}
          />
          <Route
            exact
            path="/bill/:id"
            render={(props) => <GenereateBill {...props} />}
          />
          <Route
            exact
            path="/transfers"
            render={(props) => <ListTransfers {...props} />}
          />
          <Route
            exact
            path="/refunds"
            render={(props) => <Refundstable {...props} />}
          />
          <Route
            exact
            path="/shoptet/newinstall/activation/:syslang/:username/:activationkey+"
            render={(props) => <Shoptetactivation {...props} />}
          />
          <Route
            exact
            path="/eshops"
            render={(props) => <Eshops user={user.currentUser} {...props} />}
          />
          <Route
            exact
            path="/eshops/emag/:id"
            render={(props) => <Emagsettings {...props} />}
          />
          <Route
            exact
            path="/eshops/emag/addnew/login"
            render={(props) => <Emaglogin {...props} />}
          />
          <Route
            exact
            path="/eshops/woocommerce/:id"
            render={(props) => <Woosettings {...props} />}
          />
          <Route
            exact
            path="/eshops/Eshop-rychle/:id"
            render={(props) => <Eshoprychle {...props} />}
          />
            <Route
            exact
            path="/eshops/esteto/:id"
            render={(props) => <Esteto {...props} />}
          />
          <Route
            exact
            path="/eshops/Eshop-rychle/addnew/login"
            render={(props) => <Eshoprychleapi {...props} />}
          />
          <Route
            exact
            path="/eshops/woocommerce/addnew/login"
            render={(props) => <Woologin {...props} />}
          />
          <Route
            exact
            path="/eshops/esteto/addnew/login"
            render={(props) => <Estetologin {...props} />}
          />
        
          <Route
            exact
            path="/users/eshoprychle/addnew"
            render={(props) => <EshoprychleSignup {...props} />}
          />
          <Route
            exact
            path="/eshops/shoptet/:id"
            render={(props) => <Shoptetsettings {...props} />}
          />
          <Route
            exact
            path="/costcalculator"
            render={(props) => <Costcalculator {...props} />}
          />
          <Route
            exact
            path="/costcalculator/ro"
            render={(props) => <CostcalculatorRo {...props} />}
          />

          <Route
            exact
            path="/demoacount/login/:username/:password/"
            render={(props) => (
              <Demoaccount isAuthenticated={isAuthenticated} {...props} />
            )}
          />

          <Route
            exact
            path="/internal/loginas/:username/:password+"
            render={(props) => (
              <Loginas {...props} />
            )}
          />

          <Route render={(props) => <NotFound {...props} />} />
        </Switch>
        {/* <div>Footer</div>       */}
      </div>
      // </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  username: state.user.currentUser.username,
  errors: state.errors,
  user: state.user,
  users: state.users.userlist,
});
export default withRouter(
  connect(mapStateToProps, {
    signUpAction,
    signInAction,
    logOutAction,
    getUsers,
    removeError,
  })(Main)
);
