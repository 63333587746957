import React from "react";
import { Button } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import { connect } from "react-redux";

import { deleteProductGroup } from "../../../actions/product";

function CancelOrderModal(props) {
  const {
    isModalOpen,
    handleCloseModal,
    _id,
    deleteProductGroup,
    history
  } = props;

  const handleDelete = () => {
    deleteProductGroup(_id, history);
    handleCloseModal();
  };

  return (
    <Modal
      open={isModalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={handleCloseModal}
      center
      classNames={{
        modal: ""
      }}
    >
      <div style={{ padding: "2.5em 0.7em 1em" }}>
        <p>Are you sure you want to delete this group?</p>
        <div style={{ marginTop: "1.5em" }} className="flex--right">
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button color="red" onClick={handleDelete}>
            Yes, Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default connect(() => {}, { deleteProductGroup })(CancelOrderModal);
