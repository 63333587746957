import React, { useState } from "react";
import { Segment} from "semantic-ui-react";

import { DatePicker,SelectPicker,Input,InputGroup ,IconButton,Icon} from "rsuite";


const itemStyle = {
  margin:"5px",
  width:"275px",
  border: "none !important",
  color: "#3F72AF !important"
}
const Filters =(props)=> {
  
    const {
      syslang,lang,
      handleUpdateFilter,
      warehouses,
      filters,
      products,
      handleDateChange
    } = props;

    const [search,setSearch]=useState()
    
    const handleSearch = (value)=>{
      
      setSearch(value)
      if(value.length===0) handleUpdateFilter({name:'orderNr',value:null}) 
    }
    
    return (
      <div className="filters_wrapper">
        <Segment>
        <div className="flex_alignLeft">
          {/* <Header as="h3">Filters</Header> */}
        </div>
        
      <div className="filters-item" style={{maxWidth:"500px",minWidth:'310px'}}>
          <InputGroup  inside style={{  marginBottom: 10,display:'flex'}}>
            <Input
                
                placeholder={lang.filters.searchorder[syslang]}
                value={search}
                onChange={handleSearch}
              />
                <IconButton 
                onClick={() =>
                  handleUpdateFilter({ name: "orderNr", value:search })}

                  size="md" icon={<Icon icon='search' />} />
            </InputGroup>
         
          </div>


        <div className="flex_alignLeft">

            <SelectPicker
              placeholder={lang.filters.status[syslang]}
              value={filters.status}
              
              onChange={(value)=>handleUpdateFilter({value,name:'status'})}
              searchable={false}
              data={[{label:"New",value:"New"},{label:"Processed",value:"Processed"}]}
              
              
            />
          
            <SelectPicker
              placeholder={lang.filters.warehouse[syslang]}
              value={filters.warehouse}
              
              onChange={(value)=>handleUpdateFilter({value,name:'warehouse'})}
              searchable={true}
              data={warehouses.map(warehouse=>({value:warehouse._id,label:warehouse.name}))}
              
              
            /> 
        
           <SelectPicker
              placeholder={lang.filters.product[syslang]}
              value={filters.status}
              style={{maxWidth:'420px',minWidth:"270px"}}
              onChange={(value)=>handleUpdateFilter({value,name:'product'})}
              searchable={true}
              data={products.map(product=>({value:product._id,label:product.name}))}
              
            />            
        </div>
        
      </Segment>
        <div>
             <DatePicker
                   value={filters.dateFrom}
                   isClearable={true}
                   style={itemStyle}
                   // placement="leftStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateChange({ dateFrom: date })}
                   placeholder={lang.filters.datepicker.from[syslang]}
                 ></DatePicker>
         
     
                 <DatePicker
                   value={filters.dateTo}
                   style={itemStyle}
                   isClearable={true}
                   // placement="leftStart"
                   format="DD-MM-YYYY"
                   onChange={(date) => handleDateChange({ dateTo: date })}
                   placeholder={lang.filters.datepicker.to[syslang]}
                 ></DatePicker>
           
          </div>
      
      </div>
    );
  
}

export default Filters;
