const initState = {
  errorMsg: "",
  successMsg: "",
  userNotifications: [],
  isPopupOpen: false,
  popupProps: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case "ADD_ERROR":
      return { ...state, errorMsg: action.error };
    case "REMOVE_ERROR":
      return {
        ...state,
        errorMsg: "",
      };

    case "ADD_SUCCESS_MSG":
      return { ...state, successMsg: action.payload };
    case "REMOVE_SUCCESS_MSG":
      return {
        ...state,
        successMsg: "",
      };

    case "ADD_NOTIFICATION":
      return {
        ...state,
        userNotifications: [...state.userNotifications, action.notification],
      };

    case "GET_USER_NOTIFICATIONS":
      return {
        ...state,
        userNotifications: action.payload,
      };
    case "DELETE_ONE_USER_NOTIFICATION":
      return {
        ...state,
        userNotifications: [
          ...state.userNotifications.filter(
            (notification) => notification._id !== action.payload
          ),
        ],
      };
    case "DELETE_USER_NOTIFICATIONS":
      return {
        ...state,
        userNotifications: [],
      };

    case "GET_INFOTAGS":
      return { ...state, infotags: action.payload };

    case "OPEN_POPUP":
      return { ...state, isPopupOpen: true, popupProps: action.payload };
    case "CLOSE_POPUP":
      return { ...state, isPopupOpen: false, popupProps: {} };

    default:
      return state;
  }
};
