import React, { useState } from "react";
// import CountryFilter from "../Filters/CountryFilter";
import { DatePicker, Input } from "rsuite";
import Invoicestable from "./table";
import { Header, Divider, Segment } from "semantic-ui-react";
import moment from "moment";

const itemStyle = {
  margin: "5px",
  width: "300px",
  border: "none !important",
  color: "#3F72AF !important",
};

const from = new Date(moment(new Date()).utc().startOf("month"));

const Invoices = (props) => {
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(new Date());
  const [clientOrderNr, setClientOrderNr] = useState(null);
  const [sequenceNumber, setSequence] = useState(null);
  const [openModal, setExportInputOpen] = useState(false);

  return (
    <div className="flex--column" style={{ rowGap: "10px", margin: "30px" }}>
      <segment name="header">
        <Header>Invoices</Header>
      </segment>
      <Divider></Divider>
      <segment name="Filters">
        {/* <CountryFilter
          handleChange={(e, { value }) => setCountry(value)}
          value={country}
        /> */}

        <DatePicker
          // disabled={isLoading}
          oneTap
          value={dateFrom}
          isClearable={true}
          // placement="leftStart"
          format="DD-MM-YYYY"
          onChange={(date) => setDateFrom(date)}
          placeholder="from..."
          style={itemStyle}
        ></DatePicker>

        <DatePicker
          // disabled={isLoading}
          oneTap
          value={dateTo}
          isClearable={true}
          // placement="leftStart"
          format="DD-MM-YYYY"
          onChange={(date) => setDateTo(date)}
          placeholder="to..."
          style={itemStyle}
        ></DatePicker>
        <div className="flex--left">
          <div style={{ width: "200px", marginRight: "10px" }}>
            {" "}
            <Input
              showMask
              placeholder="order number"
              value={clientOrderNr}
              onChange={(value) => setClientOrderNr(value)}
            ></Input>
          </div>
          <div style={{ width: "200px" }}>
            {" "}
            <Input
              showMask
              placeholder="invoice number"
              value={sequenceNumber}
              onChange={(value) => setSequence(value)}
            ></Input>
          </div>
        </div>
      </segment>
      <Segment style={{ width: "1100px" }}>
        {/* <div className='flex--between'>
            <div></div>
            <ItemsPerPage
                total={invoices.total}
                style={{ height: "1em" }}
                value={itemsPerPage}
                handleChange={(e,{value})=>setItemsPerpage(value)}
              />

          </div> */}

        <Invoicestable
          // country={country}
          dateFrom={dateFrom}
          dateTo={dateTo}
          clientOrderNr={clientOrderNr}
          sequenceNumber={sequenceNumber}
          openModal={openModal}
          setExportInputOpen={setExportInputOpen}
        ></Invoicestable>
      </Segment>

    </div>
  );
};

export default Invoices;
