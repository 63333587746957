import React, { useState, useEffect } from "react";
import { Input, Button } from "semantic-ui-react";

const initialState = {
  name:'',
  quantity: 1,
  VAT_procent: 0,
  netto_price: 0,
  //
  price: 0,
  netto_total: 0,
  brutto_total: 0,
};

function AddItem({ addItem, withAddVisible }) {
  const [
    {
      name,
      quantity,
      VAT_procent,
      netto_price,
      VAT_amount,
      price,
      netto_total,
      brutto_total,
    },
    setState,
  ] = useState(initialState);
  const handleChange = (e, { name, value }) =>
    setState((s) => ({ ...s, [name]: value }));

  useEffect(() => {
    if (VAT_procent || netto_price)
      setState((s) => ({
        ...s,
        VAT_amount:netto_price*VAT_procent/100*quantity,
        price: ((1 + VAT_procent / 100) * netto_price).toFixed(2),
      }));
  }, [VAT_procent, netto_price]);

  useEffect(() => {
    if (quantity || netto_price)
      setState((s) => ({
        ...s,
        netto_total: (quantity * netto_price).toFixed(2),
      }));
  }, [quantity, netto_price]);

  useEffect(() => {
    if (quantity || VAT_procent || netto_price)
      setState((s) => ({
        ...s,
        brutto_total: (
          quantity *
          netto_price *
          (1 + VAT_procent / 100)
        ).toFixed(2),
      }));
  }, [quantity, VAT_procent, netto_price]);

  const [addOpen, setAddOpen] = useState(false);

  const handleClear = () => {
    setState(initialState);
    setAddOpen(false);
  };
  const handleAdd = () => {
    addItem({ name,
      quantity,
      VAT_procent,
      VAT_amount,
      netto_price,
      price,
      netto_total,
      brutto_total });
    setState(initialState);
    setAddOpen(false);
  };

  if (withAddVisible || addOpen)
    return (
      <div className="bill-add-item">
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">Name:</label>
          <Input
            value={name}
            name="name"
            type="text"
            placeholder="name..."
            onChange={handleChange}
          />
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">Quantity:</label>
          <Input
            value={quantity}
            name="quantity"
            type="number"
            min={0}
            placeholder="quantity..."
            onChange={handleChange}
          />
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">Unit price (NETTO):</label>
          <Input
            value={netto_price}
            name="netto_price"
            type="number"
            min={0}
            placeholder="NETTO price..."
            onChange={handleChange}
          />
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">VAT rate:</label>
          <Input
            value={VAT_procent}
            name="VAT_procent"
            type="number"
            min={0}
            placeholder="10%..."
            onChange={handleChange}
          />
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">BRUTTO Price:</label>
          <span style={{ paddingLeft: "1em" }}>{price}</span>
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">Total NETTO:</label>
          <span style={{ paddingLeft: "1em" }}>{netto_total}</span>
        </div>
        <div className="bill-add-item__field">
          <label className="bill-add-item__label">Total BRUTTO:</label>
          <span style={{ paddingLeft: "1em" }}>{brutto_total}</span>
        </div>

        <div className="flex--right">
          <Button compact basic onClick={handleClear}>
            Clear
          </Button>
          <Button compact onClick={handleAdd}>
            +Add
          </Button>
        </div>
      </div>
    );
  else
    return (
      <div className="create-bill-add-item-btn">
        <Button onClick={() => setAddOpen(true)} content="Add Item" />
      </div>
    );
}

export default AddItem;
