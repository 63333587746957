import React,{useState, useEffect} from 'react'
import {Modal,Button,Form,FormGroup,FormControl,Input,InputGroup,ControlLabel,Alert,HelpBlock} from 'rsuite'
// import CurrencyFilter from '../../Filters/CurrencyFilter'
import { Divider } from 'semantic-ui-react'
import {createRefund} from '../../../../actions/refunds'

const Newrefund =({show=true,closeModal,order})=>{
const [isLoading,setLoading] = useState(false)
  const [formValue,setFormvalue]=useState({
    name:null,
    country:null,
    city:null,
    street:null,
    amount:0,
    IBAN:null,
    account_nr:null,
    account_code:null,
    currency:'EUR'

  })
  
 const submit =()=>{
  setLoading(true)
  createRefund(formValue)
  .then(resp=>{
    closeModal()
    setLoading(false)
    Alert.success(`Refund request created and pending for approval.`,3000)})
  .catch(error=>{  
    setLoading(false)
    closeModal()
    Alert.error(error.message,5000)})
 }
  useEffect(()=>{

    setFormvalue({
      ...formValue,
      // amount: order.payment.COD.amount,
      country:  order.shipping.shipTo.country,
      city:     order.shipping.shipTo.city,
      street:     order.shipping.shipTo.street,
      currency: order.shipping.shipTo.country==="Czech"?'CZK':"EUR",
      name:    `${order.shipping.shipTo.client.firstName}${order.shipping.shipTo.client.lastName?` ${order.shipping.shipTo.client.lastName}`:''}`,
      order:   order._id,
      phone:   order.shipping.shipTo.phone,
    })
  },[order])
  
    return (
        <Modal   show={show} onHide={closeModal} >
        <Modal.Header>
        <Modal.Title>Create New Refund</Modal.Title>
        </Modal.Header>
        <Divider></Divider>
        <Modal.Body>
        <Form 
            
              onChange={formValue => {  
                setFormvalue(formValue)
               
              }}
              formValue={formValue}
            >
              <div className='flex--column'>
              <ControlLabel>Amount</ControlLabel>
              <InputGroup style={{
                                width: 300,
                                marginBottom: 10
                              }}>
                <InputGroup.Addon>{formValue.currency}</InputGroup.Addon>
                <Input  
                    type='number'
                    value={formValue.amount} 
                    onChange={(value)=>setFormvalue({...formValue,amount:value})} />
                
              </InputGroup>

              {formValue.currency==='EUR'&&<FormGroup>
                <ControlLabel>IBAN</ControlLabel>
                <FormControl name="IBAN" value={formValue.IBAN}   disabled={isLoading}/>
              </FormGroup>}

              {formValue.currency!=='EUR'&&<div className='flex--left'>
              <FormGroup >
                <ControlLabel>Bank Account</ControlLabel>
                <FormControl style={{
                                width: 250,
                                // marginBottom: 10
                              }}
                              disabled={isLoading}
                              name="account_nr" value={formValue.account_nr}/>
              </FormGroup>
              
              <FormGroup >

                  <ControlLabel>Bank Code</ControlLabel>
                -  
                  <FormControl style={{
                                width: 100,
                                // marginBottom: 10
                              }}
                              disabled={isLoading}
                              name="account_code" value={formValue.account_code}/>
                </FormGroup>
                </div>
            }

              <Divider></Divider>
                {/* <FormControl      
                      type='number'
                      name="amount"
                      placeholder="amount"
                      style={{ width: 100,marginRight:'10px' }}
                  /> */}

                  {/* <CurrencyFilter
                      fluid={false}
                      handleChange={(e,{value})=>setCurrency(value)}
                      value={currency}
                      multiple={false}
                  /> */}
                  </div>
              <FormGroup>
                <ControlLabel>Name</ControlLabel>
                <FormControl name="name" value={formValue.name} disabled={isLoading}/>
                <HelpBlock>phone: {formValue.phone}</HelpBlock>

              </FormGroup>
              <div className='flex--left'>
              <FormGroup>
                <ControlLabel>Country</ControlLabel>
                <FormControl style={{
                                width: 150,
                                // marginBottom: 10
                              }}
                              name="country" value={formValue.country}
                              disabled={isLoading}
                              />
              </FormGroup>
              <FormGroup  style={{
                                marginLeft: 10
                              }}>
                <ControlLabel>City</ControlLabel>
                <FormControl style={{
                                width: 150,
                              }}
                              name="city" value={formValue.city}
                              disabled={isLoading}/>
              </FormGroup>
              </div>
              <FormGroup>
                <ControlLabel>Street</ControlLabel>
                <FormControl name="street" value ={formValue.street} disabled={isLoading}/>
              </FormGroup>
            
            </Form>          
    </Modal.Body>
 <Modal.Footer>
   <Button onClick={()=>submit()} appearance="primary">
     Ok
   </Button>
   <Button onClick={closeModal} appearance="subtle">
     Cancel
   </Button>
 </Modal.Footer>
</Modal>
       )
}
 



export default Newrefund