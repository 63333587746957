import React, { useEffect } from "react";
import {    Header, Segment } from "semantic-ui-react";
import { connect } from "react-redux";


import {
  getTransfers,
  updateFilters,
} from "../../../actions/transfer";
// import CanViewPage from "../../role-based-access/CanViewPage";
import TransfersTable from "./Table";
import Filters from "./Filters";
import ItemsPerPage from "../../ItemsPerPage";
import Pagination from "../../Filters/Pagination";
import lang from '../../../lang/config.json'

function ListTransfers({
  transfers,
  isLoading,
  filters,
  total,
  getTransfers,
  updateFilters,
  syslang
}) {
  useEffect(() => getTransfers(filters), [filters, getTransfers]);
  return (
    // <CanViewPage path="/transfers">
      <main className="page-wrapper">
        <div className="flex--between">
          <Header>{lang.codtransffers.title[syslang]}</Header>
        </div>

     
        <Segment>
          <div className="flex--right">
          {/* <Filters 
            lang={lang}
            syslang={syslang}
            filters={filters} updateFilters={updateFilters} />
            <ItemsPerPage
              total={total}
              value={filters.itemsPerPage}
              handleChange={(e, { name, value }) =>
                updateFilters({ [name]: value })
              }
            /> */}
          </div>
          <TransfersTable
            syslang={syslang}
            lang={lang}
            currentPage={filters.currentPage}
            itemsPerPage={filters.itemsPerPage}
            transfers={transfers}
            isLoading={isLoading}
       
          />
          <div className="flex--center">
            <Pagination
              currentPage={filters.currentPage}
              itemsPerPage={filters.itemsPerPage}
              total={total}
              updateFilter={updateFilters}
            />
          </div>
        </Segment>
      </main>
    // </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
  transfers: reduxState.transfers.list,
  total: reduxState.transfers.total,
  isLoading: reduxState.transfers.isLoading,
  filters: reduxState.transfers.filters,
  syslang:reduxState.user.currentUser.sys_lang
});
export default connect(mapStateToProps, {
  getTransfers,
  updateFilters,
})(ListTransfers);
