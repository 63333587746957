import {
  GET_COURIERS,
  GET_COURIERS_BY_WAREHOUSE,
  GET_COURIERS_BY_COUNTRY,
  GET_COURIER,
  UPDATE_COURIER_FILTER,
  GET_COURIER_QUOTATIONS,
} from "./actionTypes";
import { apiCall } from "../services/api";
import { addError } from "./error";

export const getCouriers = (filters = {}) => (dispatch) => {
  const { country, name } = filters;
  const countriesUrl = country
    ? country.map((c) => `country=${c}`).join("&")
    : "";
  const nameUrl = name ? `name=${name}` : "";
  const filtersUrl =
    country || name
      ? "/?" + countriesUrl + (countriesUrl && nameUrl && "&") + nameUrl
      : "";

  apiCall("get", `${process.env.REACT_APP_API}/couriers${filtersUrl}`)
    .then((data) => dispatch({ type: GET_COURIERS, payload: data }))
    .catch((err) => dispatch(addError(err.message)));
};

export const getCouriersByCountry = (country) => (dispatch) => {
  apiCall("get", `${process.env.REACT_APP_API}/couriers/?country=${country}`)
    .then((data) => dispatch({ type: GET_COURIERS_BY_COUNTRY, payload: data }))
    .catch((err) => dispatch(addError(err.message)));
};

export const getCouriersByWarehouse = () => (dispatch) => {
  apiCall("get", `${process.env.REACT_APP_API}/couriers/warehouse`)
    .then((data) =>
      dispatch({ type: GET_COURIERS_BY_WAREHOUSE, payload: data })
    )
    .catch((err) => dispatch(addError(err.message)));
};

export const getCourier = (id) => (dispatch) => {
  apiCall("get", `${process.env.REACT_APP_API}/couriers/${id}`)
    .then((data) => dispatch({ type: GET_COURIER, payload: data }))
    .catch((err) => dispatch(addError(err.message)));
};

export const updateFilters = (filter) => ({
  type: UPDATE_COURIER_FILTER,
  payload: filter,
});

export const getCourierQuotations = (id) => (dispatch) => {
  apiCall("get", `${process.env.REACT_APP_API}/couriers/${id}/quotations`)
    .then((data) => dispatch({ type: GET_COURIER_QUOTATIONS, payload: data }))
    .catch((err) => dispatch(addError(err.message)));
};


export const setDefaultCourier =(id)=>(dispatch)=>{
  apiCall("post", `${process.env.REACT_APP_API}/couriers/${id}`)
   .then((data) => dispatch({
       type: GET_COURIER,
       payload: data
     }))
     .catch((err) => dispatch(addError(err.message)));
}