import {
  ADD_INBOUND_SHIPMENT,
  
  GET_INBOUND_SHIPMENTS,
  INBOUND_FILTER
} from "./actionTypes";

import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = { type: "START_LOADING" };
const stopLoading = { type: "STOP_LOADING" };

const addShipmentAction = shipment => ({
  type: ADD_INBOUND_SHIPMENT,
  shipment
});

export const getShipment = id => dispatch => {
  dispatch(startLoading);
  dispatch(removeError());
  dispatch({
    type: "UPDATING"
  });
  const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}`;
  apiCall("get", url)
    .then(resp => {

      dispatch(addShipmentAction(resp))
      dispatch(stopLoading);

    })
      
    .catch(error => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getShipments = filter => dispatch => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/parcels/inbound`;
  apiCall("post", url, filter)
    .then(shipments =>{
      dispatch(stopLoading);
      dispatch(getShipmentsAction(shipments))})
    .catch(error => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

const getShipmentsAction = shipments => ({
  type: GET_INBOUND_SHIPMENTS,
  shipments
});

export const updateFilter = filter => ({
  type: INBOUND_FILTER,
  filter
});


export const setAsProcessed = id => dispatch => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/parcels/inbound/${id}/setasprocessed`;
  apiCall("put", url, {})
    .then(resp =>{
      dispatch(stopLoading);
      dispatch(addShipmentAction(resp))

      })
    .catch(error => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};