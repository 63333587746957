

const initState ={
   invoices:{
       list:[],
       total:null
   },
   creditNotes:{
       list:[],
       total:null
   },
   creditNotesSummary:[],
   isLoading:false
}
const Invoices = (state=initState,action)=>{
  

    switch (action.type) {
        
    case "GET_INVOICES":
      return {
        ...state,
        invoices: {
          ...state.invoices,
          list: action.payload,
          // total: action.payload.total,
        },
        isLoading: false,
      };

    // CREDIT NOTES
    case "GET_CREDITNOTES_SUMMARY":
      return {
        ...state,
        creditNotesSummary: action.payload,
        // total: action.payload.total,
        isLoading: false,
      };
    
    case "GET_CREDITNOTES":
      return {
        ...state,
        creditNotes: {
          ...state.creditNotes,
          list: action.payload.list,
          total: action.payload.total,
        },
        isLoading: false,
      };
  
    case "START_SERVICES_LOADING":
      return { ...state, isLoading: true };
    case "STOP_SERVICES_LOADING":
      return { ...state, isLoading: false };

    default:
      return state;
  }

}


export default Invoices


  