import React from "react";
import { Segment, Icon } from "semantic-ui-react";

function MobileItemCard({ item }) {
  const {
    _id,
    country,
    currency,
    isActive,
    courier_transport,
    courier_return,
    courier_COD_Service_cash,
    courier_COD_Service_card,
    courier_toll_rate,
    courier_fuel_charge,
  } = item;

  return (
    <Segment>
      <div className="order-card--grid">
        <span className="small--text">Country:</span>
        <span className="bold--text">{country}</span>

        <span className="small--text">Active:</span>
        <p>{isActive && <Icon name="check" />}</p>

        <span className="small--text">Currency:</span>
        <p>{currency}</p>

        <span className="small--text">Prices:</span>
        <p>
          {" "}
          <ul className="quotations-prices-list">
            {courier_transport && (
              <li>
                Transport: <span>{courier_transport}</span>
              </li>
            )}
            {courier_return && (
              <li>
                Return: <span>{courier_return}</span>
              </li>
            )}
            {courier_COD_Service_cash && (
              <li>
                COD service cash: <span>{courier_COD_Service_cash}</span>
              </li>
            )}
            {courier_COD_Service_card && (
              <li>
                COD service card: <span>{courier_COD_Service_card}</span>
              </li>
            )}
            {courier_toll_rate && (
              <li>
                Toll rate: <span>{courier_toll_rate}</span>
              </li>
            )}
            {courier_fuel_charge && (
              <li>
                Fuel charge: <span>{courier_fuel_charge}</span>
              </li>
            )}
          </ul>
        </p>
      </div>
    </Segment>
  );
}

export default MobileItemCard;
