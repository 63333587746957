import React from "react";
import { Table, Header, Label, Icon, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { ExcelRenderer } from "react-excel-renderer";
import ExportExcel from "../../ExportExcel";

import { uploadProdFromXLS } from "../../../actions/product";
import { dataSet, colNames } from "./uploadProductsTemplate";
import "../../Dropzone/dropzone.scss";

const initState = {
  dragContent: "Drag 'n' drop the file or click here",
  fileName: "",
  formatError: false,
  data: [],
  errors: {}
};

class AddProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;

    this.fileHandler = this.fileHandler.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.handleSaveProducts = this.handleSaveProducts.bind(this);
  }

  fileHandler(files) {
    let fileObj = files[0];
    if (!fileObj.name.includes(".xlsx")) {
      this.setState({
        formatError: true,
        fileName: "",
        data: [],
        dragContent: "Drag 'n' drop the file or click here"
      });
      return;
    }
    const requiredKeys = ["name", "codes.sku", "codes.barcode"];
    const keyNames = [
      "name",
      "codes.sku",
      "codes.barcode",
      "color",
      "size",
      "proprieties.weight"
    ];

    function mapRows(arr) {
      let data = [];
      let errors = {};
      arr.shift();
      arr.forEach((row, i) => {
        let product = {};
        let productErrs = [];
        for (let j = 0; j < row.length; j++) {
          product[keyNames[j]] = row[j];
          if (requiredKeys.includes(keyNames[j]) && row[j] === undefined) {
            productErrs.push(keyNames[j]);
          }
        }
        data.push(product);
        if (productErrs.length) errors[i] = productErrs;
      });
      return [data, errors];
    }
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
      } else {
        const [data, errors] = mapRows(resp.rows);
        this.setState({
          fileName: fileObj.name,
          formatError: false,
          data,
          errors
        });
      }
    });
  }

  removeFile() {
    this.setState({ ...initState });
  }

  handleSaveProducts() {
    const { businessclient, errors, data } = this.state;
    const products = data.filter((prod, i) => !errors[i]);
    console.log({ products, businessclient });
    this.props.uploadProdFromXLS({ products, businessclient });
  }

  render() {
    const { errors } = this.props;
    const { businessclient } = this.state;
    // console.log(this.state);

    const tableHeadRow = (
      <Table.Row>
        <Table.HeaderCell>#</Table.HeaderCell>
        <Table.HeaderCell>Valid</Table.HeaderCell>
        <Table.HeaderCell>Product</Table.HeaderCell>
        <Table.HeaderCell>SKU</Table.HeaderCell>
        <Table.HeaderCell>Barcode</Table.HeaderCell>
        <Table.HeaderCell>Color</Table.HeaderCell>
        <Table.HeaderCell>Size</Table.HeaderCell>
        <Table.HeaderCell>Weight</Table.HeaderCell>
      </Table.Row>
    );

    const tableRows = this.state.data.map((p, i) => (
      <Table.Row key={i} error={!!this.state.errors[i]}>
        <Table.Cell>{i + 1}</Table.Cell>
        <Table.Cell collapsing>
          {this.state.errors[i] ? (
            <Icon name="remove circle" color="red" size="large" />
          ) : (
            <Icon name="check circle" color="green" size="large" />
          )}
          {/* <Label circular color="green">
            <Icon name="check" />
          </Label> */}
        </Table.Cell>

        {p.name === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p.name}</Table.Cell>
        )}
        {p["codes.sku"] === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p["codes.sku"]}</Table.Cell>
        )}

        {p["codes.barcode"] === undefined ? (
          <Table.Cell negative className="bold--text">
            ! required
          </Table.Cell>
        ) : (
          <Table.Cell>{p["codes.barcode"]}</Table.Cell>
        )}
        <Table.Cell>{p.color}</Table.Cell>
        <Table.Cell>{p.size}</Table.Cell>
        <Table.Cell>{p["proprieties.weight"]}</Table.Cell>
      </Table.Row>
    ));

    return (
      <div className="products-from-excel">
        <div style={{ marginRight: "1em" }}>
          <ExportExcel
            filename="products-template"
            excelSheetName="upload-products-template"
            dataSet={dataSet}
            colNames={colNames}
            button={
              <Button.Group basic>
                <Button>
                  <Icon name="download" />
                  Download Example
                </Button>
              </Button.Group>
            }
          />
        </div>

        <section className="upload-file">
          <Header>1. Upload a file with products (.xlsx format)</Header>
          {/* <form className="upload-file__form">
            <label className="upload-file__input" for="file-upload">
              Upload file
              <input
                id="file-upload"
                type="file"
                placeholder="Add Excel File"
                onChange={e => this.fileHandler(e.target)}
              />
            </label>
          </form> */}

          {!this.state.fileName && (
            <Dropzone
              onDrop={acceptedFiles => this.fileHandler(acceptedFiles)}
              onDragEnter={() =>
                this.setState({ dragContent: "Drop here ..." })
              }
            >
              {({ getRootProps, getInputProps }) => (
                <section className="upload-file__drop-container">
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <p>{this.state.dragContent}</p>
                  </div>
                </section>
              )}
            </Dropzone>
          )}

          {this.state.fileName && (
            <div className="upload-file__file-name">
              <Label image>
                <Icon name="file" />
                {this.state.fileName}
                <Icon name="delete" onClick={this.removeFile} />
              </Label>
            </div>
          )}
          {this.state.formatError && (
            <p className="upload-file__error">
              * Must be an Excel file ( .xlsx )
            </p>
          )}
        </section>

        {/* View Table */}
        <section className="view-table">
          {this.state.data && this.state.data.length > 0 && (
            <div className="table-wrapper--horizontal-scroll">
              <Table unstackable celled>
                <Table.Header>{tableHeadRow}</Table.Header>
                <Table.Body>{tableRows}</Table.Body>
              </Table>
            </div>
          )}
        </section>

        {/* Feedback */}
        {this.state.data &&
          this.state.data.length > 0 &&
          !this.props.status &&
          (!Object.keys(this.state.errors).length ? (
            <section className="feedback-section feedback-section_success">
              <p className="feedback-section__success">
                <Icon name="check" color="green" />
                There are no errors. The file seems to pass the requirements.
                <br /> You can save the products now.
              </p>
            </section>
          ) : (
            <section className="feedback-section feedback-section_errors">
              <p className="feedback-section__errors">
                <Icon name="warning sign" color="yellow" />
                There are some errors.
                <br /> The products with missing required information will not
                be saved.
                <br /> If you want to add them too, please fill in the missing
                cells and reupload the file.
              </p>
            </section>
          ))}

        {/* Save Products */}
        <section className="save-products">
          <Header>2. Save the added products</Header>
          {this.props.status === "success" && (
            <Button color="green" size="huge">
              Products Saved
            </Button>
          )}
          {this.props.status !== "success" && (
            <Button
              color="blue"
              basic
              // disabled={this.state.data && !this.state.data.length}
              loading={this.props.isLoading}
              size="huge"
              onClick={this.handleSaveProducts}
            >
              Save Products
            </Button>
          )}
        </section>

        {/* Server Response */}
        {this.props.unsuccessful &&
          this.props.unsuccessful.length > 0 &&
          this.props.status === "fail" &&
          this.state.fileName && (
            <section className="feedback-section feedback-section_server-response">
              <div className="table-wrapper--horizontal-scroll">
                <Table unstackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product</Table.HeaderCell>
                      <Table.HeaderCell>Error</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.unsuccessful &&
                      this.props.unsuccessful.map((p, i) => (
                        <Table.Row key={i}>
                          <Table.Cell>
                            {p.name} - {p["codes.sku"]}
                          </Table.Cell>
                          <Table.Cell>{p.error}</Table.Cell>
                        </Table.Row>
                      ))}
                  </Table.Body>
                </Table>
              </div>
            </section>
          )}
        {this.props.status === "success" && this.state.fileName && (
          <section className="feedback-section feedback-section_success feedback-section_server-response">
            <p className="feedback-section__success">
              <Icon name="check" color="green" />
              All product successfully saved
            </p>
          </section>
        )}
      </div>
    );
  }
}

const mapStateToProps = reduxState => ({
  unsuccessful:
    // [
    //   {
    //     name: "Product",
    //     "codes.sku": 252353,
    //     error: "Some error to display "
    //   }
    // ],
    reduxState.newproducts.resp.unsuccessful,
  status: reduxState.newproducts.resp.status,
  isLoading: reduxState.newproducts.isLoading
});

export default connect(mapStateToProps, { uploadProdFromXLS })(AddProducts);
