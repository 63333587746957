import { combineReducers } from "redux";
import loading from "./loading";
import user from "./currentUser";
import errors from "./error";
import users from "./users";
import inventory from "./inventory";
import shipments from "./shipments";
import inboundshipments from "./inboundshipment";
import purchaseOrders from "./pos";
import companies from "./company";
import businessclients from "./businessclients";
import receivedOrders from "./receivedOrders";
import picking from "./picking";
import packing from "./packing";
// import selectedPackage from "./selectedPackage";
import couriers from "./couriers";
import newproducts from "./newproducts";
import filters from "./filters";
import reports from "./reports";
import services from "./services";
import notifications from "./notifications";
import transfers from "./transfers";
import Invoices from "./invoices";
import filtersmain from "./filtersmain";
import droplists from "./droplists";
const appReducer = combineReducers({
  loading,
  user,
  errors,
  users,
  inventory,
  shipments,
  purchaseOrders,
  companies,
  businessclients,
  inboundshipments,
  orders: receivedOrders,
  picking,
  packing,
  couriers,
  newproducts,
  filters,
  reports,
  services,
  notifications,
  transfers,
  Invoices,
  filtersmain,
  droplists
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") state = undefined;

  return appReducer(state, action);
};
export default rootReducer;
