import React, { useState } from "react";
import { Button, Header, TextArea } from "semantic-ui-react";
import Modal from "react-responsive-modal";
import ExportButton from "../ExportButton";


function Exportids({openModal=false,setExportInputOpen}) {

  const [ids, setData] = useState();

  const handleInput        = (e,{value})    =>setData(value)

  const modal = (
    <Modal
      open={openModal}
      showCloseIcon={false}
      closeOnEsc={true}
      onClose={() => setExportInputOpen(false)}
      center
      classNames={{
        modal: "",
      }}
    >
      <Header as="h4">Export Input Ids</Header>
      
      <TextArea
        style={{ width: "100%" }}
        placeholder="insert orders [space] separated"
        value={ids}
        onChange={handleInput}
      ></TextArea>

      <div style={{ marginTop: "2.7em" }} className="right--text"></div>
      <ExportButton
          styles={{ width: "350px",margin:'2px' }}
          total={""}
          filters={{ ids}}
          fileName={`invoices_ids`}
          url="/invoices/export"
          text={`Export`}

        />
    </Modal>
  );
  return (

    <div>
        <Button fluid color="grey"  onClick={()=>setExportInputOpen(true)}>
            Export From Input ids
          </Button>     
           {modal}
    </div>
   
  );
}

export default (Exportids);
