import React from "react";
import { Dropdown } from "semantic-ui-react";
import { DatePicker } from "rsuite";
import CountryFilter from '../../../Filters/CountryFilter'

import { statuses } from "../../orderStatuses";
import { createOptions } from "../../../../helpers/createOptions";

function MobileOrdersFilters(props) {
  const {
    products,
    couriers,
    internFilters,
    handleDropdownChange,
    handleDateChange
  } = props;

  return (
    <>
      <Dropdown
        fluid
        search
        placeholder="Status..."
        value={internFilters.status}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={statuses.map((status, i) => ({
          value: status,
          text: status,
          key: i
        }))}
        closeOnChange={true}
        name="status"
      />

      <Dropdown
        multiple
        fluid
        search
        placeholder="Product..."
        value={internFilters.product}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={createOptions(products)}
        closeOnChange={true}
        name="product"
      />
      <CountryFilter
          handleChange={handleDropdownChange}
          fluid={true}
        ></CountryFilter>

      <Dropdown
        search
        multiple
        fluid
        placeholder="Courier..."
        value={internFilters.courier}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={createOptions(couriers)}
        closeOnChange={true}
        name="courier"
      />

      <DatePicker
              value={internFilters.dateFrom}
              isClearable={true}
              style={{width:"100%"}}
              // placement="leftStart"
              format="DD-MM-YYYY"
              onChange={(date) => handleDateChange({ dateFrom: date })}
              placeholder="from..."
            ></DatePicker>
    

            <DatePicker
              value={internFilters.dateTo}
              
              isClearable={true}
              style={{width:"100%"}}
              format="DD-MM-YYYY"
              onChange={(date) => handleDateChange({ dateTo: date })}
              placeholder="to..."
            ></DatePicker>
        
    </>
  );
}

export default MobileOrdersFilters;
