import React from "react";
import { Icon, Button, Divider, Header, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { createOrder } from "../../../actions/orders";
import ProductsList from "../ProductsList/ProductsList";
import AddProduct from "../ProductsList/AddProduct";
import ClientInfoForm from "./ClientInfoForm";

import lang from '../../../lang/config.json'

const sh = "shipping.shipTo.";

class CreateOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdressAsShipping: false,
      [sh + "client.isCompany"]: null,
      [sh + "client.companyName"]: null,
      [sh + "client.taxId"]: null,
      [sh + "client.firstName"]: null,
      [sh + "client.lastName"]: null,
      [sh + "client.phone"]: null,
      [sh + "client.country"]: null,
      [sh + "client.province"]: null,
      [sh + "client.city"]: null,
      [sh + "client.street"]: null,
      [sh + "client.postcode"]: null,
      [sh + "client.streetNr"]: null,
      [sh + "country"]: null,
      [sh + "province"]: null,
      [sh + "city"]: null,
      [sh + "street"]: null,
      [sh + "postcode"]: null,
      [sh + "streetNr"]: null,
      [sh + "phone"]: null,
      items: [],
      'payment.COD.amount':0,
      businessclient: "",
      clientOrderNr: "",
      validationErrors: {
        sh_country: false,
        sh_city: false,
        sh_street: false,
        sh_postcode: false,
      },
      validationErrMsg: "",
    };
    this.handleCreateOrder = this.handleCreateOrder.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleAdressCheck = this.handleAdressCheck.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
  }

  handleChange(e, { name, value }) {
    const adressFields = [
      "country",
      "province",
      "city",
      "street",
      "streetNr",
      "postcode",
      "phone"
    ];
    const adressField = adressFields.find(
      (f) => name === `shipping.shipTo.client.${f}`
    );
    const shippingAdressField = adressFields.find(
      (f) => name === `shipping.shipTo.${f}`
    );
    let adressHandling = {};
    if (this.state.isAdressAsShipping) {
      if (adressField) {
        adressHandling = { isAdressAsShipping: false };
      } else if (shippingAdressField) {
        adressHandling = {
          [`shipping.shipTo.client.${shippingAdressField}`]: value,
        };
      }
    }
    this.setState((state) => ({
      [name]: value,
      ...adressHandling,
      validationErrors: {
        ...state.validationErrors,
        sh_country:
          state.validationErrors.sh_country &&
          name === "shipping.shipTo.country"
            ? true
            : false,
        sh_city: state[sh + "city"] === "" ? true : false,
        sh_street: state[sh + "street"] === "" ? true : false,
        sh_postcode: state[sh + "postcode"] === "" ? true : false,
      },
    }));
  }

  handleCheck(e, { name, value }) {
    if (name === sh + "client.isCompany") {
      this.setState({
        [name]: !value,
        [sh + "client.isCompany"]: null,
        [sh + "client.companyName"]: null,
        [sh + "client.taxId"]: null,
        [sh + "client.phone"]: null,
        [sh + "client.country"]: null,
        [sh + "client.province"]: null,
        [sh + "client.city"]: null,
        [sh + "client.street"]: null,
        [sh + "client.postcode"]: null,
        [sh + "client.streetNr"]: null,
      });
    } else {
      this.setState({ [name]: !value });
    }
  }

  handleAdressCheck(e, { value }) {
    if (value) {
      this.setState((s) => ({
        isAdressAsShipping: !value,
      }));
    } else {
      this.setState((s) => ({
        isAdressAsShipping: !value,
        "shipping.shipTo.client.country": s[sh + "country"],
        "shipping.shipTo.client.province": s[sh + "province"],
        "shipping.shipTo.client.city": s[sh + "city"],
        "shipping.shipTo.client.street": s[sh + "street"],
        "shipping.shipTo.client.postcode": s[sh + "postcode"],
        "shipping.shipTo.client.streetNr": s[sh + "streetNr"],
      }));
    }
  }


  handleAddProduct = (newProduct) => {
    this.setState((state) => {
      const newProducts = [...state.items, newProduct];
      return { items: newProducts };
    });
  };

  handleDeleteProduct = (id) => {
    this.setState((state) => {
      return {
        items: state.items.filter((p) => p.product !== id),
      };
    });
  };

  handleCreateOrder() {
    if (
      !this.state[sh + "country"] ||
      !this.state[sh + "city"] ||
      !this.state[sh + "street"] ||
      !this.state[sh + "postcode"]
    ) {
      this.setState((state) => ({
        validationErrors: {
          ...state.validationErrors,
          sh_country: !state[sh + "country"] ? true : false,
          sh_city: !state[sh + "city"] ? true : false,
          sh_street: !state[sh + "street"] ? true : false,
          sh_postcode: !state[sh + "postcode"] ? true : false,
        },
        validationErrMsg:
          "Order was not created. Please fill in all the required fields",
      }));
      return;
    }

    const query = {
      [sh + "client.isCompany"]: this.state[sh + "client.isCompany"],
      [sh + "client.companyName"]: this.state[sh + "client.companyName"],
      [sh + "client.taxId"]: this.state[sh + "client.taxId"],
      [sh + "client.firstName"]: this.state[sh + "client.firstName"],
      [sh + "client.lastName"]: this.state[sh + "client.lastName"],
      [sh + "client.phone"]: this.state[sh + "client.phone"],
      [sh + "client.country"]: this.state[sh + "client.country"],
      [sh + "client.province"]: this.state[sh + "client.province"],
      [sh + "client.city"]: this.state[sh + "client.city"],
      [sh + "client.street"]: this.state[sh + "client.street"],
      [sh + "client.postcode"]: this.state[sh + "client.postcode"],
      [sh + "client.streetNr"]: this.state[sh + "client.streetNr"],
      [sh + "country"]: this.state[sh + "country"],
      [sh + "province"]: this.state[sh + "province"],
      [sh + "city"]: this.state[sh + "city"],
      [sh + "street"]: this.state[sh + "street"],
      [sh + "postcode"]: this.state[sh + "postcode"],
      [sh + "streetNr"]: this.state[sh + "streetNr"],
      [sh + "phone"]: this.state[sh + "phone"],
      items: this.state.items,
      businessclient: this.state.businessclient,
      clientOrderNr: this.state.clientOrderNr,
      isAdressAsShipping: this.state.isAdressAsShipping,
      'payment.COD.amount':this.state['payment.COD.amount']
    };
    this.props.createOrder(query, this.props.history);
  }

  render() {
    const { validationErrMsg, items, businessclient } = this.state;
    const {user}=this.props
    return (
      
        <div className="a-wrapper page-wrapper create-order">
          {validationErrMsg && (
            <div className="alert alert-danger">{validationErrMsg}</div>
          )}
          <div className="flex_spaceBetween vertical-align margin-bottom">
            {/* <Header className="form__header" as="h3">
            Create new order
          </Header> */}
            <Link to="/orders">
              <Icon name="arrow left" />
              {lang.orders.createOrder.backButton[user.currentUser.sys_lang]}
            </Link>

            <Button color="teal" onClick={this.handleCreateOrder}>
            {lang.orders.createOrder.saveButton[user.currentUser.sys_lang]}
            </Button>
          </div>
          {/* <Divider /> */}

          <Header as="h3"> {lang.orders.createOrder.title[user.currentUser.sys_lang]}</Header>
          <Segment padded>
            <ClientInfoForm
              lang={lang}
              user ={user}
              {...this.state}
              optionsCountries
              handleChange={this.handleChange}
              handleCheck={this.handleCheck}
              handleAdressCheck={this.handleAdressCheck}
            />
          </Segment>

          {/* <div className="products">
            <Divider className="margin-bottom" horizontal>
            {lang.orders.createOrder.addproductdivider[user.currentUser.sys_lang]}
            </Divider>
          </div> */}

          {/* <Segment>
            <div className="form-wrapper create-order-products">
              <AddProduct
                lang={lang}
                user={user}
                handleAddProduct={this.handleAddProduct}
                bclientId={businessclient}
              />
              <ProductsList
              lang={lang}
              user={user}

                items={items}
                handleDelete={this.handleDeleteProduct}
              />

             
            </div>
          </Segment>
           */}
          <div className="flex--right mt--2">
                <Button
                  color="teal"
                  size="large"
                  onClick={this.handleCreateOrder}
                > {lang.orders.createOrder.saveButton[user.currentUser.sys_lang]}

                </Button>

                <Link to="/orders">
                  <Button size="large"> {lang.orders.createOrder.cancelbutton[user.currentUser.sys_lang]}</Button>
                </Link>
              </div>
        </div>
    );
  }
}

const mapStateToProps = (reduxState) => ({
  orders: reduxState.receivedOrders,
  user:reduxState.user,
  
});

export default connect(mapStateToProps, { createOrder })(CreateOrder);

// export default CreateOrder;
