import React, { useState, useEffect } from "react";
import {  Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { closePopup } from "../actions/notifications";
import { addError } from "../actions/error";
import { apiCall } from "../services/api";

function ExportWindow({
  // mapData,
  url,
  filters,
  fileName,
  closePopup,
  addError,
}) {
  const [isProcessing, setIsProcessing] = useState(true);
  const [rawData, setRawData] = useState([]);
  // const [mappedData, setMappedData] = useState([]);
  // console.log({
  //   // mapData,
  //   url,
  //   filters,
  //   fileName,
  //   rawData,
  //   mappedData,
  //   isProcessing,
  // });

  // console.log(isProcessing);
  function getData(filters) {
    console.log("getting Data ....");
    const completeUrl = process.env.REACT_APP_API + url;
    apiCall("post", completeUrl, filters)
      .then((resp) => {
        if (resp.length) setRawData(resp);
        else addError("No objects were found for export");
      })
      .catch((err) => {
        addError(err.message);
        closePopup();
      });
  }

  function createExcelFile(dataForExport) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(dataForExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
    setTimeout(closePopup, 7000);
  }

  useEffect(() => {
    setIsProcessing(true);
    getData(filters);
  }, [filters]);

  // useEffect(() => {
  //   setMappedData(mapData(rawData));
  // }, [rawData]);

  useEffect(() => {
    if (rawData.length > 0) {
      setIsProcessing(false);
      createExcelFile(rawData);
    }
  }, [rawData]);

  if (isProcessing)
    return (
      <p className="notification--text-on">
        <Icon name="download" />{" "}
        <span style={{ color: "white" }} className="blink_me">
          Getting data...
        </span>
      </p>
    );
  else
    return (
      <p className="notification--text-on">
        <Icon name="check" /> Finished!
      </p>
    );
}

const mapStateToProps = (reduxState) => ({
  isOpen: reduxState.notifications.isPopupOpen,
  // mapData: reduxState.notifications.popupProps.mapData,
  filters: reduxState.notifications.popupProps.filters,
  url: reduxState.notifications.popupProps.url,
  fileName: reduxState.notifications.popupProps.fileName,
});
export default connect(mapStateToProps, { closePopup, addError })(ExportWindow);
