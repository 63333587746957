import React,{useEffect,useState} from 'react'
import {signInAsAction} from '../actions/auth'
import { connect } from "react-redux";
// import {isMobile} from 'react-device-detect';

const Loginas =(props)=>{

const {username,password} = props.match.params
// const {signInAsAction}=props

const [isAuthenticated,setAuth] = useState(false)
useEffect(()=>

   {
    // localStorage.clear()     
   if(!isAuthenticated) signInAsAction({username,password,lang:'ENG'}).then(resp=>setAuth(true))},[isAuthenticated]
    )


    return( 
        <div>   
            {isAuthenticated && window.location.replace("/orders")}
            {!isAuthenticated && <div>loading account...</div>}
            </div>
             
    )
}


export default connect(null,{})(Loginas)