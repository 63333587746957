// import {
//     GET_BUSINESSCLIENTS,
//   } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = { type: "START_SERVICES_LOADING" };
const stopLoading = { type: "STOP_SERVICES_LOADING" };

export const getTransfers = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";
  const url = `${process.env.REACT_APP_API}/transffers?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => {
      dispatch(stopLoading);
      dispatch({ type: "GET_TRANSFERS", payload: resp })})
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateFilters = (newFilter) => ({
  type: "UPDATE_TRANSFER_FILTERS",
  payload: newFilter,
});


export const getTransfer = (id) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/transffers/${id}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_TRANSFER", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};


