import {
  GET_BUSINESSCLIENTS,
  GET_BUSINESSCLIENT,
  CREATE_BUSINESSCLIENT,
  UPDATE_BUSINESSCLIENT,
  GET_BUSINESSCLIENT_SETTINGS,
  CREATE_BUSINESSCLIENT_SETTINGS,
  UPDATE_BUSINESSCLIENT_SETTINGS,
  // Quotations
  CREATE_FULFILMENT_QUOTATION,
  CREATE_COURIER_QUOTATION,
  GET_FULFILMENT_QUOTATIONS,
  GET_COURIER_QUOTATIONS,
  GET_FULFILMENT_QUOTATION,
  GET_COURIER_QUOTATION,
  DELETE_FULFILMENT_QUOTATIONS,
  DELETE_COURIER_QUOTATIONS,
  SET_ACTIVE_FULFILMENT_QUOTATION,
  SET_ACTIVE_COURIER_QUOTATION,
  GET_BUSINESSCLIENT_SHIPTOS,
  ADD_BUSINESSCLIENT_SHIPTOS,
} from "../actions/actionTypes";

const initialState = {
  list: [],
  openClient: { companyids: [], settings: {} },
  isLoading: false,
  total: 0,
  fulfilmentQuotations: [],
  courierQuotations: [],
  sequences: [],
  fulfilmentQLoading: false,
  courierQLoading: false,
  sequencesLoading: false,
  shipTos:[]
};

const businessclients = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUSINESSCLIENTS:
      return {
        ...state,
        list: [...action.payload.list],
        total: action.payload.total,
        isLoading: false,
      };

    case "GET_SEQUENCES":
      return {
        ...state,
        sequences: action.payload,
        sequencesLoading: false,
      };
    case "CREATE_SEQUENCE":
      return {
        ...state,
        sequences: [...state.sequences, action.payload],
        sequencesLoading: false,
      };
    case "UPDATE_SEQUENCE":
      return {
        ...state,
        sequences: state.sequences.map((sequence) =>
          action.payload._id === sequence._id ? action.payload : sequence
        ),
        sequencesLoading: false,
      };
    case "DELETE_SEQUENCE":
      return {
        ...state,
        sequences: action.payload,
        sequencesLoading: false,
      };

    case "CREATE_CLIENTIDS":
      return {
        ...state,
        openClient: {
          ...state.openClient,
          companyids: [...state.openClient.companyids, action.payload],
        },
        isLoading: false,
      };
    case "UPDATE_CLIENTIDS":
      return {
        ...state,
        openClient: {
          ...state.openClient,
          companyids: state.openClient.companyids.map((coids) =>
            action.payload._id === coids._id ? action.payload : coids
          ),
        },
        isLoading: false,
      };

    case "DELETE_CLIENTIDS":
      return {
        ...state,
        openClient: {
          ...state.openClient,
          companyids: state.openClient.companyids.filter(
            ({ _id }) => action.payload !== _id
          ),
        },
        isLoading: false,
      };

    case GET_BUSINESSCLIENT:
    case CREATE_BUSINESSCLIENT:
    case UPDATE_BUSINESSCLIENT:
      return {
        ...state,
        openClient: action.payload,
        isLoading: false,
      };

    case GET_BUSINESSCLIENT_SETTINGS:
    case CREATE_BUSINESSCLIENT_SETTINGS:
    case UPDATE_BUSINESSCLIENT_SETTINGS:
      return {
        ...state,
        openClient: { ...state.openClient, settings: { ...action.payload } },
        // isLoading: false,
      };

    // CREATE
    // case CREATE_FULFILMENT_QUOTATION:
    //   return {
    //     ...state,
    //     // openClient: {
    //     //   ...state.openClient,
    //     //   settings: {
    //     //     ...state.openClient.settings,
    //     fulfilmentQuotations: [...state.fulfilmentQuotations, action.payload],
    //     // },
    //     // },
    //     fulfilmentQLoading: false,
    //   };
    // case CREATE_COURIER_QUOTATION:
    //   return {
    //     ...state,
    //     // openClient: {
    //     //   ...state.openClient,
    //     //   settings: {
    //     //     ...state.openClient.settings,
    //     courierQuotations: [...state.courierQuotations, action.payload],
    //     // },
    //     // },
    //     courierQLoading: false,
    //   };

    // GET ALL
    case GET_FULFILMENT_QUOTATIONS:
    case SET_ACTIVE_FULFILMENT_QUOTATION:
      return {
        ...state,
        // openClient: {
        //   ...state.openClient,
        //   settings: {
        //     ...state.openClient.settings,
        fulfilmentQuotations: action.payload,
        //   },
        // },
        fulfilmentQLoading: false,
      };
    case GET_COURIER_QUOTATIONS:
    case SET_ACTIVE_COURIER_QUOTATION:
      return {
        ...state,
        // openClient: {
        //   ...state.openClient,
        //   settings: {
        //     ...state.openClient.settings,
        courierQuotations: action.payload,
        //   },
        // },
        courierQLoading: false,
      };

    // GET ONE
    case GET_FULFILMENT_QUOTATION:
      return {
        ...state,
        fulfilmentQuotations: state.fulfilmentQuotations.find(
          ({ _id }) => _id === action.payload._id
        )
          ? state.fulfilmentQuotations.map((el) => {
              if (el._id === action.payload._id) return action.payload;
              else return el;
            })
          : [...state.fulfilmentQuotations, action.payload],
        fulfilmentQLoading: false,
      };
    case GET_COURIER_QUOTATION:
      return {
        ...state,
        courierQuotations: state.courierQuotations.find(
          ({ _id }) => _id === action.payload._id
        )
          ? state.courierQuotationsstate.map((el) => {
              if (el._id === action.payload._id) return action.payload;
              else return el;
            })
          : [...state.courierQuotations, action.payload],
        courierQLoading: false,
      };

    // DELETE
    case DELETE_FULFILMENT_QUOTATIONS:
      return {
        ...state,
        fulfilmentQuotations: state.fulfilmentQuotations.filter(
          ({ _id }) => _id !== action.payload
        ),
        fulfilmentQLoading: false,
      };
    case DELETE_COURIER_QUOTATIONS:
      return {
        ...state,
        courierQuotations: state.courierQuotations.filter(
          ({ _id }) => _id !== action.payload
        ),
        courierQLoading: false,
      };

    case GET_BUSINESSCLIENT_SHIPTOS:
      return {
        ...state,
        shipTos: action.payload
        }


    case ADD_BUSINESSCLIENT_SHIPTOS:

      let newList = [...state.shipTos,action.payload]

      return {
        ...state,
        shipTos: newList}

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    case "START_COURIER_QUOTATIONS_LOADING":
      return { ...state, courierQLoading: true };
    case "STOP_COURIER_QUOTATIONS_LOADING":
      return { ...state, courierQLoading: false };

    case "START_FULFILMENT_QUOTATIONS_LOADING":
      return { ...state, fulfilmentQLoading: true };
    case "STOP_FULFILMENT_QUOTATIONS_LOADING":
      return { ...state, fulfilmentQLoading: false };

    case "START_SEQUENCES_LOADING":
      return { ...state, sequencesLoading: true };
    case "STOP_SEQUENCES_LOADING":
      return { ...state, sequencesLoading: false };

    default:
      return state;
  }
};
export default businessclients;
