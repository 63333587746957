import { START_LOADING, STOP_LOADING } from "./actionTypes.js";

export const startLoading = () => {
  // console.log("START");
  return {
    type: START_LOADING
  };
};
export const stopLoading = () => {
  // console.log("STOP");
  return {
    type: STOP_LOADING
  };
};
