import {apiCall} from "../services/api"
import {addError,removeError} from "./error"
import {Operator} from '../setup'
import {setCurrentUser} from './auth'
const getUsersAction=(data)=>{
    return {
        type:"LOAD_USERS",
        data
    }
}
export const getUsers=()=>(dispatch=>{
     apiCall(`get`, `${process.env.REACT_APP_API}/users`)
            .then(users => {
                        
                if (users.foundUsers && users.foundUsers.length > 0) {
                        
                        let userlist = users.foundUsers.map(user => new Operator(user))
                        dispatch(getUsersAction({
                            userlist,
                            viewPermissions: users.routePermission
                        }))
                }
               
                })
            .catch(error => dispatch(addError(error.message)))
})


export const updateUser =(query)=>(dispatch=>{
    // debugger
    // dispatch({type:"ISLOADING"})
    apiCall('put', `${process.env.REACT_APP_API}/users`,query)
    .then(user =>    {
          dispatch(setCurrentUser(user))
            }
 )
    .catch(error => dispatch(addError(error.message)))
})

