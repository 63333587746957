import React from "react";
import { Segment,  Header } from "semantic-ui-react";

const ClientIds = ({client_ids=[]}) => {
  const items = client_ids.map((item,i) => (
    <tr>
      
      <td>{i+1}</td>
      <td>{item.eshop.eshopUrl}</td>
      <td>{item.id}</td>
    </tr>
  ));

  return (
    <div className="card_">
      <Segment>
        <Header as="h4">
          API Settings
        </Header>
        {/* <Divider /> */}
        <table className="stock-available-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Eshop</th>
              <th>Id</th>
            </tr>
          </thead>
          <tbody>{items}</tbody>
        </table>
      </Segment>
    </div>
  );
};

export default ClientIds;
