import { apiCall } from "../services/api";
import { addError } from "./error";
import { GET_NOTIFICATIONS } from "./actionTypes";

export const getNotfications = (userId) => (dispatch) => {
  const url = `${process.env.REACT_APP_API}/users/${userId}/notifications`;
  apiCall("get", url)
    .then((notifications) =>
      dispatch({
        type: GET_NOTIFICATIONS,
        notifications,
      })
    )
    .catch((err) => dispatch(addError(err.message)));
};

// export const getUserNotifications = () => (dispatch) => {
//   const url = `${process.env.REACT_APP_API}/notifications`;
//   apiCall("get", url)
//     .then((resp) =>
//       dispatch({
//         type: "GET_USER_NOTIFICATIONS",
//         payload: resp,
//       })
//     )
//     .catch((err) => dispatch(addError(err.message)));
// };

// export const delteteOneUserNotification = (id) => (dispatch) => {
//   const url = `${process.env.REACT_APP_API}/notifications/${id}`;
//   apiCall("delete", url)
//     .then((resp) =>
//       dispatch({
//         type: "DELETE_ONE_USER_NOTIFICATION",
//         payload: resp,
//       })
//     )
//     .catch((err) => dispatch(addError(err.message)));
// };

// export const delteteAllUserNotifications = () => (dispatch) => {
//   const url = `${process.env.REACT_APP_API}/notifications`;
//   apiCall("delete", url)
//     .then((resp) =>
//       dispatch({
//         type: "DELETE_USER_NOTIFICATIONS",
//         payload: resp,
//       })
//     )
//     .catch((err) => dispatch(addError(err.message)));
// };

// INFO TAGS
// export const getInfoTags = () => (dispatch) => {
//   const url = `${process.env.REACT_APP_API}/infotags`;
//   apiCall("get", url)
//     .then((resp) => dispatch({ type: "GET_INFOTAGS", payload: resp }))
//     .catch((err) => dispatch(addError(err.message)));
// };

// Export Popup
export const openPopup = (popupProps) => ({
  type: "OPEN_POPUP",
  payload: popupProps,
});
export const closePopup = () => ({ type: "CLOSE_POPUP" });
