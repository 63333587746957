
import React ,{useState} from 'react'
import {apiCall} from '../../../services/api'
import {Container,Message,Content,FlexboxGrid,ControlLabel,ButtonToolbar,Form,FormGroup,Panel,FormControl,Button,Alert} from 'rsuite'
import emag from './emag.jpg'
import Modal from "react-responsive-modal";


const Emaglogin =(props) =>{
  
  
  const [username,setUsername] = useState()
  const [password,setPassword] = useState()
  const [isModalOpen,setModalOpen]  = useState(false)

  const createEmagShop =()=>{
  const url = `${process.env.REACT_APP_API}/eshops/emag`;

     apiCall('post',url,{username,password})
      .then(eshop=>{
        
        Alert.success('added new Eshop')
        props.history.push(`/eshops/emag/${eshop._id}`)
      })
      .catch(error=>Alert.error(error.message,5000))
  }


  const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          <img style={{cursor:'zoom-in'}}src={emag} alt="help"></img>
                      </Modal>
  
  return (
  <div >
{viewImg}
    <Container>

        <Message
                                showIcon
                                type="warning"
                                title="Pas 1. Setare IP [37.139.16.98]"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    <p>Instructiuni:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Logativa in contul EMAG</li>
                                      <li>{`2. Adaugati IP 37.139.16.98 si 146.185.134.120 in lista aprobata. 
                                            Menu[Contul meu] > Profil > Detalii Technice > Adauga IP nou`}
                                          </li>
                                      {/* <li>{`3. Copiati utilizatorul din tabelul cu IP pentru a fi inclus in campul de mai jos "Username:"`}
                                          </li> */}
                                      
                                    </ul>
                                  </div>
                                  
                                       <a  onClick={()=>setModalOpen(true)}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={emag} alt="help"></img></a> 
                                  </div>
                                  }
                              />

      <Content>
                  
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel header={<h3>EMAG LOGIN</h3>} bordered>
              <Form fluid >
                   <Message
                                // showIcon
                                type="info"
                                title="EMAG Usename(email)"
                                description={
                                  <div>
                                    <FormControl name="name" onChange={(value)=>setUsername(value)} />
                                  </div>
                                  }
                              />
                       <Message
                                // showIcon
                                type="warning"
                                title="EMAG Password"
                                description={
                                  <div>
                                    <p>Parola de access la contul EMAG</p>
                                      <FormControl name="password" type="password" onChange={(value)=>setPassword(value)}/>
                                  </div>
                                  }
                              />
                
                  
                
                
                  
                
                <FormGroup>
                  <ButtonToolbar>
                    <Button appearance="primary" onClick={()=>createEmagShop()}>Login</Button>
                    
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  </div>)
}

export default Emaglogin