import {
    apiCall
} from "../services/api";

import {
    GET_BILLABLE_SERVICES
}  from './actionTypes'
import {addError,removeError,addSuccessMsg} from './error'




export const getBillableServices =(filter)=>dispatch=>{
    const url = `${process.env.REACT_APP_API}/services/billable`;
  
    apiCall('post',url,filter)
    .then(resp=>  dispatch({type:GET_BILLABLE_SERVICES,payload:resp}))
     .catch(err => {dispatch(addError(err.message))});
  
  }
  export const updateFilters = (newFilter) => ({
    type: "UPDATE_FILTER",
    payload: newFilter,
  });