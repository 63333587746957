import React from "react";
import withClearCache from "../ClearCache";

import "./App.scss";
// import Main from "./Main";
import MainWithNav from "./MainWithNav";

const ClearCacheComponent = withClearCache(MainWithNav);


// const MainWithNav = MainWithNav_HOC(Main);

class App extends React.Component {
  render() {
    // return <MainWithNav></MainWithNav>
    return <ClearCacheComponent />;
  }
}

export default App;
