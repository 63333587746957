import React, { useEffect } from "react";
import { Header, Segment, Icon, Label } from "semantic-ui-react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ExportButton from "../../../ExportButton";

import { getBill,getBillServices } from "../../../../actions/services";
import Head from "../../../Head";
import CardsList from "../../../CardsList";
import InfoBar from "../../../InfoBar";
import Responsive from "../../../Responsive";
// import ServicesTable from "../ServicesTable";
// import ExportServices from "./ExportServices";
import ItemsList from "./ItemsList";
import MobileItemCard from "./MobileItemCard";
import "../bills.scss";
import Servicesummary from "./runbillsummary";
import lang from '../../../../lang/config.json'

const statusColor = {
  true: "green",
  false: "yellow",
  "Not paid- Over due": "red",
};

function ViewBill({
  bill,
  syslang,
  getBill,
  isLoading,
  match,getBillServices,
  servicesSummary,
  servicesTotal
}) {
  const {
     sequenceNumber,
    // dates = {},
    period = {},
    country,
    currency,
    isPaid,
    totals = {},
    creditNote,
    items = [],
  } = bill;

  const billId = match.params.id;
  // const [dataForExport, setDataForExport] = useState([]);

  useEffect(() => {
    getBill(billId);
    getBillServices(billId);
  }, [billId]);

  // if (isLoading && !bill._id)
  //   return (
  //     <div style={{ marginTop: "2em", marginBottom: "2em" }}>
  //       <Loader active inline="centered" />
  //     </div>
  //   );

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar header={`Bill ${ sequenceNumber || ""}`} linkBack="/billing/bills">
            <Link to={`/bill/${billId}`}>
              <Icon name="file" />
              View
            </Link>{" "}
          </InfoBar>
        }
      />

      <main className="page-wrapper" style={{maxWidth:"1000px"}}>
        <Responsive
          desktopComponent={
            <Head linkBack="/billing/bills" header={`${lang.bills.title[syslang]} ${ sequenceNumber || ""}`}>
              {/* <div> */}
              <Link to={`/bill/${billId}`}>
                <Icon name="file" />
                PDF
              </Link>
              {/* </div> */}
            </Head>
          }
        />

        <Responsive
          mobileComponent={
            <Link to={`/bill/${billId}`}>
              <Icon name="file" />
              PDF
            </Link>
          }
        />

        <Segment padded loading={isLoading}>
          <div className="bill-grid">
            <section className="bill-grid__details">
              <Description
                label={lang.table.status[syslang]}
                text={
                  <Label color={statusColor[isPaid]} compact size="small">
                    {isPaid?"Paid":"Not paid"}
                  </Label>
                }
              />
              <Description
               label={lang.table.period[syslang]}
                text={
                  <>
                    <Moment utc format="DD.MM.YY">{period.start}</Moment> -{" "}
                    <Moment utc format="DD.MM.YY">{period.end}</Moment>
                  </>
                }
              />
              <Description
                label={lang.table.creditnote[syslang]}
                text={creditNote && creditNote.creditNoteNr}
              />
            </section>
            <section className="bill-grid__details">
              <Description                          
                 label={lang.bills.viewbill.country[syslang]}
 text={country} />
              <Description 
                                          label={lang.bills.viewbill.currency[syslang]}
                                          text={currency} />
            </section>
            <section className="bill-grid__details">
              <Description
                            label={lang.bills.viewbill.vattotal[syslang]}

                text={totals.VAT_total && totals.VAT_total.toFixed(2)}
              />
              <Description
                  label={lang.bills.viewbill.nettototal[syslang]}
                text={totals.VAT_total && totals.NETTO_total.toFixed(2)}
              />
              <Description
                  label={lang.bills.viewbill.bruttototal[syslang]}
                  text={totals.BRUTTO_total && totals.BRUTTO_total.toFixed(2)}
              />
            </section>
          </div>
        </Segment>


        
        <Segment padded>
          <div className='flex_spaceBetween'>
          {/* <Header as="h4">Items</Header> */}
          {/* <ExportButton
                      total={total}
                      fileName={`${bill.sequenceNumber}_services`}
                      url={`/bills/${bill._id}/services/export`}
                    /> */}
          </div>
          
          
          <Responsive
            mobileComponent={
              <CardsList
                items={items}
                isLoading={isLoading}
                notFoundMsg="No Items Found"
              >
                <MobileItemCard />
              </CardsList>
            }
            desktopComponent={<ItemsList syslang={syslang} lang={lang}items={items} />}
          />
          
          
        </Segment>
        
          {servicesSummary && (
            <Segment loading={isLoading} secondary style={{ marginTop: "3em" }}>
              <div className="flex--between">
                <Header as="h4">                           
                {lang.bills.viewbill.services.title[syslang]}
          </Header>
            <div style={{width:'100px'}}>
                <ExportButton
                  fileName={`${bill.sequenceNumber}_services`}
                  total={servicesTotal}
                  filters={{}}
                  url={`/bills/${billId}/services/export`}
                />
                </div>
              </div>

              <Servicesummary services={servicesSummary} 
                
                />
              {/* <ServicesTable
                services={services}
                currentPage={1}
                itemsPerPage={0}
                displayBusinessclient={false}
                displayBill={false}
              /> */}
            </Segment>
          )}
       
      </main>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  syslang:reduxState.user.currentUser.sys_lang,
  bill: reduxState.services.openBill,
  total: reduxState.services.openBill.countServices,
  isLoading: reduxState.services.isLoading,
  servicesSummary: reduxState.services.billServicesSummary,
  servicesTotal: reduxState.services.billServicesTotal,


});

export default connect(mapStateToProps, {
  getBill,
  getBillServices,
})(ViewBill);

const Description = ({ label, text, children }) => (
  <div className="bill-description">
    <span className="bill-description__label">{label}</span>
    <span className="bill-description__text">{text}</span>
    {children && (
      <div className="bill-description bill-subdescription">{children}</div>
    )}
  </div>
);
