import React from "react";
import {  Label, Button } from "semantic-ui-react";

import FormField from "./FormField";

function EditContact({
  email,
  phone,
  name,
  position,
  i,
  bclientId,
  contactId,
  updateBusinessClientContact,
  handleUpdateWithType,
  lang,syslang
}) {
  const handleUpdate = (fieldName, value) => {
    updateBusinessClientContact({
      bclientId,
      contactId,
      updatedContact: { email, phone, name, position, [fieldName]: value },
    });
  };

  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
        <br />
        <Button
          compact
          circular
          basic
          className="list-item__delete-btn"
          size="small"
          icon="trash"
          onClick={() =>
            handleUpdateWithType({ query: contactId, type: "remove contact" })
          }
        />
      </div>

      <div className="view-grid">
        <FormField
          label="Email"
          name="email"
          value={email}
          placeholder="email..."
          handleChange={handleUpdate}
        />

        <FormField
          label={lang.table.phone[syslang]}
          name={`phone`}
          value={phone}
          placeholder="phone..."
          handleChange={handleUpdate}
        />

        <FormField
          label={lang.table.name[syslang]}
          name={`name`}
          value={name}
          placeholder="contact person's name..."
          handleChange={handleUpdate}
        />

        <FormField
          label={lang.table.worktitle[syslang]}
          name={`position`}
          value={position}
          placeholder="contact person's position..."
          handleChange={handleUpdate}
        />
      </div>

      {/* <Divider /> */}
    </div>
  );
}

export default EditContact;
