import React from 'react'
import { Button, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'



class Home extends React.Component{
  constructor(props){
    super(props)
    this.state={
      disabled:false,
      requestingNext:false,
    }
    this.handleLetStart = this.handleLetStart.bind(this)
  }
  handleLetStart(){
   
  this.props.getNextOrder()
  this.setState({requestingNext:true})
  // get next order
  // if order exist > link to that order
  // if not , throw error
                                  }

  componentWillReceiveProps(nextProps){
          let {statusCounter}=nextProps
          
          if(statusCounter){
              let newOrders = statusCounter.New
              let {toprocess} = statusCounter
              if(newOrders===0 && toprocess===0){
                this.setState({disabled: true})
                            } else {
                              this.setState({disabled:false})
                  }}
  }
  render(){

  const {
        currentUser,
        isAuthenticated, history, errors
    } = this.props



  if(!isAuthenticated) {this.props.history.push('/signin')
}
else this.props.history.push('/orders')
  
return (
  <div>Sorry,page not found. <a href='/orders'>Sign in</a></div>
)
//   const {username}=currentUser


//     return (
//         <div>
//   {
//     currentUser && currentUser.roles.some(r => ["bc_admin"].includes(r)) ? history.push('/orders') : null
//   }
 
//   {     errors.message && (
//             <div>
//                   <div className='alert alert-danger'>{errors.message}</div>
                   
//                    </div>)}
//              <Header as='h1'> Hello, {username} </Header>
                
//             <div>
           
//             page not found.
            
//                 </div>
//             </div>
//     )

}

}


export default Home