import React, { useState, useEffect, createRef } from "react";
import { Icon, Input } from "semantic-ui-react";

function EditableText({
  placeholder,
  value,
  name,
  handleChange,
  maxWidth,
  editable = true,
  transparentBackground = false
}) {
  const [isHovered, setHovered] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [text, setText] = useState(value);
  const input = createRef();

  useEffect(() => {
    setText(value);
  }, [value]);

  const handleEdit = () => {
    setIsEditable(true);
  };



  const hadleInternChange = (e, d) => {
    setText(d.value);
  };

  const handleSubmit = () => {
    setIsEditable(false);
    setHovered(false);
    handleChange({ name, value: text });
  };

  if (!editable) {
    return (
      <div
        className={`editable  editable-field__text  ${
          transparentBackground ? "editable_text" : ""
        }`}
        style={{ width: "100%", maxWidth: "500px" }}
      >
        {text}
      </div>
    );
  } else {
    if (!isEditable) {
      return (
        <div
          className={`editable  editable-field__text  ${
            transparentBackground ? "editable_text" : ""
          }`}
          onClick={handleEdit}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          {text}
          <span
            className={`editable-field__icons ${!isHovered &&
              "editable-field__icons_invisible"}`}
          >
            <Icon
              name="pencil alternate"
              color="grey"
              style={{ marginRight: "-7px" }}
              onClick={handleEdit}
            />
          </span>
        </div>
      );
    } else {
      return (
        <Input
          ref={input}
          fluid={maxWidth}
          style={maxWidth ? { maxWidth } : {}}
          placeholder={placeholder}
          value={text}
          name={name}
          onChange={hadleInternChange}
          onBlur={handleSubmit}
        />
      );
    }
  }
}

export default EditableText;
