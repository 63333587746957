import {
  ADD_PACKAGES,
  UPDATE_ORDER,
  GET_PACKER_ORDERS,
  GET_PACKER_DRAFT_ORDERS,
  PRINT_AWB_SUCCESS,
  PRINT_AWB_START,
  PRINT_AWB_STOP
} from "../actions/actionTypes";

const initialState = {
  list: [],
  total: null,
  draftList: [],
  openOrder: {
    _id: null,
    items: [],
    shipping: {
      shipTo: ""
    }
  },
  isLoading: false,
  isAWBPrinting: false,
  isBarcodePrinting: false,
  isAWBPrinted: false,
  isBarcodePrinted: false,
  isPacked: false,
  isDraft: false
};

function packing(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_STATE':
      return {
        ...initialState
      }
    case ADD_PACKAGES:
      return {
        ...state,
        isLoading: false,
        openOrder: action.order,
        isAWBPrinted: false,
        isBarcodePrinted: false,
        isPacked:action.order.status === 'Packed',
        isDraft:action.order.status==='Draft'
      };
    case GET_PACKER_ORDERS:
      return {
        ...initialState,
        list: action.payload.list,
        total: action.payload.total,
        // isLoading: false
      };
    case GET_PACKER_DRAFT_ORDERS:
      return {
        ...initialState,
        draftList: action.payload,
        // isLoading: false
      };

    case UPDATE_ORDER:
      return {
        ...state,
        openOrder: action.payload,
        isLoading: false
      };

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    case PRINT_AWB_START:
      return { ...state, isAWBPrinting: true };

    case PRINT_AWB_STOP:
      return { ...state, isAWBPrinting: false, isAWBPrinted: false };

    case PRINT_AWB_SUCCESS:
      return { ...state, isAWBPrinted: true, isAWBPrinting: false };

    case "PRINT_BARCODE_START":
      return { ...state, isBarcodePrinting: true };

    case "PRINT_BARCODE_STOP":
      return {
        ...state,
        isBarcodePrinting: false,
        isBarcodePrinted: false
      };

    case "PRINT_BARCODE_SUCCESS":
      return { ...state, isBarcodePrinted: true, isBarcodePrinting: false };
    default:
      return state;
  }
}

export default packing;
