const initstate = {
  isLoading: false,
  list: [],
  filters: {
    currentPage: 1,
    displayView: 10,
    products: [],
    dateFrom: null,
    dateTo: null
  },
  total: null,
  updating: false,
  openShipment: {
    warehouse: "",
    client: { name: "", _id: "" },
    seller: { name: "", _id: "" },
    height: "",
    weight: "",
    volume: "",
    photos: [],
    comment: "",
    goodsreceived: [
      {
        stockMovement: {
          inventory: {
            product: {
              name: null,
              codes: {
                barcode: null,
                sku: null,
                clientsku: null
              }
            },
            photos: { files: [] }
          }
        }
      }
    ]
  }
};

const inboundshipments = (state = initstate, action) => {
  switch (action.type) {
    case "INBOUND_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filter
        }
      };
    case "INBOUND_SHIPMENT_GOODSRECEIVED":
      return {
        ...state,
        updating: false,
        openShipment: {
          ...state.openShipment,
          goodsreceived: action.goodsreceived
        }
      };
    case "ADD_INBOUND_SHIPMENT_PHOTOS_INVENTORY":
      return {
        ...state,
        //  updating: false,
        openShipment: {
          ...state.openShipment,
          goodsreceived: action.goodsreceived
        }
      };
    case "ADD_INBOUND_SHIPMENT_PHOTOS":
      return {
        ...state,
        updating: false,
        openShipment: { ...state.openShipment, photos: action.photos }
      };
    case "UPDATING":
      return { ...state, updating: true };
    case "ADD_INBOUND_SHIPMENT":
      return {
        ...state,
        openShipment: action.shipment,
        updating: false,
        isLoading: false
      };

    case "GET_SHIPMENTS":
      return {
        ...state,
        list: action.shipments.list,
        total: action.shipments.total
      };
    case "GET_INBOUND_SHIPMENT":
      return { ...state, openShipment: action.data, isLoading: false };

    case "CLEAR_SHIPMENT_STATE":
      return {
        ...state,
        openShipment: { ...initstate.openShipment }
      };

    case "DELETE_SHIPMENT":
      // debugger
      const newList = [];

      state.list.forEach(item => {
        if (item._id !== action.id) {
          newList.push(item);
        }
      });

      return {
        ...state,
        list: newList,
        total: state.total - 1
      };

    case "GET_INBOUND_SHIPMENTS":
      return {
        ...state,
        list: action.shipments.list,
        total: action.shipments.total
      };

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default inboundshipments;
