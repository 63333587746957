import React from "react";
import { Table } from "semantic-ui-react";
// import EditableText from "../../EditableText";

function ItemsList({ items = [], 
    
     editable }) {
  if (!items.length) return null;
  return (
    <Table basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>#</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Unit price (NETTO)</Table.HeaderCell>
          <Table.HeaderCell>VAT rate (%)</Table.HeaderCell>
          {!editable && <Table.HeaderCell>VAT amount </Table.HeaderCell>}
          {/* <Table.HeaderCell>BRUTTO Price</Table.HeaderCell> */}
          <Table.HeaderCell>Total NETTO</Table.HeaderCell>
          <Table.HeaderCell>Total BRUTTO</Table.HeaderCell>
          {/* {(!isPaid || editable) && <Table.HeaderCell>Action</Table.HeaderCell>} */}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {items.map(
          (
            {
              _id,
              name,
              quantity,
              VAT_procent,
              VAT_amount,
              netto_price, //
              price,
              netto_total,
              brutto_total,
            },
            i
          ) => (
            <Table.Row key={i}>
              <Table.Cell>{i + 1}</Table.Cell>
              <Table.Cell>
                {name}
                {/* {editable ? (
                  <EditableText
                    placeholder="description..."
                    name="description"
                    value={description}
                    maxWidth={160}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  description
                )} */}
              </Table.Cell>
              <Table.Cell>
                {quantity}
                {/* {editable ? (
                  <EditableText
                    placeholder="quantity..."
                    name="quantity"
                    value={quantity}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  quantity
                )} */}
              </Table.Cell>
              <Table.Cell>
                {netto_price}
                {/* {editable ? (
                  <EditableText
                    placeholder="NETTO price..."
                    name="netto_price"
                    value={netto_price}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  Number(netto_price).toFixed(2)
                )} */}
              </Table.Cell>
              <Table.Cell>
                {VAT_procent}
                {/* {editable ? (
                  <EditableText
                    placeholder="VAT rate..."
                    name="VAT_procent"
                    value={VAT_procent}
                    maxWidth={70}
                    handleChange={({ name, value }) =>
                      updateItem(i, { name, value })
                    }
                  />
                ) : (
                  VAT_procent
                )} */}
              </Table.Cell>

              {!editable && <Table.Cell>{VAT_amount}</Table.Cell>}

              <Table.Cell>
                {editable ? (
                  <span style={editable ? { lineHeight: "2.7" } : {}}>
                    {((1 + VAT_procent / 100) * Number(netto_price)).toFixed(2)}
                  </span>
                ) : (
                  price
                )}
              </Table.Cell>

              <Table.Cell>
                {editable ? (
                  <span style={editable ? { lineHeight: "2.7" } : {}}>
                    {(quantity * netto_price).toFixed(2)}
                  </span>
                ) : (
                  netto_total.toFixed(2)
                )}
              </Table.Cell>
              {/* <Table.Cell>
                {editable ? (
                  <span style={editable ? { lineHeight: "2.7" } : {}}>
                    {(
                      quantity *
                      Number(netto_price) *
                      (1 + Number(VAT_procent) / 100)
                    ).toFixed(2)}
                  </span>
                ) : (
                  brutto_total.toFixed(2)
                )}
              </Table.Cell> */}
              {/* {editable && (
                <Table.Cell>
                  <Button
                    basic
                    compact
                    color="red"
                    size="tiny"
                    icon="trash"
                    onClick={() => deleteItem(i)}
                  />
                </Table.Cell>
              )} */}
{/* 
              {!editable && (
                <Table.Cell>
                  <Button
                    basic
                    compact
                    color="red"
                    size="tiny"
                    icon="trash"
                    onClick={() => removeItemAction(_id)}
                  />
                </Table.Cell>
              )} */}
            </Table.Row>
          )
        )}
      </Table.Body>
    </Table>
  );
}

export default ItemsList;
