import React, { useState, useEffect } from "react";
import {
  Segment,
  // Dimmer,
  // Loader,
  // Icon,
  Header,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";

import {
  createBusinessClient,
  updateBusinessClient,
  getBusinessClient,
  updateBusinessClientContact,
  updateBusinessClientBankAccount,
  createSequence,
  deleteSequence,
  addClientIds,
  updateClientIds,
  deleteClientIds,
} from "../../actions/businessclient";
// import CanViewPage from "../../role-based-access/CanViewPage";
// import CanView from "../../role-based-access/CanView";
import EditAddress from "./EditAddress";
import EditContact from "./EditContact";
import EditBankAccount from "./EditBankAccount";
import EditClientIds from "./EditClientIds";
// import AddBankAccount from "./CreateBC/AddBankAccount";
import AddContact from "./CreateBC/AddContact";
// import AddClientIds from "./CreateBC/AddClientIds";
import FormField from "./FormField";
// import ProfileSettings from "./ProfileSettings/ProfileSettings";
import "./businessclient.scss";
import lang from '../../lang/config.json'

function Businessprofile({
  syslang,
  businessclient,
  updateBusinessClient,
  updateBusinessClientContact,
  updateBusinessClientBankAccount,
  currentUser,
  updateClientIds,
  deleteClientIds,
  getBusinessClient,
  createBusinessClient,
  match,
  history
}) {
  const addNewClient=()=>{
    createBusinessClient(history)
  }
  useEffect(() => getBusinessClient(match.params.id), []);
  // const [formValues, setFormValues] = useState(initFormValues);
  const {
    name,
    address,
    contacts = [],
    bankaccounts = [],
    companyids = [],
  } = businessclient;

  const handleUpdate = (name, value) =>
    updateBusinessClient({ id: match.params.id, query: { [name]: value } });

  
  const [addContactIsOpen, setAddContactOpen] = useState(false);
  // const [addBankIsOpen, setAddBankOpen] = useState(false);
  // const [addClientIdsIsOpen, setAddClientIdsOpen] = useState(false);

  const handleUpdateWithType = ({ query, type }) =>
    updateBusinessClient({
      id: match.params.id,
      query,
      type,
    });

if(!currentUser.businessclient&&match.params.id==='null') return (<button onClick={addNewClient}>Add New</button>)

  return (
    // <CanViewPage path="/businessclients/create/new">
      <main className="page-wrapper a-wrapper">
        
        <Segment padded>
          <Header as="h2">{lang.settings.company.title[syslang]}</Header>

          <div className="businessclient">
            {/* <div className="businessclient__sidebar">
              <Header as="h4">Name</Header>
            </div> */}
            <div className="businessclient__form">
              <div className="view-grid_no-bg view-grid " style={{fontSize:"20px"}}>
                <FormField
                  label={lang.settings.company.companyname[syslang]}
                  name="name"
                  value={name}
                  placeholder="name..."
                  handleChange={handleUpdate}
                  editable={true}
                />
              </div>
            </div>
            {/* Address  */}
            <div className="businessclient__sidebar">
              <Header as="h3">{lang.table.address[syslang]}</Header>
            </div>

                <EditAddress 
                   editable={true}
                  lang={lang}
                  syslang={syslang}
                  handleUpdate={handleUpdate} {...address} />
            
            {/* Contacts */}
            <div className="businessclient__sidebar">
              <Header as="h3">{lang.table.contact[syslang]}</Header>
            </div>
            <div className="businessclient__form">
              {contacts.map((contact, i) => (
                <EditContact
                 lang={lang}
                 syslang={syslang}
                  {...contact}
                  key={contact._id}
                  i={i}
                  contactId={contact._id}
                  bclientId={match.params.id}
                  // contacts={contacts}
                  updateBusinessClientContact={updateBusinessClientContact}
                  // removeContact={removeContact}
                  // updateContacts={updateContacts}
                  handleUpdateWithType={handleUpdateWithType}
                />
              ))}
              {addContactIsOpen ? (
                <AddContact
                  lang={lang}
                  syslang={syslang}
                  addContact={(query) =>
                    handleUpdateWithType({ query, type: "new contact" })
                  }
                />
              ) : (
                // <CanView path="/businessclients/:id" action="edit">
                  <Button
                    circular
                    compact
                    basic
                    // floated="right"
                    style={{ marginLeft: "4em" }}
                    size="mini"
                    icon="plus"
                    content="Add"
                    onClick={() => setAddContactOpen(true)}
                  />
                // </CanView>
              )}
            </div>
            {/* Bank Account */}
            <div className="businessclient__sidebar">
              <Header as="h3">{lang.table.bankdetails[syslang]}</Header>
            </div>
            <div className="businessclient__form">
              {bankaccounts.map((account, i) => (
                <EditBankAccount
                   editable={false}

                  lang={lang}
                  syslang={syslang}
                  {...account}
                  bankAccountId={account._id}
                  bclientId={match.params.id}
                  key={i}
                  i={i}
                  handleUpdateWithType={handleUpdateWithType}
                  updateBusinessClientBankAccount={
                    updateBusinessClientBankAccount
                  }
                  // updateBankAccount={updateBankAccount}
                  // removeBankAccount={removeBankAccount}
                />
              ))}
              
            </div>

            {/* Client Ids */}
            <div className="businessclient__sidebar">
              <Header as="h3">{lang.settings.company.companyid[syslang]} <span style={{fontSize:'12px'}}></span></Header>
              
            </div>
            <div className="businessclient__form">
              {companyids.map((coIds, i) => (
                <EditClientIds
                  syslang={syslang}
                  lang={lang}
                  {...coIds}
                  id={coIds._id}
                  bclientId={match.params.id}
                  key={i}
                  i={i}
                  
                  updateClientIds={updateClientIds}
                  deleteClientIds={deleteClientIds}
                />
              ))}
              {/*   */}
            </div>
          </div>
        </Segment>
{/* 
        <ProfileSettings
              settings={businessclient.settings}

          /> */}
      </main>
    // </CanViewPage>
  );
}

const mapStateToProps = (reduxState, ownProps) => ({
  isLoading: reduxState.businessclients.isLoading,
  businessclient: reduxState.businessclients.openClient,
  syslang: reduxState.user.currentUser.sys_lang,
  currentUser:reduxState.user.currentUser
});

export default connect(mapStateToProps, {
  updateBusinessClient,
  getBusinessClient,
  createBusinessClient,
  updateBusinessClientContact,
  updateBusinessClientBankAccount,
  createSequence,
  deleteSequence,
  addClientIds,
  updateClientIds,
  deleteClientIds,
})(Businessprofile);
