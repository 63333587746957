import React, { useState, useEffect } from "react";
import {
  Header,
  Button,
  Loader,
  Table,
  Segment,
  Input
} from "semantic-ui-react";
import Moment from "react-moment";

// import ProductList from "./ProductList";
import DeleteGroupModal from "./DeleteGroupModal";

function ProductGroupInfo(props) {
  const {
    isLoading,
    productGroup,
    updateProductGroup,
    removeProduct,
    history
  } = props;
  const [isModalOpen, setModal] = useState(false);
  const [isEditingTitle, setEditTitle] = useState(false);
  const [title, setTitle] = useState("");

  const handleToggleModale = () => setModal(!isModalOpen);
  const handleEditTitle = () => setEditTitle(!isEditingTitle);
  const handleTitleChange = (e, { value }) => setTitle(value);
  const handleSave = () => {
    handleEditTitle();
    updateProductGroup({ id: productGroup._id, name: title });
    console.log(title);
  };

  useEffect(() => {
    if (isEditingTitle) setTitle(productGroup.name);
  }, [isEditingTitle]);

  const tableBody =
    productGroup.products &&
    productGroup.products.map(({ _id, name, codes = { sku: "" } }, index) => (
      <Table.Row key={_id + index}>
        <Table.Cell verticalAlign="middle">{index + 1}</Table.Cell>
        <Table.Cell verticalAlign="middle">
          <span>{name}</span> - <span className="grey--text">{codes.sku}</span>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <Button
            size="tiny"
            color="red"
            basic
            icon="trash"
            onClick={() => removeProduct([_id])}
          />
        </Table.Cell>
      </Table.Row>
    ));

  if (!productGroup._id) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else {
    return (
      <section className="pg-add-products">
        <div className="pg-info--edit-buttons">
          <div>
            <Button
              compact
              size="tiny"
              color="yellow"
              disabled={isEditingTitle}
              onClick={handleEditTitle}
            >
              Change name
            </Button>
            <Button
              compact
              size="tiny"
              color="red"
              onClick={handleToggleModale}
            >
              Delete group
            </Button>
          </div>

          <DeleteGroupModal
            _id={productGroup._id}
            isModalOpen={isModalOpen}
            handleCloseModal={handleToggleModale}
            history={history}
          />

          {isEditingTitle && (
            <Segment raised>
              <label>New group name:</label>
              <br />
              <Input
                type="text"
                placeholder="New name..."
                action
                value={title}
                onChange={handleTitleChange}
              >
                <input />
                <Button color="yellow" onClick={handleSave} size="tiny" compact>
                  Save
                </Button>
                <Button onClick={handleEditTitle} size="tiny" compact>
                  Cancel
                </Button>
              </Input>
            </Segment>
          )}
        </div>

        <div class="small--text">
          <span
            class="grey--text"
            style={{ minWidth: "5.8em", display: "inline-block" }}
          >
            Created at:
          </span>
          <Moment format="DD-MM-YY HH:mm">{productGroup.createdAt}</Moment>
        </div>
        <div class="small--text">
          <span
            class="grey--text"
            style={{ minWidth: "5.8em", display: "inline-block" }}
          >
            Updated at:
          </span>
          <Moment format="DD-MM-YY HH:mm">{productGroup.updatedAt}</Moment>
        </div>

        {/* <ProductList
                    products={productGroup.products}
                    isLoading={isLoading}
                    button={{
                      action: removeProducts,
                      text: "Delete",
                      color: "red",
                      icon: "trash"
                    }}
                  /> */}
        <Table unstackable selectable style={{ marginTop: "1.3em" }}>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      </section>
    );
  }
}

export default ProductGroupInfo;
