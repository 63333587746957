import React, { Component } from "react";
import { Button, Form,  Header, Segment } from "semantic-ui-react";
import {isMobile} from 'react-device-detect';

import "./authform.scss";

export class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      isMobile
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.onAuth(this.state);
    this.setState({
      username: "",
      password: "",
      email: ""
    });
  }
  componentDidMount() {
    this.setState({isMobile})
  }
  render() {
    const { username, password } = this.state;
    const {  isAuthenticated } = this.props;


    // if (roles !== undefined && currentUser.roles.indexOf('manager') !== -1) {
    //     url = `/orders`

    // } else {
    //      url = `/users/${currentUser.userId}/orders/next`

    // }
    const LoginForm = (
      <div className="login-form--wrapper">
        <div className="login-form">
          {/*
                      Heads up! The styles below are necessary for the correct render of this example.
                      You can do same with CSS, the main idea is that all the elements up to the `Grid`
                      below must have a height of 100%.
                    */}
          <style>{`
                      body > div,
                      body > div > div,
                      body > div > div > div.login-form {
                        height: 100%;
                      }
                    `}</style>

          <Header as="h2" color="teal" textAlign="center">
            Log-in to your account
          </Header>
          <Form size="large">
            <Segment padded>
              <label className="auth-form--label">Username:</label>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="username"
                id="username"
                name="username"
                onChange={this.handleChange}
                value={username}
              />
              <label className="auth-form--label">Password:</label>
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="password"
                type="password"
                id="password"
                name="password"
                onChange={this.handleChange}
                value={password}
              />

              <Button
                style={{ marginTop: "1.6em" }}
                color="teal"
                fluid
                size="large"
                onClick={this.handleSubmit}
              >
                Login
              </Button>
            </Segment>
          </Form>
          {/* <Message>
                          New to us? <a href='#'>Sign Up</a>
                        </Message> */}
        </div>
      </div>
    );

    return (
      <div>
        {isAuthenticated && this.props.history.push("/")}

        {!isAuthenticated && LoginForm}
      </div>
    );
  }
}
