import {
  UPDATE_PRODUCT,
  CREATE_PRODUCT_GROUP,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUPS,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  ADD_PRODUCTS_PRODUCT_GROUP,
  REMOVE_PRODUCTS_PRODUCT_GROUP
} from "../actions/actionTypes";

const initialState = {
  list: [],
  total: null,
  filters: {
    products: '',
    productgroups: [],
    warehouses: '',
    currentPage: 1,
    itemsPerPage: 10,
    sort: {}
  },
  warehouses: [],
  isLoading: true,
  moveTypes: [],
  products: [],
  clientProducts: [],
  productsTotal: null,

  productGroups: [],
  openProductGroup: {},

  openProduct: {
    notPairedOrders: 0,
    stockMovements: { movements: [], total: null },
    stockTotals: [],
    name: "",
    codes: {
      sku: "",
      clientsku: "",
      barcode: ""
    },
    size: "N/A",
    color: "N/A",
    description: {
      note: "",
      dimentions: {
        height: "",
        lenght: "",
        width: "",
        weight: ""
      }
    }
  },
  succMsg: null
  // errMsg:null
};
const inventory = (state = initialState, action) => {
  switch (action.type) {
    case "WAREHOUSE_FILTER":
      return {
        ...state,
        isLoading: true,
        filters: {
          ...state.filters,
          ...action.filter
        }
      };
    case "GET_STOCKMOVEMENTS":
      return {
        ...state,
        openProduct: {
          ...state.openProduct,
          stockMovements: action.payload.stockmoves,
          stockTotals: action.payload.warehouseTotal
        }
      };
  }
  switch (action.type) {
    case "GET_WAREHOUSES":
      return {
        ...state,
        warehouses: action.data,
        succMsg: false
      };

    case "ADD_MOVETYPES":
      return { ...state, moveTypes: action.movetypes };
    case "GET_STOCKMOVEMENT":
      return {
        ...state,
        stockMovements: { movements: action.data, total: null }
      };
    case "GET_CLIENT_PRODUCTS":
      return {
        ...state,
        clientProducts: action.products
      };
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.products.list
      };
    case "GET_FILTERED_PRODUCTS":
      return {
        ...state,
        products: action.products.list,
        productsTotal: action.products.total
      };
    case "GET_PRODUCT":
      return {
        ...state,
        openProduct: {
          ...state.openProduct,
          ...action.product,
          notPairedOrders: action.notPairedOrders
        }
      };

    case "UPDATE_PRODUCT":
      return {
        ...state,
        openProduct: {
          ...state.openProduct,
          ...action.product
        }
      };

    case GET_PRODUCT_GROUPS:
      return {
        ...state,
        productGroups: action.groups
      };
    case GET_PRODUCT_GROUP:
    case CREATE_PRODUCT_GROUP:
    case UPDATE_PRODUCT_GROUP:
    case ADD_PRODUCTS_PRODUCT_GROUP:
    case REMOVE_PRODUCTS_PRODUCT_GROUP:
      return {
        ...state,
        openProductGroup: { ...state.openProductGroup, ...action.payload }
      };

    case DELETE_PRODUCT_GROUP:
      return {
        ...state,
        productGroups: state.productGroups.filter(
          ({ _id }) => _id !== action.payload.id
        )
      };

    case "GET_INVENTORY":
      return {
        ...state,
        isLoading: false,

        list: action.data.list,
        totals: action.data.totals
      };

    case "SUCC_MSG":
      return {
        ...state,
        succMsg: action.data
      };
    default:
      return state;
  }
};
export default inventory;
