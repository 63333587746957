import React from "react";

import { countries } from "../Filters/data";
import FormField from "./FormField";

function EditAddress({
  country,
  region,
  province,
  city,
  street,
  streetNr,
  postcode,
  handleUpdate,
  lang,syslang,
  editable,
}) {
  const countriesOptions = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  // console.log(address);

  return (
    <div className="businessclient__form">
      <div className="view-grid_no-bg view-grid ">
        <FormField
          editable={editable}
          label={lang.table.country[syslang]}
          name="address.country"
          placeholder="country ..."
          value={country}
          handleChange={handleUpdate}
          options={countriesOptions}
        />

        <FormField
          editable={editable}
          label={lang.table.region[syslang]}
          name="address.region"
          placeholder="region ..."
          value={region}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label="Province"
          name="address.province"
          placeholder="province ..."
          value={province}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.table.city[syslang]}
          name="address.city"
          placeholder="city ..."
          value={city}
          handleChange={handleUpdate}
        />

        <FormField
          label={lang.table.street[syslang]}
          name="address.street"
          placeholder="street ..."
          value={street}
          handleChange={handleUpdate}
        />

        <FormField          
          editable={editable}
          label={lang.table.nr[syslang]}
          name="address.streetNr"
          placeholder="street nr ..."
          value={streetNr}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.table.postcode[syslang]}
          name="address.postcode"
          placeholder="postcode ..."
          value={postcode}
          handleChange={handleUpdate}
        />
      </div>
    </div>
  );
}

export default EditAddress;
