import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

import {
  GET_PRODUCTS,
  GET_CLIENT_PRODUCTS,
  GET_PRODUCT,
  GET_FILTERED_PRODUCTS,
  GET_STOCKMOVEMENTS,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  ADD_XLS_PRODUCTS,
  GET_PRODUCT_GROUP,
  GET_PRODUCT_GROUPS,
  CREATE_PRODUCT_GROUP,
  UPDATE_PRODUCT_GROUP,
  DELETE_PRODUCT_GROUP,
  ADD_PRODUCTS_PRODUCT_GROUP,
  REMOVE_PRODUCTS_PRODUCT_GROUP
} from "./actionTypes";

export const createProduct = product => dispatch => {
  const url = `${process.env.REACT_APP_API}/products`;
  dispatch({ type: "START_LOADING_NEW_PROD" });
  apiCall("post", url, product)
    .then(product => {
      dispatch({ type: CREATE_PRODUCT, product });
    })
    .catch(err => {
      dispatch({ type: "STOP_LOADING_NEW_PROD" });
      dispatch(addError(err.message));
    });
};

export const getProductList = () => dispatch => {
  apiCall(`get`, `${process.env.REACT_APP_API}/products/`)
    .then(products => {
      dispatch({ type: GET_PRODUCTS, products });
    })
    .catch(err => {
      dispatch(addError(err.message));
    });
};

export const getProductListLimit = ({searchTerm,warehouse}) => {

 return new Promise((resolve,reject)=>{
        apiCall(`get`, `${process.env.REACT_APP_API}/products?searchTerm=${searchTerm}&warehouse=${warehouse}`)
          .then(products => {
            resolve(products)
          })
          .catch(err => {
            reject(err)
            console.log(err)
          });
 })
  
};

export const getFilteredProductList = ({
  currentPage,
  itemsPerPage,
  searchTerm = "",
  selectedBclients = []
}) => dispatch => {
  dispatch({ type: "LOADING_START" });
  const bclientStr = selectedBclients.length
    ? selectedBclients.map(item => `&businessclient=${item}`).join("")
    : "";
  const searchTermStr = searchTerm ? `&searchTerm=${searchTerm}` : "";
  const url = `${process.env.REACT_APP_API}/products/?currentPage=${currentPage}&itemsPerPage=${itemsPerPage}${searchTermStr}${bclientStr}`;
  apiCall(`get`, url)
    .then(products => {
      dispatch({ type: GET_FILTERED_PRODUCTS, products });
      dispatch({ type: "LOADING_STOP" });
    })
    .catch(err => {
      dispatch(addError(err.message));
      dispatch({ type: "LOADING_STOP" });
    });
  // .finally(dispatch({ type: "LOADING_STOP" }));
};

// export const getClientProductList = businessclient => dispatch => {
//   apiCall(
//     `post`,
//     `${process.env.REACT_APP_API}/products/businessclient/${businessclient}`
//   )
//     .then(products => dispatch({ type: GET_CLIENT_PRODUCTS, products }))
//     .catch(err => dispatch(addError(err.message)));
// };

export const getProduct = id => dispatch => {
  apiCall("get", `${process.env.REACT_APP_API}/products/${id}`)
    .then(resp =>
      dispatch({
        type: GET_PRODUCT,
        product: resp.foundProduct,
        notPairedOrders: resp.notPairedOrders
      })
    )
    .catch(err => dispatch(addError(err.message)));
};

export const updateProduct = (id, body) => dispatch => {
  apiCall("put", `${process.env.REACT_APP_API}/products/${id}`, body)
    .then(resp =>
      dispatch({
        type: UPDATE_PRODUCT,
        product: resp
      })
    )
    .catch(err => dispatch(addError(err.message)));
};
export const updateMultiple = ({ids, query}) => dispatch => {
    dispatch({ type: "LOADING_START" });

  apiCall("put", `${process.env.REACT_APP_API}/products`, {ids,query})
    .then(products =>{
         dispatch({ type: GET_FILTERED_PRODUCTS, products })
          dispatch({ type: "LOADING_STOP" })
    })
    .catch(err => dispatch(addError(err.message)));
};

export const getMovements = ({ id, filters }) => dispatch => {
      dispatch({ type: "LOADING_START" });

  const url = `${process.env.REACT_APP_API}/products/${id}/movements`;
  apiCall("post", url,filters)
    .then(resp =>{
        dispatch({
        type: GET_STOCKMOVEMENTS,
        payload: resp
      })
    dispatch({ type: "LOADING_STOP" })
    })
    .catch(err => dispatch(addError(err.message)));
};

export const uploadProdFromXLS = ({ products, businessclient }) => dispatch => {
  dispatch({ type: "START_LOADING_NEW_PROD" });
  const url = `${process.env.REACT_APP_API}/products/upload/xls`;
  dispatch(removeError);
  apiCall("post", url, { products, businessclient })
    .then(resp => dispatch({ type: ADD_XLS_PRODUCTS, payload: resp }))
    .catch(err => {
      dispatch({ type: "STOP_LOADING_NEW_PROD" });
      dispatch(addError(err.message));
    });
};

export const pairToOrders = id => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/${id}/pairToOrders`;
  apiCall("post", url)
    .then(resp => alert(`Paired ${resp.paired} orders`))
    .catch(err => dispatch(addError(err.message)));
};

// Product Group
export const createProductGroup = (history, body) => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/`;
  apiCall(`post`, url, body)
    .then(resp => {
      dispatch({ type: CREATE_PRODUCT_GROUP, payload: resp });
      history.push(`/productgroup/${resp._id}`);
    })
    .catch(err => dispatch(addError(err.message)));
};

export const getProductGroup = _id => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/${_id}`;
  apiCall(`get`, url)
    .then(resp => dispatch({ type: GET_PRODUCT_GROUP, payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};

export const getProductGroups = () => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups`;
  apiCall(`get`, url)
    .then(resp => dispatch({ type: GET_PRODUCT_GROUPS, groups: resp }))
    .catch(err => dispatch(addError(err.message)));
};

export const updateProductGroup = ({ id, name }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/${id}`;
  apiCall(`put`, url, { name })
    .then(resp => dispatch({ type: UPDATE_PRODUCT_GROUP, payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};

export const deleteProductGroup = (id, history) => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/${id}`;
  apiCall(`delete`, url)
    .then(resp => dispatch({ type: DELETE_PRODUCT_GROUP, payload: resp }))
    .then(() => history && history.push("/products/list/group"))
    .catch(err => dispatch(addError(err.message)));
};

export const addProductsProductGroup = ({ _id, products }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/${_id}/products`;
  apiCall(`post`, url, { products })
    .then(resp => dispatch({ type: ADD_PRODUCTS_PRODUCT_GROUP, payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};

export const removeProductsProductGroup = ({ _id, products }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/products/groups/${_id}/products/${products[0]}`;
  apiCall(`delete`, url)
    .then(resp =>
      dispatch({ type: REMOVE_PRODUCTS_PRODUCT_GROUP, payload: resp })
    )
    .catch(err => dispatch(addError(err.message)));
};
