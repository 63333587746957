import React,{useState} from "react";
import { Header, Input,Form } from "semantic-ui-react";
import ProductFilter from "../Filters/ProductFilter";
import WarehouseFilter from '../Filters/WarehouseFilter'



const Filters = ({
  handleUpdateFilter,
  filters,
  isLoading,
}) => {
  
  const [search,setSearch] = useState()

  // useEffect(()=>{
    
  //   if(!search) {
  //     handleUpdateFilter(null, {value:'clear',
  //     name:'productSKU'});}
  // },[search])

  return (
    // <Segment>
    <div className="filters_">
      <div className="flex_alignLeft">
        <Header as="h3">Filters</Header>
      </div>
      <div className="flex_alignLeft">
        
        <WarehouseFilter
           style={{width:'333px'}}
           handleChange={handleUpdateFilter}      
           warehouse={filters.warehouse}   
           multiple={true}
        />
          <ProductFilter
            value={filters.product}
            multiple={true}
            disabled={!filters.businessclient}
            businessclient={filters.businessclient}
            handleChange={    handleUpdateFilter}
            style={{width:'333px',height:'35px',background:'white'}}

          ></ProductFilter>
        

        <div className="filter_status">
          <div style={{display:'flex',justifyContent:'left'}}>
            <Form onSubmit={()=>handleUpdateFilter(null, {value:search,
                                        name:'productSKU'})}>
              <Input
                clearable
                fluid
                style={{width:'333px'}}
                loading={isLoading && filters.productSKU}
                placeholder="product SKU..."
                name="productSKU"
                value={search}
                onChange={(e, {value}) => {
                    setSearch(value)
                    if(!value)  handleUpdateFilter(e, {value:null,name:'productSKU'})
                      // else  handleUpdateFilter(e, {value,name:'productSKU'})
                }}
              />
              {/* {search&&<button
                onClick={(e)=>{
                  handleUpdateFilter(null, {value:search,
                                        name:'productSKU'});
                  // alert('go')
                }}
              >Go</button>} */}
              
              </Form>
            </div>
        </div>
       
      </div>
    </div>
    // </Segment>
  );
};

export default Filters;
