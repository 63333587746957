import React,{useState} from "react";
import { Table, Label, Header, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

// import ExportButton from "../../ExportButton";
// import { mapData } from "./mapDataForExport";

export const statusColor = {
  New: "red",
  pending: "orange",
  Shipping: "blue",
  Delivered: "green",
};


export const ShipmentTable = ({
  shipments,
  user,
  lang,
  isLoading,
}) => {
  // if (shipments.length===0){return (<div>No shipments.</div>)}
  const ships_headers = (
    <Table.Header>
      <Table.Row>
        {/* < Checkbox></Checkbox> */}
        <Table.HeaderCell>ID</Table.HeaderCell>
        {/* <Table.HeaderCell>Created</Table.HeaderCell> */}
        <Table.HeaderCell>               {lang.table.warehouse[user.currentUser.sys_lang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.ordernr[user.currentUser.sys_lang]}</Table.HeaderCell>
        <Table.HeaderCell> {lang.table.details[user.currentUser.sys_lang]}</Table.HeaderCell>
        <Table.HeaderCell> {lang.table.transport[user.currentUser.sys_lang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.dates[user.currentUser.sys_lang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.status[user.currentUser.sys_lang]}</Table.HeaderCell>
        {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
      </Table.Row>
    </Table.Header>
  );
  
  const shipments_list = shipments.map((shipment, index) => {
    return (
      <tr key={index}>
        <Table.Cell>
          <Link to={`/parcels/inbound/${shipment._id}`} target='_blank'>
            {shipment._id.substr(-9)}
          </Link>
        </Table.Cell>
        {/* <Table.Cell > <Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment></Table.Cell> */}
        <Table.Cell>
          {shipment.warehouse ? shipment.warehouse.name : "NA"}
        </Table.Cell>
          
      <Table.Cell>
       
                {shipment.order ? <Link to={`/orders/${shipment.order._id}`}>{shipment.order.clientOrderNr}</Link> : "NA"}
                <br></br>
                {shipment.order&&shipment.order.shipping.shipTo.client.firstName}
              </Table.Cell>

        <Table.Cell>
        {lang.table.nrofpacks[user.currentUser.sys_lang]}: {shipment.nrOfPacks}
          <br></br>
          {lang.table.weight[user.currentUser.sys_lang]}:{shipment.weightKg}
        </Table.Cell>

        <Table.Cell>{shipment.transporter}</Table.Cell>
        <Table.Cell>
        {lang.table.createdAt[user.currentUser.sys_lang]}:<Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment>
          <br></br>
          {lang.table.updatedAt[user.currentUser.sys_lang]}:<Moment format="DD-MM-YY HH:mm">{shipment.updatedAt}</Moment>
        </Table.Cell>
        <Table.Cell>
          <Label color={statusColor[shipment.status]}>{shipment.status}</Label>
        </Table.Cell>
        {/* <Table.Cell>
          {shipment.status !== "Delivered" && (
            <Button
              // icon="trash alternate outline"
              // color = 'grey'
              // id = 'itemlistrow_action'
              basic
              onClick={() => setAsProcessed(shipment._id)}
            >Set As processed</Button>
          )}
        </Table.Cell> */}
      </tr>
    );
  });

  return (
    <section>
      
      {isLoading && (
        <div style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Loader active inline="centered" />
        </div>
      )}

      {!shipments.length && !isLoading && (
        <Header
          style={{ paddingTop: "2em", paddingBottom: "1em" }}
          as="h4"
          textAlign="center"
          color="grey"
        >
         ....///.....
        </Header>
      )}

      {shipments.length > 0 && !isLoading && (
        <div className="table-wrapper--horizontal-scroll">
          <Table basic="very" unstackable selectable>
            {ships_headers}
            <tbody>{shipments_list}</tbody>
          </Table>
        </div>
      )}
    </section>
  );
};
