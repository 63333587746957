import React from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";

import { getBill } from "../../../../actions/services";
import Invoice from "./BillPdf";

function GenerateInvoice({ bill, getBill, match }) {
  React.useEffect(() => {
    getBill(match.params.id);
  }, []);

  function createPdfInvoice() {
    const opt = {
      filename: `bill -${bill.sequenceNumber}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
      enableLinks: true,
    };

    const invoice = document.getElementById("invoice");

    html2pdf()
      .set(opt)
      .from(invoice)
      .outputPdf("dataurlstring")
      .then((data) => console.log(data))
      .save();
  }
  return (
    <>
      <div style={{ textAlign: "center", margin: "0.5em" }}>
        <Button onClick={createPdfInvoice}>
          <Icon name="download" />
          Download
        </Button>
      </div>
      <div style={{ display: "block" }}>
        <Invoice bill={bill} />
      </div>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  bill: reduxState.services.openBill,
});

export default connect(mapStateToProps, {
  getBill,
})(GenerateInvoice);
