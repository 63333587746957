import {
  GET_WAREHOUSES,
  GET_STOCKMOVEMENT,
  GET_INVENTORY,
  WAREHOUSE_FILTER
} from "./actionTypes";

import { apiCall } from "../services/api";
import {
  Alert
} from 'rsuite'

import { addError, removeError } from "./error";

export const searchSku = query => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/warehouse/${query.warehouse_id}/inventory/${query.sku}`;

  apiCall("post", url)
    .then(resp => dispatch(getAllInventoryAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

export const getWarehouses = () => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/warehouse`;

  apiCall("get", url)
    .then(resp => dispatch(getWarehousesAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

const getWarehousesAction = data => ({
  type: GET_WAREHOUSES,
  data
});

export const getStockMovements = ({
  limit,
  skip,
  warehouse_id,
  sku
}) => dispatch => {
  const url = `${process.env.REACT_APP_API}/warehouse/${warehouse_id}/inventory/${sku}/movement/${skip}/${limit}`;
  apiCall("get", url)
    .then(resp => dispatch(getStockMovementAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};
const getStockMovementAction = data => ({
  type: GET_STOCKMOVEMENT,
  data
});

export const getMoveTypes = () => dispatch => {
  dispatch(removeError());
  apiCall("get", `${process.env.REACT_APP_API}/warehouse/movetypes`)
    .then(resp => dispatch(getMoveTypesAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};


const getMoveTypesAction = movetypes => ({
  type: "ADD_MOVETYPES",
  movetypes
});

export const getAllInventory = query => {
  const url = `${process.env.REACT_APP_API}/inventorycards/stocks`;

  return apiCall("post", url, query)
    .then(resp => (resp))
    .catch(err => Alert.error(err.message));
};

const getAllInventoryAction = data => ({
  type: GET_INVENTORY,
  data
});

export const uploadPhotos = ({ id, files, removedFiles }) => dispatch => {
  const url = `${process.env.REACT_APP_API}/inventorycards/${id}/photos`;

  apiCall("post", url, { files, removedFiles })
    .then(resp => dispatch(getAllInventoryAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

export const updateFilterAction = filter => ({
  type: WAREHOUSE_FILTER,
  filter
});

export const updateFilter = filter => (dispatch, getState) => {
  const updateFilterPromise = new Promise((resolve, reject) => {
    resolve(dispatch(updateFilterAction(filter)));
  });
  updateFilterPromise.then(() => {
    const filters = getState().inventory.filters;
    dispatch(getAllInventory(filters));
  });
};

export const inventoryCount = query => dispatch => {
  //query : {product,warehouse,quantity_available,quantity_blocked,quantity_damaged}

  const url = `${process.env.REACT_APP_API}/warehouse/inventorycount`;

  dispatch({ type: "SUCC_MSG", data: false });
  apiCall("post", url, query)
    .then(resp => {
      if (resp.status === "success") {
        dispatch({ type: "SUCC_MSG", data: true });
      }
    })
    .catch(err => dispatch(addError(err.message)));
};


  export const getBalanceAtDate = (body) => {
    const url = `${process.env.REACT_APP_API}/inventorycards/stocks_atdate`;
    return apiCall("post", url, body)
      .then((resp) => resp)
      .catch((err) => Alert.error(err.message))
  };