import React from "react";
import { Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function Head({ linkBack, header, children }) {
  return (
    <header className="flex--between vertical--center">
      <div className="flex--between vertical--center">
        {linkBack && (
          <Link to={linkBack} style={{ marginRight: "1em" }}>
            <Icon name="arrow left" />
          </Link>
        )}

        <Header className="head--header" as="h1">
          {header}
        </Header>
      </div>
      {children}
    </header>
  );
}

export default Head;
