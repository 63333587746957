import React from "react";
import Modal from "react-responsive-modal";
import {
  Divider,
  Form,
  Button,
  
  Header,
  
} from "semantic-ui-react";
// import Droparea from '../Dropzone/droparea'
import Preview from "../Uploader/photos";
import { countries } from "../../countries";
import lang from '../../lang/config.json'

export class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      executeChildMethod: false,
      files: this.props.files || [],
      updated: {
        addedFiles: [],
        removedFiles: []
      }
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSaveForm = this.handleSaveForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddView = this.handleAddView.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.addFilesToState = this.addFilesToState.bind(this);
  }
  addFilesToState(files) {
    this.state.updated.addedFiles.push(files);
    // this.setState({...this.state,
    //         updated:{...this.state.updated,addedFiles:files}})
  }

  uploadFiles(files) {
    this.props.uploadFiles({
      id: this.props.openShipment._id,
      files
    });
  }

  handleAddView() {
    this.setState({ showModal: true });
  }

  uploadExecuted() {
    this.setState(prevstate => ({
      executeChildMethod: false,
      showModal: false
    }));
  }

  handleSaveForm() {
    this.setState({
      ...this.state,
      executeChildMethod: true,
      updated: {
        client: null,
        seller: null,
        warehouse: null,
        removedFiles: [],
        addedFiles: []
      }
    });
  }
  handleChange(e, props) {
    this.setState({
      [props.name]: props.value
    });
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  render() {
    const { files, handleAddFiles, handleRemoveFile,user } = this.props;

    const items = files.map((item, index) => (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.country}</td>
        <td>{item.desctiption}</td>
        <td>{item.file}</td>
        <td>
          <Button compact icon="times" size="tiny" basic />
        </td>
      </tr>
    ));

    const documents = (
      <table style={{ width: "100%" }} className="stock-available-table">
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Country</th>
          <th>Description</th>
          <th>File</th>
          <th>Action</th>
        </tr>
        {items}
      </table>
    );

    const addModal = (
      <Modal
        open={this.state.showModal}
        showCloseIcon={true}
        closeOnEsc={true}
        onClose={() => {
          this.handleClose();
        }}
        center
        classNames={{
          modal: ""
        }}
      >
        <div id="product_label">
          <div className="flex_spaceBetween">
            <h3>Add New Document</h3>
          </div>
          <Divider></Divider>
          <Form>
            <Form.Field>
              <label>Name</label>
              <input placeholder="type of label" />
            </Form.Field>
            <Form.Select
              required
              name="country"
              label="Country"
              placeholder="Country"
              value={this.state.country}
              fluid
              search
              options={countries.map(country => ({
                text: country,
                value: country
              }))}
              onChange={this.handleChange}
              error={this.state.country === null}
            />
            <Form.Field>
              <label>Description</label>
              <textarea rows="4" cols="50"></textarea>
            </Form.Field>

            <Preview
              uploadExecuted={this.uploadExecuted}
              executeChildMethod={this.state.executeChildMethod}
              uploadFiles={this.uploadFiles}
              addFiles={this.addFilesToState}
              businessclient={"asdf09a8sdfas0df7a"}
              sourceId={"9879087223ewv"}
              sourceName={"product"}
              name={"files"}
            />

            <Button type="submit" onClick={this.handleSaveForm}>
              Save
            </Button>
          </Form>
        </div>
      </Modal>
    );

    const { showModal } = this.state;

    return (
      <div className="">
        {showModal && addModal}
        {/* <Segment> */}
        <div className="flex_spaceBetween">
          <Header as="h4">Documents (draft)</Header>
          <Button type="submit" onClick={this.handleAddView}>
            Add
          </Button>
        </div>
        {/* <Divider/> */}
        {documents}
        {/* </Segment> */}
      </div>
    );
  }
}
