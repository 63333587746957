/* eslint-disable jsx-a11y/anchor-is-valid */

import React,{useState,useEffect} from 'react'
import {Table,Checkbox,Alert,Icon,DatePicker,Input,InputGroup,InputPicker,Dropdown,Button,Notification} from 'rsuite'
import {getRefunds,updateRefundStatusSelected,getRefund,deleteRefund} from '../../actions/refunds'
import Viewrefund from './editRefund'
import Moment from 'react-moment'
import ExportButton from "../ExportButton";
import lang from '../../lang/config.json'
import { connect } from "react-redux";
import {Label,Segment} from 'semantic-ui-react'


const { Column, HeaderCell, Cell,Pagination:TablePagination } = Table;

const itemStyle = {
  margin:"5px",
  width:"275px",
  border: "none !important",
  color: "#3F72AF !important"
}

const statuses=[
  {
  value:"New",
  label:"New"
},
 {
  value:"Approved",
  label:"Approved"
},
{value:'not sent',
  label:'not sent'},
 {
  value:"Rejected",
  label:"Rejected"
},
 {
  value:"Pending Bank Confirmation",
  label:"Pending Bank Confirmation"
},
{
  value:"duplicate",
  label:"Duplicate"
},
 {
  value:"Refunded",
  label:"Refunded"
},
 {
  value:"error",
  label:"error"
},


]

const badgeColor={
  "New":'blue',
  "error":'red',
  "Approved":"cyan",
  "Refunded":"green",
  "duplicate":"orange",
  "Rejected":"orange",
  "Pending Bank Confirmation":'violet',
  "Pending Confirmation":'violet',
  
}
const Refundstable =function({updateListParent,syslang,history}){

    const [searchTerm,setSearchterm]=useState()
    const [sortSetting,setsortSetting] = useState({
                                                    sortType:'desc',
                                                    sortColumn:'createdAt',
                                                    dateFrom:'',
                                                    dateTo:'',
                                                    status:'',
                                                    businessclient:'',
                                                  })
    const [viewRefund,setViewrefund]    = useState(false)
    const [updateList,setupdateList]    = useState(true)
    const [refundId,setRefundId]        = useState(null)
    const [checkedKeys,setChecked]      = useState([])
    const [allChecked,setAllchecked]    = useState(false)
    const [page,setPage]                = useState(1)
    const [resultsPage,setResultsPage]  = useState(10)
    const [data,setData]                = useState([])
    const [total,setTotal]              = useState([])
    const [loading,setLoading]          = useState(false)
    

    useEffect(()=>{
      // if(!updateList) return 
      setLoading(true)

        getRefunds({
          currentPage:page,
          limit:      resultsPage,
          sortColumn: sortSetting.sortColumn,
          sortType:   sortSetting.sortType,
          dateFrom:   sortSetting.dateFrom,
          dateTo:     sortSetting.dateTo,
          searchTerm: searchTerm,
          status:     sortSetting.status,
          businessclient:     sortSetting.businessclient,
        })
        .then(resp=>{
                    setData(resp.list)
                    setTotal(resp.total)
                    setLoading(false)
            })
        .catch(error=>{
            setLoading(false)
            Alert.error(error.message)})
    
    },[updateList, page, resultsPage, sortSetting, updateListParent, searchTerm])

  const updateStatusSelected =(status)=>{
                  setLoading(true)

                  updateRefundStatusSelected({refunds:checkedKeys,
                                              status})
                  .then(resp=>{
                    setLoading(false)
                    setupdateList(!updateList)
                  
                    Alert.success("Refund was updated",5000)})
                  .catch(error=>{  
                    setLoading(false)
                    Alert.error(error.message,5000)})
                }

  const handleSortColumn =(sortColumn,sortType)=>{
    
    setsortSetting({sortColumn,sortType})
    
  }
  const handleDeleteRefund=(id)=>{
      deleteRefund(id)
      .then(resp=>{
        setupdateList(!updateList)
        Alert.success('Refund deleted.')
      })
      .catch(error=>Alert.error(error.message))
    }

   const handleCheckAll=(value,checked) =>{
     if(checked) {
          const allChecked =data.map(item => item._id)
          console.log(checked,allChecked)
      setAllchecked(true)
      setChecked(allChecked)
                }
            else {
              setAllchecked(false)
              setChecked([])
            }
  }

  const handleCheck=(value, checked) =>{
      console.log(value,checked)
           
                if (checked) setChecked([...checkedKeys,value])
                else {
                  const i = checkedKeys.indexOf(value)
                  if(i>=0) {
                    setChecked(checkedKeys.splice(i+1,1))
                  }
                }
  }

  const getParcel = (id)=>{
   
   Notification.info({
     placement:'bottomEnd',
     title:'Getting return parcel'
   })
       getRefund(id)
          .then(resp=>{
              
              Notification.closeAll()
              if(resp.inboundparcel) {
                history.push(`/parcels/inbound/${resp.inboundparcel}`)
                    } else Alert.info('No received parcel related to this order.')
            })
            .catch(error=>{
              
              Alert.error(error.message)})
          }
  
return (
      <div style={{margin:'10px'}} className='flex--column'>
      
      <h4>{lang.refunds.title[syslang]}</h4>
      {/* <Divider></Divider> */}
  
        <Segment id='filters' className='flex--between'>
          <div className='flex--left'>
            <div id='selectedrefunds' style={{marginRight:'10px'}}>

              <Dropdown   appearance="ghost" 
                          disabled={checkedKeys.length===0}
                          title={`Selected ${checkedKeys.length>0?checkedKeys.length:''}`}
                      >
                <Dropdown.Item onClick={()=>updateStatusSelected("Approved")}>{lang.status.approved[syslang]}</Dropdown.Item>
                <Dropdown.Item onClick={()=>updateStatusSelected("Rejected")}>{lang.status.rejected[syslang]}</Dropdown.Item>
              </Dropdown>
              
            </div>

            <div id='refundserach' className='flex--left'>

                      <InputGroup style={{
                                        width: 300,
                                        marginBottom: 10
                                      }}>
                              
                              <Input value={searchTerm} 
                                      onChange={(value)=>{  
                                                      setSearchterm(value)
                                                      if(!value) setupdateList(!updateList)
                                                      }}
                                      placeholder={lang.filters.searchrefunds[syslang]} />

                          <InputGroup.Button>
                          {/* <Button size='xs'>click</Button> */}
                            <Icon onClick ={()=>setupdateList(!updateList)} icon="search" />
                          </InputGroup.Button>
                        </InputGroup>
                        <Button
                              disabled={!searchTerm}
                              onClick ={()=>setupdateList(!updateList)}
                              appearance="ghost"
                              style={{height:'35px',marginLeft:"5px"}}
                              // icon={<Icon className="fill-color" icon={SvgIcons.Search} size="lg" />}
                              
                            >
                              {lang.buttons.search[syslang]}
                            </Button>
                        </div>
          </div>

            
            <div className='flex--between'>
           
            <div id='status'> 
              
                  <InputPicker
                      placeholder={lang.filters.status[syslang]}
                      creatable
                      data={statuses}
                      onChange={(value)=>setsortSetting({...sortSetting,status:value})}
                      style={{ width: 224, marginRight:'15px'}}
                    />
              
            </div>                                                  
            
            {total>0&&<div id='refundexport'>
              <ExportButton
                  disabled={loading}
                  total={total}
                  filters={ {
                              total:      total,
                              sortColumn: sortSetting.sortColumn,
                              sortType:   sortSetting.sortType,
                              dateFrom:   sortSetting.dateFrom,
                              dateTo:     sortSetting.dateTo,
                              searchTerm,
                              status:     sortSetting.status,
                            
                            }
                          }
                  fileName={`refunds_${sortSetting.sortColumn}`}
                  url="/refunds/export"
                  text='export'

                />
            </div>}
          </div>
        </Segment>
        
          <div id='refundcalendar'> 
              
                    <DatePicker
                      disabled={loading}
                      oneTap
                      value={sortSetting.dateFrom}
                      isClearable={true}
                      // placement="leftStart"
                      format="DD-MM-YYYY"
                      onChange={(date) => setsortSetting({...sortSetting, dateFrom: date })}
                      placeholder={lang.filters.datepicker.from[syslang]}
                      style={itemStyle}
                    ></DatePicker>
                
                    <DatePicker
                      disabled={loading}
                      oneTap
                      value={sortSetting.dateTo}
                      isClearable={true}
                      // placement="leftStart"
                      format="DD-MM-YYYY"
                      onChange={(date) => setsortSetting({...sortSetting, dateTo: date })}
                      placeholder={lang.filters.datepicker.to[syslang]}
                      style={itemStyle}
                    ></DatePicker>
        </div>
        <Segment id='refundtable'>
          <Table
              sortType ={sortSetting.sortType}
              sortColumn={sortSetting.sortColumn}
              wordWrap
              loading={loading}
              autoHeight
                // height={420}
                // width={800}
                data={data}
                id="table"
                onSortColumn={handleSortColumn}
          >
            <Column width={50} align="center" >
              <HeaderCell style={{ padding: 0 }}>
                <div style={{ lineHeight: '40px' }}>
                  <Checkbox
                    inline
                    checked={allChecked}
                    onChange={(value,checked)=>handleCheckAll(value,checked)}
                  />
                </div>
              </HeaderCell>
              <CheckCell
                dataKey="_id"
                checkedKeys={checkedKeys}
                onChange={(value,checked)=>handleCheck(value,checked)}
              />
            </Column>
            <Column width={105} resizable>
              <HeaderCell>ID</HeaderCell>
              <Cell dataKey='refundid'>
              
              </Cell>
            </Column>
            
            <Column width={105} resizable>
              <HeaderCell>{lang.inboundparcel.title[syslang]}</HeaderCell>
              <Cell dataKey='_id'
                
                >
                  {rowData => (<div>
                        <button onClick={()=>getParcel(rowData._id)}>view</button>                          
                          </div>)}
              
              </Cell>
            </Column>
            
            <Column width={150} align="center" resizable>
              <HeaderCell>{lang.table.order[syslang]}</HeaderCell>
              <Cell >
                      {rowData => (<div>
                      
                          <a href={`/orders/${rowData.order._id}`}>{rowData.order.clientOrderNr}</a>
                          
                          
                          </div>)}
              </Cell>
            </Column>
            <Column width={145} resizable sortable> 
              <HeaderCell>{lang.table.dates[syslang]}</HeaderCell>
              <Cell dataKey='createdAt'>{rowData =>(
                <div style={{fontSize:'12px'}}className='flex--column'>
                  
                  <div>{lang.table.createdAt[syslang]}:<Moment utc format="DD-MM-YYYY">{rowData.createdAt}</Moment></div>
                  <div>{lang.table.updatedAt[syslang]}:<Moment utc format="DD-MM-YYYY">{rowData.updatedAt}</Moment></div>

                </div>
              )}</Cell>
            </Column>
            <Column width={170} resizable sortable>
              <HeaderCell>{lang.table.status[syslang]}</HeaderCell>
              <Cell dataKey='status' >
                {rowData=>(<div>
                  <Label  color={badgeColor[rowData.status]}>
                  {rowData.status}
                  </Label>
                    {/* <p>{rowData.status}</p> */}
                  <p style={{fontSize:'10px'}}>{rowData.error_msgs}</p>
                </div>)}
              </Cell>
            </Column>
            <Column width={160} resizable>
              <HeaderCell>{lang.table.name[syslang]}</HeaderCell>
              <Cell dataKey='name'>
              
              </Cell>
            </Column>
            
            <Column width={120} resizable>
              <HeaderCell>{lang.table.country[syslang]}</HeaderCell>
              <Cell >
                {rowData => (`${rowData.country}`)}
              </Cell>
            </Column>

            <Column width={85} resizable sortable>
                <HeaderCell>{lang.table.amount[syslang]}</HeaderCell>
              <Cell dataKey='amount'>
                {rowData => (`${rowData.amount} ${rowData.currency}`)}
              </Cell>
            </Column>

            <Column width={300} resizable>
<HeaderCell>{lang.table.bankaccount[syslang]}</HeaderCell>  
            <Cell>{rowData => rowData.IBAN?rowData.IBAN:`${rowData.account_nr}/${rowData.account_code}`}</Cell>
              
            </Column>
            
            <Column width={80} fixed="right">
              <HeaderCell>....</HeaderCell>

              <Cell>
                {rowData => {
                  if(!['Refunded','Pending Bank Confirmation'].includes(rowData.status))
                  return (
                    <span>
                      <Icon   
                            
                            style={{cursor:'pointer'}}
                            icon='edit2'
                            onClick={()=>{
                              setViewrefund(true)
                              setRefundId(rowData._id)
                              }}/> 

                              |{' '}
                      <Icon 
                        style={{cursor:'pointer'}}
                        icon="trash-o"
                        onClick={()=>handleDeleteRefund(rowData._id)}/>  
                    </span>
                  );
                }}
              </Cell>
            </Column>
          

          </Table>
          <TablePagination
            lengthMenu={[
              {
                value: 10,
                label: 10
              },
              {
                value: 20,
                label: 20
              },
              {
                value: 1000,
                label: 'All'
              },
            ]}
            activePage={page}
            displayLength={resultsPage}
            total={total}
            onChangePage={(value)=>setPage(value)}
            onChangeLength={(value)=>setResultsPage(value)}
          />
        </Segment>
        <div ></div>
          <Viewrefund
            show={viewRefund}
            closeModal={()=>setViewrefund(false)}
            refundId={refundId}
            updateList={()=>setupdateList(!updateList)}

        ></Viewrefund>
      </div>
    );
}

const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: '46px' }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={checkedKeys.indexOf(rowData[dataKey])>=0}
      />
    </div>
  </Cell>
);

function mapStateToProps(reduxState) {

  return {
    syslang:reduxState.user.currentUser.sys_lang
  }
}

export default connect(mapStateToProps,{})(Refundstable)