import React, { useEffect } from "react";
import { Header, Button, Segment ,Label} from "semantic-ui-react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import { getCourier, setDefaultCourier } from "../../../actions/couriers";
import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import CourierQuotationsTable from "./CourierQuotationsTable";

function CourierPage(props) {
  const { courier, getCourier, setDefaultCourier,match } = props;
  const { name, country, isDefault, _id } = courier;

  useEffect(() => getCourier(match.params.id), []);

  return (
    <>
      <Responsive
        mobileComponent={<InfoBar header="Courier Page" linkBack="/couriers" />}
      />
      <main className="page-wrapper a-wrapper">
        <Responsive
          desktopComponent={
            <div><Head linkBack="/couriers" header="Courier Page"></Head>
            </div>
          }
        />

        <Segment>
          <div className="mt--2 flex_spaceBetween">
           <div>
            <div className='flex_alignLeft'>
                <Header as="h1" content={name} /> 
               {isDefault && <Label style={{marginLeft:'15px'}}>Default</Label>}
             </div> 
             <span className="mr--1">{country}</span>{" "} </div> 
           {!isDefault && <Button basic color='blue' onClick={() => setDefaultCourier(_id)}>Set as default</Button>}

          </div>
        </Segment>

        <Segment>
          <Header as="h4">Courier quotations</Header>
          <CourierQuotationsTable courier={match.params.id} />
        </Segment>
      </main>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  courier: reduxState.couriers.openCourier,
});

export default connect(mapStateToProps, { getCourier, setDefaultCourier })(CourierPage);
