
export function Operator(user) {
        this.value = user.username
        this.text = user.username
        this.country = user.country
        this.roles = user.roles
        this.status = user.status
        this._id = user._id
        this.user_id = user.user_id

}


export const statusOptions = [
        {
                key: "New",
                value: 'New',
                text: 'New'
        },
        {
                key: "Priority",
                value: 'Priority',
                text: 'Priority'
        },
        {
                key: "Approved",
                value: 'Approved',
                text: 'Approved'
        },
        {
                key: "In progress",
                value: 'In progress',
                text: 'In progress'
        },
        {
                key: "Rejected",
                value: 'Rejected',
                text: 'Rejected'
        },
        {
                key: "Spam",
                value: 'Spam',
                text: 'Spam'
        },
        {
                key: "Fishy",
                value: 'Fishy',
                text: 'Fishy'
        },
        {
                key: "Shipped",
                value: 'Shipped',
                text: 'Shipped'
        },
        {
                key: "Not Shipped",
                value: 'Not Shipped',
                text: 'Not Shipped'
        },
        {
                key: "Shipping",
                value: 'Shipping',
                text: 'Shipping'
        },
        {
                key: "Refunded",
                value: 'Refunded',
                text: 'Refunded'
        },
        {
                key: "at_currier",
                value: 'at_currier',
                text: 'at_currier'
        },
]

export const shipmentStatusOptions=[
         {
                 key: "New",
                 value: 'New',
                 text: 'New'
         },
         {
                 key: "pending",
                 value: 'pending',
                 text: 'pending'
         },
         
         {
                 key: "Shipping",
                 value: 'Shipping',
                 text: 'Shipping'
         },
         
         {
                 key: "Delivered",
                 value: 'Delivered',
                 text: 'Delivered'
         },
         {
                 key: "Partially Delivered",
                 value: 'Partially Delivered',
                 text: 'Partially Delivered'
         },
         
]

export const currencyOptions = [
        {
         key:"CZK",
         value:"CZK",
         text:"CZK"       },
                        {
         key:"EUR",
         value:"EUR",
         text:"EUR"       },
        
]
