
import React ,{useState} from 'react'
import {apiCall} from '../../../services/api'
import {
  Container,Message,Content,FlexboxGrid,
  ButtonToolbar,Form,FormGroup,
  Panel,Button,Alert,
InputGroup,Input} from 'rsuite'
import woo from './info.png'
import Modal from "react-responsive-modal";


const Eshoprychleapi =(props) =>{
  
  
  const [shopUrl,setUrl] = useState('')
  const [apiUrl,setApiUrl] = useState('')
  const [isModalOpen,setModalOpen]  = useState(false)

  const createEshop =()=>{
  const url = `${process.env.REACT_APP_API}/eshops/eshoprychle`;

     apiCall('post',url,{shopUrl,apiUrl})
      .then(eshop=>{
        
        Alert.success('added new Eshop')
        props.history.push(`/eshops/eshop-rychle/${eshop._id}`)
      })
      .catch(error=>Alert.error(error.message,5000))
  }


  const viewImg =  <Modal
                        open={isModalOpen}
                        showCloseIcon={true}
                        closeOnEsc={true}
                        onClose={() => {
                          setModalOpen(false)
                        }}
                        center
                        classNames={{
                          modal: ""
                        }}
                      >
                          <img style={{cursor:'zoom-in'}}src={woo} alt="help"></img>
                      </Modal>
  
  return (
  <div >
{viewImg}
    <Container>

        <Message
                                showIcon
                                type="warning"
                                title="Nastavení"
                                description={
                                  <div className='flex--left'>
                                    <div> 
                                    <p>Postup:</p>
                                    <ul style={{ listStyleType: "none"}}>
                                      <li>1. Ve vaší Eshop-rychle administrativě, v menu Eshop/Export, v záložce Export objednávek - zkopírujte API adresu k objednávkám (viz obrazek)</li>
                                      <li>2. Vložte API URL adresu do políčka "API URL
                                          </li>
                                      {/* <li>{`3. Copiati utilizatorul din tabelul cu IP pentru a fi inclus in campul de mai jos "consumer_key:"`}
                                          </li> */}
                                      
                                    </ul>
                                  </div>
                                  
                                       <a onClick={()=>setModalOpen(true)}><img style={{maxWidth:'250px',cursor:'zoom-in',marginLeft:'34px'}}src={woo} alt="help"></img></a> 
                                  </div>
                                  }
                              />

      <Content>
                  
        <FlexboxGrid justify="center">
          <FlexboxGrid.Item colspan={12}>
            <Panel bordered>
              <Form fluid >
                   <Message
                                // showIcon
                                type="info"
                                title="Eshop URL"
                                description={
                                  <div>
                                   <InputGroup >
                                      <InputGroup.Addon>https://www.</InputGroup.Addon>
                                      <Input value={shopUrl} onChange={(value)=>{setUrl(value)}}/>
                                    </InputGroup>
                                  </div>
                                  }
                              />
                
                <Message
                                // showIcon
                                type="info"
                                title="API URL"
                                description={
                                  <div>
                                   <InputGroup >
                                      {/* <InputGroup.Addon>https://www.</InputGroup.Addon> */}
                                      <Input value={apiUrl} onChange={(value)=>{setApiUrl(value)}}/>
                                    </InputGroup>
                                  </div>
                                  }
                              />
                
                
                  
                
                <FormGroup>
                  <ButtonToolbar>
                    <Button style={{marginTop:'10px'}} appearance="primary" onClick={()=>createEshop()}>Ok</Button>
                    
                  </ButtonToolbar>
                </FormGroup>
              </Form>
            </Panel>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </Content>
    </Container>
  </div>)
}

export default Eshoprychleapi