import React, { useState, useEffect } from "react";
import Moment from "react-moment";

function BillPdf({ bill }) {
  // console.log(bill);
  const {
    businessclientId,
    businessclient: client = {
      address: {},
      contacts: [],
      bankaccounts: [],
      companyids: [],
    },
    sequenceNumber,
    totals = {},

    currency,
    dates = {},
    issuer = {
      address:{

      }
    },
    issuerId,
    items = [],
    period,
  } = bill;

  const [clientBankAccount, setClientBankAccount] = useState({});
  // const [companyids, setCompanyids] = useState([]);
  
  useEffect(() => {
    if (
      issuer &&
      issuer.bankaccounts &&
      issuer.bankaccounts.length &&
      currency
    ) {
      let bankAccount = issuer.bankaccounts.find(
        (item) => item.currency === currency
      );
     if (bankAccount) setClientBankAccount(bankAccount);
    }
  }, [issuer, currency]);

  return (
    <>
      <div class="invoice-box" id="invoice">
        <div class="invoice-wrapper">
          <header>
            <h1>Bill</h1>
            <span>{sequenceNumber}</span>
          </header>
          {/* <!-- Main Table --> */}
          <section class="main-table">
            {/* <!-- Col 1 --> */}
            <div
              class="main-table__col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* Client Address */}
              <div class="main-table__cell">
                <div className="col2__table">
                  <div className="col2__label flex_">Issuer:</div>
                  {client && (
                    <div>
                      <span className="text_bold text_medium customer-address">
                        {issuer.name}
                        <br />
                        {issuer.address.street
                          ? issuer.address.street
                          : ""}{" "}
                        {issuer.address.streetNr
                          ? issuer.address.streetNr
                          : " "}
                        <br />
                        {issuer.address.postcode} {issuer.address.city}
                        <br />
                        {issuer.address.country}
                        <br />
                      </span>
                      ID: {issuerId && issuerId.coid}
                      <br />
                      TAXID: {issuerId && issuerId.taxid}
                    </div>
                  )}
                </div>
              </div>

              {/* Payment */}
              <div class="main-table__cell">
                <table className="payment-table">
                  <tbody>
                    <tr>
                      <td>Form of payment:</td>
                      <td>bank wire</td>
                    </tr>
                    <tr>
                      <table className="bank-table">
                        <tbody>
                          <tr>
                            <td>Bank:</td>
                            <td>
                              {clientBankAccount && clientBankAccount.bankname}
                            </td>
                          </tr>
                          {clientBankAccount.accountNr && (
                            <tr>
                              <td>Bank account:</td>
                              <td className="black-outline">
                                {clientBankAccount &&
                                  clientBankAccount.accountNr}{" "}
                                / {clientBankAccount.bankCode}
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>IBAN:</td>
                            <td>
                              {clientBankAccount.IBAN
                                ? clientBankAccount.IBAN
                                : ""}
                            </td>
                          </tr>
                          {clientBankAccount.SWIFT && (
                            <tr>
                              <td>BIC:</td>
                              <td>
                                {clientBankAccount && clientBankAccount.SWIFT}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </tr>
                    <tr>
                      <td>Var.sym</td>
                      <td>{sequenceNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* <!-- Col 2 --> */}
            <div
              class="main-table__col"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {/* Client Address */}
              <div class="main-table__cell">
                <div className="col2__table">
                  <div className="col2__label flex_">Customer:</div>
                  {client && (
                    <div>
                      <span className="text_bold text_medium customer-address">
                        {client.name}
                        <br />
                        {client.address.street} {client.address.streetNr}
                        <br />
                        {client.address.postcode} {client.address.city}
                        <br />
                        {client.address.country}
                        <br />
                      </span>
                      ID: {businessclientId && businessclientId.coid}
                      <br />
                      TAXID: {businessclientId && businessclientId.taxid}
                    </div>
                  )}
                </div>
              </div>

              {/* Payment */}
              {/* <div class="main-table__cell">
                <table className="payment-table">
                  <tbody>
                    <tr>
                      <td>Form of payment:</td>
                      <td>{paymentType}</td>
                    </tr>
                    <tr>
                      <td>Order ID:</td>
                      <td class="text_bold">{actionSourceId.clientOrderNr}</td>
                    </tr>
                    <tr>
                      <td>Var.sym</td>
                      <td>{variableSymbol || sequenceNumber}</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              {/* Payment Dates */}
              <div class="main-table__cell main-table__cell_last main-table__cell_payment-dates ">
                <div className="payment-dates__grid">
                  <span className="payment-dates__label">Issue Date:</span>
                  <span className="payment-dates__date">
                    {dates && dates.issuedDate && (
                      <Moment utc format="DD.MM.YYYY">
                        {dates && dates.issuedDate}
                      </Moment>
                    )}
                  </span>

                  <span className="payment-dates__label">Due Date:</span>
                  <span className="black-outline payment-dates__date">
                    {dates && dates.issuedDate && (
                      <Moment utc format="DD.MM.YYYY">
                        {dates && dates.dueDate}
                      </Moment>
                    )}
                  </span>

                  <span className="payment-dates__label">
                    Date of taxable supply:
                  </span>
                  <span className="payment-dates__date">
                    {dates && dates.issuedDate && (
                      <Moment utc format="DD.MM.YYYY">
                        {dates && dates.taxDate}
                      </Moment>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </section>

          {/* Items, Prices */}
          <section className="items">
            <table className="products-table">
              <thead>
                <tr className="text_bold">
                  <th className="item-name">Deliveries designation:</th>
                  <th>Quantity</th>
                  <th>NETTO Price</th>
                  <th>VAT Rate</th>
                  <th>NETTO Total</th>
                  <th>BRUTTO Total</th>
                </tr>
              </thead>
              <tbody>
                {items.map(
                  (
                    {
                      product,
                      description,
                      quantity,
                      netto_price,
                      netto_total,
                      brutto_total,
                      VAT_procent,
                    },
                    i
                  ) => (
                    <tr key={i}>
                      <td className="item-name">
                        {product ? product.name : description}
                      </td>
                      <td>{quantity}</td>
                      <td>{netto_price.toFixed(2)}</td>
                      <td>{VAT_procent}</td>
                      <td>{netto_total.toFixed(2)}</td>
                      <td>{brutto_total.toFixed(2)}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            {period && (
              <div>
                period:
                <Moment utc format="DD/MM/YYYY">
                  {period.start}
                </Moment>{" "}
                - {/* <div>{period.end}</div> */}
                <Moment utc format="DD/MM/YYYY">
                  {period.end}
                </Moment>
              </div>
            )}
          </section>

          {/* Recapitulation */}
          <section className="recapitulation">
            <div className="recap-tables">
              <table className="recap-tables__second">
                <tbody>
                  <tr>
                    <td>NETTO Total</td>
                    <td>
                      {totals.NETTO_total && totals.NETTO_total.toFixed(2)}
                    </td>
                  </tr>
                  <tr>
                    <td>VAT Total</td>
                    <td>{totals.VAT_total && totals.VAT_total.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>BRUTTO Total</td>
                    <td>
                      {totals.BRUTTO_total && totals.BRUTTO_total.toFixed(2)}{" "}
                      {currency}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <div className="signature"></div>
        </div>
      </div>
    </>
  );
}

export default BillPdf;
