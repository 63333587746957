import React, { useEffect } from "react";
import { Button, Segment, Grid, Loader, Icon, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import {
  getProductGroup,
  updateProductGroup,
  addProductsProductGroup,
  removeProductsProductGroup
} from "../../../actions/product";

import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import AddProducts from "./AddProducts";
import ProductGroupInfo from "./ProductGroupInfo";
import "../productGroup.scss";

function EditViewPG(props) {
  const {
    productGroup = { products: [] },
    isLoading,
    getProductGroup,
    updateProductGroup,
    addProductsProductGroup,
    removeProductsProductGroup,
    match,
    history
  } = props;

  useEffect(() => getProductGroup(match.params.id), []);

  const addProducts = products =>
    addProductsProductGroup({ _id: match.params.id, products });
  const removeProduct = products =>
    removeProductsProductGroup({ _id: match.params.id, products });
  const productsFromPG =
    productGroup.products && productGroup.products.length > 0
      ? productGroup.products.map(({ _id }) => _id)
      : [];

  console.log({ productsFromPG });

  if (!productGroup) {
    return (
      <div>
        <Loader active />
      </div>
    );
  } else {
    return (
      <>
        <Responsive
          mobileComponent={
            <InfoBar
              header="Edit Product Group"
              linkBack="/products/list/group"
            />
          }
        />
        <main
          className="page-wrapper product-group-container"
          style={{ maxWidth: "100vw" }}
        >
          <Responsive
            desktopComponent={
              <Head
                header="Edit Product Group"
                linkBack="/products/list/group"
              />
            }
          />
          {/* <Link to="/products/list/group">
          <Icon name="arrow left" /> Product group List
        </Link> */}
          <Segment>
            <div className="product-group">
              <Header as="h2" className="">
                {productGroup.name}{" "}
                <span className="grey--text small--text">
                  - {productGroup.products && productGroup.products.length}{" "}
                  products
                </span>
              </Header>
              <div className="product-group-columns">
                <div className="product-group-columns__info">
                  <ProductGroupInfo
                    productGroup={productGroup}
                    removeProduct={removeProduct}
                    updateProductGroup={updateProductGroup}
                    history={history}
                  />
                </div>
                <div className="product-group-columns__add-prod">
                  <AddProducts
                    addProducts={addProducts}
                    productsFromPG={productsFromPG}
                  />
                </div>
              </div>
            </div>
          </Segment>
        </main>
      </>
    );
  }
}

const mapStateToProps = reduxState => ({
  productGroup: reduxState.inventory.openProductGroup,
  isLoading: reduxState.loading.loading
});
export default connect(mapStateToProps, {
  getProductGroup,
  updateProductGroup,
  addProductsProductGroup,
  removeProductsProductGroup
})(EditViewPG);
