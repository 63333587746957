import React from "react";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { paid, label } from "./BillsTable";
import "../bills.scss";

function ItemCard({ item, index, currentPage, itemsPerPage }) {
  const {
    _id,
    country,
    status,
    items,
    dates,
     sequenceNumber,
    currency,
    period,
    totals,
    isPaid,
  } = item;

  return (
    <Segment>
      <div className="order-card">
        <span className="bills-cards-index">
          {(currentPage - 1) * itemsPerPage + index + 1}.
        </span>
        <Link to={"/billing/bills/" + _id} className="bills-cards- sequenceNumber">
          { sequenceNumber}{" "}
        </Link>
        {period && period.start && period.end && (
          <div>
            <Moment format="DD.MM.YYYY">{period.start}</Moment> -{" "}
            <Moment format="DD.MM.YYYY">{period.end}</Moment>
          </div>
        )}

        <div className="order-card--status">
          <Label color={label[isPaid]}>{status}</Label>
        </div>
        <Divider />
        <div className="order-card--grid">
          <span className="small--text">Date:</span>
          <div>
            <p className="small--text">
              Issued:{" "}
              <Moment format="DD.MM.YY HH:mm">
                {dates && dates.issuedDate}
              </Moment>
            </p>
            <p className="small--text">
              Tax date:{" "}
              <Moment format="DD.MM.YY HH:mm">{dates && dates.taxDate}</Moment>
            </p>
            <p className="small--text">
              Due date:{" "}
              <Moment format="DD.MM.YY HH:mm">{dates && dates.dueDate}</Moment>
            </p>
          </div>

          <span className="small--text">Totals:</span>
          <div>
            <p>VAT total: {totals && totals.VAT_total.toFixed(2)}</p>
            <p>NETTO total: {totals && totals.NETTO_total.toFixed(2)}</p>
            <p>BRUTTO total: {totals && totals.BRUTTO_total.toFixed(2)}</p>
          </div>

          <span className="small--text">Currency:</span>
          <div>
            {currency}
            {country && <div className="small--text">({country})</div>}
          </div>
        </div>
      </div>
    </Segment>
  );
}

export default ItemCard;
