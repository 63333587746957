import React from "react";
import { Table, Loader, Header, Checkbox } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";


const triggerLink = {
  Goodsreceived: "",
  Packinglist: "",
  Order: "/orders",
  Inboundshipment: "/shipments/inbound",
  Outboundshipment: "/shipments/outbound",
  Inventorycount: "",
  Purchaseorder: "/pos",
  // businessclient: "/businessclients",
};

function ServicesTable({
  services,
  isLoading,
  currentPage,
  itemsPerPage,
  displayBusinessclient = true,
  displayBill = true,
  style = {},
  // isHeaderChecked,
  // checkedItems,
  // handleSingleCheck,
  // handleMultipleCheck,
}) {
  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !services.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
          No services found
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>

      <Table.HeaderCell>Description</Table.HeaderCell>
      <Table.HeaderCell>Trigger</Table.HeaderCell>
  
      <Table.HeaderCell>Amount</Table.HeaderCell>
      <Table.HeaderCell>Created at</Table.HeaderCell>

      {displayBill && <Table.HeaderCell>Bill</Table.HeaderCell>}
    </Table.Row>
  );

  const tableBody = services.map(
    (
      {
        _id,
        quotation,
        serviceType,
        trigger,
        triggerId,
        currency,
        country,
        isBilled,
        description,
        bill,
        createdAt,
        amount,
        ...restOfService
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        <Table.Cell>
          {serviceType.name}
          <div class="small--text grey--text">{serviceType.category}</div>
        </Table.Cell>
        <Table.Cell>
          <Link to={triggerLink[trigger] + "/" + triggerId} target="_blank">
            {trigger}
          </Link>
          <div className="small--text">{triggerId}</div>
        </Table.Cell>
       
       
        <Table.Cell>
        {amount} {currency}
        </Table.Cell>
         <Table.Cell>
          <>
            <Moment
              className="small--text"
              format="DD-MM-YY HH:mm"
              key={createdAt}
              style={{ display: "block" }}
            >
              {createdAt}
            </Moment>
          </>
        </Table.Cell>
        {displayBill && (
          <Table.Cell>
            {isBilled ? <Link to={`/billing/bills/${bill}`}>View</Link> : "pending"}
            {/* + (bill && bill[0]) */}
          </Table.Cell>
        )}
      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very" style={style} loading={true}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default ServicesTable;
