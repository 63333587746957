import React from "react";
import { Table,  Label, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export const statusColor = {
  New: "red",
  pending: "orange",
  Shipping: "blue",
  Delivered: "green"
};

export const Shipmentlist = ({
  shipments,
  syslang,lang
}) => {
  if (!shipments.length)
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
        No Shipments Found
      </Header>
    );

  const ships_headers = (
    <Table.Header>
      <Table.Row>
        {/* < Checkbox></Checkbox> */}
        <Table.HeaderCell>ID</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.status[syslang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.warehouse[syslang]}</Table.HeaderCell>
        {/* <Table.HeaderCell>Client</Table.HeaderCell> */}
        <Table.HeaderCell>{lang.table.supplier[syslang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.details[syslang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.transport[syslang]}</Table.HeaderCell>
        <Table.HeaderCell>{lang.table.dates[syslang]}</Table.HeaderCell>
        {/* <Table.HeaderCell>Action</Table.HeaderCell> */}
      </Table.Row>
    </Table.Header>
  );

  const shipments_list = shipments.map((shipment, index) => {
    return (
      <tr key={index}>
        <Table.Cell>
          <Link to={`/shipments/inbound/${shipment._id}`}>
            {shipment._id.substr(-9)}
          </Link>
        </Table.Cell>

        <Table.Cell>
          <Label color={statusColor[shipment.status]}>{shipment.status}</Label>
        </Table.Cell>

        <Table.Cell>
          {shipment.warehouse ? shipment.warehouse.name : "NA"}
        </Table.Cell>
        {/* <Table.Cell>{shipment.client ? shipment.client.name : "NA"}</Table.Cell> */}
        <Table.Cell>{shipment.seller ? shipment.seller.name : "NA"}</Table.Cell>

        <Table.Cell>
        {lang.table.nrofpacks[syslang]}: {shipment.nrOfPacks}
          <br />
        {lang.table.weight[syslang]}: {shipment.weightKg}
        </Table.Cell>

        <Table.Cell>{shipment.transporter}</Table.Cell>

        <Table.Cell>
          {lang.table.createdAt[syslang]}<Moment format="DD-MM-YY HH:mm">{shipment.createdAt}</Moment>
          <br />
          {lang.table.updatedAt[syslang]}<Moment format="DD-MM-YY HH:mm">{shipment.updatedAt}</Moment>
        </Table.Cell>

        {/* <Table.Cell>
          {shipment.status !== "Delivered" && (
            <Button
              icon="trash alternate outline"
              // color = 'grey'
              // id = 'itemlistrow_action'
              basic
              onClick={() => deleteShipment(shipment._id)}
            />
          )}
        </Table.Cell> */}
      </tr>
    );
  });

  return (
    <div className="table-wrapper--horizontal-scroll">
      <Table unstackable selectable>
        {ships_headers}
        <tbody>{shipments_list}</tbody>
      </Table>
    </div>
  );
};
