
import { addError } from "./error";
import { apiCall, setTokenHeader } from "../services/api";
import history from "../history";
// import {store} from '../containers/CRM'
// import {Alert} from 'rsuite'

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}
export const setCurrentUser = currentUser => ({
  type: "SET_CURRENT_USER",
  currentUser
});

export const signUpAction = ({username,password},history )=> dispatch =>
  apiCall(`post`, `${process.env.REACT_APP_API}/auth/signup`, {username,password})
    .then(currentUser => {
      const token = currentUser.token;
      localStorage.setItem(`jwtToken`, token);
      setAuthorizationToken(token);

      dispatch(setCurrentUser(currentUser));
      history.push(`/profile/${currentUser.businessclient}`)
    })
    .catch(err => {
      dispatch(addError(err.message))
      
      setAuthorizationToken(false)
        });

export const signInAction = authInfo => dispatch =>
  apiCall(`post`, `${process.env.REACT_APP_API}/auth/signin`, authInfo)
    .then(currentUser => {
      const token = currentUser.token;
      localStorage.setItem(`jwtToken`, token);
      setAuthorizationToken(token);
      // dispatch(removeError());
      dispatch(setCurrentUser(currentUser));
      if(authInfo.lang) {
          dispatch(setDemoLng((authInfo.lang).toUpperCase()))
      }
    })

    .catch(err => {
      
      dispatch(addError(err.message))
      
      setAuthorizationToken(false) //delete from axios default header
      // store.dispatch({
      //     type: "USER_LOGOUT"
      // })
      // history.push(`/signin`);
    });


export const signInAsAction = authInfo => {
 return apiCall(`post`, `${process.env.REACT_APP_API}/auth/signinas`, authInfo)
    .then(currentUser => {
      const token = currentUser.token;
      localStorage.setItem(`jwtToken`, token);
      setAuthorizationToken(token);
        
    })
    .catch(err => {
      setAuthorizationToken(false) //delete from axios default header
     
    })}

export const logOutAction = () => dispatch => {
      dispatch({type:'USER_LOGOUT'})
      localStorage.clear();
      // dispatch(removeError());
      setAuthorizationToken(false); //delete from axios default header
      // dispatch(setCurrentUser({}));
      history.push(`/signin`);
    };
export const logOutActionAS = () => dispatch => {
      dispatch({type:'USER_LOGOUT'})
      localStorage.clear()
      setAuthorizationToken(false); //delete from axios default header
    };
    

export const setDemoLng =(lang)=>{
  return ({
    type:"DEMOACCOUNT_LANG",
    lang,
  })
}