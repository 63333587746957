import axios from 'axios'

import {
    setAuthorizationToken
} from '../actions/auth'

const httpClient = axios.create()


export function setTokenHeader(token){
    if (token) {
        httpClient.defaults.headers.common['Authorization']=`Barear ${token}`
        // axios.defaults.headers.common['username'] = username
    } else {
        delete axios.defaults.headers.common['Authorization']
        // delete axios.defaults.headers.common['username']
    }
}


export function apiCall(method,path,data){
    return new Promise ((resolve,reject)=>{
        httpClient[method](path,data)
            .then(res =>{
                // console.log(res)
                if(res.data.error) {
                    
                    if ([401,403].includes(res.data.error.code)) {
            
                        window.location.href = "/signin";
                        localStorage.clear();
                        setAuthorizationToken(false); //delete from axios default header
                        // store.dispatch({ type: "USER_LOGOUT" });
                      }
                    reject(res.data.error)}
                resolve(res.data)})
            .catch(err => {
                // console.log(err)
               
                    // console.log(err.response)       
                   if (err.response === undefined) {
                        // const state = store.getState()
                        // const url =''
                        // const logData = {
                        //         service:'internal.wyzbox.com',
                        //         url:path,
                        //         body:data,
                        //         method,
                        //         code:503,
                        //         user:state.user.currentUser.username,
                        //         serverity:"High"
                        // }
                      
                        // // httpClient.post(url,logData)

          reject({ message: "Reconnecting..." });
        } else {
          if ([401,403].includes(err.response.status)) {
            
            window.location.href = "/signin";
            localStorage.clear();
            setAuthorizationToken(false); //delete from axios default header
            // store.dispatch({ type: "USER_LOGOUT" });
          }
          reject(err.response.data.error);
        }
                      })
    })
}