import React, { useState, useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { getStockTurnover } from "../../../actions/reports";
import ExportExcel from "../../ExportExcel";



function ExportButton({ data, filters, getStockTurnover }) {
  // useEffect(() => getStockTurnover(filters), [filters]);


  const [dataSet, setData] = useState();

  const colNames = [
    { label: "Sku", value: "sku" },
    { label: "Name", value: "name" },
    { label: "balance", value: "balance" },
    { label: "turnover", value: "turnover" },
  ];

  useEffect(() => {
  
    let tempDataSet = [];
    
    for (let i=0;i<data.length;i++){
      const line = data[i]
      
      tempDataSet.push(
                  {sku:Object.values(line)[0].sku,
                  name:Object.values(line)[0].name,
                  balance:Object.values(line)[0].balance,
                  turnover:Object.values(line)[0].turnover

      })
    }

    setData(tempDataSet);
  }, [data]);

  return (
    <ExportExcel
      filename="turvover"
      excelSheetName="xdays"
      dataSet={dataSet}
      colNames={colNames}
      button={
        <Button basic color="grey" >
          <Icon name="excel file" />
          Export
        </Button>
      }
    />
  );
}

const mapStateToProps = (reduxState) => ({
  
  filters:    reduxState.reports.stocksturnover.filters,
  data:     reduxState.reports.stocksturnover.list,
});
export default connect(mapStateToProps, { getStockTurnover })(ExportButton);
