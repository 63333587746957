import { GET_COMPANIES, CREATE_CO } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

export const getCompanies = () => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/company/`;

  apiCall("get", url)
    .then(resp => dispatch(getCompaniesAction(resp)))
    .catch(err => console.log(err));
  // .catch(err => dispatch(addError(err.message)))
};

const getCompaniesAction = data => ({
  type: GET_COMPANIES,
  data
});

export const createCo = data => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/company`;
  apiCall("post", url, data)
    .then(resp => dispatch(createCoAction(resp)))
    .catch(err => dispatch(addError(err.message)));
};

const createCoAction = company => ({
  type: CREATE_CO,
  company
});
