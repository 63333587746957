import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

import { getCouriersByCountry } from "../../actions/couriers";
import { createOptions } from "../../helpers/createOptions";

function CourierFilter({
  value,
  handleChange,
  multiple,
  fluid,
  error = false,
  // initialValue,
  couriers = [],
  getCouriersByCountry,
  country=null
}) {
  const [options, setOptions] = useState([]);

  useEffect(() => { if(country) getCouriersByCountry(country)}, []);
  useEffect(() => setOptions(createOptions(couriers)), [couriers]);

  return (
    <Dropdown
      placeholder="courier..."
      name="courier"
      value={value}
      // initialValue={initialValue}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
      // value: _id,
      // text: name,
      // key: _id
    />
  );
}

const mapStateToProps = (reduxState) => ({
  couriers: reduxState.couriers.listByCountry,
});

export default connect(mapStateToProps, { getCouriersByCountry })(CourierFilter);
