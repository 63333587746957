export const statuses = [
  "All",
  "New",
  "Draft",
  "Canceled",
  "Order Ready for picking",
  "Error",
  "PackingError",
  "No goods",
  "Goods Blocked",
  "Pending",
  "Product Picking",
  "Packing",
  "Packed",
  "Shipping",
  "Unsuccessful attempt",
  "Delivered",
  "Done",
  "Not shipped",
  "COD RECEIVED",
  "COD TRANFFERED TO CLIENT",
  "Validated",
  "Unpaired items",
];
