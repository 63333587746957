import React from "react";
import { Table } from "semantic-ui-react";

import Responsive from "../../Responsive";
import CardsList from "../../CardsList";
import ProductItem from "./ProductItem";
import ProductCard from "./ProductCard";


function ProductsList({
  lang,
  user,
  items,
  handleDelete,
  style = {},
}) {

  if (items.length === 0) return null;

  const tableRows = items
    .filter(p => p !== null)
    .map((p, i) => (
      <ProductItem
        lang={lang}
        name={p.name}
        price={p.price}
        quantity={p.quantity}
        _id={p._id}
        productId={p.product}
        i={i}
        handleDelete={handleDelete}
        user={user}
      />
    ))

    return (
      <Responsive
        mobileComponent={
          <CardsList items={items} notFoundMsg="No Products">
            <ProductCard onlyQuantity={true} />
          </CardsList>
        }
        desktopComponent={
          <div className="table-wrapper--horizontal-scroll" style={style}>
            <Table
              
              striped
              unstackable
              textAlign="center"
              padded="very"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>#</Table.HeaderCell>
                  <Table.HeaderCell>                 
                    {lang.orders.createOrder.producttable.name[user.currentUser.sys_lang]}</Table.HeaderCell>
                  {/* <Table.HeaderCell>SKU</Table.HeaderCell> */}
                  <Table.HeaderCell> {lang.orders.createOrder.producttable.quantity[user.currentUser.sys_lang]}</Table.HeaderCell>
                  <Table.HeaderCell> {lang.orders.createOrder.producttable.price[user.currentUser.sys_lang]}</Table.HeaderCell>
                 <Table.HeaderCell>{lang.orders.createOrder.producttable.actions[user.currentUser.sys_lang]}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>{tableRows}</Table.Body>
            </Table>
          </div>
        }
      />
    );
}

export default ProductsList;
