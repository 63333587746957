import React, { useState } from "react";
import { Button, Tab, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
// import ListPG from "../../Productgroup/ListPG";
import ProductsList from "./ProductsList";
import lang from '../../../lang/config.json'

const linkToTab = {
  product: 0,
  group: 1
};

function ProductsListPage({ match, history,user }) {
  const initialIndex = linkToTab[match.params.activeTab];
  const [activeIndex, setActiveIndex] = useState(initialIndex);

  const handleTabChange = (e, { activeIndex }) => {
    history.push(`/products/list/${Object.keys(linkToTab)[activeIndex]}`);
    setActiveIndex(activeIndex);
  };

  const panes = [
    {
      menuItem: lang.products.viewproduct.title[user.currentUser.sys_lang],
      render: () => (
        <Tab.Pane attached={false}>
          <ProductsList />
        </Tab.Pane>
      )
    },
    {
      menuItem:lang.products.list.productgroup[user.currentUser.sys_lang],
      render: () => (
        <Tab.Pane attached={false}>
          {/* <ListPG /> */}
        </Tab.Pane>
      )
    }
  ];
  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar
            header={lang.products.list.title[user.currentUser.sys_lang]}
            buttons={
              <>
                <Link to="/products/create/new">
                  <Button compact size="tiny" basic color="grey">
                    <Icon name="plus" size="small" />
                    {lang.products.list.addproductbutton[user.currentUser.sys_lang]}
                  </Button>
                </Link>
                {/* <Link to="/productgroup/create/new">
                  <Button compact size="tiny">
                    <Icon name="plus" size="small" /> 
                     {lang.products.list.addgroupproduct[user.currentUser.sys_lang]}
                  </Button>
                </Link> */}
              </>
            }
          />
        }
      />
      <main className="page-wrapper product-list-container">
        <Responsive
          desktopComponent={
            <Head header={lang.products.list.title[user.currentUser.sys_lang]}>
              <div>
                <Link to="/products/create/new">
                  <Button> 
                  {lang.products.list.addproductbutton[user.currentUser.sys_lang]}
                  </Button>
                </Link>
                {/* <Link to="/productgroup/create/new">
                  <Button color="blue">{lang.products.list.addgroupproduct[user.currentUser.sys_lang]}</Button>
                </Link> */}
              </div>
            </Head>
          }
        />

        <Tab
          menu={{
            color: "teal",
            inverted: false,
            attached: false,
            tabular: false,
            borderless: true
            // secondary: true
          }}
          panes={panes}
          activeIndex={activeIndex}
          onTabChange={handleTabChange}
        />
      </main>
    </>
  );
}

export default ProductsListPage;
