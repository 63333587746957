import React from "react";
import { Icon, Input, Dropdown } from "semantic-ui-react";

const EditableField = ({
  name,
  loading=false,
  initialValue,
  handleContentUpdate,
  options = null,
  label,
  editable =true
}) => {
  const [isHovered, setHovered] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);
  const [text, setText] = React.useState(initialValue);

  const handleChange = (e, d) => {
    if (e.target) {
      setText(e.target.value);
    } else {
      setText(d.value);
    }
  };

  const handleSubmit = (e, d) => {
    setIsEditable(false);
    setHovered(false);
    // handleChange(e, d);
    if (e.target) {
      handleContentUpdate(name, e.target.value);
    } else {
      handleContentUpdate(name, text);
    }
  };

  const handleSubmitDropdown = (e, { value }) => {
    setIsEditable(false);
    setHovered(false);
    // handleChange(e, d);
    handleContentUpdate(name, value);
  };

  const handleEdit = () => {
    // alert(editable)
   if(editable) setIsEditable(true);
  };
  const handleClean = () => {
    setIsEditable(false);
    setText(null);
    handleContentUpdate(name, "");
  };
  // const handleReset = () => {
  //   setIsEditable(false);
  //   setText(initialValue);
  // };

  React.useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  const input = options ? (
    <Dropdown
      loading={loading}
      select
      search
      fluid
      name={name}
      value={text}
      onChange={handleSubmitDropdown}
      onBlur={handleSubmitDropdown}
      options={options}
      style={{
        height: "32px",
        display: "block",
        maxWidth: "100%",
        borderColor: "#85b7d9",
        borderRadius: ".3em",
        paddingLeft: "0.75em",
      }}
      //        {options.map((o) => {
      //   return (
      //     <option key={o.key} value={o.value} name={name}>
      //       {o.text}
      //     </option>
      //   );
      // })}
    />
  ) : (
    // <div>
    <Input
      loading={loading}
      autoFocus={true}
      autoComplete="off"
      focus
      fluid
      name={name}
      placeholder={label}
      value={text}
      onChange={handleChange}
      onBlur={handleSubmit}
      style={{
        height: "32px",
        borderRadius: "0",
        border: "none, none, 1px solid blue, none !important",
        width: "100%",
      }}
    />
    // </div>
  );

  if (isEditable) {
    return input;
  } else {
    return (
      <div
        className="editable-field__text"
        onClick={handleEdit}
        onMouseEnter={() => { if (editable) setHovered(true)}}
        onMouseLeave={() => setHovered(false)}
      >
        {text}
        <span
          className={`editable-field__icons ${
            !isHovered && "editable-field__icons_invisible"
          }`}
        >
          <Icon
            name="pencil alternate"
            color="grey"
            style={{ marginRight: "6px" }}
            onClick={handleEdit}
          />
          <Icon
            name="eraser"
            color="grey"
            style={{ marginRight: "2px" }}
            onClick={editable&&handleClean}
          />
          {/* <Icon name="undo alternate" color="grey" onClick={handleReset} /> */}
        </span>
      </div>
    );
  }
};

export default EditableField;
