import React, { Component } from "react";
import { Segment, Header, Button,Form } from "semantic-ui-react";

// import ItemView from "./ItemView";
import ItemList from "./ItemList";

class GoodsReceived extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openItem: {
        _id: "",
        stockMovement: {
          inventory: {
            _id: "",
            product: { name: "" },
            photos: {
              files: []
            }
          }
        }
      },
   
  }};
    

  render() {
    const {  goodsreceived ,isLoading,user,lang} = this.props;
    return (
      <Form loading={    isLoading}
    >
      <section className="inbound-goodsreceived">
        {/* <ItemView
          openItem={this.state.openItem} 
          shipmentId={this.props.openShipment._id}
        /> */}

        <Segment padded>
          <div className="flex--between">
            <Header as="h3">{lang.table.items[user.currentUser.sys_lang]}</Header>
          </div>

          {goodsreceived.length > 0 && goodsreceived[0]._id && (
            <ItemList
             user={user}
             lang={lang}
              goodsreceived={goodsreceived}
              handleViewPhotos={this.props.handleViewPhotos}
            />
          )}
        </Segment>
      </section>
      </Form>
    );
  }
}
export default (GoodsReceived);
