// import {
//     GET_BUSINESSCLIENTS,
//   } from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";

const startLoading = { type: "START_SERVICES_LOADING" };
const stopLoading = { type: "STOP_SERVICES_LOADING" };

export const clearServicesForBillingSummary = () => (dispatch) => {
  dispatch({
    type: "CLEAR_SERVICES_FOR_BILLING",
  });
};

export const getServices = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = filters
    ? Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";
  const url = `${process.env.REACT_APP_API}/services?${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_SERVICES", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateFilters = (newFilter) => ({
  type: "UPDATE_FILTER",
  payload: newFilter,
});


// export const getServicesForBilling = (filters) => (dispatch) => {
//   dispatch(startLoading);
//   dispatch(removeError());
//   const filtersUrl = filters
//     ? Object.entries(filters)
//         .filter(([key, value]) => value)
//         .map(([key, value]) => key + "=" + value)
//         .join("&")
//     : "";
//   const url = `${process.env.REACT_APP_API}/services/billpreview?${filtersUrl}`;
//   apiCall("get", url)
//     .then((resp) =>
//       dispatch({ type: "GET_SERVICES_FOR_BILLING", payload: resp })
//     )
//     .catch((error) => {
//       dispatch(stopLoading);
//       dispatch(addError(error.message));
//     });
// };

///////////////////////////////////////////////////////////////////////////////
// BILLS
export const getBills = (filters) => (dispatch) => {
  dispatch(startLoading);
  dispatch(removeError());
  const filtersUrl = filters
    ? "?" +
      Object.entries(filters)
        .filter(([key, value]) => value)
        .map(([key, value]) => key + "=" + value)
        .join("&")
    : "";
  const url = `${process.env.REACT_APP_API}/bills${filtersUrl}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_BILLS", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getBill = (id) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/bills/${id}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_BILL", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const getBillServices = (billId) => (dispatch) => {
  dispatch(startLoading);
  const url = `${process.env.REACT_APP_API}/bills/${billId}/services`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_BILL_SERVICES", payload: resp }))
    .catch((error) => {
      dispatch(stopLoading);
      dispatch(addError(error.message));
    });
};

export const updateBillsFilters = (newFilter) => ({
  type: "UPDATE_BILLS_FILTER",
  payload: newFilter,
});
