import React from "react";
import { Segment } from "semantic-ui-react";
// import { DateRangePicker } from "rsuite";
import { DatePicker } from "rsuite";



function Filters({
  filters: {  dateFrom, dateTo },
  setFrom,setTo,
}) {
  
  return (
    <Segment>
      <p>Filters:</p>
      <div className="flex--left">
      
         <DatePicker
              oneTap
              style={{width:'200px'}}
              value={dateFrom}
              isClearable={true}
              format="DD-MM-YYYY"
              onChange={(date) => setFrom(date)}
              placeholder="from..."
            />

              <DatePicker
              oneTap
              style={{width:'200px'}}
              value={dateTo}
              isClearable={true}
              format="DD-MM-YYYY"
              onChange={(date) => setTo(date)}
              placeholder="to..."
            />
      </div>
      {/* <div className="services-filters-date">
        <DateRangePicker
          placeholder="Select Date Range"
          block
          // value={date}
          onChange={([dateFrom, dateTo]) =>
            updateFilters({ dateFrom, dateTo, currentPage: 1 })
          }
        />
      </div> */}
      {/* <Dropdown
        placeholder="status..."
        name="status"
        value={status}
        clearable
        selection
        selectOnBlur={false}
        closeOnChange={true}
        onChange={handleFilterChange}
        options={[
          "Not paid - In due",
          "Not paid- Over due",
          "Paid",
        ].map((option, i) => ({ key: i, value: option, text: option }))}
      /> */}
    </Segment>
  );
}
export default Filters;
