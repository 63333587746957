/* eslint-disable default-case */
import React, { useReducer } from "react";
import { Segment} from "semantic-ui-react";
import Img from "react-image";
import ImgsViewer from "react-images-viewer";

// import Previews from "../../Uploader/photos";
import ImageSlider from "./ImageSlider";
// import {removeFiles} from '../../../actions/uploadFiles'
// import {Alert} from 'rsuite'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initialState = {
  executeChildMethod: false,
  editMode: false,
  // photos: [],
  shipmentOpen: false,
  newShipment: true,
  isLightBoxOpen: false,
  currentImg: 0,
  imgURLs: [],
  hasUpdates: false,
  updates: {
    removedFiles: [],
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "UPLOAD_EXECUTED":
      return { ...state, executeChildMethod: false, editMode: false };

  

    case "VIEW_PHOTO":
      const imageURLs =
        action.payload.files && action.payload.files.length > 0
          ? action.payload.files.map(({ publicurl }) => ({
              src: publicurl
            }))
          : [];
      return {
        ...state,
        imgURLs: imageURLs,
        currentImg: action.payload.index,
        isLightBoxOpen: true,
        updates:{
          ...state.updates,
          removedFiles:[]
        }
      };

    case "GO_TO_NEXT":
      if (state.currentImg < state.imgURLs.length)
        return { ...state, currentImg: state.currentImg + 1 };
      else return state;

    case "GO_TO_PREVIOUS":
      if (state.currentImg > 0) return { ...state, currentImg: state.currentImg - 1 };
      else return state;

    case "CLOSE_LIGHTBOX":
      return { ...state, isLightBoxOpen: false };

    case "DELETE_PHOTO":
      
      return {
        ...state,
        updates: {
          ...state.updates,
          removedFiles: [...state.updates.removedFiles, action.payload.id]
        },
      };

    case "UPDATE_PHOTOS_LIST":
      return {
        ...state,
        updates:{...state.updates,
            removedFiles:[],
            addedFiles:[]}
      }
  }
}

function Images(props) {
  const { openShipment,isLoading } = props;
  const [state, dispatch] = useReducer(reducer, initialState);


  const images =
    openShipment.photos && openShipment.photos.length > 0
      ? openShipment.photos.map((photo, index) => {
          let opacity =state.updates.removedFiles.includes(photo._id)?{opacity:0.5}:{opacity:1}
          return (
            <div >

            <div style={{margin:'10px'}}>
              <Img
                style={opacity}
                onClick={() =>
                  dispatch({ type: "VIEW_PHOTO", payload: { index, files:openShipment.photos } })
                }
                src={photo.publicurl}
                alt="text"
              />
      
                
              
            </div></div>
          );
        })
      : [];

  return (
    <Segment loading={isLoading} >
      <div className="inbound_photosx" >
        {images.length > 0 && (
          <div>
            <ImageSlider images={images} />
               
           </div>
        )}

        {state.imgURLs.length > 0 && (
          <ImgsViewer
            imgs={state.imgURLs}
            currImg={state.currentImg}
            isOpen={state.isLightBoxOpen}
            onClickPrev={() => dispatch({ type: "GO_TO_PREVIOUS" })}
            onClickNext={() => dispatch({ type: "GO_TO_NEXT" })}
            onClose={() => dispatch({ type: "CLOSE_LIGHTBOX" })}
            showThumbnails={true}
            backdropCloseable={true}
            preloadNextImg={false}
            // customCtrls={['test']}
            // closeBtnTitle={'CLOSE'}
          />
        )}

        {/* <Header as="h3">Add photos</Header>
        <Previews
          isLoading={isLoading}
          setLoading = {setIsloading}
          cb={filesUploaded}
          businessclient={openShipment.businessclient}
          triggerId={openShipment._id}
          trigger={"Inboundparcel"}
          name={"photos"}
          files={state.updates.addFiles}
        /> */}
      </div>
    </Segment>
  );
}

export default Images;
