import React, { useState  } from "react";
import { Table,  Loader, Header,Checkbox,Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import lang from '../../../lang/config.json'
import {Dropdown} from 'rsuite'
// import CreateUpdateTime from "../../CreateUpdateTime";


function ReducedTable({ products, isLoading, itemsPerPage, currentPage,user,updateMultiple }) {

  const [selected,setSelected] = useState([])
  const [selectAll,setSelectAll] = useState(false)

  const handleCheckbox=({checked,_id})=>{
    if(checked) setSelected([...selected,_id])
    if(!checked) {
        const newList = selected.filter(item=>item!==_id)
        setSelected(newList)
    }
  }
  const handleSelectAll =(checked)=>{
    if(!checked) {
        setSelected([])
        setSelectAll(false)
      }
    if(checked){
      setSelectAll(checked)
    const all = products.map(item=>item._id)
    setSelected(all)}
    
  }
  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>
        <Checkbox
          checked={selectAll}
          onChange={(e,{checked})=>handleSelectAll(checked)}
          ></Checkbox>
      </Table.HeaderCell>
      {/* <Table.HeaderCell textAlign="center" className="index-col-padding ">
        #
      </Table.HeaderCell> */}
      <Table.HeaderCell>{lang.products.list.table.product[user.currentUser.sys_lang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.products.list.importorders[user.currentUser.sys_lang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>{lang.table.settings[user.currentUser.sys_lang]}</Table.HeaderCell> */}
     {/* <Table.HeaderCell>Eshop</Table.HeaderCell> */}
      <Table.HeaderCell>{lang.products.list.table.sku[user.currentUser.sys_lang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>{lang.products.list.table.dates[user.currentUser.sys_lang]}</Table.HeaderCell> */}
      <Table.HeaderCell>{lang.products.list.table.options[user.currentUser.sys_lang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.products.list.table.properties[user.currentUser.sys_lang]}</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = products.map(
    (
      {
        _id,
        allowImportOrders,
        name,
        codes = {
          sku: "",
          barcode: ""
        },
        businessclient = { name: "" },
        color,
        size,
        shoptet=null,
        eshop={eshopUrl:''},
        proprities = {
          dimensions: {
            height: "",
            width: "",
            lenght: ""
          },
          weight: ""
        },
        createdAt,
        updatedAt
      },
      index
    ) => (
      <Table.Row key={_id} className="prod-list__item product-row">
        <Table.Cell>
          <Checkbox
            checked={selected.includes(_id)}
            onChange={(e,{checked})=>handleCheckbox({checked,_id})}
          />

          
        </Table.Cell>
        {/* <Table.Cell
          collapsing
          textAlign="center"
          verticalAlign="middle"
          className="index-col-padding"
        >
          {itemsPerPage * (currentPage - 1) + index + 1}
        </Table.Cell> */}
        <Table.Cell verticalAlign="middle">
          <Link className="x-large--text bold--text" to={`/products/${_id}`}>
            {name}
          </Link>
          <br />

          <span className="grey--text small--text">
            {businessclient && businessclient.name}
          </span>

        </Table.Cell>
        <Table.Cell>
         {allowImportOrders?"Ano":"NE"}
          {/* {shoptet&&<div>
            
            {shoptet.isAllowed&&<div>Shoptet <Icon name='check'></Icon></div>}
            {!shoptet.isAllowed&&<div>Shoptet <Icon name='close'></Icon></div>}
            
            </div>} */}
            
          </Table.Cell>
        {/* <Table.Cell>{eshop.eshopUrl}</Table.Cell> */}
        <Table.Cell>{codes.sku}</Table.Cell>
        {/* <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell> */}
        <Table.Cell>
          <div className="product-row__options">
            {size && (
              <p className="product-row__row">
                <span className="product-row__label">
                      {lang.products.list.table.size[user.currentUser.sys_lang]}
</span> {size}
              </p>
            )}
            {color && (
              <p className="product-row__row">
                <span className="product-row__label">    {lang.products.list.table.color[user.currentUser.sys_lang]}</span> {color}
              </p>
            )}
          </div>
        </Table.Cell>
        <Table.Cell>
          {proprities && (
            <div className="product-row__options">
              {proprities.weight && (
                <p className="product-row__row">
                  <span className="product-row__label">    {lang.products.list.table.weight[user.currentUser.sys_lang]}</span>{" "}
                  {proprities.weight}
                </p>
              )}

              {proprities.dimentions && (
                <p className="product-row__row">
                  <span className="product-row__label">    
                    {lang.products.list.table.dimensions[user.currentUser.sys_lang]}</span>{" "}
                  {Object.values(proprities.dimentions)
                    .filter(d => d)
                    .map(d => d + "cm")
                    .join(" x ")}
                </p>
              )}
            </div>
          )}
        </Table.Cell>
      </Table.Row>
    )
  );



  return (
    <div className="table-wrapper--horizontal-scroll">
      <segment id='menu'>
        <Dropdown 
          disabled={selected.length===0}
          title={selected.length>0?`${lang.products.buttons.importorders[user.currentUser.sys_lang]} ${selected.length>0?selected.length:''}`:lang.products.buttons.selectproduct[user.currentUser.sys_lang]}
           appearance="default">
                  
                      <Dropdown.Item 
                          onClick={()=>updateMultiple({ids:selected,query:{allowImportOrders:true}})}>

                              {lang.products.buttons.allow[user.currentUser.sys_lang]} 
                              </Dropdown.Item>
                      <Dropdown.Item
                      onClick={()=>updateMultiple({ids:selected,query:{allowImportOrders:false  }})}>
                        {lang.products.buttons.stop[user.currentUser.sys_lang]}</Dropdown.Item>
                  

        </Dropdown>
      </segment>
      {!isLoading && products.length === 0 && (
        <Header textAlign="center" color="grey">
          No products found
        </Header>
      )}
      {isLoading && products.length === 0 && (
        <Loader active inline="centered" />
      )}
      {products.length > 0 && (
        <Table
          unstackable
          inveted
          basic="very"
          padded
          selectable
          className="prod-list"
        >
          <Table.Header color="teal" className="prod-list__header">
            {tableHeader}
          </Table.Header>
          <Table.Body>{tableBody}</Table.Body>
        </Table>
      )}
    </div>
  );
}

export default ReducedTable;
