import React from "react";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { statusColor } from "../OrdersTable";
import "./orders-mobile.scss";

function ItemCard({ item }) {
  const {
    _id,
    clientOrderNr,
    status,
    createdAt,
    updatedAt,
    shipping,
    processing,
    errMsgs,
    items
  } = item;

  let products = [];
  if (items && items.length > 0) {
    products = items.map(item => {
      if (item) {
        let name=item.name?item.name:"Na"
        const length =name.length
        if(length>15) name =`${name.substring(0,15)}...`

        return (
          <div
            style={{ whiteSpace: "pre" }}
            className="large--text dark-blue--text"
          >
            {name} -{item.quantity}
          </div>
        );
      }
    });
  }

  let errors = [];
  if (errMsgs && errMsgs.length > 0) {
    errors = errMsgs.map(error => <div>{error}</div>);
  }

  return (
    <Segment>
       <Label ribbon color={statusColor[status]} horizontal>
            {status}
          </Label>
      <div className="order-card">
        <Link to={"/orders/" + _id} className="order-card--link">
          {clientOrderNr}
          <br></br>
           <Moment
            style={{
                color: "#565656",
                whiteSpace: "pre",
                paddingRight: "1em"
              }}
               className="small--text"
              format="DD-MM-YY HH:mm"
            >
              {createdAt}
            </Moment>
        </Link>

        <div className="order-card--status">
         
          {processing && processing.isOpenUser && (
            <div class="smallinfo">open by: {processing.isOpenUser}</div>
          )}
          <div className="smallinfo">{errors}</div>
        </div>

        <Divider />
        <div className="order-card--grid">
          {/* <span className="small--text">Date:</span>
          <div>
            <span className="small--text">Updated at:</span>{" "}
            <Moment
              style={{
                color: "#565656",
                whiteSpace: "pre",
                paddingRight: "1em"
              }}
              format="DD-MM-YY HH:mm"
            >
              {updatedAt}
            </Moment>
            <br /> */}
            {/* <span className="small--text">Created at:</span>{" "}
            <Moment
              style={{ color: "#565656", whiteSpace: "pre" }}
              format="DD-MM-YY HH:mm"
            >
              {createdAt}
            </Moment> */}
          {/* </div> */}

          {/* <span>Products:</span> */}
          <div>{products}</div>
          <br></br>
          <span>Courier:</span>
          <p>{shipping.courier ? shipping.courier.name : ""}</p>
        </div>
      </div>
    </Segment>
  );
}

export default ItemCard;
