import React from "react";
import Img from "react-image";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import EditableText from '../../EditableText'
import EditableDropdown from '../../EditableDropdown'
import { currency } from "../../Filters/data"

const currencyOptions=currency.map((currency) => ({
    key: currency,
    value: currency,
    text: currency,
  }));


const ItemList = ({
  goodsreceived,
  handleChange,
  handleEdit,
  handleDelete,
  handleViewPhotos,
  lang,
  user
}) => {


  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.photos[user.currentUser.sys_lang]}</Table.HeaderCell>
      <Table.HeaderCell>Product</Table.HeaderCell>
      <Table.HeaderCell>IDS</Table.HeaderCell>
      <Table.HeaderCell>Cost</Table.HeaderCell>
      <Table.HeaderCell>Currency</Table.HeaderCell>
      <Table.HeaderCell>Position</Table.HeaderCell>
      <Table.HeaderCell>Quantity</Table.HeaderCell>
      <Table.HeaderCell>Action</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = goodsreceived.map((item, index) => {
  const path =undefined
      
    let product = {};
    if (!item.product) {
      product = {
        name: "NA",
        codes: { sku: "NA", barcode: "NA", clientsku: "NA" }
      };
    } else product = item.product;

    return (
      <Table.Row>
        <Table.Cell>{index + 1}</Table.Cell>
        <Table.Cell>
          {path && (
            <div id="image">
              <Img
                onClick={() =>
                  handleViewPhotos({
                    index: 0,
                    files: item.stockMovement.inventory.photos.files
                  })
                }
                alt="img"
                src={path}
              />
            </div>
          )}
          <Button
            basic
            compact
            size="tiny"
            // color="grey"
            onClick={() => handleEdit(index)}
          >
            {path && `Edit`}
            {!path && `Add photo`}
          </Button>
        </Table.Cell>
        <Table.Cell>
        {item.product&&<Link to={`/products/${item.product._id}`} target='_blank'>{product.name}</Link> } 
          {/* {product.name} */}
        {!item.product && (
            <Button basic compact size="tiny">
              Pair
            </Button>
          )}
        </Table.Cell>
        <Table.Cell>
          <span className="small--text bold--text">
            <span className="normal--text inbound-goodsreceived__label">
              Barcode:
            </span>
            {product.codes.barcode || ""}
            <br />
            <span className="normal--text inbound-goodsreceived__label">
              Sku:
            </span>
            {product.codes.sku || ""}
            {/* <br />
            <span className="normal--text">Client:</span>{" "}
            {product.codes.clientsku || ""} */}
          </span>
        </Table.Cell>
        <Table.Cell>
          {(item.inventoryCard.cost && 
                  <EditableText 
                        name ='cost.amount'
                        value={item.inventoryCard.cost.amount}
                        handleChange = {(props)=>handleChange({...props,cardId:item.inventoryCard._id})}
                      />
               
            )}

            
        </Table.Cell>
        <Table.Cell>
          <EditableDropdown
            value={(item.inventoryCard.cost && item.inventoryCard.cost.currency)||null}
            name='cost.currency'
            options={currencyOptions}
            handleChange = {(props)=>handleChange({...props,cardId:item.inventoryCard._id})}

          ></EditableDropdown>
           {/* {(item.inventoryCard.cost && item.inventoryCard.cost.currency)} */}

        </Table.Cell>
        <Table.Cell>
          {item.inventoryCard.inhouse_position || ""}
        </Table.Cell>
        <Table.Cell>
          {item.stockMovement.quantity}
          {item.stockMovement.quantity_damaged
            ? `/${item.stockMovement.quantity_damaged}`
            : ""}
        </Table.Cell>
        <Table.Cell>
          <Button
            basic
            icon="trash"
            size="tiny"
            onClick={() => handleDelete(index)}
          />
        </Table.Cell>
      </Table.Row>
    );
  });

  return (
    <Table basic="very" unstackable style={{ marginTop: "2em" }}>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
};

export default ItemList;
