const initialState={
    userlist: [],
    isLoading:false,
    viewPermissions:{}
}
const users = (state = initialState, action) => {

    switch (action.type) {
        case "LOAD_USERS":
        
            return {...state,
                userlist:action.data.userlist,
                viewPermissions: action.data.viewPermissions,
                isLoading:false
            }
       
        case "ISLOADING":
            return {...state,
                         isLoading:true
            }
        default:
            return state
    }
}
export default users