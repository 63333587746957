import React from "react";
import { Segment, Dropdown, Header } from "semantic-ui-react";
import { DateRangePicker } from "rsuite";

function Filters({ filters, handleFilterChange, updateFilters,user,lang }) {
  const { status, date } = filters;
  return (
    <Segment>
      {/* <Header as="h4">Filters:</Header> */}
      <div className="services-filters">
        <div className="services-filters-date">
          <DateRangePicker
            placeholder={lang.filters.datepicker.range[user.currentUser.sys_lang]}
            block
            value={date}
            onChange={([dateFrom, dateTo]) =>
              updateFilters({ dateFrom, dateTo, currentPage: 1 })
            }
          />
        </div>
        <Dropdown
          className="services-filters-status"
          placeholder={lang.filters.status[user.currentUser.sys_lang]}
          name="status"
          value={status}
          clearable
          selection
          selectOnBlur={false}
          closeOnChange={true}
          onChange={handleFilterChange}
          options={[
            "Not paid - In due",
            "Not paid- Over due",
            "Paid",
          ].map((option, i) => ({ key: i, value: option, text: option }))}
        />
      </div>
    </Segment>
  );
}
export default Filters;
