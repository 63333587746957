import React, { Component } from "react";
import {
  Header,
  Pagination,
  Segment,
} from "semantic-ui-react";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";

import {updateFilter,
  getShipments,setAsProcessed
} from "../../../actions/inboundparcel";
import { getWarehouses } from "../../../actions/inventory";
import { getProductList } from "../../../actions/product.js";

// import Pagination from "../../pagination";
// import CanViewPage from "../../../role-based-access/CanViewPage";
import ItemsPerPage from "../../ItemsPerPage";
import Responsive from "../../Responsive";
import CardsList from "../../CardsList";
import MobileItemCard from "./MobileItemCard";
import { ShipmentTable } from "./ShipmentTable";
import Filters from "./Filters";
// import "../inbound.scss"
import lang from '../../../lang/config.json'


class Inboundparcels extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        product: "",
        warehouse: "",
        status: "",
      },
    };
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);  
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  handleDateChange(filters){
     const updateFilter = new Promise((resolve, reject) =>
      resolve(this.props.updateFilter(filters))
    );
    updateFilter.then(() => this.props.getShipments(this.props.filters));
  }

  handleUpdateFilter({ value, name }) {
    
      
    const updateFilter = new Promise((resolve) =>
      resolve(this.props.updateFilter({ [name]:value}))
    );

    updateFilter.then(() => this.props.getShipments(this.props.filters));
  }
  componentDidMount() {
    const { filters } = this.props;
    this.props.getShipments(filters);
    
    this.props.getProductList();
    this.props.getWarehouses();
  }
  render() {
    const {
      setAsProcessed,
      updating,
      total,
      inboundshipments,
      filters,
      companies,
      businessclients,
      user,
      isLoading,
      products,
      syslang
    } = this.props;

    const loading = <div>Loading...</div>;

    if (updating) return { loading };

    return (
      // <CanViewPage path="/parcels/inbound">
        <div className="inbound" style={{padding:"15px"}}>
          <div className="flex_spaceBetween">
            <Header as="h3">{lang.inboundparcel.title[syslang]}</Header>
            
          </div>
          
        <Filters
          syslang={user.currentUser.sys_lang}
          lang={lang}
          handleDateChange={this.handleDateChange}
          handleUpdateFilter={this.handleUpdateFilter}
          warehouses={this.props.warehouses}
          filters={filters}
          companies={companies}
          businessclients={businessclients}
          products={products}
        />

          <Segment padded>
            <Responsive
              breakpoint={700}
              mobileComponent={
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "1em",
                    }}
                  >
                    <ItemsPerPage
                      total={total}
                      value={filters.itemsPerPage}
                      handleChange={this.handleUpdateFilter}
                    />
                  </div>
                  <CardsList
                    items={inboundshipments}
                    notFoundMsg="No Parcels Found"
                  >
                    <MobileItemCard />
                  </CardsList>
                </>
              }
              desktopComponent={
                <ShipmentTable
                  setAsProcessed={setAsProcessed}
                  user={user}
                  lang={lang}
                  shipments={inboundshipments}
                  filters={filters}
                  isLoading={isLoading}
                  total={total}
                />
              }
            />


{total > filters.displayView && (
              <Pagination
                style={{ marginTop: "1em" }}
                activePage={filters.currentPage}
                totalPages={Math.ceil(total / filters.displayView)}
                boundaryRange={1}
                siblingRange={1}
                firstItem={null}
                lastItem={null}
                onPageChange={(e, { activePage }) =>this.handleUpdateFilter({value:activePage,name:"currentPage"})}
              />
            )}
          </Segment>
        </div>
      // </CanViewPage>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    inboundshipments: reduxState.inboundshipments.list,
    total: reduxState.inboundshipments.total,
    isLoading: reduxState.inboundshipments.isLoading,
    products: reduxState.inventory.products,
    warehouses: reduxState.inventory.warehouses,
    filters: reduxState.inboundshipments.filters,
    businessclients: reduxState.businessclients.list,
    user: reduxState.user,
    syslang:reduxState.user.currentUser.sys_lang
  };
}
export default connect(mapStateToProps, {
  getShipments,
  getWarehouses,
  getProductList,
  updateFilter,
  setAsProcessed
  
})(Inboundparcels);
