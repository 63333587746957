const companies = (state = [], action) => {
  switch (action.type) {
    case "GET_COMPANIES":
      return [...action.data];

    case "CREATE_CO":
      let newList = [...state];
      newList.push(action.company);
      return [...newList];

    default:
      return state;
  }
};
export default companies;
