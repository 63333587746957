import React, { useState } from "react";
import { Icon, Button, Divider, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Drawer,Dropdown } from "rsuite";
import lang from '../../lang/config.json'
import { logOutAction, signInAction } from "../../actions/auth";
import { updateUser } from "../../actions/user";


function TopNavUser({ user, logOutAction,mobile=false,syslang ,updateUser}) {
  const handleLogOut = () => {
    logOutAction();
  };

  const [isDrawerOpen, setDrawer] = useState(false);
  const openDrawer = () => setDrawer(true);
  const closeDrawer = () => setDrawer(false);

  const setSyslanguage =(value)=>
  {
    updateUser({'settings.sys_lang':value})
  }
  // useEffect(() => {
  //   console.log(user);
  //   if (!user.isAuthenticated) {
  //     console.log(user);
  //     history.push("/signin");
  //   }
  // }, [user.isAuthenticated]);

  return (
    <>
      <div 
      // className="top-nav--user"
      >
        <Dropdown
        onSelect={(eventKey)=>setSyslanguage(eventKey)}
        title={user.currentUser.sys_lang}>
          
          <Dropdown.Item eventKey="ENG">ENG</Dropdown.Item>
          <Dropdown.Item eventKey="RO">RO</Dropdown.Item>
          <Dropdown.Item eventKey="CZ">CZ</Dropdown.Item>
          <Dropdown.Item eventKey="SK">SK</Dropdown.Item>
          <Dropdown.Item eventKey="RU">RU</Dropdown.Item>
        </Dropdown>
        {/* <span className="user--text"> */}
        <Button
          circular
          compact
          size="huge"
          // color="blue"
          icon="user outline"
          // className="user--icon"
          onClick={openDrawer}
          style={{ marginRight: 0 }}
        />
        {/* </span> */}

        {/* Hi,{user.currentUser.username} */}
      </div>
      
      
      <Drawer show={isDrawerOpen} onHide={closeDrawer} full={mobile}>
        <Drawer.Header>
          <div className="flex--left">
            <Icon
              circular
              inverted
              compact
              color="grey"
              size="large"
              name="user outline"
              style={{ marginRight: "1em" }}
            />
            <div>
              <Header as="h6" style={{ marginBottom: "0.2em" }}>
                {lang.topbar.hi[syslang]}, {user.currentUser.username}
              </Header>
              <Link
                className="user--logout small--text"
                to="/signin"
                onClick={() => {
                  handleLogOut();
                  closeDrawer();
                }}
              >
                <Icon name="log out" className="user--icon" />
                Log Out
              </Link>
            </div>
          </div>
        </Drawer.Header>
        <Divider />

        <Drawer.Body>
          <div>
            {lang.topbar.nonotification[syslang]}
          </div>
          {/* <ul className="user-profile-drawer-list">
            <li>
              <Link to="/user-profile-settings" onClick={closeDrawer}>
                <Icon name="setting" /> Settings
              </Link>
            </li>
          </ul> */}
        </Drawer.Body>

        <Drawer.Footer>
          <Button onClick={closeDrawer}>{lang.topbar.closebutton[syslang]}</Button>
        </Drawer.Footer>
      </Drawer>
    </>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  syslang:state.user.currentUser.sys_lang

});

export default withRouter(
  connect(mapStateToProps, { logOutAction, signInAction ,updateUser})(TopNavUser)
);
