import React from "react";
import { Dropdown } from "semantic-ui-react";

import { countries } from "../../countries";

function CountryFilter({
  value,
  handleChange,
  name = "country",
  placeholder = "country...",
  multiple = false,
  fluid = false,
  selection = true,
  error = false,
  className = "",
}) {
  const options = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  return (
    <Dropdown
      className={className}
      autoComplete="nope"
      placeholder={placeholder}
      name={name}
      value={value}
      multiple={multiple}
      fluid={fluid}
      error={error}
      clearable
      selection={selection}
      search
      selectOnBlur={false}
      closeOnChange={true}
      onChange={handleChange}
      options={options}
    />
  );
}

export default CountryFilter;
