/* eslint-disable default-case */
import React, { useReducer,  } from "react";
import { Segment, } from "semantic-ui-react";
import Img from "react-image";
import ImgsViewer from "react-images-viewer";
import ImageSlider from "./ImageSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const initialState = {
  executeChildMethod: false,
  editMode: false,
  // photos: [],
  shipmentOpen: false,
  newShipment: true,
  isLightBoxOpen: false,
  currentImg: 0,
  imgURLs: [],
  hasUpdates: false,
  
};

function reducer(state, action) {
  switch (action.type) {

    case "VIEW_PHOTO":
      const imageURLs =
        action.payload.files && action.payload.files.length > 0
          ? action.payload.files.map(({ publicurl }) => ({
              src: publicurl
            }))
          : [];
      return {
        ...state,
        imgURLs: imageURLs,
        currentImg: action.payload.index,
        isLightBoxOpen: true,
        updates:{
          ...state.updates,
          removedFiles:[]
        }
      };

    case "GO_TO_NEXT":
      if (state.currentImg < state.imgURLs.length)
        return { ...state, currentImg: state.currentImg + 1 };
      else return state;

    case "GO_TO_PREVIOUS":
      if (state.currentImg > 0) return { ...state, currentImg: state.currentImg - 1 };
      else return state;

    case "CLOSE_LIGHTBOX":
      return { ...state, isLightBoxOpen: false };

    }
}

function Images(props) {
  const { openShipment,isLoading } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  


  
  const files = openShipment.photos;
  const images =
    files && files.length > 0
      ? files.map((photo, index) => {
          
          return (
            <div >

            <div style={{margin:'10px'}}>
              <Img
                
                onClick={() =>
                  dispatch({ type: "VIEW_PHOTO", payload: { index, files } })
                }
                src={photo.publicurl}
                alt="text"
              />
      
              
            </div></div>
          );
        })
      : [];

  return (
    <Segment loading={isLoading}>
      <div className="inbound_photosx">
        
        {images.length > 0 && (
          <div>
            <ImageSlider images={images} />
                
           </div>
        )}
        
        {state.imgURLs.length > 0 && (
          <ImgsViewer
            imgs={state.imgURLs}
            currImg={state.currentImg}
            isOpen={state.isLightBoxOpen}
            onClickPrev={() => dispatch({ type: "GO_TO_PREVIOUS" })}
            onClickNext={() => dispatch({ type: "GO_TO_NEXT" })}
            onClose={() => dispatch({ type: "CLOSE_LIGHTBOX" })}
            showThumbnails={true}
            backdropCloseable={true}
            preloadNextImg={false}
            // customCtrls={['test']}
            // closeBtnTitle={'CLOSE'}
          />
        )}
       

      </div>
    </Segment>
  );
}

export default Images;
