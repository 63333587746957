const initialState = {
  list: [],
  filters: {
    status: "",
    businessclient: "",
    currentPage: 1,
    itemsPerPage: 10,
  },
  total: null,
  openTransfer: {},
  isLoading: false,
};

const transfers = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRANSFERS":
      return {
        ...state,
        list: action.payload.list,
        total: action.payload.total,
        isLoading: false,
      };
    case "UPDATE_TRANSFER_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };

    case "GET_TRANSFER":
      return { ...state, openTransfer: {...action.payload.foundTransfer,
                                      ordersCount:action.payload.ordersCount}, isLoading: false };
    case "CREATE_TRANSFER":
      return { ...state, list: [...state.list, action.payload] };
    case "UPDATE_TRANSFER":
      return { ...state, isLoading: false, openTransfer: action.payload };

    case "START_LOADING":
      return { ...state, isLoading: true };
    case "STOP_LOADING":
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
export default transfers;
