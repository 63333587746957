import React from "react";
import Moment from "react-moment";
import EditableText from "../../EditableText";

function ShipmentInfo(props) {
  const {
    openShipment,lang,user
  } = props;

  
  return (
    <div className="edit-inbound-shipment__grid">
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.warehouse[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="warehouse..."
            name="warehouse"
            value={openShipment.warehouse.name}
            editable={false}
          />
        </div>
        {/* <div className="edit-inbound-shipment__field">
          <label>BusinessClient:</label>
          <EditableText
            placeholder="client..."
            name="businessclient"
            value={openShipment.businessclient}
            
          />
        </div> */}
        
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.transport[user.currentUser.sys_lang]}</label>
          <EditableText
            placeholder="transporter company ..."
            name="transporter"
            value={openShipment.transporter}
                     editable={false}

          />
        </div>
       
      </div>
      <div className="edit-inbound-shipment__col">
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.updatedAt[user.currentUser.sys_lang]}</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.updatedAt}</Moment>
          </span>
        </div>
        <div className="edit-inbound-shipment__field">
          <label>{lang.table.createdAt[user.currentUser.sys_lang]}</label>
          <span>
            <Moment format="DD/MM/YYYY hh:mm">{openShipment.createdAt}</Moment>
          </span>
        </div>
      </div>
    </div>
  );
}

export default (ShipmentInfo);
