import React from "react";
import { Segment, Label, Divider } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

import { statusColor } from "./ShipmentTable";

function MobileItemCard({ item }) {
  const {
    _id,
    warehouse,
    seller,
    client,
    nrOfPacks,
    weightKg,
    status,
    transporter,
    createdAt,
    updatedAt,
  } = item;

  return (
    <Segment>
      <div className="order-card">
        <Link to={`/parcels/inbound/${_id}`} className="x-large--text">
          {_id.substr(-9)}
        </Link>

        <div style={{ marginTop: "0.5em" }}>
          <Label color={statusColor[status]}>{status}</Label>
        </div>

        <Divider />
        <div className="order-card--grid">
          <span className="small--text">Date:</span>
          <div>
            <span className="small--text">Updated at:</span>{" "}
            <Moment
              style={{ whiteSpace: "pre", paddingLeft: "0.7em" }}
              className="grey--text bold--text small--text"
              format="DD-MM-YY HH:mm"
            >
              {updatedAt}
            </Moment>
            <br />
            <span className="small--text">Created at:</span>{" "}
            <Moment
              style={{ whiteSpace: "pre", paddingLeft: "1em" }}
              className="grey--text bold--text small--text"
              format="DD-MM-YY HH:mm"
            >
              {createdAt}
            </Moment>
          </div>

          <span>Warehouse:</span>
          <div className="large--text dark-blue--text">
            {warehouse ? warehouse.name : "NA"}
          </div>

          <span>Supplier:</span>
          <p className="large--text dark-blue--text">
            {seller ? seller.name : "NA"}
          </p>

          <span>Client:</span>
          <p className="large--text dark-blue--text">
            {client ? client.name : "NA"}
          </p>

          <span className="small--text" style={{ paddingTop: "1em" }}>
            Details:
          </span>
          <div className="expanded-product-card--grid expanded-product-card--grid_codes">
            {nrOfPacks && (
              <>
                <span className="product-row__label">Nr. Of Packs:</span>
                <span className="">{nrOfPacks}</span>
              </>
            )}
            {weightKg && (
              <>
                <span className="product-row__label">Weight (kg):</span>
                <span className="">{weightKg}</span>
              </>
            )}
          </div>

          <span>Transporter:</span>
          <p>{transporter ? transporter : "NA"}</p>
        </div>
      </div>
    </Segment>
  );
}

export default MobileItemCard;
