import React from "react";
import {  Label, Button } from "semantic-ui-react";

import { countries } from "../Filters/data";
import FormField from "./FormField";

function EditClientIds({
  country,
  coid,
  taxid,
  i,
  bclientId,
  id,
  deleteClientIds,
  updateClientIds,
  syslang,lang,editable=false
}) {
  const countriesOptions = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  const handleUpdate = (name, value) =>
    updateClientIds({
      clientId: bclientId,
      id,
      update: { [name]: value },
    });

  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
        <br />
        {/* <Button
          compact
          circular
          basic
          className="list-item__delete-btn"
          size="small"
          icon="trash"
          onClick={() => deleteClientIds({ clientId: bclientId, id })}
        /> */}
      </div>

      <div className="view-grid">
        <FormField
          editable={editable}
          label={lang.table.country[syslang]}
          name="country"
          placeholder="country ..."
          value={country}
          handleChange={handleUpdate}
          options={countriesOptions}
        />

        <FormField
          editable={editable}
          label={lang.settings.company.companyid[syslang]}
          name="coid"
          placeholder="company ID ..."
          value={coid}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.settings.company.taxid[syslang]}
          name="taxid"
          placeholder="TAX ID ..."
          value={taxid}
          handleChange={handleUpdate}
        />
      </div>
      {/* <Divider /> */}
    </div>
  );
}

export default EditClientIds;
