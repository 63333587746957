import React, { Component } from "react";
import { Segment, Dropdown, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import {
  getShipments,
  updateFilter,
} from "../../../actions/inboundshipment";
import { getWarehouses } from "../../../actions/inventory";
import { getCompanies } from "../../../actions/company";
import { getProductList } from "../../../actions/product";

import Responsive from "../../Responsive";
import MobileFilters from "../../MobileFilters";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
// import CardsList from "../../CardsList";
import { Shipmentlist } from "./ShipmentTable";
import MobileInboundFilters from "./MobileInboundFilters";
// import MobileItemCard from "./MobileItemCard";
import Filters from "./Filters";
import "../inbound.scss";
import lang from '../../../lang/config.json'
import ExportButton from "./ExportButton";

class ListInboundShipment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        // product: "",
        warehouse: "",
        status: ""
      }
    };

    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.updateFiltersAndGetShipment = this.updateFiltersAndGetShipment.bind(
      this
    );
  }

  updateFiltersAndGetShipment(filters) {
    const updateFilter = new Promise((resolve, reject) =>
      resolve(this.props.updateFilter(filters))
    );
    updateFilter.then(() => this.props.getShipments(this.props.filters));
  }

  onChangePage(page) {
    this.updateFiltersAndGetShipment({ currentPage: page });
  }

  handleUpdateFilter({ value, name }) {
    this.updateFiltersAndGetShipment({ [name]: value });
  }

  componentDidMount() {
    const { filters } = this.props;
    this.props.getShipments(filters);
    this.props.getWarehouses();
    this.props.getProductList({});
    this.props.getCompanies();
  }

  render() {
    const {
      updating,
      total,
      inboundshipments,
      filters,
      companies,
      warehouses,
      products,
      user,
    } = this.props;

    const loading = <div>Loading...</div>;

    if (updating) return { loading };

    return (
      <>
        <Responsive
          breakpoint={700}
          mobileComponent={
            <InfoBar header={lang.inboundshipments.title[user.currentUser.sys_lang]} filters="true" />
          }
        />

        <div className="inbound page-wrapper">
          <Responsive
            breakpoint={700}
            desktopComponent={<Head header={lang.inboundshipments.title[user.currentUser.sys_lang]}/>}
          />

          {/* <Link to="/shipments/inbound/new">
            <Button color="blue">+ Add Shipment</Button>
          </Link> */}

          {/* <Divider /> */}

          <Responsive
            breakpoint={700}
            mobileComponent={
              <MobileFilters
             
                filters={filters}
                initialFiltersState={{
                  status: [],
                  warehouse: [],
                  seller: [],
                  product: [],
                  dateFrom: null,
                  dateTo: null
                }}
                addFilterAndGetItems={this.updateFiltersAndGetShipment}
              >
                <MobileInboundFilters
                   user={user}
                   lang={lang}
                  companies={companies}
                  warehouses={warehouses}
                />
              </MobileFilters>
            }
            desktopComponent={
                  <Filters
                    user={user}
                    lang={lang}
                    products={products}
                    warehouses={warehouses}
                    companies={companies}
                    filters={filters}
                    handleUpdateFilter={this.handleUpdateFilter}
                    handleDateChange={this.updateFiltersAndGetShipment}
                  />
                 

            }
          />

          <Segment>
            <Responsive
              breakpoint={700}
              mobileComponent={
                <div className="flex--right" style={{ marginBottom: "0.6em" }}>
                  <Dropdown
                    selectOnBlur={false}
                    compact
                    value={filters.displayView}
                    onChange={this.handleUpdateFilter}
                    selection
                    options={[
                      { text: "2", key: "2", value: 2 },
                      { text: "10", key: "10", value: 10 },
                      { text: "30", key: "30", value: 30 },
                      { text: "50", key: "50", value: 50 },
                      { text: "All", key: "All", value: 0 }
                    ]}
                    closeOnChange={true}
                    name="displayView"
                  />
                </div>
              }
            />

            <ExportButton
            
            data={inboundshipments}
            filename='inboundshipments'>


            </ExportButton>
  
                <Shipmentlist
                  lang={lang}
                  syslang={user.currentUser.sys_lang}
                  handleUpdateDate={this.handleUpdateDate}
                  shipments={inboundshipments}
                  filters={filters}
                />


            {total > filters.displayView && (
              <Pagination
                style={{ marginTop: "1em" }}
                activePage={filters.currentPage}
                totalPages={Math.ceil(total / filters.displayView)}
                boundaryRange={1}
                siblingRange={1}
                firstItem={null}
                lastItem={null}
                onPageChange={(e, { activePage }) =>
                  this.onChangePage(activePage)
                }
              />
            )}
          </Segment>
        </div>
      </>
    );
  }
}

function mapStateToProps(reduxState) {
  return {
    inboundshipments: reduxState.inboundshipments.list,
    total: reduxState.inboundshipments.total,
    updating: reduxState.inboundshipments.updating,
    products: reduxState.inventory.products,
    warehouses: reduxState.inventory.warehouses,
    companies: reduxState.companies,
    filters: reduxState.inboundshipments.filters,
    syslang:reduxState.user.currentUser.sys_lang
  }
}
export default connect(mapStateToProps, {
  getShipments,
  getWarehouses,
  getProductList,
  updateFilter,
  getCompanies,
  
})(ListInboundShipment);
