import React, { useEffect } from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";

import { getBills, updateBillsFilters } from "../../../../actions/services";
import Head from "../../../Head";
import CardsList from "../../../CardsList";
import InfoBar from "../../../InfoBar";
import Responsive from "../../../Responsive";
import ItemsPerPage from "../../../ItemsPerPage";
import Pagination from "../../../Filters/Pagination";
import BillsTable from "./BillsTable";
import Filters from "./Filters";
import MobileItemCard from "./MobileItemCard";
import lang from '../../../../lang/config.json'

// import "../../services.scss";

function BillList({
  bills,
  filters,
  isLoading,
  total,
  getBills,
  updateBillsFilters,
  user,
}) {
  useEffect(() => getBills(filters), [filters]);
  const { itemsPerPage, currentPage } = filters;

  const handleFilterChange = (e, { name, value }) =>
    updateBillsFilters({ [name]: value, currentPage: 1 });

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar
            header={lang.bills.title[user.currentUser.sys_lang]}
            // filters={true}
          />
        }
      />
      <main className="page-wrapper">
        <Responsive desktopComponent={<Head header={lang.bills.title[user.currentUser.sys_lang]} />} />

        <Filters
        user={user}
        lang={lang}
          filters={filters}
          handleFilterChange={handleFilterChange}
          updateFilters={updateBillsFilters}
        />
        <Segment>
          <div className="flex--right">
            <ItemsPerPage
              total={total}
              value={itemsPerPage}
              handleChange={handleFilterChange}
              options={[10, 30, 50, "All"]}
            />
          </div>

          <Responsive
            mobileComponent={
              <CardsList
                items={bills}
                isLoading={isLoading}
                notFoundMsg="No Bills Found"
              >
                <MobileItemCard
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                />
              </CardsList>
            }
            desktopComponent={
              <BillsTable
               syslang={user.currentUser.sys_lang}
               lang={lang}
                bills={bills}
                isLoading={isLoading}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
              />
            }
          />

          {total > itemsPerPage && (
            <Pagination
              currentPage={currentPage}
              total={total}
              itemsPerPage={itemsPerPage}
              updateFilter={({ currentPage }) =>
                updateBillsFilters({ currentPage })
              }
            />
          )}
        </Segment>
      </main>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  filters: reduxState.services.bills.filters,
  user: reduxState.user,
  isLoading: reduxState.services.isLoading,
  bills: reduxState.services.bills.list,
  total: reduxState.services.bills.total,
});
export default connect(mapStateToProps, {
  getBills,
  updateBillsFilters,
})(BillList);
