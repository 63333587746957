import React from 'react'
import {Table,Header,Segment} from 'semantic-ui-react'
import {Link} from 'react-router-dom'

function Nogoodstable({data,intranzit}){
   
  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell width={5}>Product name</Table.HeaderCell>
      <Table.HeaderCell width={3}>No Goods Quantity</Table.HeaderCell>
      {/* <Table.HeaderCell width={3}>In tranzit</Table.HeaderCell> */}
      
      {/* <Table.HeaderCell textAlign="right">Action</Table.HeaderCell> */}
    </Table.Row>
  );


  const rows = data.products.map((product,i) =>  {
    let shipping={
      quantity:0,
      pos:[]
    }

    for (const client in intranzit){
        if(client===product.businessclient){
          
          for(const sku in intranzit[client]){
            if(sku===product.sku[0]){
                shipping={
                  quantity:intranzit[client][sku].total,
                  pos:intranzit[client][sku].poIds
                }
                
            }
          }
        }
    }
    return(
      <Table.Row key={product._id}>
        <Table.Cell> {product.name[0]}</Table.Cell>
        <Table.Cell> {product.quantity}</Table.Cell>
        {/* <Table.Cell>
            {shipping.quantity} 
            {shipping.quantity>0 && shipping.pos.map(po=><div><Link to={`/pos/${po}`} target='_blank'>PO-{po.substring(1,6)}</Link></div>)
                }
        </Table.Cell> */}
        {/* <Table.Cell textAlign="right"> x
            </Table.Cell> */}
      </Table.Row>
    )}
  );


  return (
    <Segment primary color="yellow">
      <Header>{data.client[0].name} - {data._id.country}</Header>
      <Table unstackable basic="very"  
             columns={5}>
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{rows}</Table.Body>
      </Table>
      
    </Segment>
  );
}

export default Nogoodstable