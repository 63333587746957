import React, { Component } from "react";
import { Segment, Header, Form } from "semantic-ui-react";

import ItemList from "./ItemList";

class GoodsReceived extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openItem: {
        _id: "",
        stockMovement: {
          inventory: {
            _id: "",
            product: { name: "" },
            photos: {
              files: []
            }
          }
        }
      }
    };
  }


  render() {
    const {  goodsreceived=[], updating } = this.props;
    return (
      <Form loading={updating}>
        <section className="inbound-goodsreceived">
         

          <Segment padded>
            <div className="flex--between">
              {/* <Header as="h3">Items</Header> */}
              {/* <Button onClick={this.handleAddNew}>Add Item</Button> */}
            </div>

            {goodsreceived.length > 0 && goodsreceived[0]._id && (
              <ItemList
                goodsreceived={goodsreceived}
                handleViewPhotos={this.props.handleViewPhotos}
              />
            )}
          </Segment>
        </section>
      </Form>
    );
  }
}

export default (GoodsReceived);
