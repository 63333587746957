import React, { useEffect } from "react";
import { Icon, Segment, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import Modal from "react-responsive-modal";
import {Button,Label}from 'semantic-ui-react'
import {
  getShipment,setAsProcessed
} from "../../../actions/inboundparcel";
import ShipmentInfo from "./ShipmentInfo";
import Images from "./Images";
// import Items from "./Items";
import GoodsReceived from "../Items/GoodsReceived";
// import CanViewPage from "../../../role-based-access/CanViewPage";
import lang from '../../../lang/config.json'

// import "../inbound.scss";
const statusColor={
  'New':"red",
  "Processed":"green"
}
function Inboundparcel(props) {
  const {
    getShipment,
    setAsProcessed,
    openShipment,
    match,
    user,
    isLoading
  } = props;


  
  useEffect(() => {
    getShipment(match.params.id)
  }, [getShipment, match.params.id]);

 
  return (
    // <CanViewPage path="/parcels/inbound/:id">
      
      <main className="page-wrapper a-wrapper">
        <div className='flex--between'>
              <Link to="/parcels/inbound/">
                <Icon name="arrow left" /> {lang.inboundparcel.backlink[user.currentUser.sys_lang]}
              </Link>
             {openShipment.status!=="Processed"&&
              <Button
                    onClick={()=>setAsProcessed(openShipment._id)}
                  >{lang.inboundparcel.setasprocessedbutton[user.currentUser.sys_lang]}</Button>
                }

      </div>

        <Segment loading={isLoading} padded>
          <div className="flex--between">
            <div>
            <Label
                color={statusColor[openShipment.status]}
                ribbon
                // size="tiny"
                style={{ transform: "translate(-7px, -2px)" }}
              >
                {openShipment.status}
              </Label>
              <Header as="h3" className="edit-inbound-shipment__header">
              {lang.inboundparcel.title[user.currentUser.sys_lang]}
              </Header>
              {openShipment._id && (
                <div className="smallinfo">ID: {openShipment._id}</div>
              )}
            </div>
           
          </div>

          <ShipmentInfo
          user={user}
          lang={lang}
            openShipment={openShipment}
          />

          {openShipment.order&&<div>
            
            <div>Related order </div>
              <Link to={`/orders/${openShipment.order._id}`}>{openShipment.order.clientOrderNr}</Link>
            </div>}
         </Segment>

        <Images openShipment={openShipment}
                          isLoading={isLoading}
                          />
        <GoodsReceived
        user={user}
        lang={lang}
          openShipment={openShipment}
          goodsreceived={openShipment.goodsreceived}
          id ={match.params.id}
          isLoading={isLoading}
        />
      </main>
    // </CanViewPage>
  );
}

const mapStateToProps = (reduxState) => ({
      openShipment: reduxState.inboundshipments.openShipment,
      isLoading: reduxState.inboundshipments.isLoading,
      user: reduxState.user


});

export default connect(mapStateToProps, {getShipment,setAsProcessed})(Inboundparcel);
