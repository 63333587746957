const initstate = {
  list: [],
  total: 0,
  openPo: {
    shipTo: { address: { country: "" } },
    customer: { name: "", address: { country: "" } },
    supplier: { name: "", address: { country: "", city: "", street: "" } },
    shipment: "",
    status: "",
    tracking: {
      shippedDate: "",
      deliveredDate: "",
      emailed_supplier: ""
    },
    items: [],
    documents: []
  },
  uploading: false,
  listLoading: false
};

const purchaseOrders = (state = initstate, action) => {
  switch (action.type) {
    case "GET_POS":
      return { ...state, list: action.pos.list, total: action.pos.total };
    case "GET_PO":
      return { ...state, openPo: action.po };

    case "DELETE_PO":
      let deletePoList = [...state.list];
      if (state.list) {
        for (let i = 0; i < deletePoList.length; i++) {
          if (action.id === deletePoList[i]._id) {
            deletePoList.splice(i, 1);
          }
        }
      }

      return {
        ...state,
        list: deletePoList,
        total: state.total - 1
      };

    case "CREATE_PO":
      const newList = [...state.list];
      newList.unshift(action.data);
      return {
        ...state,
        listLoading: false,
        list: newList
      };
    case "UPDATE_PO":
      let updatedList = [];
      if (state.list) {
        updatedList = state.list.map(po => {
          if (po._id === action.updatedPo._id) {
            po = action.updatedPo;
          }
          return po;
        });
      }

      //find updated item and replace

      return {
        ...state,
        openPo: action.updatedPo,
        list: updatedList
      };
    case "LIST_LOADING":
      return {
        ...state,
        listLoading: true
      };
    case "STOP_LIST_LOADING":
      return {
        ...state,
        listLoading: false
      };
    case "UPLOADING_PO_DOCUMENTS":
      return { ...state, uploading: true };
    case "ADD_DOCUMENTS_POS":
      return {
        ...state,
        uploading: false,
        openPo: { ...state.openPo, documents: action.documents }
      };
    default:
      return state;
  }
};

export default purchaseOrders;
