import React, { useEffect } from "react";
import { Segment } from "semantic-ui-react";
import { connect } from "react-redux";

import { getCouriers } from "../../../actions/couriers";
import Head from "../../Head";
import CardsList from "../../CardsList";
import Responsive from "../../Responsive";
import InfoBar from "../../InfoBar";
import CourierTable from "./CourierTable";
import Filters from "./Filters";
import MobileItemCard from "./MobileItemCard";
import "../courier.scss";

function CourierList({ getCouriers, couriers, filters, isLoading = false }) {
  useEffect(() => getCouriers(filters), [getCouriers, filters]);

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar
            header="Couriers"
            // filters={true}
          />
        }
      />
      <main className="page-wrapper">
        <Responsive desktopComponent={<Head header="Couriers" />} />

        <Segment>
          <Filters />
        </Segment>

        <Responsive
          mobileComponent={
            // <Segment>
            <CardsList
              items={couriers}
              isLoading={isLoading}
              notFoundMsg="No Couriers Found"
            >
              <MobileItemCard />
            </CardsList>
            // </Segment>
          }
          desktopComponent={
            <Segment padded>
              <CourierTable couriers={couriers} />
            </Segment>
          }
        />
      </main>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  couriers: reduxState.couriers.list,
  filters: reduxState.couriers.filters,
  isLoading: reduxState.couriers.isLoading,
});

export default connect(mapStateToProps, { getCouriers })(CourierList);
