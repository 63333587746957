import React from "react";
import {
  Header,
  Table,
  Dimmer,
  Loader,
  

} from "semantic-ui-react";
import Moment from "react-moment";

import ExportButton from "../../ExportButton";

function TransfersTable({
  transfers,
  isLoading,
  currentPage,
  itemsPerPage,
  syslang,lang
}) {
  if (isLoading)
    return (
      <div style={{ paddingTop: "4em", paddingBottom: "4em" }}>
        <Dimmer inverted active>
          <Loader />
        </Dimmer>
      </div>
    );

  if (!transfers.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
      
      {lang.codtransffers.notransffers[syslang]}   

   </Header>
    )
  }

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell>#</Table.HeaderCell>
      {/* <Table.HeaderCell>Businessclient</Table.HeaderCell> */}
      <Table.HeaderCell>Id</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.bankaccount[syslang]}   </Table.HeaderCell>
      <Table.HeaderCell>{lang.table.amount[syslang]}   </Table.HeaderCell>
      <Table.HeaderCell>{lang.table.status[syslang]}   </Table.HeaderCell>
      <Table.HeaderCell>{lang.table.dates[syslang]}   </Table.HeaderCell>
      <Table.HeaderCell>.</Table.HeaderCell>
    </Table.Row>
  );

  const tableBody = transfers.map(
    (
      {
        _id,
        tranfferNr,
        toBankAccount,
        amount,
        currency,
        status,

        createdAt,
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell>{(currentPage - 1) * itemsPerPage + i + 1}</Table.Cell>
        {/* <Table.Cell>{businessclient && businessclient.name}</Table.Cell> */}
        <Table.Cell>
          {tranfferNr}
        </Table.Cell>
        <Table.Cell>
          {toBankAccount && (
            <>
              <div>
                <span className="grey--text small--text transfers-bank-labels">
                    {lang.table.bankaccount[syslang]} 
  
                </span>{" "}
                {toBankAccount.accountNr} / {toBankAccount.bankCode}
              </div>
              
              {toBankAccount.IBAN&&<div>
                <span className="grey--text small--text transfers-bank-labels">
                  IBAN
                </span>{" "}
                {toBankAccount.IBAN}
              </div>}
             {toBankAccount.SWIFT&& <div>
                <span className="grey--text small--text transfers-bank-labels">
                  SWIFT
                </span>{" "}
                {toBankAccount.SWIFT}
              </div>}
            </>
          )}
        </Table.Cell>
        
        <Table.Cell>{amount.toFixed(2)} {currency}</Table.Cell>
        <Table.Cell>{status}</Table.Cell>
        <Table.Cell>
          <Moment format="DD/MM/YYYY">{createdAt}</Moment>
        </Table.Cell>
            <ExportButton
                  fileName={`${tranfferNr}`}
                  // total={30}
                  // filters={filters}
                  // mapData={mapData}
                  url={`/transffers/${_id}/orders/export`}
                />
      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very" selectable>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default TransfersTable;
