import React, { useEffect, useState } from "react";
import { apiCall } from "../../../services/api";
import { Alert, Input, Toggle, Message, InputGroup } from "rsuite";
import { Header, Segment, Button, Divider, Label } from "semantic-ui-react";
import woo from "./woo.png";
import Modal from "react-responsive-modal";
import lang from "../../../lang/config.json";

import { connect } from "react-redux";

const Woosettings = (props) => {
  const { syslang } = props;
  const { id } = props.match.params;
  const [eshop, setEshop] = useState(null);

  const [consumer_key, setconsumer_key] = useState();
  const [consumer_secret, setconsumer_secret] = useState();
  const [received_status, setreceived_status] = useState();
  const [shopUrl, setUrl] = useState();
  const [isChanged, setIschanged] = useState(false);
  const [newPass, setNewpass] = useState(false);
  const [loading, setIsloading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const updateCredentials = () => {
    setIschanged(false);
    setNewpass(false);

    const url = `${process.env.REACT_APP_API}/eshops/woo/${id}`;

    apiCall("put", url, { consumer_key, consumer_secret, shopUrl })
      .then((resp) => {
        Alert.success("Updated success");
        setEshop(resp);
        setreceived_status(resp.received_status);
        setconsumer_key(resp.woo_settings.consumer_key);
      })
      .catch((error) => Alert.error(error));
  };

  const testConnection = () => {
    setIsloading(true);
    const url = `${process.env.REACT_APP_API}/eshops/woo/${id}/test`;
    apiCall("get", url)
      .then((resp) => {
        Alert.success("connection good");
        setIsloading(false);

        setEshop({
          ...eshop,
          woo_settings: { ...eshop.woo_settings, is_setUp: true },
        });
      })
      .catch((error) => {
        setIsloading(false);
        Alert.error(`${error.message}`, 10000);
      });
  };

  const importProducts = () => {
    setIsloading(true);
    const url = `${process.env.REACT_APP_API}/eshops/woo/${id}/importproducts`;
    apiCall("get", url)
      .then((resp) => {
        Alert.success(`Imported new products: ${resp.imported}`, 10000);
        setIsloading(false);
        // setEshop(resp);
      })
      .catch((error) => {
        setIsloading(false);
        Alert.error(`${error.message}`, 10000);
      });
  };

  const updateEshop = async (query) => {
    try {
      const url = `${process.env.REACT_APP_API}/eshops/woo/${id}`;

      apiCall("put", url, query)
        .then((resp) => {
          Alert.success(`Updated...`);
          setIsloading(false);
          setEshop(resp);
        })
        .catch((error) => {
          setIsloading(false);
          Alert.error(`${error.message}`, 10000);
        });
    } catch (error) {
      Alert.error(error.message, 5000);
    }
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API}/eshops/woo/${id}`;
    apiCall("get", url)
      .then((resp) => {
        setEshop(resp);
        setUrl(resp.eshopUrl);
        setconsumer_key(resp.woo_settings.consumer_key);
        setreceived_status(resp.received_status);
      })
      .catch((error) => Alert.error(error));
  }, [id]);

  const viewImg = (
    <Modal
      open={isModalOpen}
      showCloseIcon={true}
      closeOnEsc={true}
      onClose={() => {
        setModalOpen(false);
      }}
      center
      classNames={{
        modal: "",
      }}
    >
      <img style={{ cursor: "zoom-in" }} src={woo} alt="help"></img>
    </Modal>
  );
  if (!eshop) return <div>loading...</div>;
  return (
    <div>
      {viewImg}
      <Header
        as="h2"
        style={{
          marginLeft: "20px",
          marginTop: "20px",
        }}
      >
        {lang.settings.eshops.woo.settings[syslang]}
      </Header>

      <div className="flex--left" style={{ marginTop: "30px" }}>
        <section style={{ padding: "20px", maxWidth: "800px" }}>
          <Segment padded>
            <div className="flex--between">
              <h6>{lang.settings.eshops.woo.apiconnection[syslang]}</h6>

              <div>
                {!eshop.woo_settings.is_setUp && (
                  <Button
                    color="orage"
                    loading={loading}
                    disabled={loading}
                    onClick={() => testConnection()}
                  >
                    {lang.settings.eshops.woo.step1[syslang]}
                  </Button>
                )}
                {eshop.woo_settings.is_setUp && (
                  <Label color="green">
                    {lang.settings.eshops.woo.connected[syslang]}
                  </Label>
                )}
              </div>
            </div>
            <Divider></Divider>

            <div>
              <label>Eshop Url:</label>
              <InputGroup>
                <InputGroup.Addon>https://www.</InputGroup.Addon>
                <Input
                  value={shopUrl}
                  onChange={(value) => {
                    setIschanged(true);
                    setUrl(value);
                  }}
                />
              </InputGroup>

              <label>consumer_key:</label>
              <Input
                size="md"
                placeholder="consumer_key"
                name="consumer_key"
                value={consumer_key}
                onChange={(value) => {
                  setIschanged(true);
                  setconsumer_key(value);
                }}
              ></Input>
            </div>
            <div>
              <label>consumer_secret:</label>
              {newPass && (
                <Input
                  size="md"
                  placeholder="consumer_secret"
                  onChange={(value) => {
                    setIschanged(true);
                    setconsumer_secret(value);
                  }}
                ></Input>
              )}
              <br></br>
              {!newPass && (
                <button onClick={() => setNewpass(true)}>change</button>
              )}
            </div>

            {isChanged && (
              <div className="flex--between">
                <div></div>
                <Button color="blue" onClick={() => updateCredentials()}>
                  Update
                </Button>
              </div>
            )}
          </Segment>

          <Segment padded disabled={!eshop.woo_settings.is_setUp}>
            <div className="flex--between">
              <h6>{lang.settings.eshops.woo.products[syslang]}</h6>
            </div>
            <Divider></Divider>

            <section>
              <div style={{ marginBottom: "10px" }}>
                <Toggle
                  disabled={!eshop.woo_settings.is_setUp}
                  checked={eshop.woo_settings.match_product_to_id}
                  onChange={() => {
                    updateEshop({
                      "woo_settings.match_product_to_id":
                        !eshop.woo_settings.match_product_to_id,
                      "woo_settings.match_product_to_sku":
                        eshop.woo_settings.match_product_to_id === false
                          ? false
                          : eshop.woo_settings.match_product_to_sku,
                    });
                  }}
                />
                <span>{lang.settings.eshops.woo.matchid[syslang]}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Toggle
                  disabled={!eshop.woo_settings.is_setUp}
                  checked={eshop.woo_settings.match_product_to_sku}
                  onChange={() => {
                    updateEshop({
                      "woo_settings.match_product_to_sku":
                        !eshop.woo_settings.match_product_to_sku,
                      "woo_settings.match_product_to_id":
                        eshop.woo_settings.match_product_to_sku === false
                          ? false
                          : eshop.woo_settings.match_product_to_id,
                    });
                  }}
                />
                <span>{lang.settings.eshops.woo.matchsku[syslang]}</span>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <Toggle
                  disabled={!eshop.woo_settings.is_setUp}
                  checked={eshop.update_stock_balance}
                  onChange={() => {
                    updateEshop({
                      update_stock_balance: !eshop.update_stock_balance,
                    });
                  }}
                />
                <span>{lang.settings.eshops.woo.stockbalance[syslang]}</span>
              </div>
            
            </section>

            <Divider></Divider>
            <div>
              <Button
                disabled={loading || !eshop.woo_settings.is_setUp}
                loading={loading}
                onClick={() => importProducts()}
              >
                {lang.settings.eshops.woo.step2[syslang]}
              </Button>
            </div>
          </Segment>
          <Segment>
            <Message
              type={!eshop.woo_settings.get_orders_api ? "warning" : "success"}
              // title="Descarcarea automat de comenzi noi"
              description={
                <div className="">
                  <Toggle
                    disabled={!eshop.woo_settings.is_setUp}
                    checked={eshop.woo_settings.get_orders_api}
                    onChange={() =>
                      updateEshop({
                        "woo_settings.get_orders_api":
                          !eshop.woo_settings.get_orders_api,
                      })
                    }
                  />
                  <span>
                    {lang.settings.eshops.woo.ordersimportmsg[syslang]}
                  </span>

                  <div style={{ marginTop: "10px" }}>
                    <span>Woo status for received orders</span>
                    <Input
                      // disabled={!eshop.woo_settings.is_setUp}
                      size="md"
                      placeholder="woo status..."
                      name="received_status"
                      value={received_status}
                      onChange={(value) => {
                        console.log(value);
                        setreceived_status(value);
                      }}
                    ></Input>
                    <Button
                      // disabled={!eshop.woo_settings.is_setUp}
                      onClick={() => {
                        updateEshop({ received_status });
                      }}
                    >
                      update
                    </Button>
                  </div>

                  <div style={{ marginBottom: "10px" }}>
                <Toggle
                  disabled={!eshop.woo_settings.is_setUp}
                  checked={eshop.wyzbox_status_update}
                  onChange={() => {
                    updateEshop({
                      wyzbox_status_update: !eshop.wyzbox_status_update,
                    });
                  }}
                />
                <span>Update eshop with Wyzbox Statuses (need admin status list)</span>
              </div>
              
                </div>
              }
            />
          </Segment>
        </section>
      </div>
    </div>
  );
};

function mapStateToProps(reduxState) {
  return {
    syslang: reduxState.user.currentUser.sys_lang,
  };
}
export default connect(mapStateToProps, {})(Woosettings);
