import React from "react";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { Button, Icon } from "semantic-ui-react";

// import { invoice } from "./invoiceTemplate";
import { getInvoice } from "../../../actions/orders";
import Invoice from "./Invoice";

function GenerateInvoice({ invoice, getInvoice, match }) {
  const toPrint = React.useRef(null);
  console.log(toPrint.current);

  React.useEffect(() => {
    getInvoice(match.params.id);
  }, []);

  function createPdfInvoice() {
    //   html2canvas(invoice).then(function(canvas) {
    //     document.body.appendChild(canvas);
    //   });

    // html2pdf(document.getElementById("invoice"))
    //   .then(res => {
    //     console.log(res);
    //     // const pdfBlob = new Blob([res.data], { type: "application/pdf" });
    //     // console.log(pdfBlob);
    //   })
    //   .catch(err => console.log(err));
    const opt = {
      filename: "invoice.pdf",
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 4 },
      jsPDF: { unit: "mm", format: "A4", orientation: "portrait" },
      enableLinks: true,
    };

    const invoice = document.getElementById("invoice");

    html2pdf()
      .set(opt)
      .from(invoice)
      .outputPdf("dataurlstring")
      .then((data) => console.log(data))
      .save();

    // var doc = new jsPDF();

    // doc.html(invoice, {
    //   callback: function(doc) {
    //     doc.autoPrint({ variant: "non-conform" });
    //     doc.save();
    //   }
    // });
  }
  return (
    <>
      <div style={{ textAlign: "center", margin: "0.5em" }}>
        <Button onClick={createPdfInvoice}>
          <Icon name="download" />
          Download
        </Button>
      </div>
      <div style={{ display: "block" }}>
        <Invoice ref={toPrint} invoice={invoice} />
      </div>
    </>
  );
}

const mapStateToProps = (reduxState) => ({
  invoice: reduxState.orders.invoice,
});

export default connect(mapStateToProps, {
  getInvoice,
})(GenerateInvoice);
