import React from "react";
import { Table, Loader, Header, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Moment from "react-moment";

// import CreateUpdateTime from "../CreateUpdateTime";
export const label = { true: "green", false: "yellow" };
const status = { true: "Paid", false: "Not paid - In due" };

function BillsTable({
  bills,
  isLoading,
  currentPage,
  itemsPerPage,
syslang,
lang}) {
  // const handleCheck =(e,{checked,_id})=>{

  //   updateBill(_id,{
  //             isPaid:checked,
  //             status:paid[checked]
  //           })
  // }
  if (isLoading)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Loader active inline="centered" />
      </div>
    );

  if (!isLoading && !bills.length)
    return (
      <div style={{ marginTop: "2em", marginBottom: "2em" }}>
        <Header as="h4" textAlign="center">
          .....
        </Header>
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="center">#</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.nr[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>PDF</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.dates[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.total[syslang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.table.currency[syslang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>Country</Table.HeaderCell> */}
      {/* <Table.HeaderCell>{lang.table.items[syslang]}</Table.HeaderCell> */}
      <Table.HeaderCell>{lang.table.status[syslang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>Payment Status</Table.HeaderCell> */}
    </Table.Row>
  );

  const tableBody = bills.map(
    (
      {
        _id,
        country,
        dates,
        sequenceNumber,
        currency,
        period,
        totals,
        isPaid,
      },
      i
    ) => (
      <Table.Row key={_id}>
        <Table.Cell textAlign="center">
          {(currentPage - 1) * itemsPerPage + i + 1}
        </Table.Cell>
        <Table.Cell>
          <Link to={`/billing/bills/${_id}`}>{ sequenceNumber}</Link> <br />
          {period && period.start && period.end && (
            <>
              <Moment utc format="DD.MM.YYYY">{period.start}</Moment>-
              <Moment utc format="DD.MM.YYYY">{period.end}</Moment>
            </>
          )}
        </Table.Cell>
        <Table.Cell> <Link to={`/bill/${_id}`}>view</Link></Table.Cell>
        <Table.Cell>
          <>
            <p className="small--text">
              {lang.table.createdAt[syslang]}:{" "}
              <Moment utc format="DD.MM.YY HH:mm">
                {dates && dates.issuedDate}
              </Moment>
            </p>
            <p className="small--text">
            {lang.table.taxdate[syslang]}:{" "}
              <Moment utc format="DD.MM.YY HH:mm">{dates && dates.taxDate}</Moment>
            </p>
            <p className="small--text">
            {lang.table.duedate[syslang]}:{" "}
              <Moment utc format="DD.MM.YY HH:mm">{dates && dates.dueDate}</Moment>
            </p>
          </>
        </Table.Cell>
        <Table.Cell>
          <>
            <p className="small--text">
            {lang.table.vattotal[syslang]}: {totals && totals.VAT_total.toFixed(2)}
            </p>
            <p className="small--text">
            {lang.table.nettotal[syslang]}: {totals && totals.NETTO_total.toFixed(2)}
            </p>
            <p className="small--text">
            {lang.table.bruttotoal[syslang]}: {totals && totals.BRUTTO_total.toFixed(2)}
            </p>
          </>
        </Table.Cell>
        <Table.Cell>
          {currency}
          {country && <div className="small--text">({country})</div>}
        </Table.Cell>
        {/* <Table.Cell>{country}</Table.Cell> */}
        {/* <Table.Cell>
          <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
        </Table.Cell> */}
       
        <Table.Cell>
        <Label color={label[isPaid]}>{status[isPaid]}</Label>
                </Table.Cell>
        {/* <Table.Cell>
          {isPaid && <Icon name="check" />}
          <Checkbox label='Is paid' checked={isPaid}
                    _id={_id}
                    onClick = {handleCheck}
            />
        </Table.Cell> */}
      </Table.Row>
    )
  );

  return (
    <Table unstackable basic="very">
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default BillsTable;
