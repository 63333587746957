import {
  UPDATE_ORDERS_FILTERS,
  GET_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  ADD_ORDER_UPDATES,
  CANCEL_ORDER_UPDATES,
  UPDATE_ORDER,
  UPDATE_ORDER_ADD_PRODUCT,
  UPDATE_ORDER_DELETE_PRODUCT,
  START_LOADING,
  STOP_LOADING
} from "../actions/actionTypes";

const initialState = {
  list: [],
  currentOrder: {},
  orderUpdates: {},
  isLoading: true,
  successMsg: { message: null },
  createOrderErrors: [],
  filters: {
    status: "Order Ready for picking",
    country:null,
    product:null,
    quantity: "",
    courier: null,
    itemsPerPage: 10,
    currentPage: 1,
    searchTerm: "",
    dateFrom: null,
    dateTo: null,
    sort: {
      byDate: "down"
      // byProduct: "down"
    }
  },
  totals: [],
  failedStatusChange: {
    message: "",
    status: null,
    failedOrders: []
  },
  invoice: {
    _id: "",
  },
};

const receivedOrder = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ORDERS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
          currentPage: Object.keys(action.payload).includes("currentPage")
            ? action.payload.currentPage
            : 1
        }
      };

    case GET_ORDERS:
      return {
        ...state,
        list: [...action.payload.list],
        totals: action.payload.totals,
        isLoading: false
      };

    case GET_ORDER:
      return {
        ...state,
        list: state.list.map(o =>
          o._id === action.payload._id ? action.payload : o
        ),
        currentOrder: action.payload,
        isLoading: false
      };

    case CREATE_ORDER:
      return {
        ...state,
        list: [...state.list, action.payload],
        currentOrder: action.payload,
        successMsg: { message: "The order was created" }
      };

    case ADD_ORDER_UPDATES:
      return {
        ...state,
        orderUpdates: { ...state.orderUpdates, ...action.payload }
      };

    case CANCEL_ORDER_UPDATES:
      return {
        ...state,
        orderUpdates: {}
      };

    case UPDATE_ORDER:
      return {
        ...state,
        isLoading: false,
        list: state.list.map(o =>
          o._id === action.payload._id ? action.payload : o
        ),
        currentOrder: action.payload,
        successMsg: { message: "The order was updated" },
        orderUpdates: {}
      };

    case UPDATE_ORDER_ADD_PRODUCT:
    case UPDATE_ORDER_DELETE_PRODUCT:
      return {
        ...state,
        list: state.list.map(o =>
          o._id === action.payload._id ? action.payload : o
        ),
        currentOrder: action.payload,
        successMsg: { message: "The order was updated" }
      };

    case "CANCEL_ORDER":
      return {
        ...state,
        isLoading: false,
        successMsg: { message: action.payload.status },
        currentOrder: action.payload.order
      };

    case "REMOVE_SUCCESS_MSG":
      return { ...state, successMsg: { ...state.succeasMsg, message: null } };

    case "CHANGE_ORDERS_STATUS":
      return {
        ...state,
        failedStatusChange: {
          ...action.payload
        }
      };

    case "MANUAL_ASSIGN_USER":
      // const updatedOrdersIds = action.payload.updatedOrders.map(
      //   ({ _id }) => _id
      // );
      // const newList = state.list.map(o =>
      //   updatedOrdersIds.includes(o._id)
      //     ? action.payload.updatedOrders[o._id]
      //     : o
      // );
      return {
        ...state,
        successMsg: { message: action.payload.message }
        // list: newList
      };

    case "RESET_ORDERS_STATUS":
      return {
        ...state,
        failedStatusChange: {
          message: "",
          status: null,
          failedOrders: []
        }
      };
      case "GET_INVOICE":
        return {
          ...state,
          invoice: action.payload,
        };
  
    

    case START_LOADING:
      return { ...state,
     
         isLoading: true };

    case STOP_LOADING:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default receivedOrder;
