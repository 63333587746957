import React from "react";
import { Table, Segment,Divider,Header } from "semantic-ui-react";
import ExportButton from "../../ExportButton";

// import { Link } from "react-router-dom";
// import Moment from "react-moment";

function InvoicesTable({ invoices,creditNotes }) {
  
  //   const handleCheck = (e, { checked, _id }) => {
  //     updateBill(_id, {
  //       isPaid: checked,
  //       status: paid[checked],
  //     });
  //   };
  //   if (isLoading)
  //     return (
  //       <div style={{ marginTop: "2em", marginBottom: "2em" }}>
  //         <Loader active inline="centered" />
  //       </div>
  //     );

  // if (!invoices.length) return null;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell></Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Count</Table.HeaderCell>
      <Table.HeaderCell textAlign="center">Currency</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">VAT Total</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Netto Total</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Brutto Total</Table.HeaderCell>
      <Table.HeaderCell textAlign="right">Action</Table.HeaderCell>
    </Table.Row>
  );

  const invoicesTable = invoices.map(
    (
      { _id: { month,year,country }, count, currency, BRUTTO_total, NETTO_total, VAT_total,list },
      i
    ) => (
      <Table.Row key={month}>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell textAlign="center">{count}</Table.Cell>
        <Table.Cell textAlign="center">{currency.join(", ")}</Table.Cell>
        <Table.Cell textAlign="right">{VAT_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right">{NETTO_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right">{BRUTTO_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right"> <ExportButton
                                                  total={count}
                                                  filters={list}
                                                  fileName={`invoices_${month}_${currency}`}
                                                  url="/invoices/export"
                                                  // mapData={mapData}
                                                />
            </Table.Cell>
      </Table.Row>
    )
  );

  const creditnotesTable = creditNotes.map(
    (
      { _id: { month,year,country }, count, currency, BRUTTO_total, NETTO_total, VAT_total,list },
      i
    ) => (
      <Table.Row key={month}>
        <Table.Cell>{country}</Table.Cell>
        <Table.Cell textAlign="center">{count}</Table.Cell>
        <Table.Cell textAlign="center">{currency?currency.join(", "):""}</Table.Cell>
        <Table.Cell textAlign="right">{VAT_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right">{NETTO_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right">{BRUTTO_total.toFixed(2)}</Table.Cell>
        <Table.Cell textAlign="right"> 
        <ExportButton
                                                  total={count}
                                                  filters={list}
                                                  fileName={`CNS_${month}_${currency}`}
                                                  url="/creditnotes/export"
                                                  // mapData={mapData}
                                                />
            </Table.Cell>
      </Table.Row>
    )
  );


  return (
    <Segment primary color="yellow">
      <Header>Invoices</Header>
      <Table unstackable basic="very">
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{invoicesTable}</Table.Body>
      </Table>
      
      {creditnotesTable.length>0&&
      <>
      <Header>Credit notes</Header>
      <Divider></Divider>

      <Table unstackable basic="very">
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{creditnotesTable}</Table.Body>
      </Table>
      </>}
    </Segment>
  );
}

export default InvoicesTable;
