import React from "react";
import { Label, Button } from "semantic-ui-react";

import { currency as currencyList,countries } from "../Filters/data";
import FormField from "./FormField";

function EditBankAccount({
  bankname,
  currency,
  accountNr,
  bankCode,
  IBAN,
  addressCity,
  addressCountry,
  i,
  bankAccountId,
  bclientId,
  // removeBankAccount,
  // updateBankAccount,
  handleUpdateWithType,
  updateBusinessClientBankAccount,
  syslang,lang,
  editable,
}) {
  const countriesOptions = countries.map((country, i) => ({
    key: i,
    value: country,
    text: country,
  }));

  const currencyOptions = currencyList.map((currency) => ({
    key: currency,
    value: currency,
    text: currency,
  }));

  const handleUpdate = (name, value) =>
    updateBusinessClientBankAccount({
      bclientId,
      accountId: bankAccountId,
      updatedBankAccount: {
        bankname,
        currency,
        accountNr,
        bankCode,
        addressCity,
        addressCountry,
        IBAN,
        [name]: value,
      },
    });

  return (
    <div className="list-item">
      <div className="list-item__label">
        <Label
          size="small"
          circular
          content={i + 1}
          style={{ display: "inline-block" }}
        />
        <br />
        {/* <Button
          compact
          circular
          basic
          className="list-item__delete-btn"
          size="small"
          icon="trash"
          onClick={() =>
            handleUpdateWithType({
              query: bankAccountId,
              type: "remove bank account",
            })
          }
        /> */}
      </div>

      <div className="view-grid">
        <FormField
          editable={editable}
          label={lang.table.bankdetails[syslang]}
          name="bankname"
          placeholder="bank name ..."
          value={bankname}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.table.country[syslang]}          
          name="addressCountry"
          placeholder="country ..."
          value={addressCountry}
          handleChange={handleUpdate}
          options={countriesOptions}
        />

        <FormField
          editable={editable}
          label={lang.table.city[syslang]}          
          name="addressCity"
          placeholder="city ..."
          value={addressCity}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.table.currency[syslang]}          
          name="currency"
          placeholder="currency ..."
          value={currency}
          handleChange={handleUpdate}
          options={currencyOptions}
        />

        <FormField
          editable={editable}
          label={lang.table.bankaccount[syslang]}          
          name="accountNr"
          placeholder="account Nr ..."
          value={accountNr}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label={lang.table.bankcode[syslang]}                    
          name="bankCode"
          placeholder="bank code ..."
          value={bankCode}
          handleChange={handleUpdate}
        />

        <FormField
          editable={editable}
          label="IBAN"
          name="IBAN"
          placeholder="IBAN ..."
          value={IBAN}
          handleChange={handleUpdate}
        />
      </div>
      {/* <Divider /> */}
    </div>
  );
}

export default EditBankAccount;
