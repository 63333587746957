

import React from "react";
import Moment from "react-moment";
// import 'moment-timezone';
import {Divider} from 'semantic-ui-react'

const Servicesummary = ({
  services: { foundServices}
}) => {
  // console.log(foundServices)
  if(foundServices.length===0) return <div>....//....</div>

  // const body = Object.entries(foundServices).map(([key, value]) => (
  //   <tr key={key}>
  //     <td>{key}</td>
  //     <td>{isNaN(value) ? value : value.toFixed(2)}</td>
  //   </tr>
  // ));
  const tables = foundServices.map(currencyGroups=>
    {
      let body =(<div></div>)
      if(currencyGroups.services) 
         body = currencyGroups.services.map(({service_details,total})=>
          <tr>
            <td>{service_details.name}</td>
            <td>{total.toFixed(2)}</td>
          </tr>
  )

      return ( 
        <div>        
          <Divider></Divider>
            <table>
            <tr>
              <td></td>
              <td style={{fontWeight:'bold'}}>{currencyGroups._id}</td>
            </tr>

                {body}
            <tr>
              <td style={{fontWeight:'bold'}}>Total:</td>
              <td style={{fontWeight:'bold'}}>{currencyGroups.grandTotal}</td>
            </tr>
          </table>
      </div>
      )
    }
     )
  return (
    <div>  
       {/* <table>
           <tr>
            <td  style={{fontWeight:'bold'}}>From:</td>
            <td  style={{fontWeight:'bold'}}>
            <Moment local format="DD-MM-YY HH:mm">{dateFrom}</Moment>
          </td>
        </tr>
        <tr>
          <td  style={{fontWeight:'bold'}}>To:</td>
          <td  style={{fontWeight:'bold'}}>
            <Moment utc format="DD-MM-YY HH:mm">{dateTo}</Moment>
          </td>
        </tr>
       
        </table> */}
      {tables}
      
      </div>
    
  );
};

export default Servicesummary;
