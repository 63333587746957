import React, { useState } from "react";
import { Icon, Input, Pagination } from "semantic-ui-react";
import { connect } from "react-redux";

import { getFilteredProductList,updateMultiple } from "../../../actions/product";

import ItemsPerPage from "../../ItemsPerPage";
import CardsList from "../../CardsList";
import Responsive from "../../Responsive";
import ReducedTable from "./ReducedTable";
import ExpandedTable from "./ExpandedTable";
import MobileExpandedItemCard from "./MobileExpandedItemCard";
import { useProductFilters } from "./useProductFilters";

function ProductsList(props) {
  const { products, total, getFilteredProductList, isLoading ,user,updateMultiple} = props;
  const [tableType, setTableType] = useState("reduced");
  const {
    searchTerm,
    itemsPerPage,
    currentPage,
    handleSearch,
    handleItemsPerPageChange,
    handlePageChange
  } = useProductFilters(getFilteredProductList);
  // console.log({ currentPage, itemsPerPage, searchTerm }, total);

  return (
    <>
      <div
        className="flex--between"
        style={{ marginBottom: "2em", textAlign: "right" }}
      >
        <Input
          loading={isLoading}
          placeholder="Search..."
          icon="search"
          value={searchTerm}
          onChange={handleSearch}
        />
        
        <div>
          <Icon
            name="list"
            color={tableType === "reduced" ? "teal" : "grey"}
            onClick={() => setTableType("reduced")}
          />
          <Icon
            name="list layout"
            color={tableType === "expanded" ? "teal" : "grey"}
            onClick={() => setTableType("expanded")}
          />
          <ItemsPerPage
            total={total}
            style={{ height: "1em", marginLeft: "1em" }}
            value={itemsPerPage}
            options={[10, 20, 30, 50, "All"]}
            handleChange={handleItemsPerPageChange}
          />
        </div>
      </div>

            <ReducedTable
              products={products}
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              user={user}
              updateMultiple={updateMultiple}
            />
      
      {tableType === "expanded" && (
        <Responsive
          mobileComponent={
            <CardsList
              items={products}
              isLoading={isLoading}
              notFoundMsg="No products found"
            >
              <MobileExpandedItemCard />
            </CardsList>
          }
          desktopComponent={
            <ExpandedTable
              user={user}
              products={products}
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
            />
          }
        />
      )}

      <div className="pagination-centered">
        {products.length > 0 && total > itemsPerPage && (
          <Pagination
            activePage={currentPage}
            totalPages={Math.ceil(total / itemsPerPage)}
            boundaryRange={1}
            siblingRange={1}
            firstItem={null}
            lastItem={null}
            onPageChange={handlePageChange}
          />
        )}
      </div>
    </>
  );
}
const mapStateToProps = reduxState => ({
  products: reduxState.inventory.products,
  total: reduxState.inventory.productsTotal,
  isLoading: reduxState.loading,
  user:reduxState.user
});
export default connect(mapStateToProps, {
  getFilteredProductList,updateMultiple
})(ProductsList);
