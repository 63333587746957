import React, { useEffect, useState } from "react";
import { Table, Button, Loader, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

import { getCourierQuotations } from "../../../actions/couriers";
import CardsList from "../../CardsList";
import Responsive from "../../Responsive";
import MobileItemCard from "./MobileItemCard";

function CourierQuotationsTable({
  courier,
  courierQuotations = {},
  isLoading,
  getCourierQuotations,
}) {
  useEffect(() => {getCourierQuotations(courier)}, [courier]);
  
  useEffect(()=>{
    
    let tableBody2=[]
      for(let key in courierQuotations ) {

        const item= courierQuotations[key]

           tableBody2 =[...tableBody2,item]
       
      } 
      

       setTableRows(tableBody2)

  },[courierQuotations])

  const [tableRows, setTableRows] = useState([]);
//  console.log(tableBody)
const tableBody = tableRows.map((item,index)=>(
  <Table.Row key={item._id} textAlign="left">
    <Table.Cell>{index+1}</Table.Cell>
    <Table.Cell>{item.country}</Table.Cell>
    <Table.Cell>{item.serviceType.name}</Table.Cell>
    <Table.Cell>{item.amount} {item.currency}</Table.Cell>
  </Table.Row>
))

  if (isLoading)
    return (
      <div style={{ position: "relative", padding: "4em" }}>
        <Loader active size="small" />
      </div>
    );

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      
      <Table.HeaderCell>Country</Table.HeaderCell>
      <Table.HeaderCell>Service</Table.HeaderCell>
      
      <Table.HeaderCell>Prices</Table.HeaderCell>
    </Table.Row>
  );

  


  if (!tableRows.length)
    return (
      <div className="center--text" style={{ padding: "2em" }}>
        <p>No quotations found</p>
      </div>
    );
  else
    return (
      <Responsive
        mobileComponent={
          <CardsList
            items={courierQuotations}
            isLoading={isLoading}
            notFoundMsg="No quotations found"
          >
            <MobileItemCard />
          </CardsList>
        }
        desktopComponent={
          <div className="mt--1">
            <Table basic="very">
              <Table.Header>{tableHeader}</Table.Header>
              <Table.Body>{tableBody}</Table.Body>
              
            </Table>
          </div>
        }
      />
    );
}

const mapStateToProps = (reduxState) => ({
  courierQuotations: reduxState.couriers.quotations,
  isLoading: reduxState.couriers.isLoading,
});

export default connect(mapStateToProps, {
  getCourierQuotations,
})(CourierQuotationsTable);
