import React from "react";
import {
  Table,
  Icon,
  Label,
  Header,
  Loader,
  Segment
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import lang from '../../../lang/config.json'

import CreateUpdateTime from "../../CreateUpdateTime";
import SortArrows from "./SortArrows";

export const statusColor = {
                  Error: "red",
                  "No goods": "orange",
                  "Order Ready for picking": "teal",
                  "Product Picking": "blue",
                  'Packed': "green",
                  'Draft': "yellow",
                  "Not shipped": "olive",
                  "Validated":'green',
                  'Client editing':'yellow',
                  'Canceled':'red'
};

function OrdersTable({
  orders,
  itemsPerPage,
  currentPage,
  user,
  actionColumn,
  isLoading,
  orderLink = "/orders/",
  action = "edit",
  sortable = true
}) {
  const tableHeader = (
    <Table.Row textAlign="left">
      <Table.HeaderCell>#</Table.HeaderCell> 
      
      <Table.HeaderCell>{lang.orders.list.table.columns.order[user.currentUser.sys_lang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.orders.list.table.columns.status[user.currentUser.sys_lang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>Client</Table.HeaderCell> */}
      <Table.HeaderCell>
      {lang.orders.list.table.columns.dates[user.currentUser.sys_lang]}
        {sortable && <SortArrows sortBy="byDate" />}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="left">
      {lang.orders.list.table.columns.items[user.currentUser.sys_lang]}
        {sortable && <SortArrows sortBy="byProduct" />}
      </Table.HeaderCell>
      <Table.HeaderCell>{lang.orders.list.table.columns.awb[user.currentUser.sys_lang]}</Table.HeaderCell>
      <Table.HeaderCell>{lang.orders.list.table.columns.courier[user.currentUser.sys_lang]}</Table.HeaderCell>
      {/* <Table.HeaderCell>Assigned User</Table.HeaderCell> */}
    </Table.Row>
  );

  const tableRows = orders.map(
    (
      {
        _id,
        clientOrderNr,
        status,
        createdAt,
        updatedAt,
        shipping,
        errMsgs,
        items,
        processing
      },
      index
    ) => {
      let errors = [];
      let products = [];
      if (errMsgs && errMsgs.length > 0) {
        errors = errMsgs.map(error => <div>{error}</div>);
      }

      if (items && items.length > 0) {
        products = items.map(item => {
          if (item) {
            
            const {stockAvailable} = item

            let name=item.name?item.name:"Na"
            const length =name.length

            if(length>15) name =`${name.substring(0,15)}...`
            return (
              <div style={{ whiteSpace: "pre" }}>
                  {!stockAvailable && <Icon name="attention" color="yellow" />}
                 {stockAvailable && <Icon name="checkmark" color="olive" />}
               {name} -{item.quantity}
              </div>
            );
          }
        });
      }

      return (
        
        <Table.Row key={_id} textAlign="left">
          <Table.Cell collapsing>
            {itemsPerPage * (currentPage - 1) + index + 1}
          </Table.Cell>
{/* 
          {checkColumn && (
            <Table.Cell>
              <Checkbox
                _id={_id}
                style={{ marginTop: "0.18em" }}
                checked={checkedItems.includes(_id)}
                onChange={(e, data) => handleSingleCheck(data._id)}
              />
            </Table.Cell>
          )} */}

          <Table.Cell>
            <Link to={orderLink + _id}>{clientOrderNr} </Link>
          </Table.Cell>

          <Table.Cell>
            <Label color={statusColor[status]} horizontal>
              {status}
            </Label>
            {processing.codTransfferId&&<div className='smallinfo'>COD Trasffer | {processing.codTransfferId.tranfferNr} </div>}
            {/* {processing && processing.isOpenUser && (
              <div class="smallinfo">open by: {processing.isOpenUser}</div>
            )}
            <br />
            <div className="smallinfo" style={{ maxWidth: "130px" }}>
              {errors}
            </div> */}
          </Table.Cell>
          {/* <Table.Cell>{businessclient && businessclient.name}</Table.Cell> */}
          <Table.Cell>
            <CreateUpdateTime createdAt={createdAt} updatedAt={updatedAt} />
          </Table.Cell>

          <Table.Cell textAlign="left">{products}</Table.Cell>
          <Table.Cell>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={shipping.trackingUrl}
            >
              {shipping.trackingId}
            </a>
          </Table.Cell>

          <Table.Cell>
            {shipping.courier ? shipping.courier.name : ""}
          </Table.Cell>

         
        </Table.Row>
      );
    }
  );

  if (!orders.length && !isLoading) {
    return (
      <Header
        style={{ paddingTop: "2em", paddingBottom: "1em" }}
        as="h4"
        textAlign="center"
        color="grey"
      >
       {lang.orders.list.table.noorders[user.currentUser.sys_lang]}
      </Header>
    );
  }
  return (
    <div className="table-wrapper--horizontal-scroll">
      <Segment>
      <Table
        // verticalAlign="center"
        fluid
        selectable
        // striped
        unstackable
        textAlign="left"
      >
        <Table.Header>{tableHeader}</Table.Header>
        <Table.Body>{!isLoading && orders.length && tableRows}</Table.Body>
      </Table>

      {isLoading && (
        <div style={{ marginTop: "2em", marginBottom: "2em" }}>
          <Loader active inline="centered" />
        </div>
      )}
      </Segment>
    </div>
    
  );
}

export default OrdersTable;
