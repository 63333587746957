import { useState, useEffect } from "react";

function Responsive({
  breakpoint = 600,
  desktopComponent = null,
  mobileComponent = null
}) {
  const [windowWidth, setWidth] = useState(null);

  const setWithFunct = () => {
    const width = window.innerWidth;
    setWidth(width);
  };

  useEffect(() => {
    setWithFunct();
    window.addEventListener("resize", setWithFunct);
    return () => window.removeEventListener("resize", setWithFunct);
  });

  if (windowWidth > breakpoint) return desktopComponent;
  else return mobileComponent;
}

export default Responsive;
