import {
  UPDATE_ORDERS_FILTERS,
  GET_ORDERS,
  GET_ORDER,
  CREATE_ORDER,
  ADD_ORDER_UPDATES,
  CANCEL_ORDER_UPDATES,
  UPDATE_ORDER,
  UPDATE_ORDER_ADD_PRODUCT,
  UPDATE_ORDER_DELETE_PRODUCT
} from "./actionTypes";
import { apiCall } from "../services/api";
import { addError, removeError } from "./error";
import { startLoading, stopLoading } from "./loading";
// import { logOutAction } from "./auth";
import {Alert} from 'rsuite'

// SUCCESS MSG
export const removeSuccessMsg = () => ({
  type: "REMOVE_SUCCESS_MSG"
});
// const addCreatedMsg = () => ({
//   type: "ADD_MSG_CREATED"
// });
// const addUpdatedMsg = () => ({
//   type: "ADD_MSG_UPDATED"
// });

export const updateFilter = newFilter => {
  return {
    type: UPDATE_ORDERS_FILTERS,
    payload: newFilter
  };
};

// ORDERS
export const getOrders = filters => dispatch => {
  dispatch(removeError());
  dispatch(startLoading());
  dispatch({type:"RESET_COUNTS"})
  const url = `${process.env.REACT_APP_API}/orders/filtered`;
  apiCall("post", url, filters)
    .then(resp => dispatch({ type: GET_ORDERS, payload: resp }))
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
      // if (err.code === 401) dispatch(logOutAction());
    });
};

export const getOrder = id => dispatch => {
  dispatch(removeError());
  dispatch(startLoading());
  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  apiCall("get", url)
    .then(resp => dispatch({ type: GET_ORDER, payload: resp }))
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const addUpdates = updateObj => {
  return {
    type: ADD_ORDER_UPDATES,
    payload: updateObj
  };
};

export const cancelUpdates = () => {
  return {
    type: CANCEL_ORDER_UPDATES
  };
};

export const updateOrder = (query, id) => dispatch => {
  dispatch(removeError());
  dispatch(startLoading());
  // dispatch(isLoading());
  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  apiCall("put", url, query)
    .then(resp => dispatch({ type: UPDATE_ORDER, payload: resp }))
    .catch(err => {
      dispatch(addError(err.message));
      dispatch(stopLoading());
    });
};

export const createOrder = (query, history) => dispatch => {
  dispatch(removeError());
  const url = `${process.env.REACT_APP_API}/orders`;
  apiCall("post", url, query)
    .then(resp => {
      dispatch({ type: CREATE_ORDER, payload: resp });
      history.push(`/orders/${resp._id}`);
    })
    .catch(err => dispatch(addError(err.message)));
};

export const addOrderProduct = (id, item) => dispatch => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/items`;
  apiCall("post", url, { item })
    .then(order => {
      dispatch({ type: UPDATE_ORDER_ADD_PRODUCT, payload: order });
    })
    .catch(err => dispatch(addError(err.message)));
};

export const deleteOrderProduct = ({orderId, itemId}) => dispatch => {
  const url = `${process.env.REACT_APP_API}/orders/${orderId}/items/${itemId}`;
  apiCall("delete", url)
    .then(order => {
      dispatch({ type: UPDATE_ORDER_DELETE_PRODUCT, payload: order });
    })
    .catch(err => dispatch(addError(err.message)));
};

// export const manualAssing = ({ orderIds, toUser }) => (dispatch, getState) => {
//   const { filters } = getState().orders;
//   dispatch(startLoading());
//   dispatch(removeError());
//   apiCall(`post`, `${process.env.REACT_APP_API}/orders/multiple/manualassign`, {
//     orderIds,
//     toUser
//   })
//     .then(resp => {
//       dispatch({ type: "MANUAL_ASSIGN_USER", payload: resp });
//       const url = `${process.env.REACT_APP_API}/orders`;
//       apiCall("post", url, filters)
//         .then(resp =>
//           dispatch({
//             type: GET_ORDERS,
//             payload: resp
//           })
//         )
//         .catch(err => dispatch(addError(err.message)));
//     })
//     .catch(error => {
//       dispatch(addError(error.message));
//       dispatch(stopLoading());
//     });
// };

export const cancelOrder = id =>  {
 return new Promise((resolve,reject)=>{
    const url = `${process.env.REACT_APP_API}/orders/${id}/cancel`;
    apiCall("post", url)
      .then(resp =>resolve(resp))
      .catch(err => reject(err.message))
 })

};
export const pauseOrder = id => dispatch => {
  dispatch(removeError());
    dispatch(startLoading());

  const url = `${process.env.REACT_APP_API}/orders/${id}/pause`;
  apiCall("post", url)
    .then(resp => dispatch({ type: "CANCEL_ORDER", payload: resp }))
    .catch(err => dispatch(addError(err.message)));
};

// export const changeStatus = body => (dispatch, getState) => {
//   const { filters } = getState().orders;
//   const url = `${process.env.REACT_APP_API}/orders/multiple/status`;
//   dispatch(removeError());
//   dispatch(startLoading());
//   apiCall("post", url, body) // body = {arrayOfId, newStatus}
//     .then(resp => dispatch({ type: "CHANGE_ORDERS_STATUS", payload: resp }))
//     .then(() => {
//       dispatch(getOrders(filters));
//     })
//     .catch(err => dispatch(addError(err.message)));
// };

// export const blockGoods = id =>dispatch=>{
//   const url = `${process.env.REACT_APP_API}/orders/${id}/blockgoods`;
//   apiCall("post", url)
//     .then(resp => dispatch({
//       type: UPDATE_ORDER,
//       payload: resp
//     }))
//     .catch(err => dispatch(addError(err.message)));
// }
// export const resetChangeStatus = () => ({ type: "RESET_ORDERS_STATUS" });

export const getInvoice = (id) => (dispatch) => {
  dispatch(removeError());
  // dispatch(startLoading());
  const url = `${process.env.REACT_APP_API}/invoices/${id}`;
  apiCall("get", url)
    .then((resp) => dispatch({ type: "GET_INVOICE", payload: resp }))
    .catch((err) => {
      dispatch(addError(err.message));
      // dispatch(stopLoading());
    });
};


export const Exportorders=({method='post',url,body})=>{
  return new Promise ((resolve,reject)=>{
      apiCall(method,url,body)
      .then(resp =>resolve(resp))
      .catch(error=>reject(error))
  })
}


//V2
export const getOrderV2 = (id) => {

  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  return apiCall("get", url)
    .then((resp) => (resp))
    .catch((err) => {
      Alert.error(err.message);
    });
}

export const addOrderProductV2 = (id, item) => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/items`;
  return apiCall("post", url, {
      item
    })
    .then((order) => order)
    .catch((err) => {
      Alert.error(err.message)
    });
};

export const deleteOrderProductV2 = ({
  orderId,
  itemId
}) => {
  const url = `${process.env.REACT_APP_API}/orders/${orderId}/items/${itemId}`;
  return apiCall("delete", url)
    .then((order) => order)
    .catch((err) => {
      Alert.error(err.message);
    });
}


export   const duplicateOrder = id => {
  const url = `${process.env.REACT_APP_API}/orders/${id}/duplicate`;

  return apiCall("get", url)
    .then(resp => window.open(`/orders/${resp._id}`))
    .catch(err => Alert.error(err.message));
};


export const getOfficesV2 = ({
  country,
  courierId
}) => {

  const url = `${process.env.REACT_APP_API}/orders/getoffices`;

  return apiCall("post", url, {
      country,
      courierId
    })
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message);
    });
}


export const orderActionV2 = ({
  action,
  orderId
}) => {

  const url = `${process.env.REACT_APP_API}/orders/${orderId}/action`;
  return apiCall("post", url, {
      action
    })
    .then((resp) => resp)
    .catch((err) => err);
};

export const updateOrderV2 = ({
  query,
  id
}) => {

  const url = `${process.env.REACT_APP_API}/orders/${id}`;
  return apiCall("put", url, query)
    .then((resp) => resp)
    .catch((err) => {
      Alert.error(err.message)
    });
};