export const dataSet = [
  {
    name: "Shoes",
    sku: 2306,
    barcode: 12345678,
    color: "black",
    size: "M",
    weight: ""
  }
];

export const colNames = [
  { label: "Name", value: "name" },
  { label: "SKU", value: "sku" },
  { label: "Barcode", value: "barcode" },
  { label: "Color", value: "color" },
  { label: "Size", value: "size" },
  { label: "Weight", value: "weight" }
];
