import React, {  useEffect } from "react";
import {  Segment, Header } from "semantic-ui-react";
import Images from './Images'
import { connect } from "react-redux";
// import Modal from "react-responsive-modal";

import {
  getShipment,
} from "../../../actions/inboundshipment";

import Responsive from "../../Responsive";
import CardsList from "../../CardsList";
import InfoBar from "../../InfoBar";
import Head from "../../Head";
import ShipmentInfo from "./ShipmentInfo";
// import Companyform from "../../CompanyForm";
// import Images from "./Images";
// import Items from "./Items";
import GoodsReceived from "../Items/GoodsReceived";
// import MobileItemCard from "../Items/MobileItemCard";
import lang from '../../../lang/config.json'
// import "../inbound.scss";

function ViewInboundShipment(props) {
  const {
    user,
    getShipment,
    openShipment,
    match,
    isLoading
  } = props;
  
  useEffect(() => getShipment(match.params.id), [getShipment,match.params.id]);
  
  

  return (
    <>
      <Responsive
        mobileComponent={
          <InfoBar header="Inbound Shipment" linkBack="/shipments/inbound/" />
        }
      />

      <main className="page-wrapper">
        <Responsive
          desktopComponent={
            <Head linkBack="/shipments/inbound/" header={lang.inboundshipments.title[user.currentUser.sys_lang]} />
          }
        />

        <Segment padded>
          <div className="flex--between">
            {openShipment._id && (
              <div className="smallinfo">ID: {openShipment._id.substr(-9)}</div>
            )}

            
          </div>

          <ShipmentInfo
            user={user}
            lang={lang}
            openShipment={openShipment}
            
          />

          {/* <Modal
          open={isCompanyModalOpen}
          showCloseIcon={true}
          closeOnEsc={true}
          onClose={toggleCompanyModal}
          center
          classNames={{
            modal: ""
          }}
        >
          <Companyform
            company={shipmentWithUpdates.client} //businessclient
            createCo={createCo}
            closeForm={toggleCompanyModal}
          />
        </Modal> */}
        </Segment>

      <Images
          openShipment={openShipment}
          isLoading={isLoading}
        />


        {/* <Items /> */}

    
            <GoodsReceived
              openShipment={openShipment}
              goodsreceived={openShipment.goodsreceived}
              // handleViewPhotos={this.handleViewPhotos}
            />
      </main>
    </>
  );
}

const mapStateToProps = reduxState => ({
  openShipment: reduxState.inboundshipments.openShipment,
  isLoading: reduxState.inboundshipments.isLoading,
  user:reduxState.user
});

export default connect(mapStateToProps, {
  
  getShipment,

})(ViewInboundShipment);
