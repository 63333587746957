import React, { useEffect,useState } from "react";
import {getNogoodsReport} from '../../../actions/reports'
import Nogoodstable from './table'
import {Segment,Header} from 'semantic-ui-react'

import { connect } from "react-redux";
import {updateFilters} from '../../../actions/filtersmain'
// import Filters from "./Filters";


function Nogoodsreport({getNogoodsReport,data,intranzit,updateFilters,filters}){

    
//  const  handleUpdateFilter=(e, { name, value }) =>{
//           updateFilters({[name]:value})
//   }
  
 const{country,businessclient,warehouse} =filters

    useEffect(()=>{
      getNogoodsReport({country,businessclient,shipFrom:warehouse})},[businessclient,country,warehouse])

      console.log(data)
    const tables = data.map(item =><Nogoodstable data={item} intranzit={intranzit}></Nogoodstable>)

    return(
        <main className="page-wrapper">
            <Header>No Goods Report</Header>
              {/* <Filters
                    filters={filters}
                    handleUpdateFilter={handleUpdateFilter}   
              ></Filters> */}
            <Segment>
              {tables}
            </Segment>
          
            </main>
    )
}
function mapStateToProps(reduxState){
    return ({
        filters: reduxState.filtersmain,
        data:     reduxState.reports.noGoods.report,
        // intranzit:reduxState.reports.noGoods.intranzit,
    })
}

export default connect(mapStateToProps,{getNogoodsReport,updateFilters})(Nogoodsreport)