import React, { useState, useEffect } from "react";
import {
  Header,
  Icon,
  Checkbox,
  Button,
  Loader,
  Dimmer,
} from "semantic-ui-react";
import { connect } from "react-redux";

import {
  // getBusinessProfile,
  
  updateBusinessProfile,
} from "../../actions/businessclient";
// import EditableText from "../EditableText";
import "./profile-settings.scss";

function UserProfileSettings(props) {
  const {
    settings,
    isLoading,
    // getBusinessProfile,
    createBusinessProfile,
    updateBusinessProfile,
  } = props;

  // useEffect(() => {
  //   getBusinessProfile();
  // }, []);

  const handleCreateProfileSettings = () => {
    createBusinessProfile();
  };

  const handleBusinessProfileUpdate = (e, { name, checked }) => {
    updateBusinessProfile({ [name]: checked });
  };

  if (isLoading && !settings)
    return (
      <main className="page-wrapper">
        <Dimmer active inverted>
          <Loader inverted size="medium">
            Loading
          </Loader>
        </Dimmer>
      </main>
    );

  if (!(settings && settings._id))
    return (
      <main className="page-wrapper">
        <section className="profile-settings">
          <Header as="h3">No profile settings saved yet</Header>
          <Button onClick={handleCreateProfileSettings}>Add settings</Button>
        </section>
      </main>
    );

  return (
    <main className="page-wrapper">
      <section className="profile-settings">
        <Header as="h3">Business client profile settings</Header>
        <Reveal
          header={"Orders settings"}
          subHeader={"Invoice generation, goods blocking, courier assignment"}
          body={
            <ul className="settings-list">
              <li>
                <Checkbox
                  toggle
                  name="orders.invoicing.autoGenerate"
                  checked={settings.orders.invoicing.autoGenerate}
                  onChange={handleBusinessProfileUpdate}
                />
                Generate invoice automatically
              </li>
              <li>
                <Checkbox
                  toggle
                  name="orders.automaticBlockingGoods"
                  checked={settings.orders.automaticBlockingGoods}
                  onChange={handleBusinessProfileUpdate}
                />
                Automatically block goods
              </li>
              <li>
                <Checkbox
                  toggle
                  name="orders.shipping.automaticAssingCourier"
                  checked={settings.orders.shipping.automaticAssingCourier}
                  onChange={handleBusinessProfileUpdate}
                />
                Automatically assign courier
              </li>
            </ul>
          }
        />
        <Reveal
          header={"Email notifications"}
          subHeader={"Choose to recieve email notifications or not"}
          body={
            <ul className="settings-list">
              <li>
                <Checkbox
                  toggle
                  name="notifications.email.newPo"
                  checked={settings.notifications.email.newPo}
                  onChange={handleBusinessProfileUpdate}
                />
                New Purchase Order
              </li>
              <li>
                <Checkbox
                  toggle
                  name="notifications.email.shippingPo"
                  checked={settings.notifications.email.shippingPo}
                  onChange={handleBusinessProfileUpdate}
                />
                Shipping Purchase Order
              </li>
            </ul>
          }
        />

        <Reveal
          header={"Dashboard notifications"}
          subHeader={
            "Choose what kind of notifications to see on your dashboard"
          }
          body={
            <ul className="settings-list">
              <li>
                <Checkbox
                  toggle
                  name="notifications.dashboard.tickets.newticket"
                  checked={
                    settings.notifications.dashboard &&
                    settings.notifications.dashboard.tickets.newticket
                  }
                  onChange={handleBusinessProfileUpdate}
                />
                New Purchase Order
              </li>
              <li>
                <Checkbox
                  toggle
                  name="notifications.dashboard.tickets.newmessage"
                  checked={
                    settings.notifications.dashboard &&
                    settings.notifications.dashboard.tickets.newmessage
                  }
                  onChange={handleBusinessProfileUpdate}
                />
                Shipping Purchase Order
              </li>
            </ul>
          }
        />
      </section>
    </main>
  );
}

function Reveal({ header, subHeader, body }) {
  const [opened, setOpened] = useState(false);
  const toggleState = () => setOpened((prev) => !prev);

  return (
    <div className="reveal">
      <div className="reveal__header" onClick={toggleState}>
        <div>
          <p className="large--text"> {header}</p>
          <span className="small--text grey--text">{subHeader}</span>
        </div>
        <Icon
          name={`angle ${opened ? "up" : "down"}`}
          size="large"
          color="grey"
          className="reveal__header-toggle"
        />
      </div>

      {opened && <div className="reveal__body">{body}</div>}
    </div>
  );
}

const mapStateToProps = (reduxState) => ({
  settings: reduxState.businessclients.businessclient.settings,
  isLoading: reduxState.businessclients.isLoading,
});

export default connect(mapStateToProps, {
  // getBusinessProfile,
  updateBusinessProfile,
})(UserProfileSettings);
