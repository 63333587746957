const initialState={
        isAuthenticated:false,
        currentUser: {
            sys_lang:'CZ'
        },
        permissions:{
           
            permittedRoles:[],
            canCreateRoles: []
        },
        notifications:[]
}

 const currentUser = (state=initialState,action)=>{
    switch (action.type) {
        
        case 'SET_CURRENT_USER':
            
            return {
                ...state,
                isAuthenticated: true, 
                currentUser:{...action.currentUser},
            }
      case 'UPDATE_PROCESSED_ORDERS':
            const {lastProcessedOrders}=action.data
      return {
            ...state,
            currentUser:{...state.currentUser,
                        lastProcessedOrders
            }
      }
      case 'SET_PERMISSIONS':
      return { ...state,
        
        permissions:action.permissions,

      }
      case 'USER_ADD_BUSINESSCLIENT':
          console.log('adding buss:',action.payload)
            return {
                ...state,
                currentUser:{...state.currentUser,
                                 businessclient:action.payload
                        }
            }
      case 'GET_NOTIFICATIONS':
          return {
              ...state,
              notifications:action.notifications
          }
        default:
            return state;
    }
}

export default currentUser