import React from "react";
import { Header, Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { openFilters } from "../actions/filters";

function InfoBar(props) {
  const {
    linkBack,
    header,
    linkCreateNew,
    buttons,
    filters,
    openFilters,
  } = props;

  return (
    <header className="info-bar">
      {/* Navigate Back - Arrow */}
      <div className="info-bar--group flex--left">
        {linkBack && (
          <Link to={linkBack} style={{ marginRight: "1em" }}>
            <Icon name="arrow left" />
          </Link>
        )}
        {/* Header */}
        <Header as="h1">{header}</Header>
        {/* Create New - Button */}
        {linkCreateNew && (
          <Link to={linkCreateNew}>
            <Button circular basic compact color="grey" size="tiny">
              <Icon name="plus" size="small" />
              
            </Button>
          </Link>
        )}
        {buttons}
      </div>
      {/* Filters */}
      {filters && (
        <Button
          className="info-bar--filters-btn"
          circular
          size="small"
          color="blue"
          icon="sliders horizontal"
          onClick={openFilters}
        />
      )}
    </header>
  );
}

export default connect(null, { openFilters })(InfoBar);
