import React from "react";
import { Table, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

function CourierTable({ couriers }) {
  if (!couriers.length)
    return <p className="center--text">No couriers found</p>;

  const tableHeader = (
    <Table.Row>
      <Table.HeaderCell textAlign="right">#</Table.HeaderCell>
      <Table.HeaderCell>Name</Table.HeaderCell>
      <Table.HeaderCell collapsing>Default</Table.HeaderCell>
      <Table.HeaderCell>Country</Table.HeaderCell>
      {/* <Table.HeaderCell>ID</Table.HeaderCell> */}
    </Table.Row>
  );
  console.log(couriers);
  const tableBody = couriers.map(({ _id, name, country, isDefault }, index) => (
    <Table.Row key={index}>
      <Table.Cell textAlign="right" verticalAlign="middle">
        {index + 1}
      </Table.Cell>
      <Table.Cell verticalAlign="middle">
        <Link to={`/couriers/${_id}`}>
          <span className="bold--text x-large--text">{name}</span>
        </Link>
      </Table.Cell>
      <Table.Cell verticalAlign="middle" collapsing>
        <span>{isDefault ? <Icon name="check" color="grey" /> : ""}</span>
      </Table.Cell>
      <Table.Cell verticalAlign="middle">
        <span>{country}</span>
      </Table.Cell>
      {/* <Table.Cell>{_id}</Table.Cell> */}
    </Table.Row>
  ));
  return (
    <Table unstackable basic="very" selectable>
      <Table.Header>{tableHeader}</Table.Header>
      <Table.Body>{tableBody}</Table.Body>
    </Table>
  );
}

export default CourierTable;
