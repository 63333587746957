import React from "react";
import { Dropdown } from "semantic-ui-react";
import {DatePicker} from "rsuite";

import { shipmentStatusOptions } from "../../../setup";
import { createOptions } from "../../../helpers/createOptions";
import ProductFilter from "../../Filters/ProductFilter";

function MobileInboundFilters(props) {
  const {
    companies,
    warehouses,
    internFilters,
    handleDropdownChange,
    handleDateChange,
    user,
    lang
  } = props;

  return (
    <>
      <Dropdown
        fluid
        search
        placeholder={lang.filters.status[user.currentUser.sys_lang]}
        value={internFilters.status}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={shipmentStatusOptions}
        closeOnChange={true}
        name="status"
      />

      <Dropdown
        multiple
        fluid
        search
        placeholder={lang.filters.supplier[user.currentUser.sys_lang]}
        value={internFilters.seller}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={createOptions(companies)}
        closeOnChange={true}
        name="seller"
      />

      <Dropdown
        search
        multiple
        fluid
        placeholder={lang.filters.warehouse[user.currentUser.sys_lang]}
        value={internFilters.warehouse}
        selectOnBlur={false}
        clearable
        selection
        onChange={handleDropdownChange}
        options={createOptions(warehouses)}
        closeOnChange={true}
        name="warehouse"
      />

      <ProductFilter
        value={internFilters.product}
        multiple
        fluid
        handleChange={handleDropdownChange}
      />

      <DatePicker
        selected={internFilters.dateFrom}
        isClearable={true}
        dateFormat="MMMM d, yyyy"
        onChange={date => handleDateChange({ dateFrom: date })}
        placeholder={lang.filters.datepicker.from[user.currentUser.sys_lang]}
        />

      <DatePicker
        selected={internFilters.dateTo}
        isClearable={true}
        dateFormat="MMMM d, yyyy"
        onChange={date => handleDateChange({ dateTo: date })}
        placeholder={lang.filters.datepicker.to[user.currentUser.sys_lang]}
        />
    </>
  );
}

export default MobileInboundFilters;
